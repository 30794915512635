import { Col, Form, Radio, Row, Switch } from "antd";
import * as React from "react";
import FroalaEditor from "react-froala-wysiwyg";
import { BoxMsg, DialogConfirm, fontFamily, froalaconfigs, pluginsEnabled, quickInsertButtons, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import { ButtonSave } from "../Systems/button_all";
import { useHistory } from "react-router";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import AuthenService from "../Service/AuthenService";
const Controller = 'api/AdminInstitution/';


const Admin_Institution_Edit = () => {
    const [sDetailTH, setsDetailTH] = React.useState("");
    const [sDetailEN, setsDetailEN] = React.useState("");
    const [ModeEN, setModeEN] = React.useState("" as string);
    const history = useHistory();
    var sID = "1";
    const [V_Institution, setV_Institution] = React.useState({} as any);
    const [SetValue] = Form.useForm();
    console.log(sDetailTH, "setsDetailTH")

    var handleModelChangeTH = (model: any) => {
        setsDetailTH(model);
        SetValue.setFieldsValue({
            sDetailTH: model,
        });
    };
    var handleModelChangeEN = (model: any) => {
        setsDetailEN(model);
        SetValue.setFieldsValue({
            sDetailEN: model,
        });
    };

    var configs = {
        placeholderText: "",
        attribution: false,
        key: "0BA3jA11B5C7C4A4D3aIVLEABVAYFKc2Cb1MYGH1g1NYVMiG5G4E3C3A1C8A6D4A3B4==",
        imageUploadURL: process.env.REACT_APP_URL + "api/UploadTextEditor/uploadImage",
        videoUploadURL: process.env.REACT_APP_URL + "api/UploadTextEditor/uploadImage",
        quickInsertButtons: quickInsertButtons,
        pluginsEnabled: pluginsEnabled,
        events: {
            'html.set': async function () {
                let editor: any = this
                let UserInfo: any = await AuthenService.UserInfo();
                if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
                    if (UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu === 3).map(t => t.nPermission)[0] === 2) {
                        editor.edit.off()
                    }
                }
            },
        },
        fontFamily: fontFamily,
        fontFamilySelection: true
    };

    React.useEffect(() => {
        GetDataOnPageLoad();
        GetUserInfo();
    }, []);


    const [isEdit, setisEdit] = React.useState(false);
    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            setisEdit(UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == 3).map(t => t.nPermission)[0] === 2 ? false : true)
        }

    }


    const GetDataOnPageLoad = async () => {
        let result: any = await AxiosGetJson(Controller + "GetV_InstitutionbysID?sID=" + (sID === undefined ? "0" : sID));
        setModeEN(result.sLanguage)
        setV_Institution(result);
        SetValue.setFieldsValue({
            sLanguage: result.sLanguage,
            sNameTH: "",
            sNameEN: "",
            switchStatus: result.isActive,
        })
        setsDetailTH(result.sDetailTH);
        setsDetailEN(result.sDetailEN);

    }









    const onSave = (values: any) => {


        V_Institution.sLanguage = ModeEN;
        V_Institution.sNameTH = "";
        V_Institution.sNameEN = "";
        V_Institution.sDetailTH = sDetailTH;
        V_Institution.sDetailEN = sDetailEN;
        V_Institution.isActive = values.switchStatus;

        DialogConfirm(async () => {
            let result: any = await AxiosPostJson(Controller + "Savedata", V_Institution)
            if (result.data.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Desc_Success_Save, BoxMsg.Desc_Success_Save, () => {
                    //onBack();
                });
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }


        }, null, BoxMsg.Title_Confirm, BoxMsg.Desc_Confirm_Save)


    }
    const ValidateDisabledField = (value: any) => {
        setModeEN(value.target.value);

        //if (sID === undefined) {

        // SetValue.setFieldsValue({
        //     sNameEN: "",
        // })
        // setsDetailEN("");

        //}


    };

    return (
        <React.Fragment>
            <Form onFinish={onSave}
                form={SetValue}
            >
                <br />
                <Row gutter={[16, 16]}>
                    <Col xl={12} md={12} xs={24} >
                        <b> มีเนื้อหาภาษาอังกฤษหรือไม่ </b>
                        <br />

                        <Radio.Group disabled={!isEdit} onChange={ValidateDisabledField} value={ModeEN}>
                            <Radio value="EN">ใช่</Radio>
                            <Radio value="TH">ไม่ใช่</Radio>
                        </Radio.Group>
                    </Col>

                </Row>
                <br />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <b>รายละเอียด </b><span className="text-danger ">*</span><span className="text-primary small"> ภาษาไทย </span>
                        <Form.Item
                            name="sDetailTH"
                            rules={[
                                { required: sDetailTH === "" ? true : false, message: BoxMsg.Desc_Required_Field + "รายละเอียด" },
                            ]}
                        >
                            <FroalaEditor
                                model={sDetailTH}
                                config={configs}
                                onModelChange={handleModelChangeTH}
                            />
                        </Form.Item>
                    </Col>
                </Row>


                {
                    ModeEN === "EN" ?
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <b>รายละเอียด</b> <span className="text-danger ">*</span><span className="text-success small"> ภาษาอังกฤษ </span>
                                <Form.Item
                                    name="sDetailEN"
                                    rules={[
                                        { required: sDetailEN === "" ? true : false, message: BoxMsg.Desc_Required_Field + "รายละเอียด" },
                                    ]}
                                >
                                    <FroalaEditor
                                        model={sDetailEN}
                                        config={configs}
                                        onModelChange={handleModelChangeEN}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        : null
                }


                <br />


                {
                    //<Row gutter={[16, 16]}>
                    //    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    //        <b>สถานะ</b>
                    //        <Form.Item name="switchStatus" valuePropName="checked">
                    //            <Switch disabled={!isEdit} checkedChildren="ใช้งาน" unCheckedChildren="ไม่ใช้งาน" />
                    //        </Form.Item>
                    //    </Col>
                    //</Row>
                }
                <hr />
                {isEdit ?
                    <Row gutter={[16, 16]} justify="center">
                        <Col >
                            <Form.Item >
                                <ButtonSave disabled={!isEdit} htmlType="submit" />
                            </Form.Item>
                        </Col>
                    </Row>
                    : null}
            </Form>
        </React.Fragment>
    );
};
export default Admin_Institution_Edit;
