import * as React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.css";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  thailanguage,
  englishlanguage,
  currentlanguage,
} from "../app/counterSlice";
import { Button, Col, Modal, Row, Tooltip, Slider as AntdSlider } from "antd";
import ReactPlayer from "react-player";
import { useRef } from "react";

const Page = React.forwardRef((props: { number : any , children : any} , ref: any) => {
  return (
    <div className="demoPage" ref={ref} >
      <img src={props.children} style={{ maxWidth: "100%", maxHeight: "100%", height: "auto"}} />
    </div>
  );
});

//export default class Home extends React.PureComponent<any, any>{
const Home = () => {
  var HomeBanner_Settings = {
    infinite: true,
    swipeToSlide: true,
    speed: 1000,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: false,
    arrows: true,
  };
  var ActivityList_Settings = {
    infinite: false,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  let dateslick_loaded = false;

  var DateList_Settings = {
    infinite: false,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 32,
    slidesToScroll: 1,
    autoplay: false,
    dots: false,
    arrows: true,
    useTransform: false,
    onReInit: () => {
      if (!dateslick_loaded) {
        let test = document.getElementById("DATE_CALENDAR");
        if (test) {
          let track = test.getElementsByClassName("slick-track");
          if (track.length > 0) {
            (track[0] as HTMLDivElement).style.left = "0";
            dateslick_loaded = true;
          }
        }
      }
    },
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 20,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 15,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 7,
        },
      },
    ],
  };
  var BookingList_Settings = {
    infinite: true,
    swipeToSlide: true,
    speed: 1000,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    arrows: false,
  };

  const history = useHistory();
  const translate = useSelector(currentlanguage);
  const dispatch = useDispatch();

  const myRef = React.useRef(null);
  const [sFilePath, setFilePath] = React.useState("");
  const [lstBanner, setLstBanner] = React.useState([] as any);
  const [sFilePathInfo, setFilePathInfo] = React.useState("" as any);
  const [lstNews, setLstNews] = React.useState([] as any);
  const [lstBooking, setLstBooking] = React.useState([] as any);
  const [lstEvent, setLstEvent] = React.useState([] as any);
  const [lstMonthDate, setLstMonthDate] = React.useState([] as any);
  const [learningSelected, setLearningSelected] = React.useState("All");
  const [lstIndex, setLstIndex] = React.useState([] as any);
  const [sPathWeb, setPathWeb] = React.useState("");
  const SetBackground = async () => {
    let result: any = await AxiosGetJson("api/AdminInfo/GetBackground");
    setFilePath(result.sFilePath);
  };

  const SetBanner = async (TranslatType) => {
    let result: any = await AxiosGetJson(
      "api/AdminInfo/GetBanner?Mode=" + TranslatType
    );
    setLstBanner(result);
  };

  const GetInfoGraphics = async (TranslatType) => {
    let result: any = await AxiosGetJson(
      "api/AdminInfo/GetInfoGraphicsHome?Mode=" + TranslatType
    );
    setFilePathInfo(result);
  };

  const GetLastestNews = async (TranslatType) => {
    let result: any = await AxiosGetJson(
      "api/AdminInfo/GetLastestNews?Mode=" + TranslatType
    );
    setLstNews(result);
  };

  // const onOpenNews = (sUrl) => {
  //   let sTypeComponent = "/news-detail";
  //   let sPath = `${sTypeComponent}?id=${sUrl}&ref=home`;
  //   return history.push(sPath);
  // };

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isModalVisibleFile, setIsModalVisibleFile] = React.useState(false);
  const [sPath, setsPath] = React.useState(("" as string) || undefined);
  const [lv1, setLv1] = React.useState(("" as string) || undefined);
  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const [isLoad, setIsLoad] = React.useState(false);
  const [nLvZoom, setLvZoom] = React.useState(0);
  const [lstImagePDF, setLstImagePDF] = React.useState([] as any);
    

  const handleOk = () => {
    setIsModalVisible(false);
    setsPath(undefined);
    setLv1(undefined);
    setIsModalVisibleFile(false);
    setIsFullScreen(false);
    setLstImagePDF([]);
    setLvZoom(0);
};

  const onOpenNews = async (i) => {
    await AxiosGetJson("api/AdminInfo/UpdateViewContent?id=" + i.sUrl);
    let sTypeComponent = "";
    if (i.nHeadLv0 === "1") {
        sTypeComponent = "/news-detail";
    }
    if (i.nHeadLv0 === "2") {
        sTypeComponent = "/forest-story-detail";
    }
    if (i.nHeadLv0 === "3") {
        sTypeComponent = "/learning-center-detail";
    }
    if (i.nHeadLv0 === "4") {
        sTypeComponent = "/hl-project-detail";
    }
    if (i.nHeadLv0 === "5") {
        if (i.nHeadLv1 === "16") { //law
            setIsModalVisibleFile(true);
            setsPath(i.sFileRef);
            setLv1(i.nHeadLv1);
        } else if (i.nHeadLv1 === "14") { //ebook
            setIsModalVisibleFile(true);
            setsPath(i.sFileRef);
            setLstImagePDF(i.lstImagePDF);
            setLv1(i.nHeadLv1);
        } else if (i.nHeadLv1 === "11") {
            sTypeComponent = "/knowledge-detail-plant";
        } else if (i.nHeadLv1 === "12") {
            sTypeComponent = "/knowledge-detail-animal";
        } else if (i.nHeadLv1 === "13") { //video
            setsPath(i.sUrlRef === "" ? i.sFileRef : i.sUrlRef);
            setLv1(i.nHeadLv1);
            setIsModalVisible(true);
        } else {
            sTypeComponent = "/knowledge-detail";
        }
    }
    if (sTypeComponent !== "") {
        let sPath = `${sTypeComponent}?id=${i.sUrl}&ref=home`;
        //return history.push(sPath);
        return window.open(sPath, "_self");
    } else {
        return;
    }
};


  const GetBookingList = async (TranslatType) => {
    let result: any = await AxiosGetJson("api/AdminInfo/GetBookingList?Mode=" + TranslatType);
    setLstBooking(result);
  };

  const GetEventList = async (TranslatType) => {
    let result: any = await AxiosGetJson(
      "api/AdminInfo/GetEventList?Mode=" + TranslatType
    );
    setLstEvent(result);
  };

  const GetMonthDate = async (TranslatType) => {
    let result: any = await AxiosGetJson(
      "api/AdminInfo/GetMonthDate?Mode=" + TranslatType
    );
    setLstMonthDate(result);
  };

  const onOpenBooking = (o) => {
    window.open(o.sBookingUrl, "_blank");
  };

  const GetListIndex = async (TranslatType) => {
    let result: any = await AxiosGetJson(
      "api/AdminInfo/GetListIndex?type=5&Mode=" + TranslatType
    );
    setLstIndex(result);
  };

  const onFilterLvent = async (sTypeID) => {
    let result: any = await AxiosGetJson(
      "api/AdminInfo/GetEventListFilter?Mode=" +
        translate.TranslatType +
        "&sTypeID=" +
        sTypeID
    );
    setLstEvent(result);
    GetMonthDateFilter(sTypeID);
  };

  const GetMonthDateFilter = async (sTypeID) => {
    let result: any = await AxiosGetJson(
      "api/AdminInfo/GetMonthDateFilter?Mode=" +
        translate.TranslatType +
        "&sTypeID=" +
        sTypeID
    );
    setLstMonthDate(result);
  };

  const GetPathWeb = async () => {
    let result: any = await AxiosGetJson("api/AdminInfo/GetUrlPath");
    setPathWeb(result);
}

  React.useEffect(() => {
    window.scrollTo(0, 0);
    SetBackground();
    SetBanner(translate.TranslatType);
    GetInfoGraphics(translate.TranslatType);
    GetLastestNews(translate.TranslatType);
    GetBookingList(translate.TranslatType);
    GetEventList(translate.TranslatType);
    GetMonthDate(translate.TranslatType);
    GetListIndex(translate.TranslatType);
    GetPathWeb();
  }, [translate]);

  const customSlider = useRef();

  const [autoPlayOn, setAutoPlayOn] = React.useState<boolean>(true);
  // const sliderRef = useRef<typeof Slider | null>(null);
  let sliderRef = React.useRef<Slider>(null);

  // React.useEffect(() => {
  //     // sliderRef.current.slickPlay();
  //     let test = document.getElementById("testcalenda");
  //     console.log(test);
  //     if(test)
  //     {

  //         let track = test.getElementsByClassName("slick-track");
  //         console.log(track);
  //         if(track.length > 0)
  //         {
  //             console.log("1");
  //             (track[0] as HTMLDivElement).style.left = "0"
  //         }
  //     }
  // }, [lstMonthDate])

  const previous = () => {
    // customSlider.current.slickPrev()
    sliderRef.current.slickPlay();
  };

  const prev = () => {
    sliderRef.current?.slickPrev();
  };
  const next = () => {
    sliderRef.current?.slickNext();
  };

  return (
    <div id="PAGE_HOME" style={{ backgroundImage: "url(" + sFilePath + ")" }}>
      <div className="home-banner">
        {/* <div className="logo-reforest"></div> */}
        <Slider {...HomeBanner_Settings}>
          {lstBanner.length > 0
            ? lstBanner.map((i, indx) => {
                return (
                  <div key={indx}>
                    {i.isVideo ? (
                      <div className="slick-item" style={{backgroundColor: "black"}}>
                        <ReactPlayer
                          key={"vdo_" + i.sUrl}
                          url={i.sUrl}
                          width="85%"
                          height="100%"
                          controls
                          style={{ marginLeft: "auto", marginRight: "auto" }}
                        />
                      </div>
                    ) : (
                      <div
                        className="slick-item"
                        style={{ backgroundImage: "url(" + i.sPathFile + ")" }}
                      >
                        <>
                          <div className="banner-content">
                            {/* <div style={{ fontSize: "1.5em", fontWeight: 500 }}>{translate.TranslatType === "TH" ? translate.Title1 : "PTT " + translate.Title1}&nbsp;{translate.Title2}</div> */}
                            {/*<div style={{ fontSize: "1.2em" }}>และระบบนิเวศ ปตท.</div>*/}
                            {/*<div style={{ fontSize: "1em", padding: "0.5em 0", borderTop: "1px solid #ffffff", borderBottom: "1px solid #ffffff", margin: "0.5em 0" }}>ศูนย์การเรียนรู้ป่าในกรุง</div>*/}
                            {/*<div style={{ fontSize: "0.8em", fontWeight: 500 }}>จอง / เยี่ยมชม ออนไลน์</div>*/}
                            {/*<div style={{ fontSize: "0.8em" }}>เปิดบริการแล้ว / สิงหาคม 2564</div>*/}
                          </div>
                          {i.sUrlGoto !== "" ? (
                            <a
                              className="banner-link"
                              href={i.sUrlGoto}
                              target="_blank"
                            >
                              <i className="icofont-arrow-right mr-2"></i>
                              {translate.More}
                            </a>
                          ) : null}
                        </>
                      </div>
                    )}
                  </div>
                );
              })
            : null}
          {/*<>
                        <div className="slick-item" style={{ backgroundImage: "url(" + require("../../Images/home/example/banner/banner1.jpg") + ")" }}>
                            <div className="banner-content">
                                <div style={{ fontSize: "1.5em", fontWeight: 500 }}>สถาบันปลูกป่า</div>
                                <div style={{ fontSize: "1.2em" }}>และระบบนิเวศ ปตท.</div>
                                <div style={{ fontSize: "1em", padding: "0.5em 0", borderTop: "1px solid #ffffff", borderBottom: "1px solid #ffffff", margin: "0.5em 0" }}>ศูนย์การเรียนรู้ป่าในกรุง</div>
                                <div style={{ fontSize: "0.8em", fontWeight: 500 }}>จอง / เยี่ยมชม ออนไลน์</div>
                                <div style={{ fontSize: "0.8em" }}>เปิดบริการแล้ว / สิงหาคม 2564</div>
                            </div>
                            <a className="banner-link">
                                <i className="icofont-arrow-right mr-2"></i>ดูเพิ่มเติม
                            </a>
                        </div>
                    </>
                    <><div className="slick-item" style={{ backgroundImage: "url(" + require("../../Images/home/example/banner/banner2.jpg") + ")" }}></div></>
                    <><div className="slick-item" style={{ backgroundImage: "url(" + require("../../Images/home/example/banner/banner3.jpg") + ")" }}></div></>*/}
        </Slider>
      </div>
      {
        lstNews.length > 0 ?
        <div className="home-news">
        {/* <div className="news-title">
                        <div className="title-icon">
                            <i className="icofont-bullhorn"></i>
                        </div>
                        <div className="title-label">
                            <div className="title-secondary">ข่าว</div>
                            <div className="title-primary">ประชาสัมพันธ์</div>
                        </div>
                    </div> */}
        <div className="news-panel">
          <div className="panel-head">
            <div className="panel-title">{translate.LastestNews}</div>
            <Link
              to={"/search?showType=All&Mode=" + translate.TranslatType}
              className="panel-link"
            >
              <div className="link-label">{translate.More}</div>
              <div className="link-icon">
                <i className="icofont-thin-right"></i>
              </div>
            </Link>
          </div>
          <div className="panel-body">
            {lstNews.length > 0
              ? lstNews.map((i, indx) => {
                  return (
                    <div
                      key={"new" + indx}
                      className={
                        indx === 0 ? "news-item news-primary" : "news-item"
                      }
                      onClick={() => {
                        onOpenNews(i);
                      }}
                    >
                      <div
                        className="news-cover"
                        style={{ backgroundImage: "url(" + i.sFilePath + ")" }}
                      ></div>
                      <div className="news-title">{i.sType}</div>
                      <div className="news-date">{i.sDate}</div>
                    </div>
                  );
                })
              : null}
            {/*<a className="news-item news-primary">
                            <div className="news-cover" style={{ backgroundImage: "url(" + require("../../Images/home/example/news/news1.jpg") + ")" }}></div>
                            <div className="news-title">โครงการราษฎรอาสาสมัครพิทักษ์ป่า [รสกป]</div>
                            <div className="news-date">29/12/2564</div>
                        </a>*/}
          </div>
        </div>
        </div>
        :
        null
      }
      
      <div className="home-history">
        {/*<img src={require("../../Images/home/history-info.jpg")} />*/}
        <img src={sFilePathInfo} />
      </div>
      <div className="home-calendar">
        <div className="calendar-box">
          <div className="calendar-title">
            <div className="title-icon">
              <i className="icofont-ui-calendar"></i>
            </div>
            <div className="title-label">
              <div className="title-secondary">{translate.Calendar}</div>
              <div className="title-primary">{translate.Event}</div>
            </div>
          </div>
          <div className="calendar-button">
              <li
                className={learningSelected === "All" ? "active" : ""}
              >
                  <a 
                    onClick={() => {
                            /*onSelectSub(i.sID)*/
                            let selected = "";
                            if (
                              (learningSelected !== "" &&
                                learningSelected !== "All") ||
                              learningSelected === ""
                            ) {
                              setLearningSelected("All");
                              selected = "All";
                            } else {
                              //setLearningSelected("");
                              setLearningSelected("All");
                              selected = "All";
                            }
                            onFilterLvent(selected);
                          }}>
                    <div className="button-img" style={{color: "#000000"}}>
                        <i className="icofont-ui-calendar"></i>
                    </div>
                    <div className="button-title">{translate.AllEvent}</div>
                  </a>
              </li>
            {lstIndex.length > 0
              ? lstIndex.map((i, indx) => {
                  return (
                    <li
                      key={"ref_" + indx}
                      className={learningSelected === i.sTypeID ? "active" : ""}
                    >
                      <Tooltip style={{ width: "400px" }} title={i.sName}>
                        <a
                          onClick={() => {
                            /*onSelectSub(i.sID)*/
                            let selected = "";
                            if (
                              (learningSelected !== "" &&
                                learningSelected !== i.sTypeID) ||
                              learningSelected === ""
                            ) {
                              setLearningSelected(i.sTypeID);
                              selected = i.sTypeID;
                            } else {
                              //setLearningSelected("");
                              setLearningSelected(i.sTypeID);
                              selected = i.sTypeID;
                            }
                            onFilterLvent(selected);
                          }}
                        >
                          <div className="button-img">
                            <img src={i.sImageUrl} />
                          </div>
                          <div className="button-title">{i.sName}</div>
                        </a>
                      </Tooltip>
                    </li>
                  );
                })
              : null}
            {/* 
                            <li className={"active"}>
                                <a>
                                    <div className="button-img"><img src={require("../../Images/institution/institution-2.png")} /></div>
                                    <div className="button-title">ป่าในกรุง</div>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <div className="button-img"><img src={require("../../Images/institution/institution-3.png")} /></div>
                                    <div className="button-title">ป่าวังจันทร์</div>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <div className="button-img"><img src={require("../../Images/institution/institution-4.png")} /></div>
                                    <div className="button-title">สิรินาถราชินี</div>
                                </a>
                            </li>    
                        */}
          </div>
        </div>
        <div className="calendar-panel">
          <div className="calendar-activity">
            <Slider {...ActivityList_Settings}>
              {lstEvent.length > 0
                ? lstEvent.map((e, ie) => {
                    return (
                      <div key={"e_" + ie}>
                        <div className="activity-item">
                          <div
                            className="activity-cover"
                            onClick={() => history.push(e.sLinkEvent)}
                            style={{
                              backgroundImage: "url(" + e.sImageBG + ")",
                            }}
                          >
                            <div className="activity-date">
                              <div>
                                <div>{e.sDateEvent}</div>
                                <hr className="border-white my-0" />
                                <div className="small">{e.sMonthEvent}</div>
                                <div className="small">{e.sYearEvent}</div>
                              </div>
                            </div>
                          </div>
                          <div className="activity-title">
                            <div className="title-label">{e.sNameEvent}</div>
                            <div className="title-caret"></div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
              {/*
                                <>
                                <a className="activity-item">
                                    <div className="activity-cover" style={{ backgroundImage: "url(" + require("../../Images/home/example/news/news1.jpg") + ")" }}>
                                        <div className="activity-date">
                                            <div>
                                                <div>29 - 5</div>
                                                <hr className="border-white my-0" />
                                                <div className="small">ก.ย. - ต.ค.</div>
                                                <div className="small">2564</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="activity-title">
                                        <div className="title-label">สถาบันปลูกป่า ปตท. ศูนย์การเรียนรู้ป่าในกรุง</div>
                                        <div className="title-caret"></div>
                                    </div>
                                </a>
                            </>
                            */}
            </Slider>
          </div>
          <div id="DATE_CALENDAR" className="calendar-date">
            <Slider ref={sliderRef} {...DateList_Settings}>
              {
                // lstMonthDate.length > 0 ?
                lstMonthDate.map((me, ime) => {
                  return (
                    <div key={"index" + me}>
                      {me.isChecked ? (
                        <Tooltip
                            placement="topLeft" 
                            arrowPointAtCenter
                            style={{ width : "400px" }}
                            title={() =>
                                <>
                                {
                                    me.lstEvent.map((le, ile) => {
                                        return (
                                            <div key={"le_" + ime + "_" + ile}>
                                                <b>{le.sLearningCenter}</b>{" - "}{le.sEventName}<br /><br />
                                            </div>
                                        )
                                    })
                                }
                                </>
                        }>
                            <div key={"me_" + ime} className="date-item">
                                <div
                                    className={`date-link ${me.isChecked ? "active" : ""}`}
                                    // onClick={() => {
                                    //     // me.isChecked = !me.isChecked
                                    //     // setLstMonthDate([...lstMonthDate]);

                                    //     // onSearchEvent(lstMonthDate);
                                    // }}
                                >
                                    {me.sDateNumber}
                                </div>
                            </div>
                        </Tooltip>
                      ) : (
                        // <Tooltip
                        //     style={{ width : "400px" }}
                        //     title={() =>
                        //         <>
                        //         {
                        //             me.lstEvent.map((le, ile) => {
                        //                 return (
                        //                     <div key={"le_" + ime + "_" + ile}>
                        //                         <b>{le.sEventName}</b>{" - "}<b>{le.sLearningCenter}</b><br /><br />
                        //                     </div>
                        //                 )
                        //             })
                        //         }
                        //         </>
                        // }>
                        //     <div key={"me_" + ime} className="date-item">
                        //         <div
                        //             className={`date-link ${me.isChecked ? "active" : ""}`}
                        //             // onClick={() => {
                        //             //     // me.isChecked = !me.isChecked
                        //             //     // setLstMonthDate([...lstMonthDate]);

                        //             //     // onSearchEvent(lstMonthDate);
                        //             // }}
                        //         >
                        //             {me.sDateNumber}
                        //         </div>
                        //     </div>
                        // </Tooltip>

                        <div key={"me_" + ime} className="date-item">
                          <div
                            className={`date-link ${
                              me.isChecked ? "active" : ""
                            }`}
                            // onClick={() => {
                            //     // me.isChecked = !me.isChecked
                            //     // setLstMonthDate([...lstMonthDate]);

                            //     // onSearchEvent(lstMonthDate);
                            // }}
                          >
                            {me.sDateNumber}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })
                // :
                // null
              }
            </Slider>
          </div>
          {/*<div className="calendar-date">
                        <Slider {...DateList_Settings}>
                            <><div className="date-item"><a className="date-link active">01</a></div></>
                            <><div className="date-item"><a className="date-link active">02</a></div></>
                            <><div className="date-item"><a className="date-link active">03</a></div></>
                            <><div className="date-item"><a className="date-link active">04</a></div></>
                            <><div className="date-item"><a className="date-link active">05</a></div></>
                        </Slider>
                    </div>*/}
        </div>
      </div>
      <div className="home-booking">
        <div className="booking-head">
          <div className="booking-title">
            {}
            {translate.OnlineBooking}
            {/*<b>จอง /</b> เยี่ยมชม ออนไลน์*/}
          </div>
        </div>
        <Slider
          {...BookingList_Settings}
          className="booking-slider"
          style={{ marginTop: "2vw" }}
        >
          {lstBooking.length > 0
            ? lstBooking.map((b, ib) => {
                return (
                  <div key={"b_" + ib}>
                    <div
                      className="booking-link"
                      onClick={() => onOpenBooking(b)}
                      style={{
                        backgroundImage: "url(" + b.sBooking_Path + ")",
                      }}
                    ></div>
                  </div>
                );
              })
            : null}
          {/*<><a className="booking-link" style={{ backgroundImage: "url(" + require("../../Images/home/booking/สิรินาถราชินี.png") + ")" }}></a></>
                    <><a className="booking-link" style={{ backgroundImage: "url(" + require("../../Images/home/booking/ศูนย์ป่าในกรุง.png") + ")" }}></a></>
                    <><a className="booking-link" style={{ backgroundImage: "url(" + require("../../Images/home/booking/ศูนย์ป่าวังจันทร์.png") + ")" }}></a></>*/}
        </Slider>
        <Link
          to="/hl-project"
          //to=""
          target="_self"
          //onClick={(e) => {e.preventDefault(); window.open("/hl-project", "_self");}}
          className="booking-link"
          style={{
            backgroundImage:
              "url(" +
              require("../../Images/home/booking/โครงการสำคัญ.png") +
              ")",
            marginTop: "2vw",
          }}
        ></Link>
      </div>
      {isModalVisible ? (
                <Modal
                    className="modal-x"
                    visible={isModalVisible}
                    width={1000}
                    onCancel={handleOk}
                    footer={false}
                    bodyStyle={{ backgroundColor: "#3f3838" }}
                >
                    <div className="player-wrapper" style={{ height: "500px" }}>
                        <ReactPlayer
                            key={"vdo_" + sPath}
                            playing
                            url={sPath}
                            width='100%'
                            height='100%'
                            controls
                        />
                    </div>
                </Modal>
            ) : null}
            {
                isModalVisibleFile && lv1 === "14" ? 
                (
                        <Modal
                            //className={"modal-x " + (isFullScreen ? "ebook-full" : "ebook-popup")}
                            className={"modal-x ebook-popup"}
                            visible={isModalVisibleFile}
                            //width={isFullScreen ? "100vw" : "1000"}
                            width={1000}
                            onCancel={handleOk}
                            footer={false}
                            bodyStyle={{ backgroundColor: "transparent", height: "750px"}}
                        >
                        <br />
                        {/*
                          <Row gutter={[8, 16]} justify="center">
                            <Col>
                                <Tooltip 
                                    style={{ width : "400px" }}
                                    title={translate.ZoomOut}
                                >
                                    <Button 
                                        size="middle" 
                                        shape="circle" 
                                        style={{ background: "#91d5ff", border: "none", color: "#000000" }} 
                                        disabled={isFullScreen} 
                                        onClick={() => {
                                            if (nLvZoom === 0){
                                                return;
                                            }
                                            else{
                                                setIsLoad(true);
                                                setLvZoom(nLvZoom - 1);
                                                setTimeout(() => {
                                                    setIsLoad(false);
                                                }, 500);
                                            }
                                        }}
                                    >
                                        <i className="icofont-ui-zoom-out"></i>
                                    </Button>
                                </Tooltip>
                            </Col>
                            <Col>
                                <AntdSlider 
                                    min={0} 
                                    max={5}  
                                    defaultValue={nLvZoom}
                                    value={nLvZoom} 
                                    disabled={isFullScreen} 
                                    onChange={e => {
                                        setIsLoad(true);
                                        setLvZoom(e);
                                        setTimeout(() => {
                                            setIsLoad(false);
                                        }, 500);
                                    }} 
                                    style={{width : "200px"}}
                                />
                            </Col>
                            <Col>
                                <Tooltip 
                                    style={{ width : "400px" }}
                                    title={translate.ZoomIn}
                                >
                                    <Button 
                                        size="middle" 
                                        shape="circle" 
                                        style={{ background: "#91d5ff", border: "none", color: "#000000" }} 
                                        disabled={isFullScreen} 
                                        onClick={() => {
                                            if (nLvZoom === 5){
                                                return;
                                            }
                                            else{
                                                setIsLoad(true);
                                                setLvZoom(nLvZoom + 1);
                                                setTimeout(() => {
                                                    setIsLoad(false);
                                                }, 500);
                                            }
                                        }}
                                    >
                                        <i className="icofont-ui-zoom-in"></i>
                                    </Button>
                                </Tooltip>
                            </Col>
                            <Col>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Col>
                            <Col>
                                <Tooltip 
                                    style={{ width : "400px" }}
                                    title={isFullScreen ? translate.ExitFullScreen : translate.FullScreen}
                                >
                                    <Button size="middle" shape="circle" style={{ background: "#00c3da", border: "none", color: "#000000" }}
                                        onClick={() => {
                                            setIsLoad(true);
                                            setIsFullScreen(!isFullScreen);
                                            setTimeout(() => {
                                                setIsLoad(false);
                                            }, 500);
                                        }}
                                    >
                                        <i className="icofont-resize"></i>
                                    </Button>
                                </Tooltip>
                            </Col>
                            <Col>
                                <Tooltip 
                                    style={{ width : "400px" }}
                                    title={translate.Download}
                                >
                                    <a href={sPath} download="">
                                        <Button size="middle" shape="circle" style={{ background: "#ffc107", border: "none", color: "#000000" }}>
                                            <i className="icofont-download"></i>
                                        </Button>
                                    </a>
                                </Tooltip>
                            </Col>
                        </Row>
                        <br />
                        */}
                        
                        {
                            isLoad ?
                            null
                            :
                            <React.Fragment>
                              {/* <iframe src={"/content-ebook?path=" + sPath} width={"100%"} height={"100%"}/> */}
                              <iframe src={sPathWeb + "content-ebook?path=" + sPath + "&Mode=" + translate.TranslatType} width={"100%"} height={"100%"}/>
                                {/*
                                    isFullScreen ?
                                    <div className="player-wrapper" style={{ height: "88vh", justifyContent:"center" }}>
                                        <HTMLFlipBook 
                                            width={500}
                                            height={700}
                                            style={{ marginLeft: "auto", marginRight: "auto" }} 
                                            className={""} 
                                            startPage={0} 
                                            size={"fixed"} 
                                            minWidth={0} 
                                            maxWidth={0} 
                                            minHeight={0} 
                                            maxHeight={0} 
                                            drawShadow={false} 
                                            flippingTime={1000} 
                                            usePortrait={false} 
                                            startZIndex={0} 
                                            autoSize={true} 
                                            maxShadowOpacity={0} 
                                            showCover={true} 
                                            mobileScrollSupport={false} 
                                            clickEventForward={true}
                                            useMouseEvents={true} 
                                            swipeDistance={0} 
                                            showPageCorners={true} 
                                            disableFlipByClick={false}                            
                                        >
                                        {
                                            lstImagePDF ?
                                                lstImagePDF.map((le, ile) => {
                                                    return(
                                                        <Page key={"eb_" + ile} number="1">{le}</Page>
                                                    )
                                                })
                                                :
                                                null
                                        }
                                        </HTMLFlipBook>
                                    </div>
                                    :
                                    <div className="player-wrapper" style={{ height: "600px", justifyContent:"center" }}>
                                        <HTMLFlipBook 
                                            width={400 + (nLvZoom * 15)}
                                            height={600 + (nLvZoom * 15)}
                                            style={{ marginLeft: "auto", marginRight: "auto" }} 
                                            className={""} 
                                            startPage={0} 
                                            size={"fixed"} 
                                            minWidth={0} 
                                            maxWidth={0} 
                                            minHeight={0} 
                                            maxHeight={0} 
                                            drawShadow={false} 
                                            flippingTime={1000} 
                                            usePortrait={false} 
                                            startZIndex={0} 
                                            autoSize={true} 
                                            maxShadowOpacity={0} 
                                            showCover={true} 
                                            mobileScrollSupport={false} 
                                            clickEventForward={true}
                                            useMouseEvents={true} 
                                            swipeDistance={0} 
                                            showPageCorners={true} 
                                            disableFlipByClick={false}                            
                                        >
                                        {
                                            lstImagePDF ?
                                                lstImagePDF.map((le, ile) => {
                                                    return(
                                                        <Page key={"eb_" + ile} number="1">{le}</Page>
                                                    )
                                                })
                                                :
                                                null
                                        }
                                        </HTMLFlipBook>
                                    </div>
                                      */}
                            </React.Fragment>
                        }
                    </Modal>
                )
                    :
                    null
            }

            {isModalVisibleFile && lv1 === "16" ? (
                <Modal
                    className="modal-x"
                    visible={isModalVisibleFile}
                    width={1000}
                    onCancel={handleOk}
                    footer={false}
                    bodyStyle={{ backgroundColor: "#3f3838" }}
                >
                    <br />
                    <div className="player-wrapper" style={{ height: "500px" }}>
                        <iframe src={sPath} width={"100%"} height={"100%"} />
                    </div>
                </Modal>
            ) : null}
    </div>
  );
};
export default Home;
