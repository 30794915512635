import * as React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../main-home/home.css";
import "../main-content/content.css";
import { AxiosGetJson } from "../Service/Config/AxiosMethod";
import { parse } from "query-string";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router";
import {
    thailanguage,
    englishlanguage,
    currentlanguage,
} from '../app/counterSlice';
import ReactFBLike from 'react-fb-like';
import GalleryTrigger from "../ST_Handlers/GalleryTrigger";
import Image from "../Systems/Image";
import { 
    EmailShareButton,
    FacebookShareButton,
    LineShareButton,
    EmailIcon,
    FacebookIcon,
    LineIcon,
 } from "react-share";
import { Button } from "antd";

const Main_Content_Detail = ({ isShowCover = false, adminMenu = "99" }) => {
    var { id, ref } = parse(window.location.search);
    var isCover = isShowCover ? isShowCover : false;
    var adMunu = adminMenu ? adminMenu : false;
    const [sFilePath, setFilePath] = React.useState("");
    const [contentDetail, setContentDetail] = React.useState(null as any);
    const [sFileCover, setFileCover] = React.useState("");
    const [isLoad , setIsLoad] = React.useState(false);
    const [isLike, setIsLike] = React.useState(false);
    const [nLike, setnLike] = React.useState(0);
    const history = useHistory();
    const translate = useSelector(currentlanguage);
    const dispatch = useDispatch();

    const SetBackground = async () => {
        let result: any = await AxiosGetJson("api/AdminInfo/GetBackground");
        setFilePath(result.sFilePath);
    };
    const GetContentDetail = async (idStr, TranslatType) => {
        let result: any = await AxiosGetJson(
            "api/AdminInfo/GetContentDetail?nMenuID=" + adMunu + "&id=" + idStr + "&Mode=" + TranslatType
        );
        setContentDetail(result);
        setFileCover(result.lstFileCover[0].sSaveToPath);
    };

    const onOpenRelated = (x) => {
        let sTypeComponent = "";
        if (x.nHeadLv0 === "1") {
            sTypeComponent = "/news-detail";
        }
        if (x.nHeadLv0 === "2") {
            sTypeComponent = "/forest-story-detail";
        }
        if (x.nHeadLv0 === "5") {
            sTypeComponent = "/learning-center-sub";
        }
        if (x.nHeadLv0 === "6") {
            sTypeComponent = "/hl-project-sub";
        }
        if (x.nHeadLv0 === "7") {
            sTypeComponent = "/knowledge-sub";
        }
        let sPath = `${sTypeComponent}?id=${x.sUrl}`;
        return history.push(sPath);
        // let sTypeComponent = "/news-detail";
        // let sPath = `${sTypeComponent}?id=${x.sUrl}`;
        // return history.push(sPath);
    }

    const GetNumberLike = async(idStr) =>{
        var result = await AxiosGetJson("api/AdminInfo/GetNumberLike?id=" + idStr);
        setnLike(result);
    }

    React.useEffect(() => {
        setIsLoad(false);
        var { id } = parse(window.location.search);
        var idStr: string = "";
        if (id !== undefined && id !== null) {
            idStr = id.toString().split("/").join("%2f");
        } else {
            idStr = "xvEhq%2famL3jePelPapN2%2fw==";
        }
        SetBackground();
        GetContentDetail(idStr, translate.TranslatType);
        GetNumberLike(idStr);
        window.scrollTo(0, 0);
        setIsLoad(true);
    }, [translate]);

    const onBackTo = () => {
        let sTypeComponent = "";
        let sId = "";
        let sPath = "";
        if(ref === "home"){
            sTypeComponent = "/home";
        }
        else if(ref === "search"){
            //history.goBack();
        }
        else{
            if(adMunu === "7")
            {
                sTypeComponent = "/knowledge-list";
                sId = contentDetail.sIdBefore;
            }

            if(sId !== "")
            {
                sPath = `${sTypeComponent}?id=${sId}`;
            }
            else
            {
                sPath = `${sTypeComponent}`;
            }
        }
        
        if(sPath === ""){
            return history.goBack();
        }
        else{
            return history.push(sPath);
        }
    } 

    const UpdateLikeContent = async() =>{
        await AxiosGetJson("api/AdminInfo/UpdateLikeContent?id=" + id);
    }
    const UpdateShareContent = async(nChannel) =>{
        await AxiosGetJson("api/AdminInfo/UpdateShareContent?id=" + id + "&channel=" + nChannel);
    }

    return (
        <React.Fragment>
            <div id="PAGE_HOME" style={{ backgroundImage: "url(" + sFilePath + ")" }}>
                <div className="home-news">
                    <div className="news-panel">
                        <div className="panel-head">
                            <div className="panel-title-first" style={{cursor:"pointer"}}  onClick={() => onBackTo()}>
                                <i className="icofont-arrow-left"></i> {translate.Back}
                            </div>
                            <div className="panel-title">
                                {contentDetail ? contentDetail.sSubject : ""}
                            </div>
                        </div>
                    </div>
                </div>
                {
                    contentDetail ? 
                        contentDetail.sContentType === "TH" && translate.TranslatType == "EN" ? 
                            <div
                                className="home-history"
                                style={{
                                width: "78%",
                                maxWidth:"78%",
                                height: "500px",
                                alignItems: "left",
                                marginLeft: "auto",
                                marginRight: "auto",
                                display: "block",
                                }}
                            >
                                <h3 style={{ textAlign: "center"}}>No Content</h3>
                            </div>
                            :
                            <React.Fragment>
                                {isCover ? (
                                    <div
                                    className="home-history"
                                    style={{
                                        width: "fit-content",
                                        maxWidth:"78%",
                                        // height: "400px",
                                        alignItems: "left",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        display: "block",
                                    }}
                                >
                                    <img src={sFileCover} style={{ width: "100%", height: "100%" }} />
                                </div>
                                ) : null}
                                <div className="news">
                                    <div className="news-content">
                                        <div className="content">
                                            <div id="KNOWLEDGE">
                                                <div className="row">
                                                    <div className="iknowledge">
                                                        <div className="iknowledge-info">
                                                            <div className="knowledge-flag">
                                                                {contentDetail ? contentDetail.sSubject : ""}
                                                            </div>
                                                            <div className="knowledge-level"></div>
                                                            <div className="knowledge-tags">
                                                                <div className="row">
                                                                    <div className="col-md-6 col-sm-12 col-12">
                                                                        <div className="knowledge-ex">
                                                                            <span className="head-title">{translate.PlantName} </span>
                                                                            <span className="text-title">
                                                                                {contentDetail ? contentDetail.sPlantName : ""}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 col-sm-12 col-12">
                                                                        <div className="knowledge-ex">
                                                                            <span className="head-title">
                                                                                {translate.PlantSciName}
                                                                            </span>
                                                                            <span className="text-title">
                                                                                {contentDetail
                                                                                    ? contentDetail.sPlantSciName
                                                                                    : ""}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 col-sm-12 col-12">
                                                                        <div className="knowledge-ex">
                                                                            <span className="head-title">{translate.PlantFamily}</span>
                                                                            <span className="text-title">
                                                                                {contentDetail
                                                                                    ? contentDetail.sPlantFamily
                                                                                    : ""}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 col-sm-12 col-12">
                                                                        <div className="knowledge-ex">
                                                                            <span className="head-title">{translate.PlantNative}</span>
                                                                            <span className="text-title">
                                                                                {contentDetail
                                                                                    ? contentDetail.sPlantNative
                                                                                    : ""}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12 col-sm-12 col-12">
                                                                        <div className="knowledge-ex">
                                                                            <span className="head-title">
                                                                                {translate.PlantDetail}
                                                                            </span>
                                                                            <span
                                                                                className="text-title"
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: contentDetail
                                                                                        ? contentDetail.sPlantDetails
                                                                                        : "",
                                                                                }}
                                                                            ></span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12 col-sm-12 col-12">
                                                                        <div className="knowledge-ex">
                                                                            <span className="head-title">
                                                                                {translate.Trunk}
                                                                            </span>
                                                                            <span className="text-title">
                                                                                {contentDetail ? contentDetail.sPlantTrunk : ""}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 col-sm-12 col-12">
                                                                        <div className="knowledge-ex">
                                                                            <span className="head-title">{translate.Leaf}</span>
                                                                            <span className="text-title">
                                                                                {contentDetail ? contentDetail.sPlantLeaf : ""}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 col-sm-12 col-12">
                                                                        <div className="knowledge-ex">
                                                                            <span className="head-title">{translate.Fruit}</span>
                                                                            <span className="text-title">
                                                                                {contentDetail ? contentDetail.sPlantFruit : ""}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 col-sm-12 col-12">
                                                                        <div className="knowledge-ex">
                                                                            <span className="head-title">{translate.Flower}</span>
                                                                            <span className="text-title">
                                                                                {contentDetail
                                                                                    ? contentDetail.sPlantFlower
                                                                                    : ""}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 col-sm-12 col-12">
                                                                        <div className="knowledge-ex">
                                                                            <span className="head-title">{translate.Properties}</span>
                                                                            <span
                                                                                className="text-title"
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: contentDetail
                                                                                        ? contentDetail.sPlantProperties
                                                                                        : "",
                                                                                }}
                                                                            ></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="news-title">
                                {contentDetail ? contentDetail.sSubject : ""}
                            </div>
                            <div className="news-detail"></div>
                            <div className="file-title">ชื่อพืช</div>
                            <div className="news-ref">
                                <div className="file-doc">
                                <div className="doc" style={{ marginLeft: "3em" }}>
                                    {contentDetail ? contentDetail.sPlantName : ""}
                                </div>
                                </div>
                            </div>
                            <div className="news-ref">
                                <div className="file-title">ชื่อวิทยาศาสตร์</div>
                                <div className="file-doc">
                                <div className="doc" style={{ marginLeft: "3em" }}>
                                    {contentDetail ? contentDetail.sPlantSciName : ""}
                                </div>
                                </div>
                            </div>
                            <div className="news-ref">
                                <div className="file-title">วงศ์</div>
                                <div className="file-doc">
                                <div className="doc" style={{ marginLeft: "3em" }}>
                                    {contentDetail ? contentDetail.sPlantFamily : ""}
                                </div>
                                </div>
                            </div>
                            <div className="news-ref">
                                <div className="file-title">ชื่อพื้นเมือง</div>
                                <div className="file-doc">
                                <div className="doc" style={{ marginLeft: "3em" }}>
                                    {contentDetail ? contentDetail.sPlantNative : ""}
                                </div>
                                </div>
                            </div>
                            <div className="news-ref">
                                <div className="file-title">ข้อมูลพันธุ์ไม้</div>
                                <div className="file-doc">
                                <div
                                    className="doc"
                                    style={{ marginLeft: "3em" }}
                                    dangerouslySetInnerHTML={{
                                    __html: contentDetail ? contentDetail.sPlantDetails : "",
                                    }}
                                ></div>
                                </div>
                            </div>
                            <div className="news-ref">
                                <div className="file-title">ลำต้น / ลักษณะเนื้อไม้</div>
                                <div className="file-doc">
                                <div className="doc" style={{ marginLeft: "3em" }}>
                                    {contentDetail ? contentDetail.sPlantTrunk : ""}
                                </div>
                                </div>
                            </div>
                            {contentDetail ? (
                                contentDetail.lstFilePlantTrunk.length > 0 ? (
                                <>
                                    <div className="news-img"></div>
                                    <div className="news-item">
                                    {contentDetail.lstFilePlantTrunk.map((x, ix) => {
                                        return (
                                        <div key={"tk_" + ix} className="item-box">
                                            <div
                                            className="item-cover"
                                            style={{
                                                backgroundImage:
                                                "url(" + x.sSaveToFileName + ")",
                                            }}
                                            ></div>
                                        </div>
                                        );
                                    })}
                                    </div>
                                </>
                                ) : null
                            ) : null}
                            <div className="news-ref">
                                <div className="file-title">ใบ</div>
                                <div className="file-doc">
                                <div className="doc" style={{ marginLeft: "3em" }}>
                                    {contentDetail ? contentDetail.sPlantLeaf : ""}
                                </div>
                                </div>
                            </div>
                            {contentDetail ? (
                                contentDetail.lstFilePlantLeaf.length > 0 ? (
                                <>
                                    <div className="news-img"></div>
                                    <div className="news-item">
                                    {contentDetail.lstFilePlantLeaf.map((x, ix) => {
                                        return (
                                        <div key={"tk_" + ix} className="item-box">
                                            <div
                                            className="item-cover"
                                            style={{
                                                backgroundImage:
                                                "url(" + x.sSaveToFileName + ")",
                                            }}
                                            ></div>
                                        </div>
                                        );
                                    })}
                                    </div>
                                </>
                                ) : null
                            ) : null}
                            <div className="news-ref">
                                <div className="file-title">ผล</div>
                                <div className="file-doc">
                                <div className="doc" style={{ marginLeft: "3em" }}>
                                    {contentDetail ? contentDetail.sPlantFruit : ""}
                                </div>
                                </div>
                            </div>
                            {contentDetail ? (
                                contentDetail.lstFilePlantFruit.length > 0 ? (
                                <>
                                    <div className="news-img"></div>
                                    <div className="news-item">
                                    {contentDetail.lstFilePlantFruit.map((x, ix) => {
                                        return (
                                        <div key={"tk_" + ix} className="item-box">
                                            <div
                                            className="item-cover"
                                            style={{
                                                backgroundImage:
                                                "url(" + x.sSaveToFileName + ")",
                                            }}
                                            ></div>
                                        </div>
                                        );
                                    })}
                                    </div>
                                </>
                                ) : null
                            ) : null}
                            <div className="news-ref">
                                <div className="file-title">ดอก</div>
                                <div className="file-doc">
                                <div className="doc" style={{ marginLeft: "3em" }}>
                                    {contentDetail ? contentDetail.sPlantFlower : ""}
                                </div>
                                </div>
                            </div>
                            {contentDetail ? (
                                contentDetail.lstFilePlantFlower.length > 0 ? (
                                <>
                                    <div className="news-img"></div>
                                    <div className="news-item">
                                    {contentDetail.lstFilePlantFlower.map((x, ix) => {
                                        return (
                                        <div key={"tk_" + ix} className="item-box">
                                            <div
                                            className="item-cover"
                                            style={{
                                                backgroundImage:
                                                "url(" + x.sSaveToFileName + ")",
                                            }}
                                            ></div>
                                        </div>
                                        );
                                    })}
                                    </div>
                                </>
                                ) : null
                            ) : null}
                            <div className="news-ref">
                                <div className="file-title">ประโยชน์</div>
                                <div className="file-doc">
                                <div
                                    className="doc"
                                    style={{ marginLeft: "3em" }}
                                    dangerouslySetInnerHTML={{
                                    __html: contentDetail
                                        ? contentDetail.sPlantProperties
                                        : "",
                                    }}
                                ></div>
                                </div>
                            </div> */}
                                            <br />
                                            <br />
                                            {contentDetail ? (
                                                contentDetail.lstFilePlantFlower.length > 0 || contentDetail.lstFilePlantTrunk.length > 0 || contentDetail.lstFilePlantLeaf.length > 0 || contentDetail.lstFilePlantLeaf.length > 0 ? (
                                                    <>
                                                        <div className="news-img">{translate.PlantPath}</div>
                                                        <div className="news-item">
                                                            {contentDetail.lstFilePlantTrunk.map((x, ix) => {
                                                                return (
                                                                    <div key={"tk_" + ix} className="item-box">
                                                                        <GalleryTrigger imgSrc={x.sSaveToPath}>
                                                                            <Image src={x.sSaveToPath} fallbackSrc={require("../../Images/noimage.png")} width="200px" height="140px" />
                                                                        </GalleryTrigger>
                                                                        {/*<div className="item-cover" style={{ backgroundImage: "url(" + x.sSaveToPath + ")"}}>
                                                                        </div>*/}
                                                                    </div>
                                                                );
                                                            })}
                                                            {contentDetail.lstFilePlantFlower.map((x, ix) => {
                                                                return (
                                                                    <div key={"tk_" + ix} className="item-box">
                                                                        <GalleryTrigger imgSrc={x.sSaveToPath}>
                                                                            <Image src={x.sSaveToPath} fallbackSrc={require("../../Images/noimage.png")} width="200px" height="140px" />
                                                                        </GalleryTrigger>
                                                                        {/*<div className="item-cover" style={{ backgroundImage: "url(" + x.sSaveToPath + ")"}}>
                                                                        </div>*/}
                                                                    </div>
                                                                );
                                                            })}
                                                            {contentDetail.lstFilePlantLeaf.map((x, ix) => {
                                                                return (
                                                                    <div key={"tk_" + ix} className="item-box">
                                                                        <GalleryTrigger imgSrc={x.sSaveToPath}>
                                                                            <Image src={x.sSaveToPath} fallbackSrc={require("../../Images/noimage.png")} width="200px" height="140px" />
                                                                        </GalleryTrigger>
                                                                        {/*<div className="item-cover" style={{ backgroundImage: "url(" + x.sSaveToPath + ")"}}>
                                                                        </div>*/}
                                                                    </div>
                                                                );
                                                            })}
                                                            {contentDetail.lstFilePlantFruit.map((x, ix) => {
                                                                return (
                                                                    <div key={"tk_" + ix} className="item-box">
                                                                        <GalleryTrigger imgSrc={x.sSaveToPath}>
                                                                            <Image src={x.sSaveToPath} fallbackSrc={require("../../Images/noimage.png")} width="200px" height="140px" />
                                                                        </GalleryTrigger>
                                                                        {/*<div className="item-cover" style={{ backgroundImage: "url(" + x.sSaveToPath + ")"}}>
                                                                        </div>*/}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </>
                                                ) : null
                                            ) : null}
                                            <br />
                                            <br />
                                            {contentDetail ? (
                                                contentDetail.lstFileGallery.length > 0 ? (
                                                    <>
                                                        <div className="news-img">{translate.Gallery}</div>
                                                        <div className="news-item">
                                                            {contentDetail.lstFileGallery.map((x, ix) => {
                                                                return (
                                                                    <div key={"g_" + ix} className="item-box">
                                                                        <div
                                                                            className="item-cover"
                                                                            style={{
                                                                                backgroundImage:
                                                                                    "url(" + x.sSaveToPath + ")",
                                                                            }}
                                                                        ></div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </>
                                                ) : null
                                            ) : null}
                                            {
                                                contentDetail ?
                                                    contentDetail.msRefLink.length > 0 ?
                                                        <>
                                                            <div className="news-ref">
                                                                <div className="ref-title">{translate.Reference}</div>
                                                                <div className="ref-doc">
                                                                    {
                                                                        contentDetail.msRefLink.map((x, ix) => {
                                                                            return (
                                                                                <div key={"l_" + ix} className="doc" style={{cursor: "pointer"}}>
                                                                                    <div onClick={() => {
                                                                                        window.open(x, '_blank');
                                                                                    }}>{x}</div>
                                                                                </div>
                                                                            )

                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                    :
                                                    null
                                            }
                                            {
                                                contentDetail ?
                                                    contentDetail.lstFileRef.length > 0 ?
                                                        <>
                                                            <div className="news-file">
                                                                <div className="file-title">{translate.RelatedFile}</div>
                                                                <div className="file-doc">
                                                                    {
                                                                        contentDetail.lstFileRef.map((x, ix) => {
                                                                            return (
                                                                                <div key={"f_" + ix} className="doc" style={{cursor: "pointer"}}>
                                                                                    <div onClick={() => {
                                                                                        window.open(x.sSaveToPath, '_blank');
                                                                                    }}>{x.sFileName}</div>
                                                                                </div>
                                                                            )

                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                    :
                                                    null
                                            }

                                            {/*<div className="news-date">
                                                <i className="icofont-ui-calendar"></i>&nbsp;
                                                {contentDetail ? contentDetail.sDate : ""}
                                            </div>*/}
                                            <br />
                                            {
                                                isLoad ?
                                                <React.Fragment>
                                                {/*<div className="fb-like" data-href="https://web.facebook.com/PTTRFEI/" data-width="" data-layout="standard" data-action="like" data-size="large" data-share="false"></div>*/}
                                                <Button size="middle" shape="circle" style={{ background: "#00c3da", border: "none", color: "#ffffff" }}
                                                    onClick={() => {
                                                        if(!isLike){
                                                            setIsLike(true);
                                                            UpdateLikeContent();
                                                        }
                                                        // setIsLoad(true);
                                                        // setIsFullScreen(!isFullScreen);
                                                        // setTimeout(() => {
                                                        //     setIsLoad(false);
                                                        // }, 500);
                                                    }}
                                                >
                                                    <i className="icofont-like"></i>
                                                </Button>&nbsp;&nbsp;&nbsp;{isLike ? translate.Liked : ""}{" " + nLike + " "}&nbsp;{translate.Like}
                                                <br /><br />
                                                <FacebookShareButton onClick={() =>UpdateShareContent(6)} url={window.location.href}  translate={undefined} quote={contentDetail ? contentDetail.sSubject : ""}>
                                                    <FacebookIcon size={32} round={true} path={undefined} crossOrigin={undefined} />
                                                </FacebookShareButton>
                                                &nbsp;&nbsp;
                                                <LineShareButton onClick={() =>UpdateShareContent(7)} url={window.location.href}  translate={undefined} >
                                                    <LineIcon size={32} round={true} path={undefined} crossOrigin={undefined} />
                                                </LineShareButton>
                                                &nbsp;&nbsp;
                                                <EmailShareButton url={window.location.href}  translate={undefined} >
                                                    <EmailIcon onClick={() =>UpdateShareContent(8)} size={32} round={true} path={undefined} crossOrigin={undefined} />
                                                </EmailShareButton>
                                            </React.Fragment>
                                                :
                                                null
                                            }            
                                            <br />
                                            <br />
                                            <hr />
                                            {
                                                contentDetail ?
                                                    contentDetail.lstContentRef.length > 0 ?
                                                        <>
                                                            <div className="news-related">
                                                                <div className="news-panel">
                                                                    <div className="panel-head">
                                                                        <div className="panel-title">{translate.RelatedContent}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                contentDetail.lstContentRef.map((x, ix) => {
                                                                    return (
                                                                        <div key={"ref_" + ix} className="col-xl-12 mt-3 mt-sm-5">
                                                                            <div className="ibox ibox-2" onClick={() => onOpenRelated(x)} style={{cursor: "pointer"}}>
                                                                                <div className="ibox-thumb">
                                                                                    <div
                                                                                        className="box-cover"
                                                                                        style={{ backgroundImage: "url(" + x.sFilePath + ")" }}>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="ibox-info">
                                                                                    <div className="box-title" >
                                                                                        <span>{x.sSubject}</span>
                                                                                    </div>
                                                                                    <ul className="box-links">
                                                                                        <li>
                                                                                            <div>
                                                                                                {" "}
                                                                                                <i className="icofont-ui-calendar"></i> {x.sDate}
                                                                                            </div>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )

                                                                })
                                                            }
                                                        </>
                                                        :
                                                        null
                                                    :
                                                    null
                                            }
                                            {/*
                                                <>
                                                    <div className="news-related">
                                                        <div className="news-panel">
                                                            <div className="panel-head">
                                                                <div className="panel-title">ข่าวที่เกี่ยวข้อง</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-xl-12 mt-3 mt-sm-5">
                                                            <div className="ibox ibox-1">
                                                                <div className="ibox-thumb">
                                                                    <a
                                                                        className="box-cover"
                                                                        style={{
                                                                            backgroundImage:
                                                                                "url(" +
                                                                                "../Images/home/example/news/news1.jpg" +
                                                                                ")",
                                                                        }}
                                                                    ></a>
                                                                </div>
                                                                <div className="ibox-info">
                                                                    <div className="box-title">
                                                                        <span>ถนนสีเขียวนำร่อง เพื่อคุณภาพที่ดีของคนเมือง</span>
                                                                    </div>
                                                                    <div className="box-detail">
                                                                        <span>
                                                                            Lorem Ipsum is simply dummy text of the printing and
                                                                            typesetting industry. Lorem Ipsum has been the
                                                                            industry's standard dummy text ever since the 1500s,
                                                                            when an unknown printer took a galley of type and
                                                                            scrambled it to make a type specimen book. It has
                                                                            survived not only five centuries, but also the leap
                                                                            into electronic typesetting, remaining essentially
                                                                            unchanged. It was popularised in the 1960s with the
                                                                            release of Letraset sheets containing Lorem Ipsum
                                                                            passages, and more recently with desktop publishing
                                                                            software like Aldus PageMaker including versions of
                                                                            Lorem Ipsum.
                                                                        </span>
                                                                    </div>
                                                                    <ul className="box-links">
                                                                        <li>
                                                                            <div>
                                                                                <i className="icofont-clock-time"></i> 10:43
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div>
                                                                                {" "}
                                                                                <i className="icofont-ui-calendar"></i> 20/01/2564
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div>
                                                                                <i className="icofont-eye-alt"></i> 184,123
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-12 mt-3 mt-sm-5">
                                                            <div className="ibox ibox-2">
                                                                <div className="ibox-thumb">
                                                                    <a
                                                                        className="box-cover"
                                                                        style={{
                                                                            backgroundImage:
                                                                                "url(" +
                                                                                "../Images/home/example/news/news1.jpg" +
                                                                                ")",
                                                                        }}
                                                                    ></a>
                                                                </div>
                                                                <div className="ibox-info">
                                                                    <div className="box-title">
                                                                        <span>ถนนสีเขียวนำร่อง เพื่อคุณภาพที่ดีของคนเมือง</span>
                                                                    </div>
                                                                    <div className="box-detail">
                                                                        <span>
                                                                            Lorem Ipsum is simply dummy text of the printing and
                                                                            typesetting industry. Lorem Ipsum has been the
                                                                            industry's standard dummy text ever since the 1500s,
                                                                            when an unknown printer took a galley of type and
                                                                            scrambled it to make a type specimen book. It has
                                                                            survived not only five centuries, but also the leap
                                                                            into electronic typesetting, remaining essentially
                                                                            unchanged. It was popularised in the 1960s with the
                                                                            release of Letraset sheets containing Lorem Ipsum
                                                                            passages, and more recently with desktop publishing
                                                                            software like Aldus PageMaker including versions of
                                                                            Lorem Ipsum.
                                                                        </span>
                                                                    </div>
                                                                    <ul className="box-links">
                                                                        <li>
                                                                            <div>
                                                                                <i className="icofont-clock-time"></i> 10:43
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div>
                                                                                {" "}
                                                                                <i className="icofont-ui-calendar"></i> 20/01/2564
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div>
                                                                                <i className="icofont-eye-alt"></i> 184,123
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            */}
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        :
                        null
                }
                
            </div>
        </React.Fragment>
    );
};
export default Main_Content_Detail;
