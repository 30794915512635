import { Checkbox, Col, Form, Input, Row, Switch } from "antd";
import * as React from "react";
import { ButtonBack, ButtonSave } from "../Systems/button_all";
import { BoxMsg, Decrypt, DialogConfirm, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import Fileuploader, { Extension } from "../../Fileuploader";
import AuthenService from "../Service/AuthenService";

const Admin_InfoGraphics = () => {
    const [SetValue] = Form.useForm();
    const [file, setfile] = React.useState([] as any);
    const [fileEN, setfileEN] = React.useState([] as any);

    const [isEdit, setisEdit] = React.useState(false);


    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            let test = UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == 2).map(t => t.nPermission)[0] === 2 ? false : true

            setisEdit(test)
        }
    }
    //const [lstData, setLstData] = React.useState(null as any);
    var onUploadFileSuccess = () => {
        SetValue.validateFields(['lstFileInfo']);
    };
    var onUploadFileSuccess1 = () => {
        SetValue.validateFields(['lstFileInfoEN']);
    };
    const GetInfoGraphics = async () => {
        let result: any = await AxiosGetJson("api/AdminInfo/GetInfoGraphics");
        SetValue.setFieldsValue({
            lstFileInfo: result.lstFileInfo,
            lstFileInfoEN: result.lstFileInfoEN,
        });
        setfile(result.lstFileInfo);
        setfileEN(result.lstFileInfoEN);
        //setLstData(result);
    }

    const onSave = (values: any) => {
        DialogConfirm(async () => {
            var data = {
                lstFileInfo: file,
                lstFileInfoEN: fileEN,
            }
            let result: any = await AxiosPostJson("api/AdminInfo/SaveData", data);
            if (result.data.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Save, () => {
                    //history.push("/admin-intropage-list");
                });
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }

        });
    }

    React.useEffect(() => {
        GetUserInfo();
    }, []);

    React.useEffect(() => {
        GetInfoGraphics()
        window.scrollTo(0, 0);
    }, [isEdit])

    return (
        <Form name="From" form={SetValue} onFinish={onSave}
            onFinishFailed={({ errorFields }) => {
                let element = document.querySelector(`#From_${errorFields[0].name}`);
                    var firstErrorElemY: any = null;
                    if (element) {
                        firstErrorElemY = element.getBoundingClientRect().top - 100; // bar height(100)px
                        window.scrollTo(0, window.scrollY + firstErrorElemY)
                    }
                    else{
                        element = document.getElementById(`${errorFields[0].name}`);
                        if (element) {
                            firstErrorElemY = element.getBoundingClientRect().top - 100; // bar height(100)px
                            window.scrollTo(0, window.scrollY + firstErrorElemY)
                        }
                    }
            }}>
            <br />
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <b>ภาพประกอบ </b>
                    <span className="text-danger">*</span>
                    <span className="text-primary small"> ภาษาไทย </span>
                    <Form.Item
                        name="lstFileInfo"
                        rules={[
                            { required: true, message: BoxMsg.Desc_Required_Field + "ภาพประกอบ" },
                        ]}
                    >
                        <div id="lstFileInfo">
                        <Fileuploader
                            limit="1"
                            setFileList={setfile}
                            fileList={file}
                            onComplete={onUploadFileSuccess}
                            onRemoveComplete={onUploadFileSuccess}
                            fileMaxSize="10"
                            readOnly={!isEdit}
                            extensions={Extension.Image}
                        />
                        </div>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <b>ภาพประกอบ </b>
                    <span className="text-danger">*</span>
                    <span className="text-success small"> ภาษาอังกฤษ </span>
                    <Form.Item
                        name="lstFileInfoEN"
                        rules={[
                            { required: true, message: BoxMsg.Desc_Required_Field + "ภาพประกอบภาษาอังกฤษ" },
                        ]}
                    >
                        <div id="lstFileInfoEN">
                        <Fileuploader
                            limit="1"
                            setFileList={setfileEN}
                            fileList={fileEN}
                            onComplete={onUploadFileSuccess1}
                            onRemoveComplete={onUploadFileSuccess1}
                            fileMaxSize="10"
                            readOnly={!isEdit}
                            extensions={Extension.Image}
                        />
                        </div>
                    </Form.Item>
                </Col>
            </Row>
            <hr />
            <Row gutter={[16, 16]} justify="center">
                {isEdit ?
                    <Col>
                        <ButtonSave htmlType="submit" />
                    </Col>
                    : null}
            </Row>
        </Form>

    );
};
export default Admin_InfoGraphics;


