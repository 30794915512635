import * as React from 'react';
import Main_Content_Detail from '../main-content/content-detail'

const HL_Project_Detail = (props) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Main_Content_Detail isShowCover={true} adminMenu={"6"} />
    );
}
export default HL_Project_Detail;