import * as React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../main-home/home.css";
import { AxiosGetJson } from "../../Service/Config/AxiosMethod";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import TableAntd from "../../Systems/TableAntd";
import { useSelector, useDispatch } from "react-redux";
import {
  thailanguage,
  englishlanguage,
  currentlanguage,
} from "../../app/counterSlice";

const Main_Content_Index = ({ sType = "99" }) => {
  var type = sType ? sType : "99";
  const [sFilePath, setFilePath] = React.useState("");
  const [sLandingPage, setLandingPage] = React.useState("");
  const [lstIndex, setLstIndex] = React.useState([] as any);
  const history = useHistory();
  const translate = useSelector(currentlanguage);
  const dispatch = useDispatch();

  const SetBackground = async () => {
    let result: any = await AxiosGetJson("api/AdminInfo/GetBackground");
    setFilePath(result.sFilePath);
  };

  const GetLandingPage = async (TranslatType) => {
    let result: any = await AxiosGetJson(
      "api/AdminInfo/GetLandingPage?type=" + type + "&Mode=" + TranslatType
    );
    setLandingPage(result);
  };

  const GetListIndex = async (TranslatType) => {
    let result: any = await AxiosGetJson(
      "api/AdminInfo/GetListIndex?type=" + type + "&Mode=" + TranslatType
    );
    // if (result.length === 1) {
    //   onSelectSub(result[0].sID);
    // }
    setLstIndex(result);
  };

  const onSelectSub = (sID) => {
    let sTypeComponent = "";
    if (type === "5") {
      sTypeComponent = "/learning-center-list";
    }
    if (type === "6") {
      sTypeComponent = "/hl-project-list";
    }
    if (type === "7") {
      sTypeComponent = "/knowledge-list";
    }
    let sPath = `${sTypeComponent}?id=${sID}`;
    return history.push(sPath);
  };

  React.useEffect(() => {
    SetBackground();
    GetLandingPage(translate.TranslatType);
    GetListIndex(translate.TranslatType);
    window.scrollTo(0, 0);
  }, [translate]);

  return (
    <React.Fragment>
      <div id="PAGE_HOME" style={{ backgroundImage: "url(" + sFilePath + ")" }}>
        <div className="home-news">
          <div className="news-panel">
            <div className="panel-head">
              <div className="panel-title">
                {type === "5"
                  ? translate.LearningCenter
                  : type === "6"
                  ? translate.HlProject
                  : type === "7"
                  ? translate.Knowledge
                  : ""}
              </div>
            </div>
            <div className="contents-index">
              {/* <div className="index-contents">
                                <div className="index"> */}
              {type !== "7" ? (
                <div
                  className="panel-body-transparent"
                  style={{ justifyContent: "start" }}
                >
                  {lstIndex.length > 0
                    ? lstIndex.map((i, indx) => {
                        return (
                          <div
                            key={"x_" + indx}
                            className="contents-item"
                            onClick={() => onSelectSub(i.sID)}
                          >
                            <div
                              className="contents-cover"
                              style={{
                                backgroundImage: "url(" + i.sImageUrl + ")",
                                // backgroundColor: "transparent",
                                backgroundSize: "contain",
                              }}
                            ></div>
                            <div className="contents-title">{i.sName}</div>
                          </div>
                        );
                      })
                    : null}
                  {/*<div className="news-item">
                                        <div className="news-cover" style={{ backgroundImage: "url(" + require("../../Images/home/example/news/news2.png") + ")" }}></div>
                                        <div className="news-title">โครงการราษฎรอาสาสมัครพิทักษ์ป่า [รสกป]</div>
                                    </div>*/}
                </div>
              ) : (
                <div className="panel-body-box">
                  <div className="b-box">
                    {lstIndex.length > 0
                      ? lstIndex.map((i, indx) => {
                          return (
                            <a
                              key={"ref_" + indx}
                              onClick={() => onSelectSub(i.sID)}
                              className="box-flag"
                              style={{
                                backgroundImage: "url(" + i.sImageUrl + ")",
                              }}
                            >
                              <div className="flag-title">{i.sName}</div>
                            </a>
                          );
                        })
                      : null}
                    {/* <a className="box-flag" style={{backgroundImage: `url(${require("../../Images/home/example/news/news1.jpg")})`,}}><div className="flag-title">ข้อมูลสัตว์</div></a>
                                        <a className="box-flag" style={{backgroundImage: `url(${require("../../Images/home/example/news/news1.jpg")})`,}}><div className="flag-title">วีดิทัศน์</div></a>
                                        <a className="box-flag" style={{backgroundImage: `url(${require("../../Images/home/example/news/news1.jpg")})`,}}><div className="flag-title">E-BOOK</div></a>
                                        <a className="box-flag" style={{backgroundImage: `url(${require("../../Images/home/example/news/news1.jpg")})`,}}><div className="flag-title">งานวิจัย</div></a>
                                        <a className="box-flag" style={{backgroundImage: `url(${require("../../Images/home/example/news/news1.jpg")})`,}}><div className="flag-title">กฎหมายป่าไม้</div></a>
                                        <a className="box-flag" style={{backgroundImage: `url(${require("../../Images/home/example/news/news1.jpg")})`,}}><div className="flag-title">T-VER/LESS</div></a>
                                        <a className="box-flag" style={{backgroundImage: `url(${require("../../Images/home/example/news/news1.jpg")})`,}}><div className="flag-title">INNOVATION</div></a> */}
                  </div>
                </div>
              )}
              <div
                className="panel-body-content mt-4"
                style={{ justifyContent: "start", padding: "0 2.5vw" }}
              >
                <div dangerouslySetInnerHTML={{ __html: sLandingPage }}></div>
              </div>
            </div>
            {/* </div>
                        </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Main_Content_Index;
