import * as React from 'react';
import Admin_Content_Sub from '../admin-content/admin-content-sub-edit'
import Admin_Content_Index_Edit from '../admin-content/new-content/admin-content-index-edit'
import { parse } from "query-string"

const Admin_learning_type_edit = (props) => {
    return (
        // <Admin_Content_Sub nPerMenuId={6} sType={"3"} backEvent={"/admin-learning-center-list"} />
        <Admin_Content_Index_Edit nPerMenuId={6} sType={"3"} backEvent={"/admin-learning-center-list"} />
    );
}
export default Admin_learning_type_edit;