import { Col, Input, Row, Space, Tag } from "antd";
import * as React from "react";
import { Empty, Select as AntdSelect } from "antd";
import { ButtonAdd_Table, ButtonEdit_Table, ButtonInfo_Table, ButtonSearch } from "../Systems/button_all";
import TableAntd from "../Systems/TableAntd";
import { useHistory } from "react-router";
import { BoxMsg, DialogDelete, Encrypt, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import AuthenService from "../Service/AuthenService";

const Admin_Content_List = () => {
    const history = useHistory();
    const [selectedRows, setselectedRows] = React.useState([] as Array<any>);
    const [selectedRowKeys, setrowSelected] = React.useState([] as any);
    const [lstContent, setLstContent] = React.useState([] as any);
    const [lstOrder, setLstOrder] = React.useState([] as any);
    const [txtSearch, SetTxtSearch] = React.useState({
        sSubject: "",
        sStatus: null as any
    });
    const [total, setTotal] = React.useState(0);
    const [sPageA, setsPageA] = React.useState("1" as string || undefined);
    const [sPageSizeA, setsPageSizeA] = React.useState("10" as string || undefined);
    const [sFieldA, setsFieldA] = React.useState("" as string || undefined);
    const [sOrderA, setsOrderA] = React.useState("" as string || undefined);
    const [nCurrentPage, setCurrentPage] = React.useState(1);
    const [isEdit, setisEdit] = React.useState(false);
    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            setisEdit(UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == 13).map(t => t.nPermission)[0] === 2 ? false : true)
        }
    }

    const header = [
        {
            title: "", dataIndex: "key", key: "Key_No_1", width: 1,className: "text-center",
            render: (value: any, record: { sOrder: string, nMenuID: number }) => {
                return (null);
            }, fixed: true,
        },
        {
            title: "ลำดับที่", dataIndex: "sOrder", sorter: true, key: "Key_No_11", width: 75, className: "text-center",
            render: (value: any, record: { sOrder: string, nMenuID: number }) => {
                return (
                    <AntdSelect
                        //allowClear={true}
                        showSearch={true}
                        style={{ width: "100%" }}
                        value={record.sOrder}
                        placeholder="- No. -"
                        onChange={(e: any) => { SetOrder(e, record.nMenuID) }}
                        filterOption={(input, option) =>
                            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            lstOrder.map((p) => <option key={"option_" + p} value={p.value}>{p.label + ""}</option>)
                        }
                    </AntdSelect>
                );
            }, fixed: true,
        },
        { title: "ชื่อเมนู", dataIndex: "sMenuName", key: "Key_No_2", sorter: true, className: "text-left text-nowrap", width: 325},
        { title: "ชื่อเมนูภาษาอังกฤษ", dataIndex: "sMenuNameENG", key: "Key_No_3", sorter: true, className: "text-left text-nowrap", width: 325 },
        {
            title: 'สถานะ',
            dataIndex: 'isActive',
            width: 50,
            sorter: true,
            className: "text-center",
            render: (text: any, record: { sIsActive: string; }) => (
                <Tag color={record.sIsActive === "1" ? "#60cc28" : "#cc2838"} key={text} style={{ borderRadius: "32px" }}>
                    {record.sIsActive === "1" ? "ใช้งาน" : "ไม่ใช้งาน"}
                </Tag>
            ),
        },
        {
            title: {/*<ButtonAdd_Table onClick={() => { history.push("/admin-cms"); }} />*/ },
            dataIndex: "4", key: "Key_No_8",
            className: "text-center",
            render: (text: any, record: any) => (
                isEdit ?
                <ButtonEdit_Table onClick={() => { getLinkTo_Edit(record.sMenuID) }} />
                :
                <ButtonInfo_Table
                    onClick={() => { getLinkTo_Edit(record.sMenuID); }}
                >
                    <i className="fas fa-pencil-alt"></i>
                </ButtonInfo_Table>
                

            ),
            width: 1,
        },
    ];

    const SetOrder = async (sOrder: string, nMenuID: number) => {

        if (sOrder != "0") {
            let result: any = await AxiosPostJson("api/AdminCMS/SetOrder?sOrder=" + sOrder + "&nMenuID=" + nMenuID);


            if (result.data.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Save, null);
                onselect([], []);
                GetDataOnPageLoad(sPageA, sPageSizeA, sFieldA, sOrderA);
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }
        }

    }

    const getLinkTo_Edit = (sID: any) => {
        let sTypeComponent = "/admin-cms";
        let sPath = `${sTypeComponent}?id=${sID}`;
        return history.push(sPath);
    };

    const GetDataOnPageLoad = async (sPage, sPageSize, sField, sOrder) => {
        var data = {
            sMenuName: txtSearch.sSubject,
            sStatus: txtSearch.sStatus,
            sPage: sPage ? sPage.toString() : sPageA,
            sPageSize: sPageSize ? sPageSize.toString() : sPageSizeA,
            sField: sField ? sField : sFieldA,
            sOrder: sOrder ? sOrder : sOrderA,
        };
        let result: any = await AxiosPostJson("api/AdminCMS/GetCMSList", data);
        setLstContent(result.data.cCMSList);
        setTotal(result.data.nTotal);
    }

    const onPageChange = async (page, pageSize, sorter) => {
        setsPageA(page);
        setsPageSizeA(pageSize);
        GetDataOnPageLoad(page, pageSize, sFieldA, sOrderA);
        setCurrentPage(page);
        window.scrollTo(0, 0)
    }

    const onTableChange = async (pagination, filters, sorter, extra) => {
        if (sorter) {
            setsFieldA(sorter.field);
            setsOrderA(sorter.order);
            GetDataOnPageLoad2(sPageA, sPageSizeA, sorter.field, sorter.order);
        }
    }

    const GetDataOnPageLoad2 = async (sPage, sPageSize, sField, sOrder) => {
        var data = {
            sMenuName: txtSearch.sSubject,
            sStatus: txtSearch.sStatus,
            sPage: sPage ? sPage.toString() : sPageA,
            sPageSize: sPageSize ? sPageSize.toString() : sPageSizeA,
            sField: sField ? sField : sFieldA,
            sOrder: sOrder,
        };
        let result: any = await AxiosPostJson("api/AdminCMS/GetCMSList", data);
        setLstContent(result.data.cCMSList);
        setTotal(result.data.nTotal);
    }

    const onDeleteClick = async () => {
        if (selectedRows.length > 0) {
            DialogDelete(async () => {
                let result: any = await AxiosPostJson("api/AdminCMS/DeleteData", selectedRows);
                if (result.data.sStatus === Responsestart.success) {
                    Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete,
                        () => {
                            setselectedRows([]);
                            GetDataOnPageLoad(sPageA, sPageSizeA, sFieldA, sOrderA);
                        });
                } else if (result.data.sStatus === Responsestart.warning) {
                    Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                } else {
                    Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                }
            });
        }
    }

    const onselect = (selectedRowKeys, selectedRows: React.SetStateAction<any[]>) => {
        setselectedRows(selectedRows);
        setrowSelected(selectedRowKeys);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onselect,
        //getCheckboxProps: (record) => ({
        //    disabled: false, // Column configuration not to be checked
        //    name: record.isHasDelete,
        //}),
    };

    const GetOrder = async () => {
        let result: any = await AxiosPostJson("api/AdminCMS/GetListOrder");
        setLstOrder(result.data);
    }

    React.useEffect(() => {
        GetOrder();
        GetDataOnPageLoad(undefined, undefined, undefined, undefined);
        GetUserInfo();

    }, []);

    return (
        <React.Fragment>
            <br />
            <Row justify="end" gutter={[16, 16]}>
                <Col xl={5} md={12} xs={24}>
                    <Input
                        autoComplete="off"
                        placeholder="ชื่อเมนู"
                        onChange={(e) =>
                            SetTxtSearch({
                                ...txtSearch,
                                sSubject: e.target.value,
                            })
                        }
                        onKeyPress={(e) => {
                            e.key === "Enter" && GetDataOnPageLoad(undefined, undefined, undefined, undefined);
                        }}
                    />
                </Col>
                <Col xl={4} md={10} xs={20}>
                    <AntdSelect
                        allowClear={true}
                        showSearch={true}
                        style={{ width: "100%" }}
                        value={txtSearch.sStatus}
                        placeholder="- สถานะ -"
                        onChange={(e) => {
                            SetTxtSearch({
                                ...txtSearch,
                                sStatus: e,
                            });
                        }}
                        filterOption={(input, option) =>
                            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <option value="1">ใช้งาน</option>
                        <option value="0">ไม่ใช้งาน</option>
                    </AntdSelect>
                </Col>
                <Col xl={1} md={2} xs={4}>
                    <ButtonSearch onClick={() => { GetDataOnPageLoad(sPageA, sPageSizeA, sFieldA, sOrderA); }} />
                </Col>
            </Row>
            <br />
            <div className="ant-spin-nested-loading">
                <div className="ant-spin-container">
                    <div className="ant-table">
                        <div className="ant-table-container">
                            <div className="ant-table-content">
                                <TableAntd
                                    dataSource={lstContent}
                                    columns={header}
                                    onPageChange={onPageChange}
                                    currentPage={nCurrentPage}
                                    total={total}
                                    //rowSelection={rowSelection}
                                    onChange={onTableChange}
                                    onShowDelete={false}
                                    //onClickDeleteData={onDeleteClick}
                                    //scroll={{ x: 800 }}
                                    pageSize={sPageSizeA ? parseInt(sPageSizeA) : 10}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default Admin_Content_List;