import { Button, Col, Form, Input, Modal, Row, Switch } from "antd";
import * as React from "react";
import { BoxMsg, Decrypt, DialogConfirm, DialogDelete, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import { Select as AntdSelect } from "antd";
import TableAntd from "../Systems/TableAntd";
import { ButtonBack, ButtonDelete, ButtonDelete_Table, ButtonSave, Button_Cancel } from "../Systems/button_all";
import { useHistory } from "react-router";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import { parse } from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthenService from "../Service/AuthenService";

const Controller = 'api/AdminContactUs/';


const Admin_Contact_Us_EditGroup = () => {
    const [SetValue] = Form.useForm();
    const { Option } = AntdSelect;
    const [options, setOptions] = React.useState([] as Array<any>);
    const [V_ComplaintGroup, setV_ComplaintGroup] = React.useState({} as any);
    const [V_AdminGroup_Complaint, setV_AdminGroup_Complaint] = React.useState([] as any);
    const [oSelectAutocomplete, setoSelectAutocomplete] = React.useState({} as any);
    const history = useHistory();
    var { sID } = parse(window.location.search);
    sID = sID && Decrypt(sID)

    React.useEffect(() => {
        GetDataOnPageLoad();
        GetV_AdminGroup_ComplaintbysID();
        GetT_AdminUser();
        GetUserInfo();
    }, []);
    const [isEdit, setisEdit] = React.useState(false);
    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            setisEdit(UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == 10).map(t => t.nPermission)[0] === 2 ? false : true)
        }
    }

    const GetDataOnPageLoad = async () => {
        let result: any = await AxiosGetJson(Controller + "GetV_ComplaintGroupbysID?sID=" + (sID === undefined ? "0" : sID));
        setV_ComplaintGroup(result);
        SetValue.setFieldsValue({
            sName: result.sName,
            sName_EN: result.sName_EN,
            isActive: result.isActive
        })

    }
    const GetV_AdminGroup_ComplaintbysID = async () => {
        let result: any = await AxiosGetJson(Controller + "GetV_AdminGroup_ComplaintbysID?sID=" + (sID === undefined ? "0" : sID));
        setnTotal(result.nTotal)
        setV_AdminGroup_Complaint(result.V_AdminGroup_Complaint);
    }

    const GetT_AdminUser = async () => {
        let result: any = await AxiosGetJson(Controller + "GetT_AdminUser");
        setOptions(result)
    }

    const onSave = async (values: any) => {

        let data = {
            sID: (sID === undefined ? "0" : sID),
            sName: values.sName,
            sName_EN: values.sName_EN,
            isActive: values.isActive,
            V_AdminGroup_Complaint: V_AdminGroup_Complaint
        }
        DialogConfirm(async () => {
            let result: any = await AxiosPostJson(Controller + "SaveDataGroup", data);
            if (result.data.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Save, () => {
                    onBack();
                });
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }
        })
    };
    const onBack = () => {
        history.push("/admin-contact-us-list?sMode=2");
    };
    const onSelectAutocomplete = async (value: any, select: any,) => {
        setoSelectAutocomplete(select);
    };
    const header = [
        {
            title: '',
            dataIndex: '',
            key: '',
            className: "text-center",
            width: "1%",
            render: (value, obj, index) => {
                return (
                    <Button disabled={!isEdit} shape="circle" type="primary" size="middle" danger onClick={() => onDeleteData(index)} ><i className="fas fa-times"></i></Button>
                );
            },
        },
        {
            title: 'ลำดับที่',
            dataIndex: 'key',
            key: 'key',
            className: "text-center",
            width: "10%",
            render: (value) => {
                return (<>{value}.</>);
            },
        },
        {
            title: "ชื่อ - นามสกุล",
            dataIndex: 'sName',
            key: 'sName',
            width: "40%",
            //sorter: true,
        },
        {
            title: 'ชื่อบัญชีผู้ใช้งาน',
            dataIndex: 'sUsername',
            key: "sUsername",
            //sorter: true,
            width: "40%",
        },
    ];
    //*****************************TableAntd
    const [selectedRows, setselectedRows] = React.useState([] as Array<any>);
    const [selectedRowKeys, setrowSelected] = React.useState([] as any);
    const [sPageA, setsPageA] = React.useState("1" as string || undefined);
    const [sPageSizeA, setsPageSizeA] = React.useState("10" as string || undefined);
    const [sFieldA, setsFieldA] = React.useState("" as string || undefined);
    const [sOrderA, setsOrderA] = React.useState("" as string || undefined);
    const [nCurrentPage, setCurrentPage] = React.useState(1);
    const [nTotal, setnTotal] = React.useState(0 as number);

    const onselect = (selectedRowKeys, selectedRows: React.SetStateAction<any[]>) => {
        setselectedRows(selectedRows);
        setrowSelected(selectedRowKeys);
    }

    const onPageChange = async (page, pageSize, sorter) => {
        onselect([], []);
        setsPageA(page);
        setsPageSizeA(pageSize);
        //SearchData(page, pageSize, undefined, undefined);
        setCurrentPage(page);
        window.scrollTo(0, 0);
    }
    const onTableChange = async (pagination, filters, sorter, extra) => {
        if (sorter) {
            onselect([], []);
            setsFieldA(sorter.field);
            setsOrderA(sorter.order);
            //SearchData(undefined, undefined, sorter.field, sorter.order);
        }
    }
    //*****************************TableAntd
    const onDeleteData = async (id: any) => {
        DialogDelete(async () => {
            let array = [...V_AdminGroup_Complaint]; // make a separate copy of the array
            if (id !== -1) {
                array.splice(id, 1);
                array.forEach((o, id) => o.key = id + 1);
                setV_AdminGroup_Complaint(array)
            }
            Sweetalert.Success(BoxMsg.Title_Confirm, BoxMsg.Desc_Success_Delete, null);

        })
    }

    const onClickAdd = async () => {

        if (oSelectAutocomplete.sFname !== undefined) {

            let s = [...V_AdminGroup_Complaint]
            let nkey = s.length + 1;
            s.push({
                key: nkey,
                nID_Group: parseInt(sID === undefined ? "0" : sID![0]),
                nID: 0,
                sName: oSelectAutocomplete.sFname,
                sUsername: oSelectAutocomplete.sCODE,
            })
            setV_AdminGroup_Complaint(s);
            SetValue.setFieldsValue({
                options: undefined
            });
            setoSelectAutocomplete({});
        } else {
            Sweetalert.Warning(BoxMsg.Title_Warning, BoxMsg.Desc_Required_Field + "พนักงานที่ดูแล", null);
        }
    }


    const onDelete = async () => {

        if (sID != undefined) {
            DialogDelete(async () => {
                let result: any = await AxiosPostJson(Controller + "onDeleteBysID?sID=" + (sID === undefined ? "0" : sID));
                if (result.data.sStatus === Responsestart.success) {
                    Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete, null);
                    onBack();
                } else if (result.data.sStatus === Responsestart.warning) {
                    Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                } else {
                    Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                }
            });
        }

    };

    return (
        <React.Fragment>
            <br />
            <Form
                onFinish={onSave}
                form={SetValue}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <b> หัวข้อติดต่อ </b> <span className="text-danger ">*</span><span className="text-primary small"> ภาษาไทย </span>
                        <Form.Item
                            name="sName"
                            rules={[{ required: true, message: BoxMsg.Desc_Required_Field + "ชื่อกลุ่ม" }]}

                        >
                            <Input disabled={!isEdit} maxLength={200} autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <b> หัวข้อติดต่อ </b> <span className="text-danger ">*</span><span className="text-success small"> ภาษาอังกฤษ </span>
                        <Form.Item
                            name="sName_EN"
                            rules={[{ required: true, message: BoxMsg.Desc_Required_Field + "ชื่อกลุ่ม" }]}

                        >
                            <Input disabled={!isEdit} maxLength={200} autoComplete="off" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                        <b>พนักงานที่ดูแล </b>
                        <Form.Item
                            name="options"
                        >
                            <AntdSelect
                                disabled={!isEdit}
                                allowClear={true}
                                showSearch={true}
                                options={options.filter(o => V_AdminGroup_Complaint.every(m => m.sUsername != o.sCODE))}
                                onSelect={onSelectAutocomplete}
                                placeholder="รหัสพนักงาน, ชื่อ-นามสกุล"
                            >
                            </AntdSelect>

                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                        <br />
                        <Button
                            disabled={!isEdit}
                            shape="round"
                            size="middle"
                            onClick={() => { onClickAdd(); }}
                            type="primary"
                            style={{
                                marginBottom: 16,
                            }}
                        >
                            <i className="fas fa-plus"></i>&nbsp;เพิ่ม
                        </Button>
                    </Col>
                </Row>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <TableAntd
                        dataSource={V_AdminGroup_Complaint}
                        columns={header}
                        onPageChange={onPageChange}
                        currentPage={nCurrentPage}
                        total={nTotal}
                        onChange={onTableChange}
                        onDisableDelete={selectedRows.length > 0 ? false : true}
                        onShowDelete={false}
                        onShowPagination={false}
                        pageSize={sPageSizeA ? parseInt(sPageSizeA) : 10}
                    />
                </Col>

                <br />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <b>สถานะ</b>
                        <Form.Item name="isActive" valuePropName="checked">
                            <Switch disabled={!isEdit} defaultChecked={true} checkedChildren="ใช้งาน" unCheckedChildren="ไม่ใช้งาน" style={{width : "100px"}} />
                        </Form.Item>
                    </Col>
                </Row>
                <hr />
                <Row gutter={[16, 16]} justify="center">

                    {isEdit ?
                        <React.Fragment>
                            <Col>
                                <ButtonSave htmlType="submit" />
                            </Col>

                            {
                                sID === undefined || V_ComplaintGroup.sIsuse === "Y" ?
                                    null
                                    :
                                    <Col>
                                        <ButtonDelete onClick={() => { onDelete() }} />
                                    </Col>
                            }

                        </React.Fragment>
                        : null}
                    <Col>

                        <Form.Item >
                            <Button_Cancel onClick={() => { history.push("/admin-contact-us-list?sMode=2") }} />
                        </Form.Item>

                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    );
};
export default Admin_Contact_Us_EditGroup;
