import { Col, Input, Row, Space, Tag } from "antd";
import * as React from "react";
import { Empty, Select as AntdSelect } from "antd";
import { ButtonAdd_Table, ButtonEdit_Table, ButtonExcel, ButtonInfo_Table, ButtonSearch } from "../Systems/button_all";
import TableAntd from "../Systems/TableAntd";
import { AxiosBlobJson, AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import { BoxMsg, DialogDelete, Encrypt, Responsestart, setNameFormatReport, Sweetalert } from "../Systems/SystemComponent";
import AuthenService from "../Service/AuthenService";
import { useHistory } from "react-router";
import ThaiDatePicker from "../ST_Handlers/ThaiDatePicker";

const Controller = 'api/AdminSubscribe/';


const Admin_Subscribe_List = () => {
    const [V_Subscribe, setV_Subscribe] = React.useState([] as any)
    const history = useHistory();



    const [txtSearch, SetTxtSearch] = React.useState({
        stxtEmail: "" as string || undefined,
        dSearcDate: undefined as (Date | undefined),
    });

    React.useEffect(() => {
        GetDataOnPageLoad();
        GetUserInfo();
    }, []);
    const [isEdit, setisEdit] = React.useState(false);
    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            setisEdit(UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == 44).map(t => t.nPermission)[0] === 44 ? false : true)
        }
    }

    const GetDataOnPageLoad = async () => {
        let result: any = await AxiosGetJson(Controller + "GetV_Subscribe");
        setnTotal(result.nTotal)
        setV_Subscribe(result.V_Subscribe);
    }

    const SearchData = async (sPage, sPageSize, sField, sOrder) => {
        let Page = sPage == undefined ? sPageA : sPage;
        let PageSize = sPageSize == undefined ? sPageSizeA : sPageSize;
        let Field = sField == undefined ? sFieldA : sField;
        let Order = sOrder == undefined ? sOrderA : sOrder;
        setCurrentPage(Page);

        let stxtEmail = txtSearch.stxtEmail == undefined ? "" : txtSearch.stxtEmail;
        let dSearcDate = txtSearch.dSearcDate == undefined ? "" : DateFormat(txtSearch.dSearcDate);

        let result: any = await AxiosGetJson(Controller + "SearchData?stxtEmail=" + encodeURIComponent(stxtEmail) + "&dSearcDate=" + dSearcDate + "&sPage=" + Page + "&sPageSize=" + PageSize + "&sField=" + Field + "&sOrder=" + Order);
        setnTotal(result.nTotal)
        setV_Subscribe(result.V_Subscribe);
    }

    const DateFormat = (today: any) => {
        let day = today.getDate() < 10 ? ("0" + today.getDate().toString()) : today.getDate().toString();
        let month = (today.getMonth() + 1) < 10 ? ("0" + (today.getMonth() + 1).toString()) : (today.getMonth() + 1).toString();
        let date = day + "-" + month + "-" + today.getFullYear();

        return date;
    }


    //*****************************TableAntd
    const [selectedRows, setselectedRows] = React.useState([] as Array<any>);
    const [selectedRowKeys, setrowSelected] = React.useState([] as any);
    const [sPageA, setsPageA] = React.useState("1" as string || undefined);
    const [sPageSizeA, setsPageSizeA] = React.useState("10" as string || undefined);
    const [sFieldA, setsFieldA] = React.useState("" as string || undefined);
    const [sOrderA, setsOrderA] = React.useState("" as string || undefined);
    const [nCurrentPage, setCurrentPage] = React.useState(1);
    const [nTotal, setnTotal] = React.useState(0 as number);

    const onselect = (selectedRowKeys, selectedRows: React.SetStateAction<any[]>) => {
        setselectedRows(selectedRows);
        setrowSelected(selectedRowKeys);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onselect,
        getCheckboxProps: (record) => ({
            disabled: record.sIsuse === 'Y', // Column configuration not to be checked
            sIsuse: record.sIsuse,
        }),
    };

    const onPageChange = async (page, pageSize, sorter) => {
        onselect([], []);
        setsPageA(page);
        setsPageSizeA(pageSize);
        SearchData(page, pageSize, undefined, undefined);
        setCurrentPage(page);
        window.scrollTo(0, 0);
    }
    const onTableChange = async (pagination, filters, sorter, extra) => {
        if (sorter) {
            onselect([], []);
            setsFieldA(sorter.field);
            setsOrderA(sorter.order);
            SearchData(undefined, undefined, sorter.field, sorter.order);
        }
    }
    const onDeleteClick = async () => {
        if (selectedRows.length > 0) {

            DialogDelete(async () => {
                let result: any = await AxiosPostJson(Controller + "onDeleteData", selectedRows);
                if (result.data.sStatus === Responsestart.success) {
                    Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete, null);
                    SearchData(undefined, undefined, undefined, undefined);
                    onselect([], []);
                } else if (result.data.sStatus === Responsestart.warning) {
                    Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                } else {
                    Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                }
            });
        }

    }
    //*****************************TableAntd

    const getLinkTo_Edit = (nSocialID: number) => {
        let sID = Encrypt(nSocialID)
        let sTypeComponent = `/admin-subscribe-edit`
        let sPath = `${sTypeComponent}?sID=${sID}`
        return history.push(sPath);
    };

    const header = [
        {
            title: 'ลำดับที่',
            dataIndex: 'key',
            key: 'key',
            className: "text-center",
            width: 100,
            render: (value) => {
                return (<>{value}.</>);
            },
        },
        {
            title: "Email",
            dataIndex: 'sEmail',
            key: 'sEmail',
            sorter: true,
        },
        {
            title: "วันที่สร้าง",
            dataIndex: 'sCreate',
            key: 'sCreate',
            sorter: true,
            className: "text-center",
        },
        {
            title: "IP",
            dataIndex: 'Create_IP',
            key: 'Create_IP',
            sorter: true,
            className: "text-center",
        },
        //{
        //    title: isEdit ? <ButtonAdd_Table onClick={() => { history.push("/admin-subscribe-edit"); }} /> : null,
        //    key: 'action',
        //    className: "text-center",
        //    render: (text, record) => (
        //        isEdit ?
        //            <Space size="middle">
        //                <ButtonEdit_Table
        //                    onClick={() => { getLinkTo_Edit(record.nID); }}
        //                >
        //                    <i className="fas fa-pencil-alt"></i>
        //                </ButtonEdit_Table>
        //            </Space>
        //            :
        //            <Space size="middle">
        //                <ButtonInfo_Table
        //                    onClick={() => { getLinkTo_Edit(record.nID); }}
        //                >
        //                    <i className="fas fa-pencil-alt"></i>
        //                </ButtonInfo_Table>
        //            </Space>
        //    ),
        //    width: "1%",
        //},
    ];



    const ExportExce = async () => {

        let Page = sPageA;
        let PageSize =sPageSizeA ;
        let Field = sFieldA ;
        let Order = sOrderA ;

        let stxtEmail = txtSearch.stxtEmail == undefined ? "" : txtSearch.stxtEmail;
        let dSearcDate = txtSearch.dSearcDate == undefined ? "" : DateFormat(txtSearch.dSearcDate);


        let fileName = setNameFormatReport("Report_Subscribe") + ".xlsx";
        let result: any = await AxiosBlobJson(Controller + "ExportExce?stxtEmail=" + encodeURIComponent(stxtEmail) + "&dSearcDate=" + dSearcDate + "&sPage=" + Page + "&sPageSize=" + PageSize + "&sField=" + Field + "&sOrder=" + Order);
        saveExcel(result.data, fileName)

    }

    const saveExcel = (result: BlobPart, fileName: string) => {
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }


    return (
        <React.Fragment>
            <br />
            <Row justify="end" gutter={[16, 16]}>
                <Col >

                    <Input
                        autoComplete="off"
                        placeholder="Email"
                        onChange={(e) =>
                            SetTxtSearch({
                                ...txtSearch,
                                stxtEmail: e.target.value,
                            })
                        }
                        onKeyPress={(e) => {
                            e.key === "Enter" && SearchData(1, undefined, undefined, undefined);
                        }}
                    />
                </Col>
                <Col >
                    <ThaiDatePicker
                        disabled={!isEdit}
                        value={txtSearch.dSearcDate}
                        //maxDate={txtSearch.dSearcDate}
                        small={false}
                        placeholder="วันที่สร้าง"
                        onChange={(value) => {
                            SetTxtSearch({
                                ...txtSearch,
                                dSearcDate: value,
                            });

                        }}
                    />
                </Col>
                <Col >
                    <ButtonSearch onClick={() => SearchData(1, undefined, undefined, undefined)} />
                </Col>
                <Col >
                    <ButtonExcel onClick={() => ExportExce()} />
                </Col>
            </Row>
            <br />
            <TableAntd
                dataSource={V_Subscribe}
                columns={header}
                onPageChange={onPageChange}
                currentPage={nCurrentPage}
                total={nTotal}
                onChange={onTableChange}
                rowSelection={isEdit ? rowSelection : undefined}
                onDisableDelete={selectedRows.length > 0 ? false : true}
                onShowDelete={isEdit}
                scroll={{ x: 100 }}
                onClickDeleteData={onDeleteClick}
                pageSize={sPageSizeA ? parseInt(sPageSizeA) : 10}
            />
            <br />
        </React.Fragment>
    );
};
export default Admin_Subscribe_List;
