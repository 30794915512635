import * as React from "react";

interface IProps {
    src: string;
    fallbackSrc: string;
    width?: string;
    height?: string;
}

interface IState {
    src: string;
    errored: boolean;
    width?: string;
    height?: string;
}

class Image extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            src: props.src,
            errored: false,
            width: props.width,
            height: props.height,
        };
    }

    onError = () => {
        if (!this.state.errored) {
            this.setState({
                src: this.props.fallbackSrc,
                errored: true,
            });
        }
    }

    render() {
        const { src } = this.state;
        return (
            <img style={{ width: this.state.width ? this.state.width : "60px", height: this.state.height ? this.state.height : "60px" }}
                //{...this.props}
                src={src}
                onError={this.onError}
            />
        );
    }
}

export default Image