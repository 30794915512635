import * as React from 'react';
// import Main_Content_List from '../main-content/content-list'
import Main_Content_List from '../main-content/new-content/content-list'

const HL_Project_List = (props) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Main_Content_List sType={"6"} />
    );
}
export default HL_Project_List;