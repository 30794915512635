import { Checkbox, Col, Form, Input, Radio, Row, Select, Space, Switch, Tag, TimePicker } from "antd";
import * as React from "react";
import { Empty, Select as AntdSelect } from "antd";
import { ButtonAdd_Table, ButtonBack, ButtonDelete, ButtonEdit_Table, ButtonSave, ButtonSearch, Button_Cancel } from "../../Systems/button_all";
import { useHistory } from "react-router";
import { BoxMsg, DialogConfirm, DialogDelete, Encrypt, fontFamily, pluginsEnabled, quickInsertButtons, Responsestart, Sweetalert } from "../../Systems/SystemComponent";
import { AxiosGetJson, AxiosPostJson } from "../../Service/Config/AxiosMethod";
import AuthenService from "../../Service/AuthenService";
import ThaiDatePicker from "../../ST_Handlers/ThaiDatePicker";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import Fileuploader, { Extension} from "../../../Fileuploader";
import { parse } from "query-string"
import moment from "moment";
const { Option } = AntdSelect;

const Admin_Content = ({ sType = "99", backEvent = "/", nPerMenuId = 99 }) => {
    const format = "HH:mm";
    var type = sType ? sType : "99";
    var backEv = backEvent ? backEvent : "/";
    var nMenuId = nPerMenuId ? nPerMenuId : 99;
    var configs = {
        placeholderText: "",
        attribution: false,
        key: "0BA3jA11B5C7C4A4D3aIVLEABVAYFKc2Cb1MYGH1g1NYVMiG5G4E3C3A1C8A6D4A3B4==",
        imageUploadURL: process.env.REACT_APP_URL + "api/UploadTextEditor/uploadImage",
        videoUploadURL: process.env.REACT_APP_URL + "api/UploadTextEditor/uploadImage",
        quickInsertButtons: quickInsertButtons,
        pluginsEnabled: pluginsEnabled,
        events: {
            'html.set': async function () {
                let editor: any = this
                let UserInfo: any = await AuthenService.UserInfo();
                if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
                    if (UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu === nMenuId).map(t => t.nPermission)[0] === 2) {
                        editor.edit.off()
                    }
                }
            },
        },
        fontFamily: fontFamily,
        fontFamilySelection: true,
    };
    const [ModeEN, setModeEN] = React.useState("TH" as string);
    const [lstData, setLstData] = React.useState(null as any);
    const [dShowStart, setShowStart] = React.useState(undefined as Date | undefined);
    const [dShowEnd, setShowEnd] = React.useState(undefined as Date | undefined);
    const [lstFileCoverTH, setFileCoverTH] = React.useState([] as any);
    const [lstFileCoverEN, setFileCoverEN] = React.useState([] as any);
    const [lstFileGallery, setFileGallery] = React.useState([] as any);
    const [lstFileGalleryEN, setFileGalleryEN] = React.useState([] as any);
    const [lstFileRef, setFileRef] = React.useState([] as any);
    const [lstFileRefEN, setFileRefEN] = React.useState([] as any);
    const [sContentTH, setContentTH] = React.useState("");
    const [sContentEN, setContentEN] = React.useState("");
    const [validContentTH, setValidContentTH] = React.useState(true);
    const [validContentEN, setValidContentEN] = React.useState(true);
    const [SetValue] = Form.useForm();
    const [CheckboxDate, setCheckboxDate] = React.useState(false as boolean);
    const [lstContent, setLstContent] = React.useState([] as any);
    const [isFile, setIsFile] = React.useState("0");
    const [dNewsIssueDate, setNewsIssueDate] = React.useState(undefined as Date | undefined);
    const [dNewsIssueTime, setNewsIssueTime] = React.useState(null as any);
    const [nHeadLv0, setHeadLv0] = React.useState("0");
    const [nHeadLv1, setHeadLv1] = React.useState("0");
    const [sContentLevel, setContentLevel] = React.useState(null as any);
    const [contentLevelOption, setContentLevelOption] = React.useState([] as any);
    const [sContentLv1, setContentLv1] = React.useState(null as any);
    const [sContentLv2, setContentLv2] = React.useState(null as any);
    const [sContentLv3, setContentLv3] = React.useState(null as any);
    const [sID, setID] = React.useState("");
    const history = useHistory();
    const [isEdit, setisEdit] = React.useState(false);
    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            setisEdit(UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == nMenuId).map(t => t.nPermission)[0] === 2 ? false : true)
        }
    }

    var onUploadFileSuccess = () => {
        //setLstData({ ...lstData, lstExcutionFile: fileList });
        SetValue.validateFields(['lstFileCoverTH']);
    };
    var onUploadFileSuccess1 = () => {
        //setLstData({ ...lstData, lstExcutionFile: fileList });
        SetValue.validateFields(['lstFileCoverEN']);
    };
    var onUploadFileSuccess2 = () => {
        //setLstData({ ...lstData, lstExcutionFile: fileList });
        SetValue.validateFields(['lstFileGallery']);
    };
    var onUploadFileSuccess2EN = () => {
        //setLstData({ ...lstData, lstExcutionFile: fileList });
        SetValue.validateFields(['lstFileGalleryEN']);
    };
    var onUploadFileSuccess3 = () => {
        //setLstData({ ...lstData, lstExcutionFile: fileList });
        SetValue.validateFields(['lstFileRef']);
    };
    var onUploadFileSuccess3EN = () => {
        //setLstData({ ...lstData, lstExcutionFile: fileList });
        SetValue.validateFields(['lstFileRefEN']);
    };
    const onSaveContent = (values: any) => {
        DialogConfirm(async () => {
            var data = {
                nContentID: lstData.nContentID,
                sContentID: lstData.sContentID,
                nHeadLv0: nHeadLv0,
                nHeadLv1: nHeadLv1,
                nType: lstData.nType,
                sContentLevel: lstData.sContentLevel,
                sContentLv1: sContentLv1,
                sContentLv2: sContentLv2,
                sContentLv3: sContentLv3,
                //nType: type,
                sContentMode: ModeEN,
                sSubjectTH: lstData.sSubjectTH,
                sSubjectEN: lstData.sSubjectEN,
                sContentTH: sContentTH,
                sContentEN: sContentEN,
                dShowStart: lstData.dShowStart,
                dbShowStart: lstData.dbShowStart,
                dShowEnd: lstData.dShowEnd,
                dbShowEnd: lstData.dbShowEnd,
                lstFileCoverTH: lstFileCoverTH,
                lstFileCoverEN: lstFileCoverEN,
                lstFileGallery: lstFileGallery,
                lstFileGalleryEN: lstFileGalleryEN,
                lstFileRef: lstData.lstFileRef,
                lstFileRefEN: lstData.lstFileRefEN,
                msRefContent: lstData.msRefContent,
                msRefLink: lstData.msRefLink,
                msTag: lstData.msTag,
                isActive: lstData.isActive,
                isSetBackground: lstData.isSetBackground,
                sUrl: lstData.sUrl,
                sUrlEN: lstData.sUrlEN,
                dNewsIssueDate: lstData.dNewsIssueDate,
                dbNewsIssueDate: lstData.dbNewsIssueDate,
                dNewsIssueTime: lstData.dNewsIssueTime,
                dbNewsIssueTime: lstData.dbNewsIssueTime,
            };
            let result: any = await AxiosPostJson("api/AdminContent/SaveContentdata", data)
            if (result.data.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Desc_Success_Save, BoxMsg.Desc_Success_Save, () => {
                    onBack();
                });
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }


        }, null, BoxMsg.Title_Confirm, BoxMsg.Desc_Confirm_Save)
    }

    const onBack = () => {
        history.goBack()
        // history.push({
        //     pathname: backEv,
        // });
    };

    const GetContentDetail = async (idStr, typeStr) => {
        let result: any = await AxiosGetJson("api/AdminContent/GetContentDetailData?id=" + idStr + "&sType=" + typeStr);
        SetValue.setFieldsValue({
            sContentLevel: result.sContentLevel,
            sContentLv1: result.sContentLv1,
            sContentLv2: result.sContentLv2,
            sContentLv3: result.sContentLv3,
            sSubjectTH: result.sSubjectTH,
            sSubjectEN: result.sSubjectEN,
            sContentTH: result.sContentTH,
            sContentEN: result.sContentEN,
            dShowStart: result.dShowStart === null ? undefined : new Date(result.dShowStart),
            dShowEnd: result.dShowEnd === null ? undefined : new Date(result.dShowEnd),
            lstFileCoverTH: result.lstFileCoverTH,
            lstFileCoverEN: result.lstFileCoverEN,
            lstFileGallery: result.lstFileGallery,
            lstFileGalleryEN: result.lstFileGalleryEN,
            lstFileRef: result.lstFileRef,
            lstFileRefEN: result.lstFileRefEN,
            msRefContent: result.msRefContent,
            msRefLink: result.msRefLink,
            msTag: result.msTag,
            isActive: result.isActive,
            isSetBackground: result.isSetBackground,
            sUrl: result.sUrl,
            sUrlEN: result.sUrlEN,
            dNewsIssueDate: result.dNewsIssueDate === null ? undefined : new Date(result.dNewsIssueDate),
            dNewsIssueTime: result.dNewsIssueTime === null ? null : moment(result.dNewsIssueTime),
        });
        setModeEN(result.sContentMode);
        setShowStart(result.dShowStart === null ? undefined : new Date(result.dShowStart));
        setShowEnd(result.dShowEnd === null ? undefined : new Date(result.dShowEnd));
        setCheckboxDate(idStr === "xvEhq%2famL3jePelPapN2%2fw==" ? false : result.dShowEnd === null);
        setFileCoverTH(result.lstFileCoverTH);
        setFileCoverEN(result.lstFileCoverEN);
        setFileGallery(result.lstFileGallery);
        setFileGalleryEN(result.lstFileGalleryEN);
        setFileRef(result.lstFileRef);
        setFileRefEN(result.lstFileRefEN);
        setContentTH(result.sContentTH);
        setContentEN(result.sContentEN);
        setIsFile(result.isFile === true ? "1" : "0");
        setNewsIssueDate(result.dNewsIssueDate === null ? undefined : new Date(result.dNewsIssueDate));
        setNewsIssueTime(result.dNewsIssueTime === null ? null : moment(result.dNewsIssueTime));
        setHeadLv0(result.nHeadLv0);
        setHeadLv1(result.nHeadLv1);
        setContentLevel(result.sContentLevel);
        setContentLv1(result.sContentLv1);
        setContentLv2(result.sContentLv2);
        setContentLv3(result.sContentLv3);
        setLstData(result);
        GetRelatedContent(result.nType, idStr);
    }

    const onNewsIssueTimeChange = (time) => {
        if (time === null) {
            setNewsIssueTime(time);
            setLstData({ ...lstData, dNewsIssueTime: time, dbNewsIssueTime: null });
        }
        else {
            setNewsIssueTime(time._d);
            setLstData({ ...lstData, dNewsIssueTime: time._d, dbNewsIssueTime: time._d.valueOf() });
        }

    }

    const GetRelatedContent = async (nType, idStr) => {
        let result: any = await AxiosGetJson("api/AdminContent/GetRelatedContent?nType=" + nType + "&id=" + idStr);
        setLstContent(result);
    }

    const onDelete = (sID) => {
        DialogConfirm(async () => {
            let result: any = await AxiosGetJson("api/AdminContent/DeleteDataDetail?id=" + sID);
            if (result.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete , null);
                history.goBack();
            } else if (result.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }
        }, "", "", "ท่านต้องการลบข้อมูลหรือไม่?");
    }

    const GetContentLevelOption = async (idStr, typeStr) => {
        let result: any = await AxiosGetJson("api/AdminContent/GetContentLevelOption?id=" + idStr + "&sType=" + typeStr + "&src=detail");
        setContentLevelOption(result);
    }

    const checkContentMode = async (type) => {
        let cateType = "";
        if(sContentLevel === "1"){
            cateType = sContentLv1;
        }
        if(sContentLevel === "2"){
            cateType = sContentLv2;
        }
        if(sContentLevel === "3"){
            cateType = sContentLv3;
        }
        let result: any = await AxiosGetJson("api/AdminContent/CheckContentMode?level=" + sContentLevel + "&id=" + cateType+ "&type=" + type);
        if(result) {
            Sweetalert.Warning(BoxMsg.Title_Warning, 
                "ไม่สามารถดำเนินการได้ เนื่องจากเมนูหลัก เมนูรองหรือเมนูย่อยไม่มีการตั้งค่าเนื้อหาภาษาอังกฤษ<br /> กรุณาแก้ไขที่เมนูหลัก เมนูรองหรือเมนูย่อยก่อนเพิ่มข้อมูล", 
                () =>{
                    // setModeEN("TH"); 
                    // setLstData({ ...lstData, sContentMode: "EN" });
                    //"ไม่สามารถดำเนินการได้ เนื่องจากเมนูรองไม่มีการตั้งค่าเนื้อหาภาษาอังกฤษ กรุณาแก้ไขที่เมนูรองก่อนเพิ่มข้อมูล"
                    //"ไม่สามารถสร้างรายการภาษาอังกฤษได้<br /> กรุณาตรวจสอบข้อมูลเมนูหลัก เมนูรองหรือเมนูย่อยอีกครั้ง"
                });
            setModeEN("TH"); 
            setLstData({ ...lstData, sContentMode: "TH" });
        }
        return false;
    }

    const checkContentMode1 = async (id) => {
        let cateType = "";
        if(sContentLevel === "1"){
            cateType = "0";
        }
        if(sContentLevel === "2"){
            cateType = id;
        }
        if(sContentLevel === "3"){
            cateType = id;
        }
        let result: any = await AxiosGetJson("api/AdminContent/CheckContentMode?level=" + sContentLevel + "&id=" + cateType+ "&type=" + ModeEN);
        if(result) {
            Sweetalert.Warning(BoxMsg.Title_Warning, 
                "ไม่สามารถดำเนินการได้ เนื่องจากเมนูหลักหรือเมนูรองไม่มีการตั้งค่าเนื้อหาภาษาอังกฤษ <br />กรุณาแก้ไขที่เมนูหลักหรือเมนูรองก่อนเพิ่มข้อมูล", 
                () =>{
                    // setModeEN("TH"); 
                    // setLstData({ ...lstData, sContentMode: "EN" });
                    //"ไม่สามารถดำเนินการได้ เนื่องจากเมนูรองไม่มีการตั้งค่าเนื้อหาภาษาอังกฤษ กรุณาแก้ไขที่เมนูรองก่อนเพิ่มข้อมูล"
                    //"ไม่สามารถสร้างรายการภาษาอังกฤษได้<br /> กรุณาตรวจสอบข้อมูลเมนูหลักหรือเมนูรองอีกครั้ง"
                });
            setModeEN("TH"); 
            setLstData({ ...lstData, sContentMode: "TH" });
        }
        return false;
    }

    React.useEffect(() => {
        GetUserInfo();
    }, []);

    React.useEffect(() => {
        var { id, type } = parse(window.location.search);
        var idStr: string = "";
        var typeStr: string = "";
        if (id !== undefined && id !== null) {
            idStr = id.toString().split("/").join("%2f");
        }
        else {
            idStr = "xvEhq%2famL3jePelPapN2%2fw==";
        }
        if (type !== undefined && type !== null) {
            typeStr = type.toString().split("/").join("%2f");
        }
        else {
            typeStr = "xvEhq%2famL3jePelPapN2%2fw==";
        }
        // GetRelatedContent();
        setID(idStr);
        GetContentDetail(idStr, typeStr);
        GetContentLevelOption(idStr, typeStr);
        window.scrollTo(0, 0);
    }, [isEdit])


    return (
        <React.Fragment>
            <Form name="From" form={SetValue} onFinish={onSaveContent}
                onFinishFailed={({ errorFields }) => {
                    let element = document.querySelector(`#From_${errorFields[0].name}`);
                    var firstErrorElemY: any = null;
                    if (element) {
                        firstErrorElemY = element.getBoundingClientRect().top - 100; // bar height(100)px
                        window.scrollTo(0, window.scrollY + firstErrorElemY)
                    }
                    else{
                        element = document.getElementById(`${errorFields[0].name}`);
                        if (element) {
                            firstErrorElemY = element.getBoundingClientRect().top - 100; // bar height(100)px
                            window.scrollTo(0, window.scrollY + firstErrorElemY)
                        }
                    }
                }}>
                <Row gutter={[16, 16]}>
                    <Col xl={12} md={12} xs={24} >
                        <b> ประเภทเมนู </b><span className="text-danger ">*</span>
                        <Form.Item
                            name="sContentLevel"
                            rules={[
                                { required: true, message: BoxMsg.Desc_Required_Field + "ประเภทเมนู" },
                            ]}
                        >
                            <AntdSelect
                                disabled={!isEdit}
                                allowClear={true}
                                showSearch={true}
                                style={{ width: "100%" }}
                                value={""}
                                placeholder="- เลือก -"
                                onChange={(e) => {
                                    setLstData({ ...lstData, sContentLevel: e, sContentLv2: null, sContentLv3: null});
                                    setContentLevel(e);
                                    setContentLv2(null);
                                    setContentLv3(null);
                                    SetValue.resetFields(["sContentLv2","sContentLv3"])
                                }}
                                filterOption={(input, option) =>
                                    option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {/*
                                    nPerMenuId === 6 ?
                                    <option value="1">เมนูหลัก</option>
                                    :
                                    null
                                */}
                                <option value="1">เมนูหลัก</option>
                                <option value="2">เมนูรอง</option>
                                <option value="3">เมนูย่อย</option>
                            </AntdSelect>
                        </Form.Item>
                    </Col>
                </Row>
                {
                    sContentLevel === "1" ||sContentLevel === "2" || sContentLevel === "3" ? 
                    <React.Fragment>
                        <Row gutter={[16, 16]}>
                            <Col xl={12} md={12} xs={24} >
                            <b> เมนูหลัก </b><span className="text-danger ">*</span>
                            <Form.Item
                                name="sContentLv1"
                                rules={[
                                    { required: true, message: BoxMsg.Desc_Required_Field + "เมนูหลัก" },
                                ]}
                            >
                                <AntdSelect
                                    disabled={true}
                                    allowClear={true}
                                    showSearch={true}
                                    style={{ width: "100%" }}
                                    value={""}
                                    placeholder="- เลือก -"
                                    onChange={(e) => {
                                        setLstData({ ...lstData, sContentLv1: e, sContentLv2: null, sContentLv3: null});
                                        setContentLv1(e);
                                        setContentLv2(null);
                                        setContentLv3(null);
                                        checkContentMode1(e);
                                        SetValue.resetFields(["sContentLv2","sContentLv3"])
                                    }}
                                    filterOption={(input, option) =>
                                        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        contentLevelOption.filter(f => f.nTypeIDLv2 === 0 && f.nTypeIDLv3 === 0).map((e, i) => {
                                            return (
                                                <Option key={"lv1_" + i} value={e.nTypeIDLv1 + ""}>{e.sTypeLv1Name}</Option>
                                            );
                                        })
                                    }
                                </AntdSelect>
                            </Form.Item>
                            </Col>
                        </Row>
                    </React.Fragment>
                    :
                    null
                }
                {
                    sContentLevel === "2" || sContentLevel === "3" ? 
                    <React.Fragment>
                        <Row gutter={[16, 16]}>
                            <Col xl={12} md={12} xs={24} >
                            <b> เมนูรอง </b><span className="text-danger ">*</span>
                            <Form.Item
                                name="sContentLv2"
                                rules={[
                                    { required: true, message: BoxMsg.Desc_Required_Field + "เมนูรอง" },
                                ]}
                            >
                                <AntdSelect
                                    disabled={!isEdit}
                                    allowClear={true}
                                    showSearch={true}
                                    style={{ width: "100%" }}
                                    value={""}
                                    placeholder="- เลือก -"
                                    onChange={(e) => {
                                        setLstData({ ...lstData, sContentLv2: e, sContentLv3: null});
                                        setContentLv2(e);
                                        setContentLv3(null);
                                        checkContentMode1(e);
                                        SetValue.resetFields(["sContentLv3"])
                                    }}
                                    filterOption={(input, option) =>
                                        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        contentLevelOption.filter(f => f.nTypeIDLv1 === parseInt(sContentLv1) && f.nTypeIDLv2 !== 0 && f.nTypeIDLv3 === 0).map((e, i) => {
                                            return (
                                                <Option key={"lv2_" + i} value={e.nTypeIDLv2 + ""}>{e.sTypeLv2Name}</Option>
                                            );
                                        })
                                    }
                                </AntdSelect>
                            </Form.Item>
                            </Col>
                        </Row>
                    </React.Fragment>
                    :
                    null
                }
                {
                    sContentLevel === "3" ? 
                    <React.Fragment>
                        <Row gutter={[16, 16]}>
                            <Col xl={12} md={12} xs={24} >
                            <b> เมนูย่อย </b><span className="text-danger ">*</span>
                            <Form.Item
                                name="sContentLv3"
                                rules={[
                                    { required: true, message: BoxMsg.Desc_Required_Field + "เมนูย่อย" },
                                ]}
                            >
                                <AntdSelect
                                    disabled={!isEdit}
                                    allowClear={true}
                                    showSearch={true}
                                    style={{ width: "100%" }}
                                    value={""}
                                    placeholder="- เลือก -"
                                    onChange={(e) => {
                                        setLstData({ ...lstData, sContentLv3: e});
                                        setContentLv3(e);
                                        checkContentMode1(e);
                                    }}
                                    filterOption={(input, option) =>
                                        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        contentLevelOption.filter(f => f.nTypeIDLv1 === parseInt(sContentLv1) && f.nTypeIDLv2 === parseInt(sContentLv2) && f.nTypeIDLv3 !== 0).map((e, i) => {
                                            return (
                                                <Option key={"lv3_" + i} value={e.nTypeIDLv3 + ""}>{e.sTypeLv3Name}</Option>
                                            );
                                        })
                                    }
                                </AntdSelect>
                            </Form.Item>
                            </Col>
                        </Row>
                    </React.Fragment>
                    :
                    null
                }
                <br />
                <Row gutter={[16, 16]}>
                    <Col xl={12} md={12} xs={24} >
                        <b> มีเนื้อหาภาษาอังกฤษหรือไม่ </b>
                        <br />
                        <Radio.Group disabled={!isEdit}
                            onChange={e => { 
                                setModeEN(e.target.value); 
                                setLstData({ ...lstData, sContentMode: e.target.value }); 
                                checkContentMode(e.target.value);
                            }} value={ModeEN}>
                            <Radio value="EN">ใช่</Radio>
                            <Radio value="TH">ไม่ใช่</Radio>
                        </Radio.Group>
                    </Col>
                </Row>
                <br />
                <Row gutter={[16, 16]}>
                    <Col xl={12} md={12} xs={24} >
                        <b>ชื่อเรื่อง</b> <span className="text-danger ">*</span><span className="text-primary small"> ภาษาไทย </span>
                        <Form.Item
                            name="sSubjectTH"
                            rules={[
                                { required: true, message: BoxMsg.Desc_Required_Field + "ชื่อเรื่อง" },
                            ]}
                        >
                            <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                setLstData({ ...lstData, sSubjectTH: e.target.value });
                            }} />
                        </Form.Item>
                    </Col>
                    {
                        ModeEN === "EN" ?
                            <Col xl={12} md={12} xs={24} >
                                <b>ชื่อเรื่อง</b> <span className="text-danger ">*</span><span className="text-success small"> ภาษาอังกฤษ </span>
                                <Form.Item
                                    name="sSubjectEN"
                                    rules={[
                                        { required: ModeEN === "EN", message: BoxMsg.Desc_Required_Field + "ชื่อเรื่องภาษาอังกฤษ" },
                                    ]}
                                >
                                    <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                        setLstData({ ...lstData, sSubjectEN: e.target.value });
                                    }} />
                                </Form.Item>
                            </Col>
                            : null
                    }

                </Row>
                <br />
                {
                    //type === "13" || type === "15" ?
                    nHeadLv0 === "5" && (nHeadLv1 === "13"|| nHeadLv1 === "14" || nHeadLv1 === "16") ?
                        null :
                        <React.Fragment>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    {/*<b>รายละเอียด</b><span className="text-danger ">{type === "12" || type === "13" || type === "14" || type === "15" || type === "1" || type === "2" || type === "5" || type === "6" || type === "7" || type === "8" || type === "9" || type === "10" || type === "16" ? "" : "*"}</span> <span className="text-primary small"> ภาษาไทย </span>*/}
                                    <b>รายละเอียด</b><span className="text-danger ">{(nHeadLv0 === "5" || nHeadLv0 === "1" || nHeadLv0 === "2" || nHeadLv0 === "4") && (nHeadLv0 === "1" || nHeadLv0 === "2" || nHeadLv1 === "9" || nHeadLv1 === "10" || nHeadLv1 === "13" || nHeadLv1 === "14" || nHeadLv1 === "15" || nHeadLv1 === "16") ? "" : "*"}</span> <span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name="sContentTH"
                                        rules={[
                                            { required: (nHeadLv0 === "5" || nHeadLv0 === "1" || nHeadLv0 === "2" || nHeadLv0 === "4") && (nHeadLv0 === "1" || nHeadLv0 === "2" || nHeadLv1 === "9" || nHeadLv1 === "10" || nHeadLv1 === "13" || nHeadLv1 === "14" || nHeadLv1 === "15" || nHeadLv1 === "16") ? false : validContentTH, message: BoxMsg.Desc_Required_Field + "รายละเอียด" },
                                        ]}
                                    >
                                        <div id="sContentTH">
                                            <FroalaEditor
                                                model={sContentTH}
                                                config={configs}
                                                onModelChange={(model: any) => {
                                                    var str = model.replace(/<\/?[^>]+(>|$)/g, "");
                                                    if (str.replace(/\&nbsp;/g, "").trim().length <= 0) {
                                                        setValidContentTH(true);
                                                    }
                                                    else {
                                                        setValidContentTH(false);
                                                    }
                                                    setContentTH(model);
                                                }}
                                            />
                                        </div>
                                    </Form.Item>

                                </Col>
                            </Row>
                            <br />
                            {
                                ModeEN === "EN" ?
                                    <React.Fragment>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <b>รายละเอียด</b><span className="text-danger ">{(nHeadLv0 === "5" || nHeadLv0 === "1" || nHeadLv0 === "2" || nHeadLv0 === "4") && (nHeadLv1 === "1" || nHeadLv0 === "1" || nHeadLv0 === "2" || nHeadLv1 === "9" || nHeadLv1 === "10" || nHeadLv1 === "13" || nHeadLv1 === "14" || nHeadLv1 === "15" || nHeadLv1 === "16") ? "" : "*"}</span>  <span className="text-success small"> ภาษาอังกฤษ </span>
                                                <Form.Item
                                                    name="sContentEN"
                                                    rules={[
                                                        { required: ModeEN === "EN" && ((nHeadLv0 === "5" || nHeadLv0 === "1" || nHeadLv0 === "2" || nHeadLv0 === "4") && (nHeadLv1 === "1" || nHeadLv0 === "1" || nHeadLv0 === "2" || nHeadLv1 === "9" || nHeadLv1 === "10" || nHeadLv1 === "13" || nHeadLv1 === "14" || nHeadLv1 === "15" || nHeadLv1 === "16") ? false : validContentEN), message: BoxMsg.Desc_Required_Field + "รายละเอียดภาษาอังกฤษ" },
                                                    ]}
                                                >
                                                    <div id="sContentEN">
                                                        <FroalaEditor
                                                            model={sContentEN}
                                                            config={configs}
                                                            onModelChange={(model: any) => {
                                                                var str = model.replace(/<\/?[^>]+(>|$)/g, "");
                                                                if (str.replace(/\&nbsp;/g, "").trim().length <= 0) {
                                                                    setValidContentEN(true);
                                                                }
                                                                else {
                                                                    setValidContentEN(false);
                                                                }
                                                                setContentEN(model);
                                                            }}
                                                        />
                                                    </div>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <br />
                                    </React.Fragment>
                                    : null
                            }
                        </React.Fragment>
                }

                <Row gutter={[16, 16]}>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <b> วันที่ประกาศ </b><span className="text-danger ">*</span>
                        <Form.Item
                            name="dShowStart"
                            rules={[
                                { required: true, message: BoxMsg.Desc_Required_Field + "วันที่ประกาศ" },
                            ]}
                        >
                            <ThaiDatePicker
                                id="dShowStart"
                                disabled={!isEdit}
                                value={dShowStart}
                                minDate={dNewsIssueDate}
                                maxDate={dShowEnd}
                                small={false}
                                onChange={(value) => {
                                    setShowStart(value);
                                    setLstData({ ...lstData, dShowStart: value, dbShowStart: value ? value.valueOf() : null });
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <b> วันที่สิ้นสุด </b><span className="text-danger ">*</span>
                        <Form.Item
                            name="dShowEnd"
                            rules={[
                                { required: !CheckboxDate, message: BoxMsg.Desc_Required_Field + "วันที่สิ้นสุด" },
                            ]}
                        >
                            <ThaiDatePicker
                                id="dShowEnd"
                                disabled={isEdit ? CheckboxDate : true}
                                value={dShowEnd}
                                minDate={dShowStart}
                                small={false}
                                onChange={(value) => {
                                    setShowEnd(value);
                                    setLstData({ ...lstData, dShowEnd: value, dbShowEnd: value ? value.valueOf() : null });
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xl={6} md={6} xs={24}>
                        <div style={{ marginTop: "11%" }}>
                            &nbsp;
                            <Checkbox disabled={!isEdit} checked={CheckboxDate} onChange={e => {
                                SetValue.resetFields(["dShowEnd"]);
                                setCheckboxDate(e.target.checked)
                                SetValue.setFieldsValue({
                                    dShowEnd: undefined
                                });
                                setShowEnd(undefined);
                                setLstData({ ...lstData, dShowEnd: null, dbShowEnd: null });
                            }} >
                                ไม่มีกำหนด
                            </Checkbox>
                        </div>

                    </Col>
                </Row>
                <br />
                {
                    //type === "1" ?
                    nHeadLv0 === "1" && nHeadLv1 === "1" ?
                        <Row gutter={[16, 16]}>
                            <Col xl={6} sm={24} xs={24}>
                                <b> วันที่ข่าว </b>
                                <span className="text-danger">*</span>
                                <Form.Item
                                    name="dNewsIssueDate"
                                    rules={[
                                        { required: true, message: BoxMsg.Desc_Required_Field + "วันที่ข่าว" },
                                    ]}
                                >
                                    <ThaiDatePicker
                                        id="dNewsIssueDate"
                                        disabled={!isEdit}
                                        small={false}
                                        value={dNewsIssueDate}
                                        //maxDate={new Date()}
                                        onChange={(value) => {

                                            setNewsIssueDate(value);
                                            setLstData({ ...lstData, dNewsIssueDate: value, dbNewsIssueDate: value ? value.valueOf() : null });
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            {/*
                            <Col xl={6} sm={24} xs={24}>
                                <b> เวลาข่าว </b>
                                <span className="text-danger">*</span>
                                <Form.Item
                                    name="dNewsIssueTime"
                                    rules={[
                                        { required: true, message: BoxMsg.Desc_Required_Field + "เวลาข่าว" },
                                    ]}
                                >
                                    <TimePicker
                                        disabled={!isEdit}
                                        defaultValue={dNewsIssueTime}
                                        value={dNewsIssueTime}
                                        onChange={onNewsIssueTimeChange}
                                        format={format}
                                        style={{ borderRadius: "1em" }}
                                    />
                                </Form.Item>
                            </Col>
                            */}
                        </Row>
                        :
                        null
                }
                <Row gutter={[16, 16]}>
                    <Col xl={12} md={12} xs={24} >
                        <b>ภาพปก </b><span className="text-danger ">* </span><span className="text-primary small"> ภาษาไทย </span>
                        
                        <span className="text-muted small">
                            ขนาดภาพที่แนะนำ 1280 x 600 px.
                        </span>
                        <Form.Item
                            name="lstFileCoverTH"
                            rules={[
                                { required: true, message: BoxMsg.Desc_Required_Field_File + "ภาพปก" },
                            ]}
                        >
                            <div id="lstFileCoverTH">
                            <Fileuploader
                                limit="1"
                                setFileList={setFileCoverTH}
                                fileList={lstFileCoverTH}
                                onComplete={onUploadFileSuccess}
                                onRemoveComplete={onUploadFileSuccess}
                                fileMaxSize="10"
                                readOnly={!isEdit}
                                extensions={Extension.Image}
                            />
                            </div>
                        </Form.Item>
                    </Col>
                    {
                        ModeEN === "EN" ?
                            <Col xl={12} md={12} xs={24} >
                                <b>ภาพปก </b><span className="text-danger ">* </span><span className="text-success small"> ภาษาอังกฤษ </span>
                                
                                <span className="text-muted small">
                                    ขนาดภาพที่แนะนำ 1280 x 600 px.
                                </span>
                                <Form.Item
                                    name="lstFileCoverEN"
                                    rules={[
                                        { required: ModeEN === "EN", message: BoxMsg.Desc_Required_Field_File + "ภาพปกภาษาอังกฤษ" },
                                    ]}
                                >
                                    <div id="lstFileCoverEN">
                                    <Fileuploader
                                        limit="1"
                                        setFileList={setFileCoverEN}
                                        fileList={lstFileCoverEN}
                                        onComplete={onUploadFileSuccess1}
                                        onRemoveComplete={onUploadFileSuccess1}
                                        fileMaxSize="10"
                                        readOnly={!isEdit}
                                        extensions={Extension.Image}
                                    />
                                    </div>
                                </Form.Item>
                            </Col>
                            : null
                    }

                </Row>
                <br />
                <Row gutter={[16, 16]}>
                    <Col xl={12} sm={24} xs={24}>
                        <b>คลังภาพ </b><span className="text-primary small"> ภาษาไทย </span>
                        <Form.Item
                            name="lstFileGallery"
                            rules={[
                                { required: false, message: BoxMsg.Desc_Required_Field_File + "คลังภาพ" },
                            ]}
                        >
                            <div id="lstFileGallery">
                            <Fileuploader
                                limit="100"
                                setFileList={setFileGallery}
                                fileList={lstFileGallery}
                                onComplete={onUploadFileSuccess2}
                                onRemoveComplete={onUploadFileSuccess2}
                                fileMaxSize="10"
                                readOnly={!isEdit}
                                extensions={Extension.Image}
                            />
                            </div>
                        </Form.Item>
                    </Col>
                    {
                        ModeEN == "EN" ?
                        <Col xl={12} sm={24} xs={24}>
                            <b>คลังภาพ </b><span className="text-success small"> ภาษาอังกฤษ </span>
                            <Form.Item
                                name="lstFileGalleryEN"
                                rules={[
                                    { required: false, message: BoxMsg.Desc_Required_Field_File + "คลังภาพภาษาอังกฤษ" },
                                ]}
                            >
                                <div id="lstFileGalleryEN">
                                <Fileuploader
                                    limit="100"
                                    setFileList={setFileGalleryEN}
                                    fileList={lstFileGalleryEN}
                                    onComplete={onUploadFileSuccess2EN}
                                    onRemoveComplete={onUploadFileSuccess2EN}
                                    fileMaxSize="10"
                                    readOnly={!isEdit}
                                    extensions={Extension.Image}
                                />
                                </div>
                            </Form.Item>
                        </Col>
                        :
                        null
                    }
                </Row>
                <br />
                {
                    //type === "12" ?
                    (nHeadLv0 === "5" && nHeadLv1 === "13") ?
                        <React.Fragment>
                            <Row gutter={[16, 16]}>
                                <Col xl={12} md={12} xs={24} >
                                    <b> รูปแบบวีดิทัศน์ </b><span className="text-danger">*</span>
                                    <br />
                                    <Radio.Group disabled={!isEdit} onChange={e => {
                                        setIsFile(e.target.value); SetValue.resetFields(["sUrl", "sUrlEN"]);
                                    }} value={isFile}>
                                        <Radio value={"1"}>{"File"}</Radio>
                                        <Radio value={"0"}>{"URL Link"}</Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                            <br />
                        </React.Fragment>
                        : null
                }
                {
                    isFile === "1" ?
                        <React.Fragment>
                            <Row gutter={[16, 16]}>
                                <Col xl={12} sm={24} xs={24}>
                                    <b>{(nHeadLv0 === "5" && nHeadLv1 === "13") ? "ไฟล์วิดีทัศน์" : "ไฟล์ที่เกี่ยวข้อง"} </b>
                                    {/*<span className="text-danger ">{type === "12" || type === "13" || type === "15" ? "*" : ""}</span>*/}
                                    <span className="text-danger ">{(nHeadLv0 === "5") && (nHeadLv1 === "13" || nHeadLv1 === "14" || nHeadLv1 === "16") ? "*" : ""}</span>
                                    <span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name="lstFileRef"
                                        rules={[
                                            { required: (nHeadLv0 === "5") && (nHeadLv1 === "13" || nHeadLv1 === "14" || nHeadLv1 === "16") ? true : false, message: BoxMsg.Desc_Required_Field_File + ((nHeadLv0 === "5") && (nHeadLv1 === "13") ? "ไฟล์วิดีทัศน์" : "ไฟล์ที่เกี่ยวข้อง") },
                                        ]}
                                    >
                                        <div id="lstFileRef">
                                        {
                                            (nHeadLv0 === "5") && (nHeadLv1 === "13") ?
                                                <Fileuploader
                                                    limit={"1"}
                                                    setFileList={setFileRef}
                                                    fileList={lstFileRef}
                                                    onComplete={onUploadFileSuccess3}
                                                    onRemoveComplete={onUploadFileSuccess3}
                                                    fileMaxSize={"500"}
                                                    readOnly={!isEdit}
                                                    extensions={Extension.Video}
                                                />
                                                :
                                                null
                                        }
                                        {
                                            (nHeadLv0 === "5") && (nHeadLv1 === "14" || nHeadLv1 === "16") ?
                                                <Fileuploader
                                                    limit={"1"}
                                                    setFileList={setFileRef}
                                                    fileList={lstFileRef}
                                                    onComplete={onUploadFileSuccess3}
                                                    onRemoveComplete={onUploadFileSuccess3}
                                                    fileMaxSize={"250"}
                                                    readOnly={!isEdit}
                                                    extensions={Extension.PDF}
                                                />
                                                :
                                                null
                                        }
                                        {
                                            (nHeadLv0 !== "5") || ((nHeadLv0 === "5") && (nHeadLv1 !== "13" && nHeadLv1 !== "14" && nHeadLv1 !== "16")) ?
                                                <Fileuploader
                                                    limit={"100"}
                                                    setFileList={setFileRef}
                                                    fileList={lstFileRef}
                                                    onComplete={onUploadFileSuccess3}
                                                    onRemoveComplete={onUploadFileSuccess3}
                                                    fileMaxSize={"10"}
                                                    readOnly={!isEdit}
                                                    extensions={Extension.CustomGood_Receive}
                                                />
                                                :
                                                null
                                        }
                                        </div>
                                    </Form.Item>
                                </Col>
                                {
                                    ModeEN === "EN" ?
                                    <Col xl={12} sm={24} xs={24}>
                                        <b>{(nHeadLv0 === "5" && nHeadLv1 === "13") ? "ไฟล์วิดีทัศน์" : "ไฟล์ที่เกี่ยวข้อง"} </b>
                                        {/*<span className="text-danger ">{type === "12" || type === "13" || type === "15" ? "*" : ""}</span>*/}
                                        <span className="text-danger ">{(nHeadLv0 === "5") && (nHeadLv1 === "13" || nHeadLv1 === "14" || nHeadLv1 === "16") ? "*" : ""}</span>
                                        <span className="text-success small"> ภาษาอังกฤษ </span>
                                        <Form.Item
                                            name="lstFileRefEN"
                                            rules={[
                                                { required: (nHeadLv0 === "5") && (nHeadLv1 === "13" || nHeadLv1 === "14" || nHeadLv1 === "16") ? true : false, message: BoxMsg.Desc_Required_Field_File + ((nHeadLv0 === "5") && (nHeadLv1 === "13") ? "ไฟล์วิดีทัศน์ภาษาอังกฤษ" : "ไฟล์ที่เกี่ยวข้องภาษาอังกฤษ") },
                                            ]}
                                        >
                                            <div id="lstFileRefEN">
                                            {
                                                (nHeadLv0 === "5") && (nHeadLv1 === "13") ?
                                                    <Fileuploader
                                                        limit={"1"}
                                                        setFileList={setFileRef}
                                                        fileList={lstFileRefEN}
                                                        onComplete={onUploadFileSuccess3EN}
                                                        onRemoveComplete={onUploadFileSuccess3EN}
                                                        fileMaxSize={"500"}
                                                        readOnly={!isEdit}
                                                        extensions={Extension.Video}
                                                    />
                                                    :
                                                    null
                                            }
                                            {
                                                (nHeadLv0 === "5") && (nHeadLv1 === "14" || nHeadLv1 === "16") ?
                                                    <Fileuploader
                                                        limit={"1"}
                                                        setFileList={setFileRefEN}
                                                        fileList={lstFileRefEN}
                                                        onComplete={onUploadFileSuccess3EN}
                                                        onRemoveComplete={onUploadFileSuccess3EN}
                                                        fileMaxSize={"250"}
                                                        readOnly={!isEdit}
                                                        extensions={Extension.PDF}
                                                    />
                                                    :
                                                    null
                                            }
                                            {
                                                (nHeadLv0 !== "5") || ((nHeadLv0 === "5") && (nHeadLv1 !== "13" && nHeadLv1 !== "14" && nHeadLv1 !== "16")) ?
                                                    <Fileuploader
                                                        limit={"100"}
                                                        setFileList={setFileRefEN}
                                                        fileList={lstFileRefEN}
                                                        onComplete={onUploadFileSuccess3EN}
                                                        onRemoveComplete={onUploadFileSuccess3EN}
                                                        fileMaxSize={"10"}
                                                        readOnly={!isEdit}
                                                        extensions={Extension.CustomGood_Receive}
                                                    />
                                                    :
                                                    null
                                            }
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    :
                                    null
                                }
                            </Row>
                            <br />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Row gutter={[16, 16]}>
                                <Col xl={12} sm={24} xs={24}>
                                    <b>Link Url </b><span className="text-danger ">* </span><span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name={"sUrl"}
                                        rules={[
                                            { required: true, message: BoxMsg.Desc_Required_Field + " Link Url" },
                                            { type: "url", message: "ตัวอย่าง http://example.com" },
                                        ]}
                                        style={{ marginBottom: 0 }}
                                    >
                                        <Input disabled={!isEdit} maxLength={250} autoComplete="off" onChange={e => { setLstData({ ...lstData, sUrl: e.target.value }); }} />
                                    </Form.Item>
                                </Col>
                                {
                                    ModeEN === "EN" ?
                                    <Col xl={12} sm={24} xs={24}>
                                        <b>Link Url </b><span className="text-danger ">* </span><span className="text-success small"> ภาษาอังกฤษ </span>
                                        <Form.Item
                                            name={"sUrlEN"}
                                            rules={[
                                                { required: true, message: BoxMsg.Desc_Required_Field + " Link Url ภาษาอังกฤษ" },
                                                { type: "url", message: "ตัวอย่าง http://example.com" },
                                            ]}
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Input disabled={!isEdit} maxLength={250} autoComplete="off" onChange={e => { setLstData({ ...lstData, sUrlEN: e.target.value }); }} />
                                        </Form.Item>
                                    </Col>
                                    :
                                    null
                                }
                            </Row>
                            <br />
                        </React.Fragment>
                }

                {
                    (nHeadLv0 === "5") && (nHeadLv1 === "13" || nHeadLv1 === "14" || nHeadLv1 === "16") ?
                        //type === "12" || type === "13" || type === "15" ?
                        null :
                        <React.Fragment>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <b>หัวข้อที่เกี่ยวข้อง</b>
                                    <Form.Item
                                        name="msRefContent"
                                        rules={[
                                            { required: false, message: BoxMsg.Desc_Required_Field + "หัวข้อที่เกี่ยวข้อง" },
                                        ]}
                                    >
                                        <Select
                                            disabled={!isEdit}
                                            mode="multiple"
                                            defaultValue={lstData ? lstData.msRefContent : undefined}
                                            onChange={e => {
                                                setLstData({ ...lstData, msRefContent: e });
                                            }}
                                            style={{ width: '100%' }}
                                        >
                                            {
                                                lstContent.map((e, i) => {
                                                    return (
                                                        <Option key={"cont_" + i} value={e.value}>{e.label}</Option>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <br />
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <b>ลิงก์ที่เกี่ยวข้อง</b>
                                    <Form.Item
                                        name="msRefLink"
                                        rules={[
                                            { required: false, message: BoxMsg.Desc_Required_Field + "ลิงก์ที่เกี่ยวข้อง" },
                                        ]}
                                    >
                                        <Select
                                            disabled={!isEdit}
                                            mode="tags"
                                            defaultValue={lstData ? lstData.msRefLink : undefined}
                                            onChange={e => {
                                                setLstData({ ...lstData, msRefLink: e });
                                            }}
                                            style={{ width: '100%' }}
                                            open={false}
                                        >
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <br />
                        </React.Fragment>
                }
                {
                    (nHeadLv0 === "5") && (nHeadLv1 === "14" || nHeadLv1 === "16") ?
                    //type === "12" || type === "13" || type === "15" ?
                    null :
                    <React.Fragment>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <b>แท็ก</b>
                                <Form.Item
                                    name="msTag"
                                    rules={[
                                        { required: false, message: BoxMsg.Desc_Required_Field + "แท็ก" },
                                    ]}
                                >
                                    <Select
                                        disabled={!isEdit}
                                        mode="tags"
                                        defaultValue={lstData ? lstData.msTag : undefined}
                                        onChange={e => {
                                            setLstData({ ...lstData, msTag: e });
                                        }}
                                        style={{ width: '100%' }}
                                        open={false}
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <br />
                    </React.Fragment>
                }
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <b>สถานะ</b>
                        <Form.Item name="isActive" valuePropName="checked">
                            <Switch disabled={!isEdit} defaultChecked={true} checkedChildren="ใช้งาน" unCheckedChildren="ไม่ใช้งาน" style={{width : "100px"}}
                                onChange={e => {
                                    setLstData({ ...lstData, isActive: e });
                                }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <b>กำหนดให้มีภาพพื้นหลัง</b>
                        <Form.Item name="isSetBackground" valuePropName="checked">
                            <Switch disabled={!isEdit} defaultChecked={true} checkedChildren="มี" unCheckedChildren="ไม่มี" style={{width : "100px"}}
                                onChange={e => {
                                    setLstData({ ...lstData, isSetBackground: e });
                                }} />
                        </Form.Item>
                    </Col>
                </Row>
                <br />
                <hr />
                <Row gutter={[8, 16]} justify="center">
                    {isEdit ?
                        <React.Fragment>
                            <Col>
                                <ButtonSave htmlType="submit" />
                            </Col>
                            {
                                sID !== "xvEhq%2famL3jePelPapN2%2fw==" && sID !== "" ? 
                                    <Col>
                                        <ButtonDelete onClick={() => onDelete(sID)} />
                                    </Col>
                                :
                                null
                            }
                        </React.Fragment>
                        : null}
                    <Col>
                        <Button_Cancel onClick={() => { onBack(); }}/>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    );
}
export default Admin_Content;