import { Button, Col, Form, Input, Row } from "antd";
import * as React from "react";
import { useHistory } from "react-router";
import { ButtonBack, ButtonRespond, ButtonSave, Button_Cancel } from "../Systems/button_all";
import { BoxMsg, Decrypt, DialogConfirm, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import { Empty, Select as AntdSelect } from "antd";
import { parse } from "query-string";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import ThaiDatePicker from "../ST_Handlers/ThaiDatePicker";
import TableAntd from "../Systems/TableAntd";
import AuthenService from "../Service/AuthenService";

const Controller = 'api/AdminContactUs/';


const Admin_Contact_Us_Edit = () => {
    const [SetValue] = Form.useForm();
    const history = useHistory();
    var { sComplaintID } = parse(window.location.search);
    sComplaintID = sComplaintID && Decrypt(sComplaintID)
    const [V_Complaint, setV_Complaint] = React.useState({} as any);
    const [T_Complaint_Group, setT_Complaint_Group] = React.useState([] as Array<any>);
    const [isSendmail, setisSendmail] = React.useState(false as boolean);
    const [selectedRows, setselectedRows] = React.useState([] as Array<any>);

    const [V_ComplaintTracking, setV_ComplaintTracking] = React.useState([] as any);



    const [isEdit, setisEdit] = React.useState(false);
    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            setisEdit(UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == 10).map(t => t.nPermission)[0] === 2 ? false : true)
        }
    }

    const GetDataOnPageLoad = async () => {
        let result: any = await AxiosGetJson(Controller + "GetV_ComplaintBysComplaintID?sComplaintID=" + (sComplaintID === undefined ? "0" : sComplaintID));

        setV_Complaint(result);
        SetValue.setFieldsValue({
            sName: result.sName,
            nID_Group: result.nID_Group,
            sTel: result.sTel,
            sEmail: result.sEmail,
            sSubject: result.sSubject,
            sDetail: result.sDetail,
            sUnit: result.sUnit,
            sAnswer: "",
            dCreate: result.dCreate == null ? undefined : new Date(result.dCreate),

        })

    }
    const GetT_Complaint_Group = async () => {
        let result: any = await AxiosGetJson(Controller + "GetT_Complaint_Group");
        setT_Complaint_Group(result);
    }
    const GetV_ComplaintTrackingBysComplaintID = async () => {
        let result: any = await AxiosGetJson(Controller + "GetV_ComplaintTrackingBysComplaintID?sComplaintID=" + (sComplaintID === undefined ? "0" : sComplaintID));
        setV_ComplaintTracking(result);
    }



    const onSave = async (values: any) => {
        let sAnswer = values.sAnswer;
        let iSendmail = isSendmail;
        DialogConfirm(async () => {
            let result: any = await AxiosPostJson(Controller + "Savedata?sComplaintID=" + (sComplaintID === undefined ? "0" : sComplaintID) + "&&sAnswer=" + sAnswer + "&&isSendmail=" + iSendmail)
            if (result.data.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Desc_Success_Save, BoxMsg.Desc_Success_Save, () => {
                    onBack();
                });
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }
        }, null, BoxMsg.Title_Confirm, BoxMsg.Desc_Confirm_Save)
    }
    const onBack = () => {
        history.push("/admin-contact-us-list?sMode=1")
    };


    const header = [
        {
            title: 'ลำดับที่',
            dataIndex: "key",
            key: 'key',
            className: "text-center",
            width: 25,
            render: (value) => {
                return (<>{value}.</>);
            },
        },
        {
            title: 'ข้อความที่ตอบกลับ',
            dataIndex: "sAnswer",
            key: "sUsername",
            //sorter: true,
            width: 500,
        },
        {
            title: "วันที่ตอบกลับ",
            dataIndex: "sCreate",
            key: 'sCreate',
            width: 50,
            className: "text-center",
            //sorter: true,
        },
    ];
    React.useEffect(() => {
        GetUserInfo();
    }, []);

    React.useEffect(() => {
        GetT_Complaint_Group();
        GetDataOnPageLoad();
        GetV_ComplaintTrackingBysComplaintID();
    }, [isEdit]);
    return (
        <React.Fragment>
            <Form onFinish={onSave} form={SetValue}>
                <br />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>

                        <b>ชื่อผู้ติดต่อ</b>
                        <Form.Item
                            name="sName"
                        //rules={[
                        //    { required: true, message: BoxMsg.Desc_Required_Field + "ชื่อผู้ติดต่อ" },
                        //]}
                        >
                            <Input disabled autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <b>หัวข้อติดต่อ</b>
                        <Form.Item
                            name="nID_Group"
                        //rules={[
                        //    {
                        //        required: true,
                        //        message: BoxMsg.Desc_Required_Field + "สถาบัน/ศูนย์ฯเรียนรู้"
                        //    },
                        //]}
                        >
                            <AntdSelect
                                disabled
                                allowClear={true}
                                showSearch={true}
                                style={{ width: "100%" }}
                                placeholder="- หัวข้อติดต่อ -"
                                filterOption={(input, option) =>
                                    option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {

                                    T_Complaint_Group.map((p) => {
                                        return (
                                            <option key={"nID" + p.nID} value={p.nID} >{p.sName}</option>
                                        )
                                    })
                                }
                            </AntdSelect>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>

                        <b>เบอร์โทรศัพท์</b>
                        <Form.Item
                            name="sTel"
                        //rules={[
                        //    { required: true, message: BoxMsg.Desc_Required_Field + "เบอร์โทรศัพท์" },
                        //]}
                        >
                            <Input disabled autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <b>E-mail</b>
                        <Form.Item
                            name="sEmail"
                        //rules={[
                        //    {
                        //        required: true,
                        //        message: BoxMsg.Desc_Required_Field + "E-mail"
                        //    },
                        //]}
                        >
                            <Input disabled autoComplete="off" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <b>วันที่แจ้ง</b>
                        <Form.Item
                            name="dCreate"
                        >
                            <ThaiDatePicker
                                disabled
                                small={false}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <b>เรื่อง</b>
                        <Form.Item
                            name="sSubject"
                        //rules={[
                        //    { required: true, message: BoxMsg.Desc_Required_Field + "หัวเรื่อง" },
                        //]}
                        >
                            <Input disabled autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <b>หน่วยงานผู้ติดต่อ</b>
                        <Form.Item
                            name="sUnit"
                        //rules={[
                        //    {
                        //        required: true,
                        //        message: BoxMsg.Desc_Required_Field + "E-mail"
                        //    },
                        //]}
                        >
                            <Input disabled autoComplete="off" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <b>รายละเอียด</b>
                        <Form.Item
                            name="sDetail"
                            rules={[
                                {
                                    required: true,
                                    message: BoxMsg.Desc_Required_Field + "รายละเอียด"
                                },
                            ]}
                        >
                            <Input.TextArea disabled maxLength={250} rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <i className="fa fa-reply"></i> &nbsp;<b>การตอบกลับ</b>
                <hr />

                <TableAntd
                    dataSource={V_ComplaintTracking}
                    columns={header}
                    onDisableDelete={selectedRows.length > 0 ? false : true}
                    onShowDelete={false}
                    onShowPagination={false}
                />



                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <b>ข้อความตอบกลับ</b> <span className="text-danger ">*</span>
                        <Form.Item
                            name="sAnswer"
                            rules={[
                                {
                                    required: true,
                                    message: BoxMsg.Desc_Required_Field + "ข้อความตอบกลับ"
                                },
                            ]}
                        >
                            <Input.TextArea disabled={!isEdit} rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <hr />
                <Row gutter={[16, 16]} justify="center">
                    {isEdit ?
                        <Col>
                            <ButtonRespond disabled={!isEdit} htmlType="submit" onClick={() => { setisSendmail(true) }} />
                        </Col>
                        : null}
                    <Col>
                        <Button_Cancel onClick={() => { history.push("/admin-contact-us-list?sMode=1") }} />
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    );
};
export default Admin_Contact_Us_Edit;
