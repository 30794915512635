
import { Button, Col, Input, Modal, Row, Select, Space, Tabs, Tag } from "antd";
import * as React from "react";
import { useHistory } from "react-router";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import { ButtonAdd_Table, ButtonEdit_Table, ButtonExcel, ButtonInfo_Table, ButtonSearch } from "../Systems/button_all";
import { BoxMsg, Decrypt, DialogDelete, Encrypt, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import TableAntd from "../Systems/TableAntd";
import { Empty, Select as AntdSelect } from "antd";
import { parse } from "query-string";
import AuthenService from "../Service/AuthenService";

const Controller = 'api/AdminUserPermission/';



const Admin_User_Permission_List = () => {
    const { TabPane } = Tabs;
    const { Option } = Select;
    const history = useHistory();
    const [V_AdminGroup, setV_AdminGroup] = React.useState([] as any);
    const [V_AdminUser, setV_AdminUser] = React.useState([] as any);
    const [T_AdminGroup, setT_AdminGroup] = React.useState([] as Array<any>);
    const [sTabSelect, setsTabSelect] = React.useState("2" as string);
    const [nTotalGroup, setnTotalGroup] = React.useState(0 as number);
    const [nTotalInfo, setnTotalInfo] = React.useState(0 as number);
    const [sortedInfo, setsortedInfo] = React.useState({} as any);
    const [userInfo, setUserInfo] = React.useState(null as any);

    var { sMode } = parse(window.location.search);

    const [txtSearchGroup, SetTxtSearchGroup] = React.useState({
        stxtGroupName: "" as string || undefined,
        stxtIsActive: "" as string || undefined,

    });

    const [txtSearchInfo, SettxtSearchInfo] = React.useState({
        stxtName: "" as string || undefined,
        stxtGroup: "" as string || undefined,
        stxtIsActive: "" as string || undefined,

    });

    React.useEffect(() => {
        setsTabSelect(sMode != undefined ? sMode[0] : "2");
        GetDataOnPageLoad();
        GetDropdowT_AdminGroup();
        GetUserInfo();

    }, []);
    const [isEdit, setisEdit] = React.useState(false);
    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        setUserInfo(UserInfo);
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            setisEdit(UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == 11).map(t => t.nPermission)[0] === 2 ? false : true)
        }
    }

    const GetDataOnPageLoad = async () => {
        let result: any = await AxiosGetJson(Controller + "GetV_AdminGroup");
        setnTotalGroup(result.nTotal)
        setV_AdminGroup(result.V_AdminGroup);

        let resultuser: any = await AxiosGetJson(Controller + "GetV_AdminUser");
        setnTotalInfo(resultuser.nTotal)
        setV_AdminUser(resultuser.V_AdminUser);


    }
    const GetDropdowT_AdminGroup = async () => {
        let result: any = await AxiosGetJson(Controller + "GetDropdowT_AdminGroup");
        setT_AdminGroup(result);
    }

    //*****************************TableAntd
    const [selectedRows, setselectedRows] = React.useState([] as Array<any>);
    const [selectedRowKeys, setrowSelected] = React.useState([] as any);

    const [sPageA, setsPageA] = React.useState("1" as string || undefined);
    const [sPageSizeA, setsPageSizeA] = React.useState("10" as string || undefined);
    const [sFieldA, setsFieldA] = React.useState("" as string || undefined);
    const [sOrderA, setsOrderA] = React.useState("" as string || undefined);
    const [nCurrentPage, setCurrentPage] = React.useState(1);

    const onselect = (selectedKeys, selectedRows: React.SetStateAction<any[]>) => {
        setselectedRows(selectedRows);
        setrowSelected(selectedKeys);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onselect,
        getCheckboxProps: (record) => ({
            disabled: record.sIsuse === 'Y', // Column configuration not to be checked
            sIsuse: record.sIsuse,
        }),
    };
    const rowSelectInfo = {
        selectedRowKeys,
        onChange: onselect,
        getCheckboxProps: (record) => ({
            disabled: record.sIsuse === 'Y', // Column configuration not to be checked
            sIsuse: record.sIsuse,
        }),
    };

    const onPageChange = async (page, pageSize, sorter) => {
        onselect([], []);
        //onselectInfo([], []);
        setsPageA(page);
        setsPageSizeA(pageSize);
        SearchData(page, pageSize, undefined, undefined, undefined, undefined);
        setCurrentPage(page);
        window.scrollTo(0, 0);
    }
    const onTableChange = async (pagination, filters, sorter, extra) => {
        if (sorter) {
            onselect([], []);
            //onselectInfo([], []);
            setsFieldA(sorter.order === undefined ? undefined : sorter.field);
            setsOrderA(sorter.order);
            //SearchData(undefined, undefined, sorter.field, sorter.order, undefined, undefined);
            setsortedInfo(sorter);
        }
    }

    React.useEffect(() => {
        SearchData(undefined, undefined, undefined, undefined, undefined, undefined);
    }, [sFieldA, sOrderA]);

    const onDeleteClick = async () => {
        if (selectedRows.length > 0) {

            DialogDelete(async () => {
                let result: any = await AxiosPostJson(Controller + "onDeleteData", selectedRows);
                if (result.data.sStatus === Responsestart.success) {
                    onselect([], []);
                    Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete, null);
                    SearchData(undefined, undefined, undefined, undefined, undefined, undefined);
                } else if (result.data.sStatus === Responsestart.warning) {
                    Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                } else {
                    Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                }
            });
        }

    }

    const onDeleteClickInfo = async () => {
        if (selectedRows.length > 0) {

            DialogDelete(async () => {
                let result: any = await AxiosPostJson(Controller + "onDeleteDataInfo", selectedRows);
                if (result.data.sStatus === Responsestart.success) {
                    onselect([], []);
                    SearchData(undefined, undefined, undefined, undefined, undefined, undefined);
                    Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete, null);
                } else if (result.data.sStatus === Responsestart.warning) {
                    Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                } else {
                    Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                }
            });
        }

    }




    //*****************************TableAntd
    const SearchData = async (sPage, sPageSize, sField, sOrder, sTabSelectA, sGroup) => {
        let Page = sPage == undefined ? sPageA : sPage;
        let PageSize = sPageSize == undefined ? sPageSizeA : sPageSize;
        let Field = sField == undefined ? sFieldA : sField;
        let Order = sOrder == undefined ? sOrderA : sOrder;
        let TabSelect = sTabSelectA == undefined ? sTabSelect : sTabSelectA;
        setCurrentPage(+Page)
        if (TabSelect === "1") {
            let stxtGroupName = txtSearchGroup.stxtGroupName == undefined ? "" : txtSearchGroup.stxtGroupName;
            let stxtIsActive = txtSearchGroup.stxtIsActive == undefined ? "" : txtSearchGroup.stxtIsActive;
            let result: any = await AxiosGetJson(Controller + "SearchDatabyGroup?stxtName=" + encodeURIComponent(stxtGroupName) + "&stxtIsActive=" + stxtIsActive + "&sPage=" + Page + "&sPageSize=" + PageSize + "&sField=" + Field + "&sOrder=" + Order);
            setnTotalGroup(result.nTotal)
            setV_AdminGroup(result.V_AdminGroup);
        } else {

            let stxtName = txtSearchInfo.stxtName == undefined ? "" : txtSearchInfo.stxtName;
            let stxtGroup = sGroup != undefined ? sGroup : (txtSearchInfo.stxtGroup == undefined ? "" : txtSearchInfo.stxtGroup);
            let stxtIsActive = txtSearchInfo.stxtIsActive == undefined ? "" : txtSearchInfo.stxtIsActive;

            let result: any = await AxiosGetJson(Controller + "SearchDatabyUser?stxtName=" + encodeURIComponent(stxtName) + "&stxtGroup=" + stxtGroup + "&stxtIsActive=" + stxtIsActive + "&sPage=" + Page + "&sPageSize=" + PageSize + "&sField=" + Field + "&sOrder=" + Order);
            setnTotalInfo(result.nTotal)
            setV_AdminUser(result.V_AdminUser);
        }
    }



    const getLinkTo_UserInfo = async (nID: number) => {

        SearchData("1", undefined, undefined, undefined, "2", nID)
    }

    const getLinkTo_EditGroup = (nID: number) => {
        let sID = Encrypt(nID)
        let sTypeComponent = `/admin-user-permission-editgroup`
        let sPath = `${sTypeComponent}?sID=${sID}`
        return history.push(sPath);
    };

    const getLinkTo_EditInfo = (nID: number) => {
        let sID = Encrypt(nID)
        let sTypeComponent = `/admin-user-permission-editinfo`
        let sPath = `${sTypeComponent}?sID=${sID}`
        return history.push(sPath);
    };

    const header = [
        {
            title: 'ลำดับที่',
            dataIndex: 'key',
            className: "text-center",
            width: 100,
            render: (value) => {
                return (<>{value}.</>);
            },
        },
        {
            title: "ชื่อกลุ่ม",
            dataIndex: 'sNameg',
            sorter: true,
            key: "sNameg",
            sortOrder: sortedInfo.columnKey === 'sNameg' && sortedInfo.order,
        },
        {
            title: "สมาชิก",
            dataIndex: 'nMember',
            className: "text-center",
            sorter: true,
            render: (text, record: { nID: number; }) => (

                <a style={{ color: "#3498DB" }} onClick={() => {
                    setsTabSelect("2");
                    SettxtSearchInfo({
                        ...txtSearchInfo,
                        stxtGroup: record.nID + "",
                    });
                    getLinkTo_UserInfo(record.nID)
                }
                } > {text} < i className="fas fa-user-friends" ></i ></a >

            ),
            key: "nMember",
            sortOrder: sortedInfo.columnKey === 'nMember' && sortedInfo.order,
            width: 200,
        },
        {
            title: 'สถานะ',
            dataIndex: 'sIsActiveDes',
            key: "sIsActiveDes",
            width: 1,
            sorter: true,
            className: "text-center",
            render: (text: any, record: { sIsActive: string; }) => (
                <Tag color={record.sIsActive === "1" ? "#60cc28" : "#cc2838"} key={text} style={{ borderRadius: "32px" }}>
                    {text}
                </Tag>
            ),
            sortOrder: sortedInfo.columnKey === 'sIsActiveDes' && sortedInfo.order,
        },
        {
            title: isEdit ? <ButtonAdd_Table onClick={() => { history.push("/admin-user-permission-editgroup"); }} /> : null,
            key: 'action',
            className: "text-center",
            render: (text, record) => (
                isEdit ?
                    <Space size="middle">
                        <ButtonEdit_Table onClick={() => getLinkTo_EditGroup(record.nID)} />
                    </Space>
                    :
                    <Space size="middle">
                        <ButtonInfo_Table onClick={() => getLinkTo_EditGroup(record.nID)} />
                    </Space>

            ),
            width: 1,
        },
    ];


    const headerTableInfo = [
        {
            title: 'ลำดับที่',
            dataIndex: 'key',
            width: 100,
            className: "text-center",
            render: (value) => {
                return (<>{value}.</>);
            },
        },
        {
            title: "ประเภท",
            dataIndex: 'sEmployeeTypeName',
            key: 'sEmployeeTypeName',
            className: "text-center",
            width: 100,
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'sEmployeeTypeName' && sortedInfo.order,
        },
        {
            title: 'ชื่อ - นามสกุล',
            dataIndex: 'sName',
            key: "sName",
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'sName' && sortedInfo.order,
        },
        {
            title: "ชื่อบัญชีผู้ใช้งาน",
            dataIndex: 'sUsername',
            className: "text-center",
            key: 'sUsername',
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'sUsername' && sortedInfo.order,
        },
        {
            title: 'กลุ่มผู้ดูแลระบบ',
            dataIndex: 'sGroupName',
            key: "sGroupName",
            className: "text-center",
            width: 200,
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'sGroupName' && sortedInfo.order,
        },
        {
            title: 'สถานะ',
            dataIndex: 'sIsActiveDes',
            key: "sIsActiveDes",
            width: 1,
            className: "text-center",
            sorter: true,
            render: (text: any, record: { sIsActive: string; }) => (
                <Tag color={record.sIsActive === "1" ? "#60cc28" : "#cc2838"} key={text} style={{ borderRadius: "32px" }}>
                    {text}
                </Tag>
            ),
            sortOrder: sortedInfo.columnKey === 'sIsActiveDes' && sortedInfo.order,

        },
        {
            title: isEdit ? <ButtonAdd_Table onClick={() => { history.push("/admin-user-permission-editinfo"); }} /> : null,
            key: 'action',
            render: (text, record) => (

                isEdit ?
                    <Space size="middle">
                        <ButtonEdit_Table onClick={() => { getLinkTo_EditInfo(record.nID); }} />
                    </Space>
                    :
                    <Space size="middle">
                        <ButtonInfo_Table onClick={() => { getLinkTo_EditInfo(record.nID); }} />
                    </Space>



            ),
            width: 1,
        },
    ];



    const TabsonChange = async (key: string) => {

        setsortedInfo({});
        setsFieldA(undefined);
        setsTabSelect(key);
        setsOrderA(sOrderA);
        onselect([], []);
        //onselectInfo([], []);
        setsPageA("1");
        setsPageSizeA("10");
        setCurrentPage(1);
        window.scrollTo(0, 0);
        SearchData("1", "10", undefined, undefined, key, undefined);

    }
    return (
        <React.Fragment>
            <Tabs type="card" onChange={(e) => { TabsonChange(e); }} activeKey={sTabSelect} >
                <TabPane tab="บัญชีผู้ดูแลระบบ" key="2">
                    <Row justify="end" gutter={[16, 16]}>

                        <Col xl={5} md={12} xs={24}>

                            <Input
                                autoComplete="off"
                                placeholder="ชื่อบัญชีผู้ใช้งาน, ชื่อ - นามสกุล"
                                onChange={(e) => {
                                    SettxtSearchInfo({
                                        ...txtSearchInfo,
                                        stxtName: e.target.value,
                                    });
                                }}
                                onKeyPress={(e) => {
                                    e.key === "Enter" && SearchData(undefined, undefined, undefined, undefined, undefined, undefined);
                                }}
                            />
                        </Col>
                        <Col xl={5} md={12} xs={24}>

                            <AntdSelect
                                allowClear={true}
                                showSearch={true}
                                style={{ width: "100%" }}
                                value={txtSearchInfo.stxtGroup}
                                placeholder="- กลุ่มผู้ดูแลระบบ -"
                                onChange={(e) => {
                                    SettxtSearchInfo({
                                        ...txtSearchInfo,
                                        stxtGroup: e,
                                    });
                                }}
                                filterOption={(input, option) =>
                                    option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    T_AdminGroup.map((o: any, i: any) => {
                                        return (
                                            <option key={o.nID} value={o.nID + ""}>{o.sName}</option>
                                        )
                                    }
                                    )
                                }
                            </AntdSelect>
                        </Col>
                        <Col xl={4} md={10} xs={20}>

                            <AntdSelect
                                allowClear={true}
                                showSearch={true}
                                style={{ width: "100%" }}
                                value={txtSearchInfo.stxtIsActive}
                                placeholder="- สถานะ -"
                                onChange={(e) => {
                                    SettxtSearchInfo({
                                        ...txtSearchInfo,
                                        stxtIsActive: e,
                                    });
                                }}
                                filterOption={(input, option) =>
                                    option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <option value="1">ใช้งาน</option>
                                <option value="0">ไม่ใช้งาน</option>
                            </AntdSelect>
                        </Col>
                        <Col xl={1} md={2} xs={4}>
                            <ButtonSearch onClick={() => SearchData(1, undefined, undefined, undefined, undefined, undefined)} />
                        </Col>
                    </Row>
                    <br />

                    <TableAntd
                        dataSource={V_AdminUser}
                        columns={headerTableInfo}
                        onPageChange={onPageChange}
                        currentPage={nCurrentPage}
                        total={nTotalInfo}
                        onChange={onTableChange}
                        onDisableDelete={selectedRows.length > 0 ? false : true}
                        rowSelection={isEdit ? rowSelection : undefined}
                        onShowDelete={isEdit}
                        scroll={{ x: 800 }}
                        onClickDeleteData={onDeleteClickInfo}
                        pageSize={sPageSizeA ? parseInt(sPageSizeA) : 10}
                    />

                </TabPane>
                <TabPane tab="กลุ่มผู้ดูแลระบบ" key="1">

                    <Row justify="end" gutter={[16, 16]}>
                        <Col xl={5} md={12} xs={24}>

                            <Input
                                autoComplete="off"
                                placeholder="ชื่อกลุ่ม"
                                onChange={(e) =>
                                    SetTxtSearchGroup({
                                        ...txtSearchGroup,
                                        stxtGroupName: e.target.value,
                                    })
                                }
                                onKeyPress={(e) => {
                                    e.key === "Enter" && SearchData(undefined, undefined, undefined, undefined, undefined, undefined);
                                }}
                            />
                        </Col>
                        <Col xl={4} md={10} xs={20}>
                            <AntdSelect
                                allowClear={true}
                                showSearch={true}
                                style={{ width: "100%" }}
                                value={txtSearchGroup.stxtIsActive}
                                placeholder="- สถานะ -"
                                onChange={(e) => {
                                    SetTxtSearchGroup({
                                        ...txtSearchGroup,
                                        stxtIsActive: e,
                                    });
                                }}
                                filterOption={(input, option) =>
                                    option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <option value="1">ใช้งาน</option>
                                <option value="0">ไม่ใช้งาน</option>
                            </AntdSelect>
                        </Col>
                        <Col xl={1} md={2} xs={4}>
                            <ButtonSearch onClick={() => { SearchData(1, undefined, undefined, undefined, undefined, undefined); }} />
                        </Col>
                    </Row>
                    <br />
                    <TableAntd
                        dataSource={V_AdminGroup}
                        columns={header}
                        onPageChange={onPageChange}
                        currentPage={nCurrentPage}
                        total={nTotalGroup}
                        onChange={onTableChange}
                        onDisableDelete={selectedRows.length > 0 ? false : true}
                        rowSelection={isEdit ? rowSelection : undefined}
                        onShowDelete={isEdit}
                        scroll={{ x: 800 }}
                        onClickDeleteData={onDeleteClick}
                        pageSize={sPageSizeA ? parseInt(sPageSizeA) : 10}
                    />
                </TabPane>
            </Tabs>
            <br />

        </React.Fragment>
    );
};
export default Admin_User_Permission_List;
