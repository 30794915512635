import { createSlice } from '@reduxjs/toolkit';

export const translationsObject = {
    th: {
        TranslatType: "TH",   //----------------- Type
        Contact: 'ช่องทางการติดต่อ',
        ContactUs: 'ติดต่อสอบถาม',
        ContactUsHome: 'ติดต่อเรา',
        ContactTopic: 'หัวข้อการติดต่อ',
        ContactName: 'ชื่อผู้ติดต่อ',
        ContactAgency: 'หน่วยงานผู้ติดต่อ',
        TelephoneNumber: 'เบอร์โทรศัพท์',
        Email: 'อีเมล',
        Subject: 'เรื่อง',
        Details: 'รายละเอียด',
        Send: 'ส่ง',
        SearchTerm: 'คำค้นหา',
        Province: 'จังหวัด',
        District: 'อำเภอ',
        SubDistrict: 'ตำบล',
        More_than_1_item_canbe_selected: 'สามารถเลือกได้มากกว่า 1 รายการ',
        Address: 'ที่อยู่',
        Coordinates: 'พิกัด',
        Latitude: 'ละติจูด',
        Longitude: 'ลองจิจูด',
        Readmore: 'อ่านเพิ่มเติม',
        Clear: 'ล้างข้อมูล',
        Region: 'ภูมิภาค',
        Title1: "สถาบันปลูกป่า",
        Title2: "และระบบนิเวศ ปตท.",
        Tel: "โทร.",
        PlaceHolderSearch: "ระบุคำค้นหา",
        Search: "ค้นหา",
        Subscription: "ติดตามรับข่าวสารใหม่",
        PlaceHolderEmail: "ระบุอีเมล",
        Subscript: "รับการแจ้งเตือน",
        UnSubscript: "ยกเลิกการแจ้งเตือน",
        CompanyName: "บริษัท ปตท. จำกัด (มหาชน)",
        CompanyAddress: "555 ถนนวิภาวดีรังสิต แขวงจตุจักร เขตจตุจักร กรุงเทพฯ 10900",
        LastestNews: "ข่าวสารล่าสุด",
        More: "ดูข้อมูลเพิ่มเติม",
        Calendar: "ปฏิทิน",
        Event: "กิจกรรม",
        OnlineBooking: "จอง / เยี่ยมชมออนไลน์",
        SiteMap: "แผนผังเว็บไซต์",
        AnimalName: "ชื่อสัตว์",
        AnimalSciName: "ชื่อวิทยาศาสตร์",
        AnimalNornalName: "ชื่อสามัญ",
        AnimalHabitat: "ถิ่นอาศัย",
        AnimalBehavior: "พฤติกรรม",
        AnimalCurrent: "สถานะภาพปัจจุบัน",
        Gallery: "คลังภาพ",
        Reference: "แหล่งอ้างอิง",
        RelatedFile: "ไฟล์ที่เกี่ยวข้อง",
        RelatedContent: "หัวข้อที่เกี่ยวข้อง",
        RelatedNews: "ข่าวที่เกี่ยวข้อง",
        LearningCenter: "ศูนย์เรียนรู้",
        HlProject: "โครงการสำคัญ",
        Knowledge: "องค์ความรู้",
        News: "ข่าวประชาสัมพันธ์",
        ForestStory: "เรื่องเล่าจากป่า",
        PlantName: "ชื่อพืช",
        PlantSciName: "ชื่อวิทยาศาสตร์",
        PlantFamily: "วงศ์",
        PlantNative: "ชื่อพื้นเมือง",
        PlantDetail: "ข้อมูลพันธุ์ไม้",
        Trunk: "ลำต้น / ลักษณะเนื้อไม้",
        Leaf: "ใบ",
        Fruit: "ผล",
        Flower: "ดอก",
        Properties: "ประโยชน์",
        PlantPath: "ภาพประกอบ",
        Back: "กลับ",
        EventDetail: "รายละเอียดกิจกรรม",
        EventLocation: "สถานที่จัดงาน",
        EventDuration: "ช่วงเวลาที่จัดงาน",
        Keyword: "ผลการค้นหา",
        AllContent: "รายการทั้งหมด",
        ConfirmSubscribe: "ต้องการติดตามข่าวสารหรือไม่ ?",
        ConfirmUnsubscribe: "ต้องการยกเลิกติดตามข่าวสารหรือไม่ ?",
        Confirm: "ยืนยันการทำงาน",
        NewsDate: "วันที่ข่าว",
        NewsDateContent: "วันที่ข่าว",
        To: "ถึง",
        Topic: "หัวข้อ",
        Previous: "ก่อนหน้า",
        Next: "ต่อไป",
        Download: "ดาวน์โหลด",
        FullScreen: "เด็มหน้าจอ",
        ExitFullScreen: "ออกจากเต็มหน้าจอ",
        ZoomOut: "ซูมออก",
        ZoomIn: "ซูมเข้า",
        PTTforest: "พื้นที่ป่าเก่า",
        FPTForest: "พื้นที่ป่าใหม่",
        OtherForest: "พื้นที่ป่าอื่นๆ",
        PTTForestMap: "แผนที่ป่า",
        AllEvent: "กิจกรรมทั้งหมด",
        Like: "คนถูกใจบทความนี้",
        Liked: "คุณและอีก",
        PrivacyPolicy: "นโยบายความเป็นส่วนตัว",
        CookiesPolicy: "นโยบายการใช้คุ้กกี้",
        Cancel: "ยกเลิก",
        Success: "การทำงานเสร็จสิ้น",
        Warning: "แจ้งเตือน",
        Error: "การทำงานผิดพลาด",
        Map: "เส้นทาง"
    },
    en: {
        TranslatType: "EN", //----------------- Type
        Contact: 'Contact Channel',
        ContactUs: 'Contact us',
        ContactUsHome: 'Contact us',
        ContactTopic: 'Contact Topic',
        ContactName: 'Contact Name',
        ContactAgency: 'Contact Agency',
        TelephoneNumber: 'Telephone Number',
        Email: 'Email',
        Subject: 'Subject',
        Details: 'Details',
        Send: 'Send',
        SearchTerm: 'Search Term',
        Province: 'Province',
        District: 'District',
        SubDistrict: 'SubDistrict',
        More_than_1_item_canbe_selected: 'More than 1 item can be selected',
        Address: 'Address',
        Coordinates: 'Coordinates',
        Latitude: 'Latitude',
        Longitude: 'Longitude',
        Readmore: 'Read more',
        Clear: 'Clear',
        Region: 'Region',
        Title1: "Reforestation",
        Title2: "and Ecology Institute",
        Tel: "Tel.",
        PlaceHolderSearch: "Please input to keyword",
        Search: "Search",
        Subscription: "Subscribe for new information",
        PlaceHolderEmail: "Please input email",
        Subscript: "Subscribe",
        UnSubscript: "Unsubscribe",
        CompanyName: "PTT Public Company Limited",
        CompanyAddress: "555 Vibhavadi Rangsit Road, Chatuchak Bangkok 10900 Thailand",
        LastestNews: "Lastest News",
        More: "More...",
        Calendar: "Calendar",
        Event: "Event",
        OnlineBooking: "VISIT & RESERVATION",
        SiteMap: "Site Map",
        AnimalName: "Name",
        AnimalSciName: "Science Name",
        AnimalNornalName: "Normal Name",
        AnimalHabitat: "Habitat",
        AnimalBehavior: "Behavior",
        AnimalCurrent: "In Current",
        Gallery: "Gallery",
        Reference: "Reference",
        RelatedFile: "Releted Files",
        RelatedContent: "Related Content",
        RelatedNews: "Related News",
        LearningCenter: "Learning Center",
        HlProject: "High-Light Project",
        Knowledge: "Knowledge",
        News: "News",
        ForestStory: "Forest Story",
        PlantName: "Name",
        PlantSciName: "Science Name",
        PlantFamily: "Family",
        PlantNative: "Native Name",
        PlantDetail: "Detail",
        Trunk: "Trunk",
        Leaf: "Leaf",
        Fruit: "Fruit",
        Flower: "Flower",
        Properties: "Properties",
        PlantPath: "Plant Path",
        Back: "Back",
        EventDetail: "Detail",
        EventLocation: "Location",
        EventDuration: "Duration",
        Keyword: "Keyword",
        AllContent: "All Contents",
        ConfirmSubscribe: "Do you want to subscribe ?",
        ConfirmUnsubscribe: "Do you want to unsubscribe ?",
        Confirm: "Confirm",
        NewsDate: "News Date From",
        NewsDateContent: "News Date",
        To: "To",
        Topic: "Topic",
        Previous: "Previous",
        Next: "Next",
        Download: "Download",
        FullScreen: "FullScreen",
        ExitFullScreen: "Exit FullScreen",
        ZoomOut: "Zoom Out",
        ZoomIn: "Zoom In",
        PTTforest: "PTT Forest",
        FPTForest: "FPT Forest",
        OtherForest: "Other Forest",
        PTTForestMap: "PTT Forest Map",
        AllEvent: "All Event",
        Like: "people like this content.",
        Liked: "You and",
        PrivacyPolicy: "Privacy Policy",
        CookiesPolicy: "Cookies Policy",
        Cancel: "Cancel",
        Success: "Success",
        Warning: "Warning",
        Error: "Error",
        Map: "Map"
    }
}

const persistedState = localStorage.getItem('reduxState')
    ? JSON.parse(localStorage.getItem('reduxState') + "")
    : undefined


export const slice = createSlice({
    name: 'language',
    initialState: persistedState === undefined ? translationsObject.th : persistedState,
    reducers: {
        thailanguage: state => translationsObject.th,
        englishlanguage: state => translationsObject.en,
    },
});

export const { thailanguage, englishlanguage } = slice.actions;

export const currentlanguage = state => state.counter;

export default slice.reducer;
