import { Button, Col, Input, Row, Space, Tabs, Tag } from "antd";
import * as React from "react";
import { Empty, Select as AntdSelect } from "antd";
import { ButtonAdd_Table, ButtonBack, ButtonEdit_Table, ButtonInfo_Table, ButtonSearch } from "../Systems/button_all";
import TableAntd from "../Systems/TableAntd";
import { useHistory } from "react-router";
import { BoxMsg, DialogConfirm, DialogDelete, Encrypt, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import { Pin, PinFill } from "react-bootstrap-icons";
import AuthenService from "../Service/AuthenService";

const Admin_Content_List = ({ sType = "99", editEvent = "/", nPerMenuId = 99 }) => {
    var type = sType ? sType : "99";
    var editEv = editEvent ? editEvent : "/";
    var nMenuId = nPerMenuId ? nPerMenuId : 99;
    const history = useHistory();
    const [selectedRows, setselectedRows] = React.useState([] as Array<any>);
    const [selectedRowKeys, setrowSelected] = React.useState([] as any);
    const [selectedRowsP, setselectedRowsP] = React.useState([] as Array<any>);
    const [selectedRowKeysP, setrowSelectedP] = React.useState([] as any);
    const [lstContent, setLstContent] = React.useState([] as any);
    const [lstContentPin, setLstContentPin] = React.useState([] as any);
    const [lstContentOrder, setLstContentOrder] = React.useState([] as any);
    const [txtSearch, SetTxtSearch] = React.useState({
        sSubject: "",
        sStatus: null as any
    });
    const [total, setTotal] = React.useState(0);
    const [sPageA, setsPageA] = React.useState("1" as string || undefined);
    const [sPageSizeA, setsPageSizeA] = React.useState("10" as string || undefined);
    const [sFieldA, setsFieldA] = React.useState("" as string || undefined);
    const [sOrderA, setsOrderA] = React.useState("" as string || undefined);
    const [nCurrentPage, setCurrentPage] = React.useState(1);
    const [sTabSelect, setsTabSelect] = React.useState("1" as string);
    
    const [nTotalPin, setnTotalPin] = React.useState(0 as number);
    const [sPageAP, setsPageAP] = React.useState("1" as string || undefined);
    const [sPageSizeAP, setsPageSizeAP] = React.useState("10" as string || undefined);
    const [sFieldAP, setsFieldAP] = React.useState("" as string || undefined);
    const [sOrderAP, setsOrderAP] = React.useState("" as string || undefined);
    const [nCurrentPageP, setCurrentPageP] = React.useState(1);

    const [isShowPin, setIsShowPin] = React.useState(true);

    const { TabPane } = Tabs;
    const [isEdit, setisEdit] = React.useState(false);
    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            setisEdit(UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == nMenuId).map(t => t.nPermission)[0] === 2 ? false : true)
        }
    }

    const header = [
        {
            title: "ลำดับที่", dataIndex: "key", key: "Key_No_1", width: 1, render: (text: any, record: any) => {
                return (<a key={record.nkey}>{record.nkey}.</a>);
            },
            className: "text-center text-nowrap",
        },
        { title: "หัวข้อ", dataIndex: "sSubject", key: "Key_No_2", sorter: true, className: "text-left text-wrap", width: 600, },
        { title: "วันที่แสดง ", dataIndex: "sShowStart", key: "Key_No_3", sorter: true, className: "text-center text-wrap", width: 200, },
        {
            title: 'สถานะ',
            dataIndex: 'isActive',
            width: 50,
            sorter: true,
            className: "text-center",
            render: (text: any, record: { sIsActive: string; }) => (
                <Tag color={record.sIsActive === "1" ? "#60cc28" : "#cc2838"} key={text} style={{ borderRadius: "32px" }}>
                    {record.sIsActive === "1" ? "ใช้งาน" : "ไม่ใช้งาน"}
                </Tag>
            ),
        },
        {
            title: 'ปักหมุด',
            dataIndex: 'isPin',
            width: 100,
            sorter: false,
            key: "isPin",
            className: "text-center",
            render: (text: any, record: { nID: number, isPin: boolean, isActive: boolean }) => (
                <>
                    {
                        record.isActive === true ?
                            (record.isPin === true ?
                                <Button disabled={!isEdit} shape="circle" type="text" color="link" style={{
                                    color: "#ffffff",
                                    background: "#7d68db",
                                    border: "#5867a9"

                                }}
                                    onClick={(e: any) => { ContentPin(record.nID, !record.isPin) }}
                                >
                                    <PinFill />
                                </Button >
                                :
                                <Button disabled={!isEdit} shape="circle" type="text" color="link" style={{

                                    background: "#e3e3e3",
                                    border: "#424d7d"

                                }}
                                    onClick={(e: any) => { ContentPin(record.nID, !record.isPin) }}
                                >
                                    <Pin style={{ transform: "rotate(45deg)" }} />
                                </Button >
                            )
                            :
                            null
                    }
                </>

            ),
        },
        {
            title: isEdit ? <ButtonAdd_Table onClick={() => { getLinkTo_Add(type); }} /> : null,
            dataIndex: "4", key: "Key_No_8",
            className: "text-center",
            render: (text: any, record: any) => (
                isEdit ?
                    <Space size="middle">
                        <ButtonEdit_Table
                            onClick={() => { getLinkTo_Edit(record.sID); }}
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </ButtonEdit_Table>
                    </Space>
                    :
                    <Space size="middle">
                        <ButtonInfo_Table
                            onClick={() => { getLinkTo_Edit(record.sID); }}
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </ButtonInfo_Table>
                    </Space>

            ),
            width: 1,
        },
    ];
    //<ButtonEdit_Table onClick={() => { getLinkTo_Edit(record.sID) }} />
    const ContentPin = async (nID: number, isPin: boolean) => {
        DialogConfirm(async () => {
            setIsShowPin(false);
            let result: any = await AxiosPostJson("api/AdminContent/Change_Pins?nID=" + nID + "&isPin=" + isPin);
            if (result.data.sStatus === Responsestart.success) {
                setselectedRowsP([]);
                setrowSelectedP([]);
                onselectPin([], []);
                setLstContentPin([]);
                setselectedRows([]);
                setrowSelected([]);
                onselect([], []);
                setLstContent([]);
                GetDataOnPageLoad(sPageA, sPageSizeA, sFieldA, sOrderA);
                GetDataOnPageLoadPin(sPageSizeAP, sPageSizeAP, sFieldAP, sOrderAP);
                GetOrderPin(sType)
                await Sweetalert.Success(BoxMsg.Title_Success, isPin == true ? "ปักหมุดข้อมูลเรียบร้อย" : "เอาปักหมุดออกเรียบร้อย", null);
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }
            setIsShowPin(true);
        }, "", "", isPin == true ? "ท่านต้องการปักหมุดข้อมูลหรือไม่?" : "ท่านต้องการเอาปักหมุดออกหรือไม่?")

    }

    const getLinkTo_Add = (sID: any) => {
        let sTypeComponent = editEv;
        let sPath = ``;
        if (sID === "1" || sID === "2") {
            sPath = `${sTypeComponent}`;
        }
        else {
            sPath = `${sTypeComponent}?type=${sID}`;
        }
        
        return history.push(sPath);
    };

    const getLinkTo_Edit = (sID: any) => {
        let sTypeComponent = editEv;
        let sPath = `${sTypeComponent}?id=${sID}`;
        return history.push(sPath);
    };

    const GetDataOnPageLoad = async (sPage, sPageSize, sField, sOrder) => {
        var data = {
            sType: type,
            sSubject: txtSearch.sSubject,
            sStatus: txtSearch.sStatus,
            sPage: sPage ? sPage.toString() : sPageA,
            sPageSize: sPageSize ? sPageSize.toString() : sPageSizeA,
            sField: sField ? sField : sFieldA,
            sOrder: sOrder, // ? sOrder : sOrderA,
        };
        let result: any = await AxiosPostJson("api/AdminContent/GetContentList", data);
        setLstContent(result.data.cContentList);
        setTotal(result.data.nTotal);
    }

    const GetDataOnPageLoadPin = async (sPage, sPageSize, sField, sOrder) => {
        var data = {
            sType: type,
            sSubject: txtSearch.sSubject,
            sStatus: txtSearch.sStatus,
            sPage: sPage ? sPage.toString() : sPageA,
            sPageSize: sPageSize ? sPageSize.toString() : sPageSizeA,
            sField: sField ? sField : sFieldA,
            sOrder: sOrder, // ? sOrder : sOrderA,
        };
        let result: any = await AxiosPostJson("api/AdminContent/GetContentListPin", data);
        setLstContentPin(result.data.cContentList);
        setnTotalPin(result.data.nTotal);
    }

    const onPageChange = async (page, pageSize, sorter) => {
        setsPageA(page);
        setsPageSizeA(pageSize);
        GetDataOnPageLoad(page, pageSize, sFieldA, sOrderA);
        setCurrentPage(page);
        window.scrollTo(0, 0)
    }
    const onPageChangePin = async (page, pageSize, sorter) => {
        setsPageAP(page);
        setsPageSizeAP(pageSize);
        GetDataOnPageLoadPin(page, pageSize, sFieldAP, sOrderAP);
        setCurrentPageP(page);
        window.scrollTo(0, 0)
    }

    const onTableChange = async (pagination, filters, sorter, extra) => {
        if (sorter) {
            setsFieldA(sorter.field);
            setsOrderA(sorter.order);
            GetDataOnPageLoad(sPageA, sPageSizeA, sorter.field, sorter.order);
        }
    }

    const onTableChangePin = async (pagination, filters, sorter, extra) => {
        if (sorter) {
            setLstContentPin([]);
            setsFieldAP(sorter.field);
            setsOrderAP(sorter.order);
            GetDataOnPageLoadPin(sPageAP, sPageSizeAP, sorter.field, sorter.order);
        }
    }

    const onDeleteClick = async () => {
        if (selectedRows.length > 0) {
            DialogDelete(async () => {
                setIsShowPin(false);
                let result: any = await AxiosPostJson("api/AdminContent/DeleteData", selectedRows);
                if (result.data.sStatus === Responsestart.success) {
                    Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete,
                        () => {
                            setLstContent([]);
                            setTotal(0);
                            setselectedRows([]);
                            onselect([], []);
                            GetDataOnPageLoad(sPageA, sPageSizeA, sFieldA, sOrderA);
                        });
                } else if (result.data.sStatus === Responsestart.warning) {
                    Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                } else {
                    Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                }
                setIsShowPin(true);
            });
        }
    }

    const onselect = (selectedRowKeys, selectedRows: React.SetStateAction<any[]>) => {
        setselectedRows(selectedRows);
        setrowSelected(selectedRowKeys);
    }

    const onselectPin = (selectedRowKeysPeys, selectedRows: React.SetStateAction<any[]>) => {
        setselectedRowsP(selectedRows);
        setrowSelectedP(selectedRowKeysPeys);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onselect,
        getCheckboxProps: (record) => ({
            //disabled: record.isHasDelete !== true, // Column configuration not to be checked
            disabled: false, // Column configuration not to be checked
            name: record.nID,
        }),
    };

    const rowSelectionPin = {
        selectedRowKeysP,
        onChange: onselectPin,
        onSelectAll: (selected, selectedRows, changeRows) => {
            if (selectedRowKeysP.length !== 0) {
                setrowSelectedP([]);
            }
          },
        getCheckboxProps: (record) => ({
            //disabled: record.isHasDelete !== true, // Column configuration not to be checked
            disabled: false, // Column configuration not to be checked
            name: record.nID,
        }),
    };

    const TabsonChange = async (key: string) => {

        setsTabSelect(key);
        onselect([], []);
        onselectPin([], []);
        setsPageA("1");
        setsPageAP("1");
        setsPageSizeA("10");
        setsPageSizeAP("10");
        setCurrentPage(1);
        setCurrentPageP(1);
        window.scrollTo(0, 0);
        GetDataOnPageLoad(sPageA, sPageSizeA, sFieldA, sOrderA);
        GetDataOnPageLoadPin(sPageAP, sPageSizeAP, sFieldAP, sOrderAP);
    }
    const headerPin = [
        {
            title: "", dataIndex: "key", key: "Key_No_1", width: 1, render: (text: any, record: any) => {
                return null;
            },
            className: "text-center text-nowrap",
        },
        {
            title: 'ลำดับที่',
            dataIndex: 'nOrder',
            key: 'nOrder',
            sorter: true,
            className: "text-center",
            width: 75,
            render: (value: any, record: { nOrder: number, nID: number }) => (

                <AntdSelect
                    //allowClear={true}
                    disabled={!isEdit}
                    showSearch={true}
                    style={{ width: "100%" }}
                    value={record.nOrder}
                    onChange={(e: any) => SetOrder(e.toString(), record.nID)}
                    filterOption={(input, option) =>
                        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {

                        lstContentOrder.map((p) => {
                            return (
                                <option key={"od_" +p.value} value={p.value} >{p.label + ""}</option>
                            )
                        })
                    }
                </AntdSelect>
            ),
        },
        {
            title: "หัวข้อ",
            dataIndex: 'sSubject',
            key: 'sSubject',
            sorter: true,
            width: 700,
            render: (text: any, record: { sSubject: string }) => (

                record.sSubject

            ),
        },
    ];

    const SetOrder = async (sOrder: string, nMenuID: number) => {
        setIsShowPin(false);
        if (sOrder != "0") {
            let result: any = await AxiosPostJson("api/AdminContent/SetOrder?sOrder=" + sOrder + "&nContentID=" + nMenuID + "&nTypeID=" + type);
            if (result.data.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Save, null);
                onselect([], []);
                GetDataOnPageLoad(sPageA, sPageSizeA, sFieldA, sOrderA);
                GetDataOnPageLoadPin(sPageAP, sPageSizeAP, sFieldAP, sOrderAP);
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }
        }
        setIsShowPin(true);
    }

    const onDeleteClickPin = async () => {
        if (selectedRowsP.length > 0) {
            DialogConfirm(async () => {
                setIsShowPin(false);
                let result: any = await AxiosPostJson("api/AdminContent/DeleteDataPin", selectedRowsP);
                if (result.data.sStatus === Responsestart.success) {
                    Sweetalert.Success(BoxMsg.Title_Success, "เอาปักหมุดออกเรียบร้อย", null);
                    setselectedRowsP([]);
                    setrowSelectedP([]);
                    onselectPin([], []);
                    GetDataOnPageLoad(sPageA, sPageSizeA, sFieldA, sOrderA);
                    GetDataOnPageLoadPin(undefined, undefined, undefined, undefined);
                } else if (result.data.sStatus === Responsestart.warning) {
                    Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                } else {
                    Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                }
                setIsShowPin(true);
            }, "", "", "ท่านต้องการเอาปักหมุดออกหรือไม่?");
        }
    }

    const GetOrderPin = async (sType) =>{
        let result: any = await AxiosGetJson("api/AdminContent/GetOrderPin?sType=" + sType);
        setLstContentOrder(result)
    }

    React.useEffect(() => {
        GetUserInfo();
    }, []);

    React.useEffect(() => {
        GetDataOnPageLoad(undefined, undefined, undefined, undefined);
        GetDataOnPageLoadPin(undefined, undefined, undefined, undefined);
        GetOrderPin(sType)
    }, [sType]);

    return (
        <React.Fragment>
            <br />
            <Tabs type="card" onChange={(e) => {
                // SetTxtSearch({
                //     ...txtSearch,
                //     sStatus: null,
                //     sSubject: "",
                // });
                TabsonChange(e);

            }} activeKey={sTabSelect} >
                <TabPane tab="จัดการข้อมูล" key="1">
                    <Row  gutter={[16, 16]}>
                            {
                                sType === "1" || sType === "2" ?
                                    null :
                                    <Col>
                                        <ButtonBack onClick={() => { history.goBack(); }} />
                                    </Col>
                            }
                        
                        <Col xl={6} md={8} xs={24} className="ml-auto">
                                <Input
                                    autoComplete="off"
                                    placeholder="ชื่อหัวข้อ"
                                    onChange={(e) =>
                                        SetTxtSearch({
                                            ...txtSearch,
                                            sSubject: e.target.value,
                                        })
                                    }
                                    onKeyPress={(e) => {
                                        e.key === "Enter" && GetDataOnPageLoad(undefined, undefined, undefined, undefined);
                                    }}
                                />
                        </Col>
                        <Col xl={6} md={8} xs={24}>
                                <AntdSelect
                                    allowClear={true}
                                    showSearch={true}
                                    style={{ width: "100%" }}
                                    value={txtSearch.sStatus}
                                    placeholder="- สถานะ -"
                                    onChange={(e) => {
                                        SetTxtSearch({
                                            ...txtSearch,
                                            sStatus: e,
                                        });
                                    }}
                                    filterOption={(input, option) =>
                                        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <option value="1">ใช้งาน</option>
                                    <option value="0">ไม่ใช้งาน</option>
                                </AntdSelect>
                        </Col>
                        <Col>
                            <ButtonSearch onClick={() => { GetDataOnPageLoad(sPageA, sPageSizeA, sFieldA, sOrderA); }} />
                        </Col>
                    </Row>
                    <br />
                    <div className="ant-spin-nested-loading">
                        <div className="ant-spin-container">
                            <div className="ant-table">
                                <div className="ant-table-container">
                                    <div className="ant-table-content">
                                        {
                                            isShowPin ?
                                            <TableAntd
                                                dataSource={lstContent}
                                                columns={header}
                                                onPageChange={onPageChange}
                                                currentPage={nCurrentPage}
                                                total={total}
                                                //rowSelection={rowSelection}
                                                onChange={onTableChange}
                                                //onShowDelete={true}
                                                onClickDeleteData={onDeleteClick}
                                                scroll={{ x: 650 }}
                                                rowSelection={isEdit ? rowSelection : undefined}
                                                onShowDelete={isEdit}
                                                pageSize={sPageSizeA ? parseInt(sPageSizeA) : 10}
                                            />
                                            :
                                            null
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPane>
                <TabPane tab="ปักหมุด" key="2">
                    {
                        isShowPin ?
                        <TableAntd
                            dataSource={lstContentPin}
                            columns={headerPin}
                            onPageChange={onPageChangePin}
                            currentPage={nCurrentPageP}
                            total={nTotalPin}
                            //rowSelection={rowSelection}
                            onChange={onTableChangePin}
                            onDisableDelete={selectedRowsP.length > 0 ? false : true}
                            //onShowDelete={true}
                            onClickDeleteData={onDeleteClickPin}
                            rowSelection={isEdit ? rowSelectionPin : undefined}
                            onShowDelete={isEdit}
                            pageSize={sPageSizeAP ? parseInt(sPageSizeAP) : 10}
                            
                        />
                        :
                        null
                    }
                    
                </TabPane>
            </Tabs>
            
        </React.Fragment>
    );
}
export default Admin_Content_List;