import * as React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../main-home/home.css";
import "../main-content/content.css";
import { AxiosGetJson } from "../Service/Config/AxiosMethod";
import { parse } from "query-string";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from 'react-redux';
import {
    thailanguage,
    englishlanguage,
    currentlanguage,
} from '../app/counterSlice';
import GalleryTrigger from "../ST_Handlers/GalleryTrigger";
import Image from "../Systems/Image";
import { 
    EmailShareButton,
    FacebookShareButton,
    LineShareButton,
    EmailIcon,
    FacebookIcon,
    LineIcon,
 } from "react-share";
import { Button } from "antd";
// import DocumentMeta from 'react-document-meta';
// import {Helmet} from "react-helmet";
//import { ShareButtonCircle } from "react-custom-share";

const Main_Content_Detail = ({ isShowCover = false, adminMenu = "99" }) => {
    const { id, ref } = parse(window.location.search);
    var isCover = isShowCover ? isShowCover : false;
    var adMunu = adminMenu ? adminMenu : false;
    const [sFilePath, setFilePath] = React.useState("");
    const [contentDetail, setContentDetail] = React.useState(null as any);
    const [sFileCover, setFileCover] = React.useState("");
    const [isLoad , setIsLoad] = React.useState(false);
    const [isLike, setIsLike] = React.useState(false);
    const [nLike, setnLike] = React.useState(0);
    const [sPathWeb, setPathWeb] = React.useState("");
    const history = useHistory();
    const translate = useSelector(currentlanguage);
    const dispatch = useDispatch();
    

    const SetBackground = async () => {
        let result: any = await AxiosGetJson("api/AdminInfo/GetBackground");
        setFilePath(result.sFilePath);
    };


    const GetContentDetail = async (idStr,TranslatType) => {
        let result: any = await AxiosGetJson(
            "api/AdminInfo/GetContentDetail?nMenuID=" + adMunu + "&id=" + idStr + "&Mode=" + TranslatType
        );
        setContentDetail(result);
        // let ogTitle = document.getElementById("ogTitle");
        // if(ogTitle) {
        //     ogTitle.attributes["content"] = result.sSubject;
        // }

        // let ogDesc = document.getElementById("ogDesc");
        // if(ogDesc) {
        //     ogDesc.attributes["content"] = result.sSubject;
        // }

        // let ogImage = document.getElementById("ogImage");
        // if(ogImage) {
        //     ogImage.attributes["content"] = result.lstFileCover[0].sSaveToPath;
        // }
        setFileCover(result.lstFileCover[0].sSaveToPath);
    };

    const onOpenRelated = (x) => {
        let sTypeComponent = "";
        if (x.nHeadLv0 === "1") {
            sTypeComponent = "/news-detail";
        }
        if (x.nHeadLv0 === "2") {
            sTypeComponent = "/forest-story-detail";
        }
        if (x.nHeadLv0 === "5") {
            sTypeComponent = "/learning-center-sub";
        }
        if (x.nHeadLv0 === "6") {
            sTypeComponent = "/hl-project-sub";
        }
        if (x.nHeadLv0 === "7") {
            sTypeComponent = "/knowledge-sub";
        }
        let sPath = `${sTypeComponent}?id=${x.sUrl}`;
        return history.push(sPath);
        // let sTypeComponent = "/news-detail";
        // let sPath = `${sTypeComponent}?id=${x.sUrl}`;
        // return history.push(sPath);
    }

    const onBackTo = () => {
        let sTypeComponent = "";
        let sId = "";
        let sPath = "";
        if(ref === "home"){
            sTypeComponent = "/home";
        }
        else if(ref === "search"){
            //history.goBack();
        }
        else{
            if(adMunu === "1")
            {
                sTypeComponent = "/news";
            }
            if(adMunu === "2")
            {
                sTypeComponent = "/forest-story";
            }
            if(adMunu === "5")
            {
                sTypeComponent = "/learning-center-list";
                sId = contentDetail.sIdBefore;
            }
            if(adMunu === "6")
            {
                sTypeComponent = "/hl-project-list";
                sId = contentDetail.sIdBefore;
            }
            if(adMunu === "7")
            {
                sTypeComponent = "/knowledge-list";
                sId = contentDetail.sIdBefore;
            }
        }

        if (sId !== "") {
            sPath = `${sTypeComponent}?id=${sId}`;
        }
        else {
            sPath = `${sTypeComponent}`;
        }

        if(sPath === ""){
            return history.goBack();
        }
        else{
            return window.open(sPath, "_self");
        }
        
    } 

    const UpdateLikeContent = async() =>{
        await AxiosGetJson("api/AdminInfo/UpdateLikeContent?id=" + id);
    }
    const UpdateShareContent = async(nChannel) =>{
        await AxiosGetJson("api/AdminInfo/UpdateShareContent?id=" + id + "&channel=" + nChannel);
    }

    const GetNumberLike = async(idStr) =>{
        var result = await AxiosGetJson("api/AdminInfo/GetNumberLike?id=" + idStr);
        setnLike(result);
    }

    const GetPathWeb = async () => {
        let result: any = await AxiosGetJson("api/AdminInfo/GetUrlPath");
        setPathWeb(result);
    }

    //UpdateShareContent
    React.useEffect(() => {

        setIsLoad(false);
        var { id } = parse(window.location.search);
        var idStr: string = "";
        if (id !== undefined && id !== null) {
            idStr = id.toString().split("/").join("%2f");
        } else {
            idStr = "xvEhq%2famL3jePelPapN2%2fw==";
        }
        SetBackground();
        GetContentDetail(idStr, translate.TranslatType);
        GetNumberLike(idStr);
        window.scrollTo(0, 0);
        setIsLoad(true);
        GetPathWeb();
    }, [id, translate]);

    return (
        <React.Fragment>
            {/*<Helmet>
                <meta id="ogTitle" property="og:title" content={contentDetail ? contentDetail.sSubject : ""} />
                <meta id="ogDesc" property="og:description" content={contentDetail ? contentDetail.sSubject : ""} />
                <meta id="ogImage" property="og:image" content={sFileCover} />
            </Helmet>*/}
            <div id="PAGE_HOME" style={{ backgroundImage: "url(" + sFilePath + ")" }}>
                <div className="home-news">
                    <div className="news-panel">
                        <div className="panel-head">
                            <div className="panel-title-first" style={{cursor:"pointer"}} onClick={() => onBackTo()}>
                                <i className="icofont-arrow-left"></i> {translate.Back}
                            </div>
                            <div className="panel-title">
                                {contentDetail ? contentDetail.sSubject : ""}
                            </div>
                        </div>
                    </div>
                </div>
                {
                    contentDetail ? 
                        contentDetail.sContentType === "TH" && translate.TranslatType == "EN" ? 
                            <div
                                className="home-history"
                                style={{
                                width: "78%",
                                maxWidth:"78%",
                                height: "500px",
                                alignItems: "left",
                                marginLeft: "auto",
                                marginRight: "auto",
                                display: "block",
                                }}
                            >
                                <h3 style={{ textAlign: "center"}}>No Content</h3>
                            </div>
                            :
                            
                            <React.Fragment>
                            {isCover ? (
                                <div
                                className="home-history"
                                style={{
                                width: "fit-content",
                                maxWidth:"78%",
                                // height: "400px",
                                alignItems: "left",
                                marginLeft: "auto",
                                marginRight: "auto",
                                display: "block",
                                }}
                            >
                                    <img src={sFileCover} style={{ width: "100%", height: "100%" }} />
                                </div>
                            ) : null}
                            <div className="news">
                                <div className="news-content">
                                    <div className="content">
                                        <div className="news-title">
                                            {contentDetail ? contentDetail.sSubject : ""}
                                        </div>
                                        <div className="news-detail">
                                            <div style={{ display: "inline-block" }}
                                                dangerouslySetInnerHTML={{
                                                    __html: contentDetail ? contentDetail.sContent : "",
                                                }}
                                            ></div>
                                        </div>
                                        <br />
                                        {
                                            adMunu === "1" ?
                                            <div className="news-date">
                                                {/*<i className="icofont-ui-calendar"></i>&nbsp;*/}
                                                {translate.NewsDateContent}
                                                &nbsp;&nbsp;
                                                {contentDetail ? contentDetail.sDate : ""}
                                                {/*
                                                    adMunu === "1" ?
                                                    <>
                                                        <i className="icofont-clock-time"></i>&nbsp;
                                                        {contentDetail ? contentDetail.sTime : ""}
                                                    </>
                                                    :
                                                    null
                                                */}
                                            </div>
                                            :
                                            null
                                        }
                                        {/*translate.TranslatType === "EN" ?
                                                <div className="news-date">
                                                    <ReactFBLike language={"en_US"} appId="1391350580945114" version="v2.12" />
                                                </div>
                                                :
                                                <div className="news-date">
                                                    <ReactFBLike language={"th_TH"} appId="1391350580945114" version="v2.12" />
                                                </div>*/}
                                        <br />
                                        {
                                            isLoad ?
                                                <React.Fragment>
                                                    {/*<div className="fb-like" data-href="https://web.facebook.com/PTTRFEI/" data-width="" data-layout="standard" data-action="like" data-size="large" data-share="false"></div>*/}
                                                    <Button size="middle" shape="circle" style={{ background: "#00c3da", border: "none", color: "#ffffff" }}
                                                        onClick={() => {
                                                            if(!isLike){
                                                                setIsLike(true);
                                                                UpdateLikeContent();
                                                            }
                                                            // setIsLoad(true);
                                                            // setIsFullScreen(!isFullScreen);
                                                            // setTimeout(() => {
                                                            //     setIsLoad(false);
                                                            // }, 500);
                                                        }}
                                                    >
                                                        <i className="icofont-like"></i>
                                                    </Button>&nbsp;&nbsp;&nbsp;{isLike ? translate.Liked : ""}{" " + nLike + " "}&nbsp;{translate.Like}
                                                    <br /><br />
                                                    <FacebookShareButton onClick={() =>UpdateShareContent(6)} url={window.location.href}  translate={undefined} quote={contentDetail ? contentDetail.sSubject : ""}>
                                                        <FacebookIcon size={32} round={true} path={undefined} crossOrigin={undefined} />
                                                    </FacebookShareButton>
                                                    {/*<ShareButtonCircle {...{
                                                        url: window.location.href,
                                                        network: "Facebook",
                                                        text: contentDetail ? contentDetail.sSubject : "",
                                                        longtext: contentDetail ? contentDetail.sSubject : "",
                                                        media : sPathWeb + sFileCover
                                                    }}>
                                                        <FacebookIcon onClick={() =>UpdateShareContent(6)} size={32} round={true} path={undefined} crossOrigin={undefined} />
                                                    </ShareButtonCircle>*/}
                                                    &nbsp;&nbsp;
                                                    <LineShareButton onClick={() =>UpdateShareContent(7)} url={window.location.href}  translate={undefined} >
                                                        <LineIcon size={32} round={true} path={undefined} crossOrigin={undefined} />
                                                    </LineShareButton>
                                                    &nbsp;&nbsp;
                                                    <EmailShareButton url={window.location.href}  translate={undefined} >
                                                        <EmailIcon onClick={() =>UpdateShareContent(8)} size={32} round={true} path={undefined} crossOrigin={undefined} />
                                                    </EmailShareButton>
                                                </React.Fragment>
                                            :
                                            null
                                        }
                                        <br />
                                        <br />
                                        {
                                            contentDetail ?
                                                contentDetail.lstFileGallery.length > 0 ?
                                                    <>
                                                        <div className="news-img">{translate.Gallery}</div>
                                                        <div className="news-item">
                                                            {
                                                                contentDetail.lstFileGallery.map((x, ix) => {
                                                                    return (
                                                                        <div key={"g_" + ix} className="item-box">
                                                                            <GalleryTrigger imgSrc={x.sSaveToPath}>
                                                                                <Image src={x.sSaveToPath} fallbackSrc={require("../../Images/noimage.png")} width="200px" height="140px" />
                                                                            </GalleryTrigger>
                                                                            {/*<div className="item-cover" style={{ backgroundImage: "url(" + x.sSaveToPath + ")"}}>
                                                                            </div>*/}
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </>
                                                    : null
                                                : null
                                        }
                                        {/*
                                            <>
                                                <div className="news-img">คลังภาพ</div>
                                                <div className="news-item">
                                                    <div className="item-box">
                                                        <div className="item-cover" style={{ backgroundImage: `url(${require("../../Images/home/example/news/news1.jpg")})` }}>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>

                                        */}
                                        {
                                            contentDetail ?
                                                contentDetail.msRefLink.length > 0 ?
                                                    <>
                                                        <div className="news-ref">
                                                            <div className="ref-title">{translate.Reference}</div>
                                                            <div className="ref-doc">
                                                                {
                                                                    contentDetail.msRefLink.map((x, ix) => {
                                                                        return (
                                                                            <div key={"l_" + ix} className="doc" style={{cursor: "pointer"}}>
                                                                                <div onClick={() =>{
                                                                                    window.open(x, '_blank');
                                                                                }}>{x}</div>
                                                                            </div>
                                                                        )

                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                                :
                                                null
                                        }
                                        {/*
                                            <>
                                                <div className="news-ref">
                                                    <div className="ref-title">แหล่งอ้างอิง</div>
                                                    <div className="ref-doc">
                                                        <div className="doc">
                                                            https://xd.adobe.com/view/1df7c0f3-4264-489e-817c-7147fb39bccc-48a4/screen/e497b517-0d40-4b95-8701-4b174f3e52b1/
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        */}
                                        {
                                            contentDetail ?
                                                contentDetail.lstFileRef.length > 0 ?
                                                    <>
                                                        <div className="news-file">
                                                            <div className="file-title">{translate.RelatedFile}</div>
                                                            <div className="file-doc">
                                                                {
                                                                    contentDetail.lstFileRef.map((x, ix) => {
                                                                        return (
                                                                            <div key={"f_" + ix} className="doc" style={{cursor: "pointer"}}>
                                                                                <div onClick={() =>{
                                                                                    window.open(x.sSaveToPath, '_blank');
                                                                                }}>{x.sFileName}</div>
                                                                            </div>
                                                                        )

                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                                :
                                                null
                                        }

                                        {/*
                                            <>
                                                <div className="news-file">
                                                    <div className="file-title">ไฟล์ที่เกี่ยวข้อง</div>
                                                    <div className="file-doc">
                                                        <div className="doc">
                                                            https://xd.adobe.com/view/1df7c0f3-4264-489e-817c-7147fb39bccc-48a4/screen/e497b517-0d40-4b95-8701-4b174f3e52b1/
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        */}
                                        <br />
                                        {/*
                                            adMunu !== "1" ?
                                            <div className="news-date">
                                                <i className="icofont-ui-calendar"></i>
                                                &nbsp;&nbsp;
                                                {contentDetail ? contentDetail.sDate : ""}
                                            </div>
                                            :
                                            null
                                        */}

                                        <hr />
                                        {
                                            contentDetail ?
                                                contentDetail.lstContentRef.length > 0 ?
                                                    <>
                                                        <div className="news-related">
                                                            <div className="news-panel">
                                                                <div className="panel-head">
                                                                    <div className="panel-title">{adMunu === "1" ? translate.RelatedNews : translate.RelatedContent}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            contentDetail.lstContentRef.map((x, ix) => {
                                                                return (
                                                                    <div key={"ref_" + ix} className="col-xl-12 mt-3 mt-sm-5">
                                                                        <div className="ibox ibox-2" onClick={() => onOpenRelated(x)} style={{cursor: "pointer"}}>
                                                                            <div className="ibox-thumb">
                                                                                <div
                                                                                    className="box-cover"
                                                                                    style={{ backgroundImage: "url(" + x.sFilePath + ")" }}>
                                                                                </div>
                                                                            </div>
                                                                            <div className="ibox-info">
                                                                                <div className="box-title" >
                                                                                    <span>{x.sSubject}</span>
                                                                                </div>
                                                                                <ul className="box-links">
                                                                                    <li>
                                                                                        <div>
                                                                                            {" "}
                                                                                            <i className="icofont-ui-calendar"></i> {x.sDate}
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )

                                                            })
                                                        }
                                                    </>
                                                    :
                                                    null
                                                :
                                                null
                                        }
                                        {/*
                                            <>
                                                <div className="news-related">
                                                    <div className="news-panel">
                                                        <div className="panel-head">
                                                            <div className="panel-title">ข่าวที่เกี่ยวข้อง</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-xl-12 mt-3 mt-sm-5">
                                                        <div className="ibox ibox-1">
                                                            <div className="ibox-thumb">
                                                                <a
                                                                    className="box-cover"
                                                                    style={{
                                                                        backgroundImage:
                                                                            "url(" +
                                                                            "../Images/home/example/news/news1.jpg" +
                                                                            ")",
                                                                    }}
                                                                ></a>
                                                            </div>
                                                            <div className="ibox-info">
                                                                <div className="box-title">
                                                                    <span>ถนนสีเขียวนำร่อง เพื่อคุณภาพที่ดีของคนเมือง</span>
                                                                </div>
                                                                <div className="box-detail">
                                                                    <span>
                                                                        Lorem Ipsum is simply dummy text of the printing and
                                                                        typesetting industry. Lorem Ipsum has been the
                                                                        industry's standard dummy text ever since the 1500s,
                                                                        when an unknown printer took a galley of type and
                                                                        scrambled it to make a type specimen book. It has
                                                                        survived not only five centuries, but also the leap
                                                                        into electronic typesetting, remaining essentially
                                                                        unchanged. It was popularised in the 1960s with the
                                                                        release of Letraset sheets containing Lorem Ipsum
                                                                        passages, and more recently with desktop publishing
                                                                        software like Aldus PageMaker including versions of
                                                                        Lorem Ipsum.
                                                                    </span>
                                                                </div>
                                                                <ul className="box-links">
                                                                    <li>
                                                                        <div>
                                                                            <i className="icofont-clock-time"></i> 10:43
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div>
                                                                            {" "}
                                                                            <i className="icofont-ui-calendar"></i> 20/01/2564
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div>
                                                                            <i className="icofont-eye-alt"></i> 184,123
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-12 mt-3 mt-sm-5">
                                                        <div className="ibox ibox-2">
                                                            <div className="ibox-thumb">
                                                                <a
                                                                    className="box-cover"
                                                                    style={{
                                                                        backgroundImage:
                                                                            "url(" +
                                                                            "../Images/home/example/news/news1.jpg" +
                                                                            ")",
                                                                    }}
                                                                ></a>
                                                            </div>
                                                            <div className="ibox-info">
                                                                <div className="box-title">
                                                                    <span>ถนนสีเขียวนำร่อง เพื่อคุณภาพที่ดีของคนเมือง</span>
                                                                </div>
                                                                <div className="box-detail">
                                                                    <span>
                                                                        Lorem Ipsum is simply dummy text of the printing and
                                                                        typesetting industry. Lorem Ipsum has been the
                                                                        industry's standard dummy text ever since the 1500s,
                                                                        when an unknown printer took a galley of type and
                                                                        scrambled it to make a type specimen book. It has
                                                                        survived not only five centuries, but also the leap
                                                                        into electronic typesetting, remaining essentially
                                                                        unchanged. It was popularised in the 1960s with the
                                                                        release of Letraset sheets containing Lorem Ipsum
                                                                        passages, and more recently with desktop publishing
                                                                        software like Aldus PageMaker including versions of
                                                                        Lorem Ipsum.
                                                                    </span>
                                                                </div>
                                                                <ul className="box-links">
                                                                    <li>
                                                                        <div>
                                                                            <i className="icofont-clock-time"></i> 10:43
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div>
                                                                            {" "}
                                                                            <i className="icofont-ui-calendar"></i> 20/01/2564
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div>
                                                                            <i className="icofont-eye-alt"></i> 184,123
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        */}

                                    </div>
                                </div>
                            </div>
                            </React.Fragment>
                        : null
                }

                
            </div>
        </React.Fragment>
    );
};
export default Main_Content_Detail;
