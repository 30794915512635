import { Col, DatePicker, Row, Table } from "antd";
import * as React from "react";
import { Select as AntdSelect } from "antd";
import { ButtonExcel, ButtonPDF, ButtonSearch } from "../Systems/button_all";
import { AxiosBlobJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import moment from "moment";
import { setNameFormatReport } from "../Systems/SystemComponent";


const Controller = 'api/Report/';

const Report_Like_And_Share = () => {
    const [Stp_Search, setStp_Search] = React.useState([] as any);
    const [dDateearSearch, SetdDateearSearch] = React.useState(new Date() as (Date));
    const { Option } = AntdSelect;
    const [txtSearch, SetTxtSearch] = React.useState({
        dDate: new Date() as (Date | undefined),
        sType: "4" as (string | undefined),
        sSocial: undefined as (string | undefined),
    });
    var date = new Date();

    const SearchData = async () => {

        let ModelSearcData = {
            sYear: txtSearch.dDate == undefined ? "" : txtSearch.dDate.getFullYear() + "",
            sType: txtSearch.sType == undefined ? "" : txtSearch.sType,
            sSocial: txtSearch.sSocial == undefined ? "" : txtSearch.sSocial,
            sRegion: "",
            sProvince: "",
            sMode: "3",
        }

        let result: any = await AxiosPostJson(Controller + "SearchDataMode3", ModelSearcData);
        setStp_Search(result.data);
        SetdDateearSearch(txtSearch.dDate === undefined ? new Date() : txtSearch.dDate)

    }
    let months = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.']

    const year = dDateearSearch.getFullYear();
    const dateTobeGenerate = ['01/' + year, '02/' + year, '03/' + year, '04/' + year, '05/' + year, '06/' + year, '07/' + year, '08/' + year, '09/' + year, '10/' + year, '11/' + year, '12/' + year]

    const buildColumn = () => {
        const columns = [] as any

        columns.push({
            title: 'หัวข้อ',
            dataIndex: 'sName',
            key: 'sName',
            fixed: 'left',
            align: 'left',
            className: `cell-in-group-name`,
        })


        let yearCount = 1;
        dateTobeGenerate.forEach((d: string, i: number) => {

            const yearIndex = columns.findIndex((c: { title: string; }) => c.title == d.split('/')[1])
            if (yearIndex > -1) {
                const monthIndex = columns[yearIndex].children.findIndex((c: { title: string; }) => c.title == months[parseInt(d.split('/')[0]) - 1])
                if (monthIndex == -1) {
                    columns[yearIndex].children.push({
                        title: months[parseInt(d.split('/')[0]) - 1],
                        dataIndex: `year_${d.split('/')[1]}_month_${months[parseInt(d.split('/')[0]) - 1]}`,
                        key: `year_${d.split('/')[1]}_month_${months[parseInt(d.split('/')[0]) - 1]}`,
                        align: 'right',
                        render: (text: number) => (
                            text.toLocaleString('en-us')
                        ),
                        className: `cell-in-group-month-${(yearCount % 2) + 1}`,
                    })
                }


            } else {


                yearCount += 1
                columns.push(
                    {
                        title: d.split('/')[1],
                        className: `cell-in-group-${(yearCount % 2) + 1}`,
                        children: [
                            {
                                title: months[parseInt(d.split('/')[0]) - 1],
                                dataIndex: `year_${d.split('/')[1]}_month_${months[parseInt(d.split('/')[0]) - 1]}`,
                                key: `year_${d.split('/')[1]}_month_${months[parseInt(d.split('/')[0]) - 1]}`,
                                align: 'right',
                                render: (text: number) => (
                                    text.toLocaleString('en-us')
                                ),
                                className: `cell-in-group-month-${(yearCount % 2) + 1}`,
                            }
                        ]
                    }
                )
            }


        })
        return columns

    }

    const buildData = () => {
        const data = [] as any

        Stp_Search.forEach(d => {


            const obj = {} as any
            obj['sName'] = d.sName
            obj[`year_${year}_month_${months[0]}`] = d.nM1
            obj[`year_${year}_month_${months[1]}`] = d.nM2
            obj[`year_${year}_month_${months[2]}`] = d.nM3
            obj[`year_${year}_month_${months[3]}`] = d.nM4
            obj[`year_${year}_month_${months[4]}`] = d.nM5
            obj[`year_${year}_month_${months[5]}`] = d.nM6
            obj[`year_${year}_month_${months[6]}`] = d.nM7
            obj[`year_${year}_month_${months[7]}`] = d.nM8
            obj[`year_${year}_month_${months[8]}`] = d.nM9
            obj[`year_${year}_month_${months[9]}`] = d.nM10
            obj[`year_${year}_month_${months[10]}`] = d.nM11
            obj[`year_${year}_month_${months[11]}`] = d.nM12

            data.push(obj)

        })
        return data
    }


    const header = buildColumn();
    const data = buildData();

    const buildSummary = (data) => {
        let summary = {} as any
        data.forEach(d => {
            Object.keys(d).filter(k => k !== 'sName' && k !== 'nTypeID' && k !== 'nHeadTypeID').forEach(k => {
                const month = k.split('_')[3]
                const year = k.split('_')[1]
                if (summary[year]) {
                    if (summary[year][month]) {
                        summary[year][month] += d[k]
                    } else {
                        summary[year][month] = d[k]
                    }
                } else {
                    summary[year] = { [month]: d[k] }
                }

            })
        })
        return (
            <React.Fragment>
                <Table.Summary.Row >
                    <Table.Summary.Cell index={1} className='ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left-last ant-total'>รวม</Table.Summary.Cell>
                    {
                        Object.keys(summary).map((y, i) => {
                            return Object.keys(summary[y]).map(m => {
                                return (
                                    <Table.Summary.Cell align='right' index={2} className={`summary-in-group-${(i % 2) + 1}`}>
                                        <div>{summary[y][m].toLocaleString('en-us')}</div>
                                    </Table.Summary.Cell>
                                )
                            })
                        })
                    }
                </Table.Summary.Row>
            </React.Fragment>
        )
    }


    const ExportExce = async () => {

        let ModelSearcData = {
            sYear: txtSearch.dDate == undefined ? "" : txtSearch.dDate.getFullYear() + "",
            sType: txtSearch.sType == undefined ? "" : txtSearch.sType,
            sSocial: txtSearch.sSocial == undefined ? "" : txtSearch.sSocial,
            sRegion: "",
            sProvince: "",
            sMode: "3",
        }
        let fileName = setNameFormatReport("รายงานการกดไลค์กดแชร์บนเว็บไซต์") + ".xlsx";
        let result: any = await AxiosBlobJson(Controller + "ExportExceLikeAndShare", ModelSearcData);
        saveExcel(result.data, fileName)

    }
    const saveExcel = (result: BlobPart, fileName: string) => {
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }


    const ExportPDF = async () => {

        let ModelSearcData = {
            sYear: txtSearch.dDate == undefined ? "" : txtSearch.dDate.getFullYear() + "",
            sType: txtSearch.sType == undefined ? "" : txtSearch.sType,
            sSocial: txtSearch.sSocial == undefined ? "" : txtSearch.sSocial,
            sRegion: "",
            sProvince: "",
            sMode: "3",
        }

        let fileName = setNameFormatReport("รายงานการกดไลค์กดแชร์บนเว็บไซต์") + ".pdf";
        let result: any = await AxiosBlobJson(Controller + "ExportPDFLikeAndShare", ModelSearcData);
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();



    }

    React.useEffect(() => {
        SearchData();
    }, []);


    return (
        <React.Fragment>
            <div id="REPORT">
                <Row justify="end" gutter={[16, 16]}>
                    <Col xl={4} md={10} xs={20}>
                        <DatePicker
                            value={moment(txtSearch.dDate == undefined ? new Date(new Date().getFullYear(), 0, 1) : txtSearch.dDate.toLocaleDateString('en-us', { month: '2-digit', year: 'numeric' }), 'MM/YYYY')}
                            format={'YYYY'}
                            picker="year"
                            style={{ width: "100%", borderRadius: "20px" }}
                            placeholder="Start Complaint Date"
                            onChange={(value) => {
                                SetTxtSearch({
                                    ...txtSearch,
                                    dDate: new Date(value == undefined ? new Date(date.getFullYear(), 0, 1) : value.format('YYYY')),
                                });
                            }}
                        />
                    </Col>
                    <Col xl={4} md={10} xs={20}>
                        <AntdSelect
                            showSearch={true}
                            style={{ width: "100%" }}
                            value={txtSearch.sType}
                            placeholder="- ประเภท -"
                            onChange={(e) => {
                                SetTxtSearch({
                                    ...txtSearch,
                                    sType: e,
                                    sSocial: undefined,
                                });
                            }}
                            filterOption={(input, option) =>
                                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Option key={"4"} value="4">Like</Option>
                            <Option key={"5"} value="5">Share</Option>
                        </AntdSelect>
                    </Col>
                    <Col xl={4} md={10} xs={20}>
                        <AntdSelect
                            disabled={txtSearch.sType == "5" ? false : true}
                            allowClear={true}
                            showSearch={true}
                            style={{ width: "100%" }}
                            value={txtSearch.sSocial}
                            placeholder="- แพลตฟอร์ม -"
                            onChange={(e) => {
                                SetTxtSearch({
                                    ...txtSearch,
                                    sSocial: e,
                                });
                            }}
                            filterOption={(input, option) =>
                                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Option key={"6"} value="6">Facebook</Option>
                            <Option key={"7"} value="7">Line</Option>
                            <Option key={"8"} value="8">Email</Option>
                        </AntdSelect>
                    </Col>



                    <Col >
                        <ButtonSearch onClick={() => SearchData()} />
                    </Col>
                    <Col >
                        <ButtonPDF onClick={() => ExportPDF()} />

                    </Col>
                    <Col >
                        <ButtonExcel onClick={() => ExportExce()} />
                    </Col>
                </Row>
                <br />
                <Table
                    columns={header}
                    dataSource={data}
                    bordered
                    size="small"
                    pagination={false}
                    scroll={{ x: 'max-content' }}
                    summary={buildSummary}
                />
            </div>
        </React.Fragment>
    );
};
export default Report_Like_And_Share;
