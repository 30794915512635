import * as React from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Input } from "antd";

interface IProps {
    // ref?: React.LegacyRef<HTMLInputElement>,
    allowClear?: boolean,
    small?: boolean,
    large?: boolean,
    placeholder?: string,
    minDate?: Date,
    maxDate?: Date,
    value?: Date,
    onChange?: (value?: Date) => void,
    disabled?: boolean,
    className?: string
    tooltip?: string,
    id?: string
}

interface IState {
    calendarVisible: boolean
}

export default class ThaiDatePicker extends React.Component<IProps, IState>{
    constructor(props) {
        super(props);
        this.state = {
            calendarVisible: false
        };
    }

    refDivInput = React.createRef<HTMLDivElement>();
    refDivCalendar = React.createRef<HTMLDivElement>();
    refInput = React.createRef<Input>();

    StringToDate(s: string) {
        let d: Date | undefined = undefined;
        var arr = s.split("/");
        if (arr.length == 3) {
            let dd = +arr[0];
            let MM = +arr[1] - 1;
            let yyyy = +arr[2];
            if (yyyy > 2100) d = new Date(yyyy - 543, MM, dd);
        }
        return d;
    }
    DateToString(d: Date | null | undefined) {
        let s = "";
        if (d) {
            let dd = (d.getDate() + "").padStart(2, "0");
            let MM = (d.getMonth() + 1 + "").padStart(2, "0");
            let yyyy = (d.getFullYear() + "").padStart(4, "0");
            s = dd + "/" + MM + "/" + yyyy;
        }
        return s;
    }

    ElementTest(e: HTMLElement | null, t: EventTarget | null) {
        return (e != null && t != null) ? (e == t || e.contains(t as Node)) : false;
    }

    IsCalendarElement(e: HTMLElement | null) {
        let isCalendar = false;
        if (e) {
            try {
                let e_parent = e.parentElement;
                let e_className = e.className || "";
                let p_className = e_parent ? e_parent.className || "" : "";
                isCalendar = e_className.indexOf("react-calendar") == 0 || p_className.indexOf("react-calendar") == 0;
            }
            catch { }
        }
        return isCalendar;
    }

    ButtonClear() {
        return this.props.value
            ? <span className="ant-input-clear-icon"
                onClick={() => {
                    if(this.refInput.current) this.refInput.current.blur();
                    if (this.props.onChange) this.props.onChange(undefined);
                }}>
                <i className="fa fa-times-circle" style={{ color: "rgb(128, 128, 128)" }}></i>
            </span >
            : <></>
    }

    componentDidMount() {
        document.addEventListener("click", (e) => {
            let beOrContainBy_DivInput = this.ElementTest(this.refDivInput.current, e.target);
            let beOrContainBy_DivCalendar = this.ElementTest(this.refDivCalendar.current, e.target);
            if (!beOrContainBy_DivInput && !beOrContainBy_DivCalendar && !this.IsCalendarElement(e.target as HTMLElement | null)) {
                if (this.state.calendarVisible) this.setState({ calendarVisible: false });
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                {/* <div ref={this.refDivInput}
                    className={"flex-nowrap input-group" + (this.props.small ? " input-group-sm" : "") + (this.props.large ? " input-group-lg" : "") + ((this.props.className + "").indexOf("is-invalid") > -1 ? " is-invalid" : "")}>
                    <input ref={this.props.ref} type="text"
                        className={((this.props.className + "").indexOf("form-control") > -1 ? "" : "form-control") + (this.props.className ? " " + this.props.className : "")}
                        placeholder={this.props.placeholder || "วว/ดด/ปปปป"}
                        value={this.DateToString(this.props.value)}
                        onFocus={() => { if (!this.state.calendarVisible) this.setState({ calendarVisible: true }); }}
                        readOnly={true}
                        disabled={this.props.disabled}
                        style={{ flexBasis: "100%" }}
                        data-tip={this.props.tooltip} />
                    <div className="input-group-append">
                        {this.props.disabled ? null : <button type="button" className="btn input-group-text" style={{ minWidth: "auto" }}
                            onClick={() => { if (this.props.onChange) this.props.onChange(undefined); }}><i className="fa fa-times"></i></button>}
                        <button type="button" className="btn btn-secondary" disabled={this.props.disabled}
                            onClick={() => this.setState({ calendarVisible: !this.state.calendarVisible })}>
                            <i className="fa fa-calendar-alt"></i>
                        </button>
                    </div>
                </div> */}
                <div ref={this.refDivInput}>
                    <Input ref={this.refInput} id={this.props.id} className={this.props.className} placeholder={this.props.placeholder || "DD/MM/YYYY"}
                        value={this.DateToString(this.props.value)}
                        onChange={(e) => {
                            let d = this.StringToDate(e.target.value);
                            if (this.props.onChange) this.props.onChange(d);
                        }}
                        onFocus={() => { if (!this.state.calendarVisible) this.setState({ calendarVisible: true }); }}
                        readOnly={true}
                        disabled={this.props.disabled}
                        suffix={
                            <>
                                {
                                    this.props.disabled ?
                                        null :
                                        this.ButtonClear()
                                }
                                <i className="ant-picker-suffix fa fa-calendar-alt"></i>
                            </>
                        } />
                </div>
                <div ref={this.refDivCalendar} className="position-relative" style={{ zIndex: 999999 }}>
                    <Calendar locale="en-US" calendarType="US"
                        prevLabel={<i className="fa fa-angle-left"></i>} prev2Label={<i className="fa fa-angle-double-left"></i>}
                        nextLabel={<i className="fa fa-angle-right"></i>} next2Label={<i className="fa fa-angle-double-right"></i>}
                        className={"position-absolute" +
                            (this.props.small ? " react-calendar-sm" : "") +
                            (this.props.large ? " react-calendar-lg" : "") +
                            (this.state.calendarVisible ? "" : " d-none")}
                        minDate={this.props.minDate}
                        maxDate={this.props.maxDate}
                        value={this.props.value}
                        onChange={(value) => {
                            if (this.props.onChange) this.props.onChange(value);
                            this.setState({ calendarVisible: false })
                        }} />
                </div>
            </React.Fragment>
        );
    }
}