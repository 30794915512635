import { Button, Col, Input, Modal, Row, Space, Tag } from "antd";
import * as React from "react";
import { Empty, Select as AntdSelect } from "antd";
import { ButtonAdd_Table, ButtonEdit_Table, ButtonInfo_Table, ButtonSearch } from "../Systems/button_all";
import TableAntd from "../Systems/TableAntd";
import GalleryTrigger from "../ST_Handlers/GalleryTrigger";
import { useHistory } from "react-router";
import { BoxMsg, DialogDelete, Encrypt, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import ReactPlayer from 'react-player';
import './style.css';
import Image from "../Systems/Image";
import AuthenService from "../Service/AuthenService";
const Controller = 'api/AdminBanner/';
const { Option } = AntdSelect;


const Admin_Banner_List = () => {

    const [txtSearch, SetTxtSearch] = React.useState({
        stxtName: "" as string || undefined,
        stxtIsActive: "" as string || undefined,

    });
    const [V_BannerSlide, setV_BannerSlide] = React.useState([] as any);
    const [V_BannerSlideAll, setV_BannerSlideAll] = React.useState([] as any);

    const history = useHistory();

    const [isModalVisible, setIsModalVisible] = React.useState(false);

    const [sPath, setsPath] = React.useState("" as string || undefined);
    const showModal = (sPath: string) => {
        setsPath(sPath);
        setIsModalVisible(true);

    };
    const [sFieldA, setsFieldA] = React.useState("" as string || undefined);
    const [sOrderA, setsOrderA] = React.useState("" as string || undefined);
    const handleOk = () => {
        setIsModalVisible(false);
        setsPath(undefined);
    };
    const [nTotal, setnTotal] = React.useState(0 as number);

    React.useEffect(() => {
        GetDataOnPageLoad();
        GetUserInfo();
    }, []);
    const [isEdit, setisEdit] = React.useState(false);
    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            setisEdit(UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == 2).map(t => t.nPermission)[0] === 2 ? false : true)
        }
    }

    const GetDataOnPageLoad = async () => {

        let resultall: any = await AxiosGetJson(Controller + "GetV_BannerSlideAll");
        setV_BannerSlideAll(resultall);

        let result: any = await AxiosGetJson(Controller + "GetV_BannerSlide");
        setV_BannerSlide(result.V_BannerSlide);
        setnTotal(result.nTotal);
    }



    const getLinkTo_Edit = (nSlideID: number) => {
        let sSlideID = Encrypt(nSlideID)
        let sTypeComponent = `/admin-banner-edit`
        let sPath = `${sTypeComponent}?sSlideID=${sSlideID}`
        return history.push(sPath);
    };

    const SearchData = async (sPage, sPageSize, sField, sOrder) => {
        setV_BannerSlide([]);
        let Page = sPage == undefined ? sPageA : sPage;
        let PageSize = sPageSize == undefined ? sPageSizeA : sPageSize;
        let Field = sField == undefined ? sFieldA : sField;
        let Order = sOrder == undefined ? sOrderA : sOrder;

        let stxtName = txtSearch.stxtName == undefined ? "" : txtSearch.stxtName;
        let stxtIsActive = txtSearch.stxtIsActive == undefined ? "" : txtSearch.stxtIsActive;
        let result: any = await AxiosGetJson(Controller + "SearchData?stxtName=" + encodeURIComponent(stxtName) + "&stxtIsActive=" + stxtIsActive + "&sPage=" + Page + "&sPageSize=" + PageSize + "&sField=" + Field + "&sOrder=" + Order);
        setV_BannerSlide(result.V_BannerSlide);
        setnTotal(result.nTotal);
        setCurrentPage(+Page);
        let resultall: any = await AxiosGetJson(Controller + "GetV_BannerSlideAll");
        setV_BannerSlideAll(resultall);


    }

    const SetOrder = async (sOrder: number, nSlideID: number) => {

        if (sOrder != 0) {
            let result: any = await AxiosPostJson(Controller + "SetOrder?sOrder=" + sOrder + "&nSlideID=" + nSlideID);
            if (result.data.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Save, null);
                onselect([], []);
                SearchData(1, undefined, undefined, undefined);
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }
        }

    }


    const header = [
        {
            title: "ลำดับที่", dataIndex: "nOrder", key: "Key_No", width: 1, sorter: true,
            render: (value: any, record: { nOrder: number, nSlideID: number }) => (

                <AntdSelect
                    disabled={!isEdit}
                    showSearch={true}
                    style={{ width: "100%" }}
                    value={record.nOrder}
                    onChange={(e: any) => SetOrder(e, record.nSlideID)}
                    filterOption={(input, option) =>
                        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {

                        V_BannerSlideAll.filter(f => f.nOrder != 0).sort((firstItem, secondItem) => firstItem.nOrder - secondItem.nOrder).map((p) => {
                            return (
                                <Option key={p.nOrder} value={p.nOrder} >{p.nOrder + ""}</Option>
                            )
                        })
                    }
                </AntdSelect>
            ),
            className: "text-center",
        },
        {
            title: "ภาพ/วิดีทัศน์",
            children: [
                {
                    title: "ไทย", dataIndex: "sImagePathTH", key: "Key_ImageTH",
                    render: (value: any, record: { sImagePathTH: string, sVDOPathTH: string, sUrlTH: string }) => {
                        return (

                            record.sImagePathTH != "" ?
                                <GalleryTrigger imgSrc={record.sImagePathTH}>
                                    <Image src={record.sImagePathTH} fallbackSrc={require("../../Images/noimage.png")} />
                                </GalleryTrigger>
                                :
                                <img src={require("../../Images/icon-vdo.png")} width="60" height="60" onClick={() => { showModal(record.sVDOPathTH == "" ? record.sUrlTH : record.sVDOPathTH); }} >
                                </img>
                        );
                    },
                    className: "text-center", width: 100,
                },
                {
                    title: "อังกฤษ", dataIndex: "sImagePathTH", key: "Key_ImageEN",
                    render: (value: any, record: { sImagePathEN: string, sVDOPathEN: string, sUrlEN: string, cLanguage: string }) => {
                        return (


                            record.cLanguage === "EN" ?
                                record.sImagePathEN != "" ?
                                    <GalleryTrigger imgSrc={record.sImagePathEN}>
                                        <Image src={record.sImagePathEN} fallbackSrc={require("../../Images/noimage.png")} />
                                    </GalleryTrigger>
                                    :
                                    <img src={require("../../Images/icon-vdo.png")} width="60" height="60" onClick={() => { showModal(record.sVDOPathEN == "" ? record.sUrlEN : record.sVDOPathEN); }} >
                                    </img>

                                : null

                        );
                    },
                    className: "text-center", width: 100,
                }
            ]
        },

        {
            title: "ชื่อแบนเนอร์", dataIndex: "sNameTH", key: "Key_BannerName", width: 300, sorter: true,
        },
        {
            title: "ลิงก์ข้อมูล", dataIndex: "sUrl", key: "sUrl", width: 200, sorter: true,
        },
        {
            title: "วันที่ประกาศ", dataIndex: "sDateShow", key: "sDateShow", width: 200, sorter: true, className: "text-center",
        },
        {
            title: 'สถานะ',
            dataIndex: 'sIsActiveDes',
            width: 1,
            sorter: true,
            key: "sIsActiveDes",
            className: "text-center",
            render: (text: any, record: { sIsActive: string; }) => (
                <Tag color={record.sIsActive === "1" ? "#60cc28" : "#cc2838"} key={text} style={{ borderRadius: "32px" }}>
                    {text}
                </Tag>
            ),
        },
        {
            title: isEdit ? <ButtonAdd_Table onClick={() => { history.push("/admin-banner-edit"); }} /> : null,
            key: 'action',
            className: "text-center",
            render: (text, record) => (
                isEdit ?
                    <Space size="middle">
                        <ButtonEdit_Table
                            onClick={() => { getLinkTo_Edit(record.nSlideID); }}
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </ButtonEdit_Table>
                    </Space>
                    :
                    <Space size="middle">
                        <ButtonInfo_Table
                            onClick={() => { getLinkTo_Edit(record.nSlideID); }}
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </ButtonInfo_Table>
                    </Space>
            ),
            width: 1,
        },
    ];

    //*****************************TableAntd
    const [selectedRows, setselectedRows] = React.useState([] as Array<any>);
    const [selectedRowKeys, setrowSelected] = React.useState([] as any);
    const [sPageA, setsPageA] = React.useState(1 as number || undefined);
    const [sPageSizeA, setsPageSizeA] = React.useState(10 as number || undefined);

    const [nCurrentPage, setCurrentPage] = React.useState(1);

    const onselect = (selectedRowKeys, selectedRows: React.SetStateAction<any[]>) => {
        setselectedRows(selectedRows);
        setrowSelected(selectedRowKeys);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onselect,
        getCheckboxProps: (record) => ({
            disabled: record.sIsuse === 'Y', // Column configuration not to be checked
            sIsuse: record.sIsuse,
        }),
    };

    const onPageChange = async (page, pageSize, sorter) => {

        onselect([], []);
        setsPageA(page);
        setsPageSizeA(pageSize);
        SearchData(page, pageSize, undefined, undefined);
        setCurrentPage(page);
        window.scrollTo(0, 0);
    }
    const onTableChange = async (pagination, filters, sorter, extra) => {
        if (sorter) {
            setsFieldA(sorter.order === undefined ? undefined : sorter.field);
            setsOrderA(sorter.order);
            onselect([], []);
        }
    }
    React.useEffect(() => {
        SearchData(undefined, undefined, undefined, undefined);
    }, [sFieldA, sOrderA]);

    const onDeleteClick = async () => {
        if (selectedRows.length > 0) {

            DialogDelete(async () => {
                let result: any = await AxiosPostJson(Controller + "onDeleteData", selectedRows);
                if (result.data.sStatus === Responsestart.success) {
                    Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete, null);
                    SearchData(undefined, undefined, undefined, undefined);
                    onselect([], []);
                } else if (result.data.sStatus === Responsestart.warning) {
                    Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                } else {
                    Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                }
            });
        }

    }
    //*****************************TableAntd
    return (
        <React.Fragment>

            {
                isModalVisible ?


                    <Modal className="modal-x" visible={isModalVisible} width={1000} onCancel={handleOk} footer={false} bodyStyle={{ backgroundColor: "#3f3838" }}  >
                        <div className='player-wrapper' style={{ height: "500px" }}  >
                            <ReactPlayer
                                key={"vdo_" + sPath}
                                playing
                                url={sPath}
                                width='100%'
                                height='100%'
                                controls
                            />
                        </div>

                    </Modal>
                    :
                    null
            }



            <br />

            <Row justify="end" gutter={[16, 16]}>
                <Col xl={5} md={12} xs={24}>

                    <Input
                        autoComplete="off"
                        placeholder="ชื่อแบนเนอร์"
                        onChange={(e) =>
                            SetTxtSearch({
                                ...txtSearch,
                                stxtName: e.target.value,
                            })
                        }
                        onKeyPress={(e) => {
                            e.key === "Enter" && SearchData(undefined, undefined, undefined, undefined);
                        }}
                    />
                </Col>
                <Col xl={4} md={10} xs={20}>
                    <AntdSelect
                        allowClear={true}
                        showSearch={true}
                        style={{ width: "100%" }}
                        value={txtSearch.stxtIsActive}
                        placeholder="- สถานะ -"
                        onChange={(e) => {
                            SetTxtSearch({
                                ...txtSearch,
                                stxtIsActive: e,
                            });
                        }}
                        filterOption={(input, option) =>
                            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value="1">ใช้งาน</Option>
                        <Option value="0">ไม่ใช้งาน</Option>
                    </AntdSelect>
                </Col>
                <Col xl={1} md={2} xs={4}>
                    <ButtonSearch onClick={() => SearchData(undefined, undefined, undefined, undefined)} />
                </Col>
            </Row>
            <br />

            <TableAntd
                dataSource={V_BannerSlide}
                columns={header}
                onPageChange={onPageChange}
                currentPage={nCurrentPage}
                total={nTotal}
                onChange={onTableChange}
                rowSelection={isEdit ? rowSelection : undefined}
                onDisableDelete={selectedRows.length > 0 ? false : true}
                onShowDelete={isEdit}
                scroll={{ x: 800 }}
                onClickDeleteData={onDeleteClick}
                pageSize={sPageSizeA ? sPageSizeA : 10}
            />

            <br />
        </React.Fragment>
    );
};
export default Admin_Banner_List;
