import * as React from 'react';
// import Admin_Content from '../admin-content/admin-content'
import Admin_Content from '../admin-content/new-content/admin-content'
import { parse } from "query-string"

const Admin_learning_center_edit = () => {
    var { type } = parse(window.location.search);
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Admin_Content nPerMenuId={6} sType={type ? type.toString() : ""} backEvent={"/admin-learning-center-list"} />
    );
}
export default Admin_learning_center_edit;