import * as React from 'react';
import Main_Content_Detail from '../main-content/content-animal'

const Knowledge_Detail = (props) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Main_Content_Detail isShowCover={true} adminMenu={"7"} />
    );
}
export default Knowledge_Detail;
