import * as React from 'react';
import Admin_Content from '../admin-content/admin-content'
import { parse } from "query-string"

const admin_forest_story_edit = () => {
    //const [cId, setCID] = React.useState(null as any);

    //React.useEffect(() => {
    //    var { id } = parse(window.location.search);
    //    var idStr: string = "";
    //    if (id !== undefined && id !== null) {
    //        idStr = id.toString().split("/").join("%2f");
    //    }
    //    else {
    //        idStr = "xvEhq%2famL3jePelPapN2%2fw==";
    //    }
    //    setCID(idStr);
    //    window.scrollTo(0, 0);
    //}, [])

    return (
        <Admin_Content sType={"2"} backEvent={"/admin-forest-story-list"} nPerMenuId={5} />
    );
}
export default admin_forest_story_edit;