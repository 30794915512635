import * as React from 'react';
// import Admin_Content_List from '../admin-content/admin-content-list'
import Admin_Content_List from '../admin-content/new-content/admin-content-list'
import { parse } from "query-string"

const Admin_knowledge_list = () => {
    const { id } = parse(window.location.search);
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Admin_Content_List nPerMenuId={8} sType={id ? id.toString() : ""} editEvent={"/admin-knowledge-edit"} />
    );
}
export default Admin_knowledge_list;