import { AutoComplete, Checkbox, Col, Form, Input, Modal, Radio, Row, Select, Switch } from "antd";
import * as React from "react";
import { useHistory } from "react-router";
import { ButtonBack, ButtonResetPassword, ButtonSave, Button_Cancel, ButtonDelete } from "../Systems/button_all";
import { BoxMsg, Decrypt, DialogConfirm, DialogDelete, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import { Select as AntdSelect } from "antd";
import { parse } from "query-string";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import AuthenService from "../Service/AuthenService";
const Controller = 'api/AdminUserPermission/';
const Admin_Userpermission_EditInfo = () => {
    const [hidden, hiddendiv] = React.useState(true);
    const [optUserGroup, setUserGroup] = React.useState([] as any);
    const history = useHistory();
    const { Option } = AntdSelect;
    const [options, setOptions] = React.useState([] as Array<any>);
    const [V_AdminUser, srtV_AdminUser] = React.useState({} as any);
    const [result, setResult] = React.useState([]);
    const [ModeEdit, setModeEdit] = React.useState(false as boolean);
    const [iActiveDisabled, setiActiveDisabled] = React.useState(false as boolean);
    const [modeEmployeeType, setmodeEmployeeType] = React.useState("1" as string);
    var { sID } = parse(window.location.search);
    sID = sID && Decrypt(sID)
    const [listV_AdminUser, setlistV_AdminUser] = React.useState([] as Array<any>);

    const handleSearch = (value: any) => {
        let res = [] as any;

        if (!value || value.indexOf('@') >= 0) {
            res = [];
        } else {
            res = ['gmail.com', 'hotmail.com', 'yahoo.com'].map((domain) => `${value}@${domain}`);
        }
        setResult(res);
    };
    const [SetValue] = Form.useForm();
    const [SetValueReset] = Form.useForm();


    function onChange(e: { target: { checked: any; }; }) {
        if (e.target.checked) {
            hiddendiv(false)
        } else {
            hiddendiv(true)
        }
    }

    React.useEffect(() => {

        GetV_AdminUser();
        setModeEdit(sID == undefined ? false : true)
        GetDataOnPageLoad();
        GetDropdowEmployee();

    }, []);


    const [isEdit, setisEdit] = React.useState(false);
    const GetUserInfo = async (ov_adminuser: any) => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            setisEdit(UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == 11).map(t => t.nPermission)[0] === 2 ? false : true)
            if (UserInfo.data.sEmployeeCode === ov_adminuser.sUsername) {
                setiActiveDisabled(true);
            }
        }
    }
    const GetDataOnPageLoad = async () => {
        let result: any = await AxiosGetJson(Controller + "GetV_AdminUserbysID?sID=" + (sID === undefined ? "0" : sID));
        srtV_AdminUser(result);
        SetValue.setFieldsValue({
            nGroupID: result.nGroupID === 0 ? undefined : result.nGroupID,
            sName: result.sName,
            sFirstNameTH: result.sFirstNameTH,
            sLastNameTH: result.sLastNameTH,
            sUsername: result.sUsername,
            sPassword: result.sPassword,
            sConfirmPassword: result.sPassword,
            isActive: result.isActive,
            cType: result.cType,
            sEmail: result.sEmail,
            sTitleNameTH: result.sTitleNameTH,
        })
        setmodeEmployeeType(result.cType);
        GetT_AdminGroup(result.nGroupID);

        GetUserInfo(result);
    }



    const GetT_AdminGroup = async (nGroupID: number) => {
        let result: any = await AxiosGetJson(Controller + "GetDropdowT_AdminGroup");
        setUserGroup(result === undefined === undefined ? undefined : sID != undefined ? result.filter(w => w.isActive === true || w.nID === nGroupID) : result.filter(w => w.isActive === true));
    }

    const GetDropdowEmployee = async () => {
        let result: any = await AxiosGetJson(Controller + "GetDropdowEmployee");
        setOptions(result)
    }

    const GetV_AdminUser = async () => {
        let resultuser: any = await AxiosGetJson(Controller + "GetV_AdminUser");
        setlistV_AdminUser(resultuser.V_AdminUser);
    }


    const onSelect = async (value: any, select: any,) => {
        //let result: any = await AxiosGetJson(Controller + "CheckUser?sUserID=" + select.sCODE);

        let checkuser = listV_AdminUser.filter(w => w.sUsername === select.sCODE);
        if (checkuser.length === 0) {
            SetValue.setFieldsValue({
                sEmail: select.sEmail
            })
            V_AdminUser.sOrgID = select.sCODE;
        }
        else {
            SetValue.setFieldsValue({
                sName: undefined,
                sEmail: ""
            })
            Sweetalert.Warning(BoxMsg.Title_Warning, "บัญชีผู้ใช้งานนี้ มีสิทธิ์อยู่ในระบบแล้ว<br>กลุ่ม : " + checkuser[0].sGroupName, null);
        }
    };

    const ValidateDisabledField = (value: any) => {
        setmodeEmployeeType(value);


        SetValue.setFieldsValue({
            sName: "",
            sFirstNameTH: "",
            sLastNameTH: "",
            sUsername: "",
            sPassword: "",
            sEmail: "",
            sConfirmPassword: "",
        })

    };



    const CheckUser = async (sCODE: string) => {
        //let result: any = await AxiosGetJson(Controller + "CheckUser?sUserID=" + sCODE);
        //if (result) {
        //    SetValue.setFieldsValue({
        //        sUserID: ""
        //    })
        //    Sweetalert.Warning(BoxMsg.Title_Warning, "User cannot be duplicated in the system.", null);
        //}
    }

    const onSave = (values: any) => {

        V_AdminUser.sTitleNameTH = values.sTitleNameTH;
        V_AdminUser.nGroupID = values.nGroupID;
        V_AdminUser.sFirstNameTH = values.sFirstNameTH;
        V_AdminUser.sLastNameTH = values.sLastNameTH;
        V_AdminUser.sUsername = values.sUsername;
        V_AdminUser.sPassword = values.sPassword;
        V_AdminUser.isActive = values.isActive;
        V_AdminUser.cType = values.cType;
        V_AdminUser.sEmail = values.sEmail;

        DialogConfirm(async () => {
            let result: any = await AxiosPostJson(Controller + "SaveDataInfo", V_AdminUser);
            if (result.data.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Save, () => {
                    onBack();
                });
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }
        })


    };
    const onBack = () => {
        history.push("/admin-user-permission-list?sMode=2");
    };

    const listTitleNameTH = [
        {
            value: 'นาย',
        },
        {
            value: 'นาง',
        },
        {
            value: 'น.ส.',
        },
    ];

    const [isModalVisible, setIsModalVisible] = React.useState(false);

    const ResetPassword = async (sUserID: string) => {
        setIsModalVisible(true);
    }

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const onReset = (values: any) => {
        DialogConfirm(async () => {
            let result: any = await AxiosPostJson(Controller + "ResetPassword?sUsername=" + V_AdminUser.sUsername + "&sPassword=" + values.sPasswordreset);
            if (result.data.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Save, () => {
                    GetDataOnPageLoad();
                    setIsModalVisible(false);
                });
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }
        }, "", "", "ต้องการเปลี่ยนรหัสผ่านหรือไม่")
    };

    const onDelete = async () => {

        if (sID != undefined) {

            DialogDelete(async () => {
                let result: any = await AxiosPostJson(Controller + "onDeleteBysID?sID=" + (sID === undefined ? "0" : sID));
                if (result.data.sStatus === Responsestart.success) {
                    Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete, null);
                    onBack();
                } else if (result.data.sStatus === Responsestart.warning) {
                    Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                } else {
                    Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                }
            });
        }

    };
    return (
        <React.Fragment>
            <Form
                onFinish={onSave}
                form={SetValue}
            >
                <br />
                <Row gutter={[24, 8]}>
                    <Col xl={12} md={12} xs={24}>

                        <b>กลุ่มผู้ดูแลระบบ</b>  <span className="text-muted small"> (เพื่อกำหนดสิทธิ์) </span>  <span className="text-danger ">*</span>
                        <Form.Item
                            name="nGroupID"
                            rules={[{ required: true, message: BoxMsg.Desc_Required_Field + " กลุ่มผู้ดูแลระบบ" }]}
                        >
                            <Select
                                disabled={!isEdit}
                                placeholder="- กลุ่มผู้ดูแลระบบ -"
                            >
                                {
                                    optUserGroup.map((o: any, i: any) => {
                                        return (
                                            <Option key={"Option" + o.nID} value={o.nID}>{o.sName}</Option>
                                        )
                                    }
                                    )
                                }
                            </Select>
                        </Form.Item>

                    </Col>
                </Row>

                <Row gutter={[24, 8]}>
                    <Col xl={12} md={12} xs={24} >
                        <b> ประเภทผู้ใช้งาน </b>
                        <Form.Item
                            name="cType"
                        >
                            <Radio.Group onChange={(e: any) => { ValidateDisabledField(e.target.value) }} disabled={!isEdit ? true : ModeEdit}>
                                <Radio value="1">พนักงาน</Radio>
                                <Radio value="2">อื่นๆ</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[24, 8]}>
                    {
                        modeEmployeeType == "1" ?

                            <Col xl={12} md={12} xs={24}>
                                <b>รหัสพนักงาน,ชื่อ-นามสกุล </b> <span className="text-danger ">*</span>
                                <Form.Item
                                    name="sName"
                                    rules={[
                                        { required: true, message: BoxMsg.Desc_Required_Field + " รหัสพนักงาน,ชื่อ-นามสกุล" },
                                    ]}
                                >
                                    <AntdSelect
                                        disabled={!isEdit ? true : ModeEdit}
                                        allowClear={true}
                                        showSearch={true}
                                        options={options}
                                        onSelect={onSelect}
                                        placeholder="ค้นหาด้วย รหัสพนักงาน, ชื่อ-นามสกุล"
                                    >
                                        {
                                            options ?
                                                options.map((e, indx) => {
                                                    return (
                                                        <Option key={"Option" + indx} value={e.value}>{e.label}</Option>
                                                    );
                                                })
                                                :
                                                null
                                        }
                                    </AntdSelect>
                                </Form.Item>
                            </Col>

                            :
                            <>
                                <Col xl={12} md={12} xs={24}>
                                    <Row gutter={[8, 8]}>
                                        <Col xl={4} md={4} xs={24}>
                                            <b>คำนำหน้า </b> <span className="text-danger ">*</span>
                                            <Form.Item
                                                name="sTitleNameTH"
                                                rules={[{ required: true, message: BoxMsg.Desc_Required_Field + ' คำนำหน้า' }]}
                                            >


                                                <AntdSelect
                                                    disabled={!isEdit}
                                                    style={{ width: "100%" }}
                                                    placeholder="- คำนำหน้า -"
                                                    filterOption={(input, option) =>
                                                        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {

                                                        listTitleNameTH.map((p) => {
                                                            return (
                                                                <Option key={"TitleName_" + p.value} value={p.value} >{p.value}</Option>
                                                            )
                                                        })
                                                    }
                                                </AntdSelect>



                                            </Form.Item>
                                        </Col>
                                        <Col xl={10} md={10} xs={24}>
                                            <b>ชื่อ </b> <span className="text-danger ">*</span>
                                            <Form.Item
                                                name="sFirstNameTH"

                                                rules={[{ required: true, message: BoxMsg.Desc_Required_Field + ' ชื่อ' }]}
                                            >
                                                <Input disabled={!isEdit} maxLength={50} autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col xl={10} md={10} xs={24}>
                                            <b>นามสกุล </b> <span className="text-danger">*</span>
                                            <Form.Item
                                                name="sLastNameTH"
                                                rules={[{ required: true, message: BoxMsg.Desc_Required_Field + ' นามสกุล' }]}
                                            >
                                                <Input disabled={!isEdit} maxLength={50} autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </>

                    }


                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <b>E-mail</b> <span className="text-danger ">*</span>
                        <Form.Item
                            name="sEmail"
                            rules={[{ required: true, message: BoxMsg.Desc_Required_Field + " E-mail" },
                            { type: 'email', message: 'รูปแบบอีเมลไม่ถูกต้อง (เช่น example@email.com)' },
                            ]}
                        >
                            <Input disabled={!isEdit} maxLength={250} autoComplete="off" />
                        </Form.Item>
                    </Col>
                </Row>
                {
                    modeEmployeeType != "1" ?

                        <>
                            <Row gutter={[24, 8]}>
                                <Col xl={12} md={12} xs={24}>
                                    <b>บัญชีผู้ใช้งาน </b> <span className="text-danger ">*</span>
                                    <Form.Item
                                        name="sUsername"

                                        rules={[{ required: true, message: BoxMsg.Desc_Required_Field + ' บัญชีผู้ใช้งาน' },
                                        { min: 8, message: 'บัญชีผู้ใช้งานควรมีความยาวอย่างน้อย 8 ตัวอักษร' },
                                        { max: 20, message: 'บัญชีผู้ใช้งานควรมีไม่เกิน 20 ตัวอักษร' }
                                        ]}
                                    >
                                        <Input onKeyDown={(e: any) => {
                                            var regex = new RegExp("[a-zA-Z-0-9]+")
                                            if (!regex.test(e.key)) {
                                                e.preventDefault()
                                                return false
                                            }
                                        }}

                                            autoComplete="off"
                                            maxLength={20}
                                            disabled={!isEdit ? true : ModeEdit}
                                            onBlur={(e: any) => { CheckUser(e.target.value) }} />
                                    </Form.Item>
                                </Col>

                                {
                                    sID != undefined ?
                                        <Col xl={12} md={12} xs={24}>
                                            <br />
                                            < ButtonResetPassword disabled={!isEdit} onClick={() => ResetPassword(V_AdminUser.sUsername)} />
                                        </Col>
                                        : null
                                }
                            </Row>


                            {
                                sID != undefined ?
                                    null
                                    :

                                    <Row gutter={[24, 8]}>

                                        <Col xl={12} md={12} xs={24}>
                                            <b>รหัสผ่าน</b> <span className="text-danger ">*</span><span className="text-muted small"> (ความยาว 8-20 ตัวอักษร ภาษาอังกฤษพิมพ์เล็ก/ใหญ่ ตัวเลขและอักขระพิเศษ)</span>
                                            <Form.Item
                                                name="sPassword"
                                                rules={[{ required: true, message: BoxMsg.Desc_Required_Field + ' รหัสผ่าน' },
                                                { max: 20, message: 'รหัสผ่านควรมีความยาวไม่เกิน 20 ตัวอักษร' },
                                                {
                                                    pattern: /^((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,}))|((?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])(?=.{8,}))|((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,}))|((?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,}))/,
                                                    message: `(ความยาว 8-20 ตัวอักษร ภาษาอังกฤษพิมพ์เล็ก/ใหญ่ ตัวเลขและอักขระพิเศษ)`
                                                },]}

                                            >
                                                <Input.Password disabled={isEdit && sID == undefined ? false : true} maxLength={20} autoComplete="new-password" />
                                            </Form.Item>
                                        </Col>
                                        <Col xl={12} md={12} xs={24}>




                                            <b>ยืนยันรหัสผ่าน</b> <span className="text-danger">*</span><span className="text-muted small"> (ระบุรหัสผ่านอีกครั้ง)</span>
                                            <Form.Item
                                                name="sConfirmPassword"
                                                dependencies={['sPassword']}
                                                rules={[{ required: true, message: BoxMsg.Desc_Required_Field + ' รหัสผ่าน' },
                                                { min: 8, message: 'รหัสผ่านควรมีความยาวอย่างน้อย 8 ตัวอักษร' },
                                                { max: 20, message: 'รหัสผ่านควรมีความยาวไม่เกิน 20 ตัวอักษร' },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('sPassword') === value) {
                                                            return Promise.resolve();
                                                        }

                                                        return Promise.reject(new Error('โปรดระบุรหัสผ่านให้ตรงกัน'));
                                                    },
                                                }),

                                                ]}

                                            >
                                                <Input.Password disabled={isEdit && sID == undefined ? false : true} maxLength={20} autoComplete="new-password" />
                                            </Form.Item>



                                        </Col>
                                    </Row>

                            }



                        </>
                        : null
                }

                <Row gutter={[24, 8]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <b>สถานะ</b>
                        <Form.Item name="isActive" valuePropName="checked" >
                            <Switch disabled={!isEdit || iActiveDisabled ? true : false} defaultChecked={true} checkedChildren="ใช้งาน" unCheckedChildren="ไม่ใช้งาน" style={{width : "100px"}} />
                        </Form.Item>
                    </Col>
                </Row>
                <hr />
                <Row gutter={[8, 16]} justify="center">
                    {isEdit ?
                        <React.Fragment>
                            <Col>
                                <ButtonSave disabled={!isEdit} htmlType="submit" />
                            </Col>

                            {
                                sID === undefined || V_AdminUser.sIsuse === "Y" || iActiveDisabled ?
                                    null
                                    :
                                    <Col>
                                        <ButtonDelete onClick={() => { onDelete() }} />
                                    </Col>
                            }

                        </React.Fragment>
                        : null}
                    <Col>
                        <Button_Cancel onClick={() => { history.push("/admin-user-permission-list?sMode=2") }} />
                    </Col>
                </Row>
            </Form>


            <Modal title={<> <i className="fas fa-sync-alt"></i> เปลี่ยนรหัสผ่าน </>}
                visible={isModalVisible}
                okText="ปิด"
                onCancel={handleOk}
                onOk={handleOk}
                footer={false}
                width={500}
            >

                <Form
                    onFinish={onReset}
                    form={SetValueReset}
                >
                    <Col xl={24} md={24} xs={24}>
                        <b>รหัสผ่านใหม่</b> <span className="text-danger ">*</span><span className="text-muted small"> (ความยาว 8-20 ตัวอักษร ภาษาอังกฤษพิมพ์เล็ก/ใหญ่ ตัวเลขและอักขระพิเศษ)</span>
                        <Form.Item
                            name="sPasswordreset"
                            rules={[{ required: true, message: BoxMsg.Desc_Required_Field + ' รหัสผ่าน' },
                            { max: 20, message: 'รหัสผ่านควรมีความยาวไม่เกิน 20 ตัวอักษร' },
                            {
                                pattern: /^((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,}))|((?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])(?=.{8,}))|((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,}))|((?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,}))/,
                                message: `(ความยาว 8-20 ตัวอักษร ภาษาอังกฤษพิมพ์เล็ก/ใหญ่ ตัวเลขและอักขระพิเศษ)`
                            },]}

                        >
                            <Input.Password maxLength={20} autoComplete="new-password" />
                        </Form.Item>
                        <b>ยืนยันรหัสผ่านใหม่</b> <span className="text-danger">*</span><span className="text-muted small"> (ระบุรหัสผ่านอีกครั้ง)</span>
                        <Form.Item
                            name="sConfirmPasswordreset"
                            dependencies={['sPasswordreset']}
                            rules={[{ required: true, message: BoxMsg.Desc_Required_Field + ' รหัสผ่าน' },
                            { min: 8, message: 'รหัสผ่านควรมีความยาวอย่างน้อย 8 ตัวอักษร' },
                            { max: 20, message: 'รหัสผ่านควรมีความยาวไม่เกิน 20 ตัวอักษร' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('sPasswordreset') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('โปรดระบุรหัสผ่านให้ตรงกัน'));
                                },
                            }),

                            ]}

                        >
                            <Input.Password maxLength={20} autoComplete="new-password" />
                        </Form.Item>
                        <hr />
                        <Row gutter={[16, 16]} justify="center">
                            {isEdit ?
                                <Col>
                                    <ButtonResetPassword disabled={!isEdit} htmlType="submit" />
                                </Col>
                                : null}
                            <Col>
                                <Button_Cancel onClick={() => { history.push("/admin-user-permission-list?sMode=2") }} />
                            </Col>
                        </Row>
                    </Col>
                </Form>
            </Modal >

        </React.Fragment>
    )
}
export default Admin_Userpermission_EditInfo;