import { Button, Col, Input, Row, Space, Tabs, Tag, Tooltip } from "antd";
import * as React from "react";
import { Empty, Select as AntdSelect } from "antd";
import { ButtonAdd_Table, ButtonEdit_Table, ButtonInfo_Table, ButtonSearch } from "../../Systems/button_all";
import TableAntd from "../../Systems/TableAntd";
import { useHistory } from "react-router";
import { BoxMsg, DialogConfirm, DialogDelete, Encrypt, Responsestart, Sweetalert } from "../../Systems/SystemComponent";
import { AxiosGetJson, AxiosPostJson } from "../../Service/Config/AxiosMethod";
import AuthenService from "../../Service/AuthenService";

const Admin_Content_Index = ({ sType = "99", editTypeEvent = "/", editEvent = "/", nPerMenuId = 99 }) => {
    var type = sType ? sType : "99";
    var editEv = editEvent ? editEvent : "/";
    var editTypeEv = editTypeEvent ? editTypeEvent : "/";
    var nMenuId = nPerMenuId ? nPerMenuId : 99;
    const history = useHistory();
    const [selectedRows, setselectedRows] = React.useState([] as Array<any>);
    const [selectedRowKeys, setrowSelected] = React.useState([] as any);
    const [lstContent, setLstContent] = React.useState([] as any);
    const [txtSearch, SetTxtSearch] = React.useState({
        sSubject: "",
        sStatus: null as any
    });
    const [total, setTotal] = React.useState(0);
    const [sPageA, setsPageA] = React.useState("1" as string || undefined);
    const [sPageSizeA, setsPageSizeA] = React.useState("10" as string || undefined);
    const [sFieldA, setsFieldA] = React.useState("" as string || undefined);
    const [sOrderA, setsOrderA] = React.useState("" as string || undefined);
    const [nCurrentPage, setCurrentPage] = React.useState(1);
    const [isEdit, setisEdit] = React.useState(false);
    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            setisEdit(UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == nMenuId).map(t => t.nPermission)[0] === 2 ? false : true)
        }
    }

    const header = [
        {
            title: "ลำดับที่", dataIndex: "key", key: "Key_No_1", width: 1, render: (text: any, record: any) => {
                return (<a key={record.nkey}>{record.nkey}.</a>);
            },
            className: "text-center text-nowrap",
        },
        { title: "เมนูหลัก", dataIndex: "sTypeLv1Name", key: "Key_No_2", sorter: true, className: "text-left text-wrap", width: 150, },
        { title: "เมนูรอง", dataIndex: "sTypeLv2Name", key: "Key_No_3", sorter: true, className: "text-left text-wrap", width: 150, },
        { title: "เมนูย่อย", dataIndex: "sTypeLv3Name", key: "Key_No_4", sorter: true, className: "text-left text-wrap", width: 150, },
        {
            title: 'สถานะ',
            dataIndex: 'isActive',
            width: 50,
            sorter: true,
            className: "text-center",
            render: (text: any, record: { sIsActive: string; }) => (
                <Tag color={record.sIsActive === "1" ? "#60cc28" : "#cc2838"} key={text} style={{ borderRadius: "32px" }}>
                    {record.sIsActive === "1" ? "ใช้งาน" : "ไม่ใช้งาน"}
                </Tag>
            ),
        },
        {
            title: isEdit ? <ButtonAdd_Table onClick={() => { history.push(editTypeEv); }} /> : null,
            dataIndex: "4", key: "Key_No_8",
            className: "text-left",
            render: (text: any, record: any) => (
                <React.Fragment>
                    {
                        isEdit ?
                            <Tooltip placement="top" title={"แก้ไข"}  >
                                <ButtonEdit_Table onClick={() => { getSub_Edit(record.sID) }} />
                            </Tooltip>
                            :
                            null
                    }
                    {" "}
                    {
                        // record.nTypeIDLv2 === 0 && record.nTypeIDLv3 === 0 && type !== "3" ?
                        // null
                        // :
                        isEdit ?
                        <Tooltip placement="top" title={"รายละเอียด"}  >
                            <ButtonInfo_Table onClick={() => { getSub_Info(record.sID) }} />
                        </Tooltip>
                        :
                        null
                    }
                </React.Fragment>

            ),
            width: 1,
        },
    ];

    const getSub_Edit = (sID: any) => {
        let sTypeComponent = editTypeEv;
        let sPath = `${sTypeComponent}?id=${sID}`;
        return history.push(sPath);
    };

    const getSub_Info = (sID: any) => {
        let sTypeComponent = editEv;
        let sPath = `${sTypeComponent}?id=${sID}`;
        return history.push(sPath);
    };

    const GetDataOnPageLoad = async (sPage, sPageSize, sField, sOrder) => {
        var data = {
            sType: type,
            sSubject: txtSearch.sSubject,
            sStatus: txtSearch.sStatus,
            sPage: sPage ? sPage.toString() : sPageA,
            sPageSize: sPageSize ? sPageSize.toString() : sPageSizeA,
            sField: sField ? sField : sFieldA,
            sOrder: sOrder, // ? sOrder : sOrderA,
        };
        let result: any = await AxiosPostJson("api/AdminContent/GetContentIndexList", data);
        setLstContent(result.data.cContentTypeLevel);
        setTotal(result.data.nTotal);
    }

    const onPageChange = async (page, pageSize, sorter) => {
        setsPageA(page);
        setsPageSizeA(pageSize);
        GetDataOnPageLoad(page, pageSize, sFieldA, sOrderA);
        setCurrentPage(page);
        window.scrollTo(0, 0)
    }

    const onTableChange = async (pagination, filters, sorter, extra) => {
        if (sorter) {
            setsFieldA(sorter.field);
            setsOrderA(sorter.order);
            GetDataOnPageLoad(sPageA, sPageSizeA, sorter.field, sorter.order);
        }
    }

    const onDeleteClick = async () => {
        if (selectedRows.length > 0) {
            DialogDelete(async () => {
                let result: any = await AxiosPostJson("api/AdminContent/DeleteDataCate", selectedRows);
                if (result.data.sStatus === Responsestart.success) {
                    Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete,
                        () => {
                            setselectedRows([]);
                            onselect([],[]);
                            GetDataOnPageLoad(sPageA, sPageSizeA, sFieldA, sOrderA);
                        });
                } else if (result.data.sStatus === Responsestart.warning) {
                    Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                } else {
                    Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                }
            });
        }
    }

    const onselect = (selectedRowKeys, selectedRows: React.SetStateAction<any[]>) => {
       setselectedRows(selectedRows);
       setrowSelected(selectedRowKeys);
    }

    const rowSelection = {
       selectedRowKeys,
       onChange: onselect,
       //getCheckboxProps: (record) => ({
       //    //disabled: record.isHasDelete !== true, // Column configuration not to be checked
       //    disabled: false, // Column configuration not to be checked
       //    name: record.nID,
       //}),
    };

    React.useEffect(() => {
        GetDataOnPageLoad(undefined, undefined, undefined, undefined);
        GetUserInfo();
    }, [sType]);

    return (
        <React.Fragment>
            <br />
            <Row justify="end" gutter={[16, 16]}>
                <Col xl={5} md={12} xs={24}>
                    <Input
                        autoComplete="off"
                        placeholder="ชื่อหัวข้อ"
                        onChange={(e) =>
                            SetTxtSearch({
                                ...txtSearch,
                                sSubject: e.target.value,
                            })
                        }
                        onKeyPress={(e) => {
                            e.key === "Enter" && GetDataOnPageLoad(undefined, undefined, undefined, undefined);
                        }}
                    />
                </Col>
                <Col xl={4} md={10} xs={20}>
                    <AntdSelect
                        allowClear={true}
                        showSearch={true}
                        style={{ width: "100%" }}
                        value={txtSearch.sStatus}
                        placeholder="- สถานะ -"
                        onChange={(e) => {
                            SetTxtSearch({
                                ...txtSearch,
                                sStatus: e,
                            });
                        }}
                        filterOption={(input, option) =>
                            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <option value="1">ใช้งาน</option>
                        <option value="0">ไม่ใช้งาน</option>
                    </AntdSelect>
                </Col>
                <Col xl={1} md={2} xs={4}>
                    <ButtonSearch onClick={() => { GetDataOnPageLoad(sPageA, sPageSizeA, sFieldA, sOrderA); }} />
                </Col>
            </Row>
            <br />
            <div className="ant-spin-nested-loading">
                <div className="ant-spin-container">
                    <div className="ant-table">
                        <div className="ant-table-container">
                            <div className="ant-table-content">
                                <TableAntd
                                    dataSource={lstContent}
                                    columns={header}
                                    onPageChange={onPageChange}
                                    currentPage={nCurrentPage}
                                    total={total}
                                    onChange={onTableChange}
                                    onClickDeleteData={onDeleteClick}
                                    scroll={{ x: 500 }}
                                    rowSelection={isEdit ? rowSelection : undefined}
                                    onShowDelete={isEdit}
                                    pageSize={sPageSizeA ? parseInt(sPageSizeA) : 10}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}
export default Admin_Content_Index;