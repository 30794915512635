import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import store from '../src/components/app/store';
import unregister from './registerServiceWorker';
import { Provider } from 'react-redux';
//ReactDOM.render(<App />, document.getElementById('root'));


ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);




store.subscribe(() => {
    // save a copy to localStorage
    localStorage.setItem('reduxState', JSON.stringify(store.getState().counter))
})


unregister();