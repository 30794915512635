import * as React from "react";
import { parse } from "query-string";
import { BoxMsg, Decrypt, DialogConfirm, DialogDelete, fontFamily, froalaconfigs, pluginsEnabled, quickInsertButtons, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import { Checkbox, Col, Form, Input, Radio, Row, Switch } from "antd";
import { ButtonBack, ButtonDelete, ButtonRespond, ButtonSave, Button_Cancel } from "../Systems/button_all";
import { useHistory } from "react-router";
import FroalaEditor from "react-froala-wysiwyg";
import ThaiDatePicker from "../ST_Handlers/ThaiDatePicker";
import { Empty, Select as AntdSelect } from "antd";
import Fileuploader, { Extension } from "../../Fileuploader";
import BlockUi from "react-block-ui";
import AuthenService from "../Service/AuthenService";
const { Option } = AntdSelect;

const Controller = 'api/AdminEvent/';

const Admin_Map_List = () => {
    var { sEventId } = parse(window.location.search);
    sEventId = sEventId && Decrypt(sEventId)
    const history = useHistory();
    const [ModeEN, setModeEN] = React.useState("TH" as string);
    const [sDetailTH, setsDetailTH] = React.useState("");
    const [sDetailEN, setsDetailEN] = React.useState("");
    const [SetValue] = Form.useForm();
    const [CheckboxDate, setCheckboxDate] = React.useState(false as boolean);
    const [txtSearch, SetTxtSearch] = React.useState({
        dSearchStartDate: undefined as (Date | undefined),
        dSearchEndDate: undefined as (Date | undefined),

    });
    const [st_ActiveBlock, setActiveBlock] = React.useState(true);
    const [lstFileTH, setlstFileTH] = React.useState([] as any);
    const [lstFileEN, setlstFileEN] = React.useState([] as any);
    const [lstFileImageTH, setlstFileImageTH] = React.useState([] as any);
    const [lstFileImageEN, setlstFileImageEN] = React.useState([] as any);
    const [FileImageTH, setFileImageTH] = React.useState([] as any);
    const [FileImageEN, setFileImageEN] = React.useState([] as any);
    const [V_Event, serV_Event] = React.useState([] as any);
    const [listLearning, serlistLearning] = React.useState([] as any);

    const [isEdit, setisEdit] = React.useState(false);
    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            setisEdit(UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == 12).map(t => t.nPermission)[0] === 2 ? false : true)
        }
    }

    var handleModelChangeTH = (model: any) => {
        setsDetailTH(model);
        SetValue.setFieldsValue({
            sDetailTH: model,
        });
    };
    var handleModelChangeEN = (model: any) => {
        setsDetailEN(model);
        SetValue.setFieldsValue({
            sDetailEN: model,
        });
    };

    const GetLearning = async () => {
        let result: any = await AxiosGetJson(Controller + "GetLearning");
        serlistLearning(result)
    }

    const GetDataOnPageLoad = async () => {
        let result: any = await AxiosGetJson(Controller + "GetV_EventbysEventId?sEventId=" + (sEventId === undefined ? "0" : sEventId));
        setModeEN(result.V_Event.cLanguage)
        serV_Event(result.V_Event);
        SetValue.setFieldsValue({
            sNameTH: result.V_Event.sNameTH,
            sNameEN: result.V_Event.sNameEN,
            sDescTH: result.V_Event.sDescTH,
            sDescEN: result.V_Event.sDescEN,
            FileImageTH: result.oFileImageTH,
            FileImageEN: result.oFileImageEN,
            switchStatus: result.V_Event.isActive,
            dShowStart: result.V_Event.dShowStart == null ? undefined : new Date(result.V_Event.dShowStart),
            dShowEnd: result.V_Event.dShowEnd == null ? undefined : new Date(result.V_Event.dShowEnd),
            nTypeID: result.V_Event.nTypeID,

        })
        setsDetailTH(result.V_Event.sDetailTH);
        setsDetailEN(result.V_Event.sDetailEN);
        SetTxtSearch({
            ...txtSearch,
            dSearchStartDate: result.V_Event.dShowStart == null ? undefined : new Date(result.V_Event.dShowStart),
            dSearchEndDate: result.V_Event.dShowEnd == null ? undefined : new Date(result.V_Event.dShowEnd)
        });
        setCheckboxDate(sEventId === undefined ? false : (result.V_Event.dShowEnd == null ? true : false))

        setlstFileTH(result.listFileTH);
        setlstFileEN(result.listFileEN);
        setlstFileImageTH(result.listFileImageTH);
        setlstFileImageEN(result.listFileImageEN);
        setFileImageTH(result.oFileImageTH);
        setFileImageEN(result.oFileImageEN);
        setActiveBlock(false);
    }
    const ValidateDisabledField = (value: any) => {
        setModeEN(value.target.value);
    };
    var configs = {
        placeholderText: "",
        attribution: false,
        key: "0BA3jA11B5C7C4A4D3aIVLEABVAYFKc2Cb1MYGH1g1NYVMiG5G4E3C3A1C8A6D4A3B4==",
        imageUploadURL: process.env.REACT_APP_URL + "api/UploadTextEditor/uploadImage",
        videoUploadURL: process.env.REACT_APP_URL + "api/UploadTextEditor/uploadImage",
        quickInsertButtons: quickInsertButtons,
        pluginsEnabled: pluginsEnabled,
        events: {
            'html.set': async function () {
                let editor: any = this
                let UserInfo: any = await AuthenService.UserInfo();
                if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
                    if (UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu === 12).map(t => t.nPermission)[0] === 2) {
                        editor.edit.off()
                    }
                }
            },
        },
        fontFamily: fontFamily,
        fontFamilySelection: true,
    };


    function onChange(e) {

        SetValue.resetFields(["dShowEnd"]);
        setCheckboxDate(e.target.checked)
        SetValue.setFieldsValue({
            dShowEnd: undefined
        })
    }

    const DateFormat = (today: any) => {
        let day = today.getDate() < 10 ? ("0" + today.getDate().toString()) : today.getDate().toString();
        let month = (today.getMonth() + 1) < 10 ? ("0" + (today.getMonth() + 1).toString()) : (today.getMonth() + 1).toString();
        let date = day + "/" + month + "/" + today.getFullYear();
        return date;
    }

    const onSave = (values: any) => {

        V_Event.cLanguage = ModeEN;
        V_Event.sShowStart = values.dShowStart == undefined ? "" : DateFormat(values.dShowStart);
        V_Event.sShowEnd = values.dShowEnd == undefined ? "" : DateFormat(values.dShowEnd);
        V_Event.nTypeID = values.nTypeID;
        V_Event.sDescEN = values.sDescEN;
        V_Event.sDescTH = values.sDescTH;
        V_Event.sDetailEN = sDetailEN;
        V_Event.sDetailTH = sDetailTH;
        V_Event.sNameEN = values.sNameEN;
        V_Event.sNameTH = values.sNameTH;
        V_Event.isActive = values.switchStatus;

        let data = {
            V_Event: V_Event,
            oFileImageTH: FileImageTH,
            oFileImageEN: FileImageEN,
            listFileTH: lstFileTH,
            listFileEN: lstFileEN,
            listFileImageTH: lstFileImageTH,
            listFileImageEN: lstFileImageEN,
            //listnProvince: values.nProvince.map(a => a),
        }
        DialogConfirm(async () => {
            let result: any = await AxiosPostJson(Controller + "Savedata", data)
            if (result.data.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Desc_Success_Save, BoxMsg.Desc_Success_Save, () => {
                    onBack();
                });
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }
        }, null, BoxMsg.Title_Confirm, BoxMsg.Desc_Confirm_Save)
        //} else {

        //    Sweetalert.Warning(BoxMsg.Title_Warning, "โปรดแนบไฟล์", null);
        //}


        //} else {

        //    Sweetalert.Warning(BoxMsg.Title_Warning, "โปรดแนบภาพปก", null);
        //}

    }

    const onBack = () => {
        history.push({
            pathname: "/admin-event-list",
        });
    };
    var onUploadFileSuccess = () => {
    };
    var onUploadFileImageTHSuccess = () => {
        SetValue.validateFields(['FileImageTH']);
        SetValue.setFieldsValue({
            FileImageTH: "FileImageTH"
        })
    };
    var onUploadFileImageENSuccess = () => {
        SetValue.validateFields(['FileImageEN']);
        SetValue.setFieldsValue({
            FileImageEN: "FileImageEN"
        })
    };
    var onRemoveFileImageTHComplete = () => {
        SetValue.validateFields(['FileImageTH']);
        SetValue.setFieldsValue({
            FileImageTH: ""
        })
    };
    var onRemoveFileImageENComplete = () => {
        SetValue.validateFields(['FileImageEN']);
        SetValue.setFieldsValue({
            FileImageEN: ""
        })
    };



    const onDelete = async () => {

        if (sEventId != undefined) {

            DialogDelete(async () => {
                let result: any = await AxiosPostJson(Controller + "onDeleteBysEventId?sEventId=" + (sEventId === undefined ? "0" : sEventId));
                if (result.data.sStatus === Responsestart.success) {
                    Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete, null);
                    onBack();
                } else if (result.data.sStatus === Responsestart.warning) {
                    Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                } else {
                    Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                }
            });
        }

    };
    React.useEffect(() => {
        GetUserInfo();
    }, []);
    React.useEffect(() => {
        GetDataOnPageLoad();
        GetLearning();
    }, [isEdit]);

    return (
        <React.Fragment>
            <BlockUi blocking={st_ActiveBlock}></BlockUi>
            <Form onFinish={onSave}
                scrollToFirstError
                form={SetValue}
                name="From"
                onFinishFailed={({ errorFields }) => {
                    let element = document.querySelector(`#From_${errorFields[0].name}`);
                    var firstErrorElemY: any = null;
                    if (element) {
                        firstErrorElemY = element.getBoundingClientRect().top - 100; // bar height(100)px
                        window.scrollTo(0, window.scrollY + firstErrorElemY)
                    }
                    else{
                        element = document.getElementById(`${errorFields[0].name}`);
                        if (element) {
                            firstErrorElemY = element.getBoundingClientRect().top - 100; // bar height(100)px
                            window.scrollTo(0, window.scrollY + firstErrorElemY)
                        }
                    }
                }}
            >
                <br />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                        <b> มีเนื้อหาภาษาอังกฤษหรือไม่ </b>
                        <br />
                        <Radio.Group disabled={!isEdit} onChange={ValidateDisabledField} value={ModeEN}>
                            <Radio value="EN">ใช่</Radio>
                            <Radio value="TH">ไม่ใช่</Radio>
                        </Radio.Group>
                    </Col>

                </Row>
                <br />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>

                        <b>ชื่อกิจกรรม </b> <span className="text-danger ">*</span><span className="text-primary small"> ภาษาไทย </span>
                        <Form.Item
                            name="sNameTH"
                            rules={[
                                { required: true, message: BoxMsg.Desc_Required_Field + "ชื่อป่า" },
                            ]}
                        >
                            <Input disabled={!isEdit} maxLength={250} autoComplete="off" />
                        </Form.Item>
                    </Col>
                    {
                        ModeEN === "EN" ?
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <b>ชื่อกิจกรรม </b> <span className="text-danger ">*</span> <span className="text-success small"> ภาษาอังกฤษ </span>
                                <Form.Item
                                    name="sNameEN"
                                    rules={[
                                        {
                                            required: true,
                                            message: BoxMsg.Desc_Required_Field + "ชื่อป่า"
                                        },
                                    ]}
                                >
                                    <Input disabled={!isEdit} maxLength={250} autoComplete="off" />
                                </Form.Item>
                            </Col>

                            : null
                    }
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <b>คำอธิบาย</b> <span className="text-danger ">*</span><span className="text-primary small"> ภาษาไทย </span>
                        <Form.Item
                            name="sDescTH"
                            rules={[
                                { required: true, message: BoxMsg.Desc_Required_Field + "คำอธิบาย" },
                            ]}
                        >
                            <Input.TextArea disabled={!isEdit} maxLength={2000} rows={4} />
                        </Form.Item>
                    </Col>
                    {
                        ModeEN === "EN" ?
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <b>คำอธิบาย</b> <span className="text-danger ">*</span><span className="text-success small"> ภาษาอังกฤษ </span>
                                <Form.Item
                                    name="sDescEN"
                                    rules={[
                                        { required: true, message: BoxMsg.Desc_Required_Field + "คำอธิบาย" },
                                    ]}
                                >
                                    <Input.TextArea disabled={!isEdit} maxLength={2000} rows={4} />
                                </Form.Item>
                            </Col>
                            : null
                    }
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <b>รายละเอียด </b><span className="text-danger ">*</span><span className="text-primary small"> ภาษาไทย </span>
                        <Form.Item
                            name="sDetailTH"
                            rules={[
                                { required: sDetailTH === "" ? true : false, message: BoxMsg.Desc_Required_Field + "รายละเอียด" },
                            ]}
                        >
                            <FroalaEditor
                                model={sDetailTH}
                                config={configs}
                                onModelChange={handleModelChangeTH}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {
                    ModeEN === "EN" ?
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <b>รายละเอียด</b> <span className="text-danger ">*</span><span className="text-success small"> ภาษาอังกฤษ </span>
                                <Form.Item
                                    name="sDetailEN"
                                    rules={[
                                        { required: sDetailEN === "" ? true : false, message: BoxMsg.Desc_Required_Field + "รายละเอียด" },
                                    ]}
                                >
                                    <FroalaEditor
                                        model={sDetailEN}
                                        config={configs}
                                        onModelChange={handleModelChangeEN}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        : null
                }
                <Row gutter={[16, 16]}>
                    <Col xl={12} md={12} xs={24}>
                        <Row gutter={[8, 8]} align="middle" >
                            <Col xl={9} md={9} xs={24}>
                                <b> ช่วงวันที่แสดงข้อมูล </b><span className="text-danger ">*</span>
                                <Form.Item
                                    name="dShowStart"
                                    rules={[
                                        { required: true, message: BoxMsg.Desc_Required_Field + "วันที่เริ่มต้น" },
                                    ]}
                                >
                                    <ThaiDatePicker
                                        disabled={!isEdit}
                                        value={txtSearch.dSearchStartDate}
                                        maxDate={txtSearch.dSearchEndDate}
                                        small={false}
                                        placeholder="วันที่เริ่มต้น"
                                        onChange={(value) => {
                                            SetTxtSearch({
                                                ...txtSearch,
                                                dSearchStartDate: value,
                                            });

                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xl={9} md={9} xs={24} >
                                <br />
                                <Form.Item
                                    name="dShowEnd"
                                    rules={[
                                        { required: !CheckboxDate, message: BoxMsg.Desc_Required_Field + "วันที่สิ้นสุด" },
                                    ]}
                                >
                                    <ThaiDatePicker
                                        value={txtSearch.dSearchEndDate}
                                        minDate={txtSearch.dSearchStartDate}
                                        small={false}
                                        disabled={isEdit ? CheckboxDate : true}
                                        placeholder="วันที่สิ้นสุด"
                                        onChange={(value) => {
                                            SetTxtSearch({
                                                ...txtSearch,
                                                dSearchEndDate: value,
                                            });
                                        }} />
                                </Form.Item>
                            </Col>
                            <Col xl={6} md={6} xs={24}>
                                &nbsp;
                                <Checkbox disabled={!isEdit} checked={CheckboxDate} onChange={onChange} >ไม่มีกำหนด</Checkbox>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={12} md={12} xs={24}>
                        <b> ศูนย์เรียนรู้ </b><span className="text-danger ">*</span>
                        <Form.Item
                            name="nTypeID"
                            rules={[
                                { required: true, message: BoxMsg.Desc_Required_Field + "ศูนย์เรียนรู้" },
                            ]}
                        >
                            <AntdSelect
                                disabled={!isEdit}
                                allowClear={true}
                                //mode="multiple"
                                showSearch={true}
                                style={{ width: "100%" }}
                                placeholder="- ศูนย์เรียนรู้ -"
                                filterOption={(input, option) =>
                                    option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {

                                    listLearning.map((p) => {
                                        return (
                                            <Option key={"nTypeID_" + p.nTypeID} value={p.nTypeID} >{p.sName}</Option>
                                        )
                                    })
                                }
                            </AntdSelect>
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <b>ภาพปก </b><span className="text-danger ">* </span><span className="text-primary small"> ภาษาไทย </span>

                        <span className="text-muted small">
                            ขนาดภาพที่แนะนำ 1280 x 600 px.
                        </span>
                        <Form.Item
                            name="FileImageTH"
                            rules={[
                                { required: true, message: BoxMsg.Desc_Required_Field_File + "ภาพปก" },
                            ]}
                        >
                            <div id="FileImageTH">
                            <Fileuploader
                                limit="1"
                                setFileList={setFileImageTH}
                                fileList={FileImageTH}
                                onComplete={onUploadFileImageTHSuccess}
                                onRemoveComplete={onRemoveFileImageTHComplete}
                                fileMaxSize="10"
                                readOnly={!isEdit}
                                extensions={Extension.Image}
                            />
                            </div>
                        </Form.Item>
                    </Col>
                    {
                        ModeEN === "EN" ?
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <b>ภาพปก </b><span className="text-danger ">* </span><span className="text-success small"> ภาษาอังกฤษ </span>
                                <span className="text-muted small">
                                    ขนาดภาพที่แนะนำ 1280 x 600 px.
                                </span>
                                <Form.Item
                                    name="FileImageEN"
                                    rules={[
                                        { required: true, message: BoxMsg.Desc_Required_Field_File + "ภาพปก" },
                                    ]}
                                >
                                    <div id="FileImageEN">
                                    <Fileuploader
                                        limit="1"
                                        setFileList={setFileImageEN}
                                        fileList={FileImageEN}
                                        onComplete={onUploadFileImageENSuccess}
                                        onRemoveComplete={onRemoveFileImageENComplete}
                                        fileMaxSize="10"
                                        readOnly={!isEdit}
                                        extensions={Extension.Image}
                                    />
                                    </div>
                                </Form.Item>
                            </Col>
                            : null
                    }
                </Row>
                <br />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <b>คลังภาพ </b><span className="text-primary small"> ภาษาไทย </span>

                        <Fileuploader
                            limit="10"
                            setFileList={setlstFileImageTH}
                            fileList={lstFileImageTH}
                            onComplete={onUploadFileSuccess}
                            onRemoveComplete={onUploadFileSuccess}
                            fileMaxSize="10"
                            readOnly={!isEdit}
                            extensions={Extension.Image}
                        />
                    </Col>
                    {
                        ModeEN === "EN" ?
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <b>คลังภาพ </b><span className="text-success small"> ภาษาอังกฤษ </span>

                                <Fileuploader
                                    limit="10"
                                    setFileList={setlstFileImageEN}
                                    fileList={lstFileImageEN}
                                    onComplete={onUploadFileSuccess}
                                    onRemoveComplete={onUploadFileSuccess}
                                    fileMaxSize="10"
                                    readOnly={!isEdit}
                                    extensions={Extension.Image}
                                />
                            </Col>
                            : null
                    }
                </Row>
                <br />
                <Row gutter={[16, 16]}>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <b>ไฟล์ที่เกี่ยวข้อง  </b><span className="text-primary small"> ภาษาไทย </span>
                        <Fileuploader
                            limit="10"
                            setFileList={setlstFileTH}
                            fileList={lstFileTH}
                            onComplete={onUploadFileSuccess}
                            onRemoveComplete={onUploadFileSuccess}
                            fileMaxSize="10"
                            readOnly={!isEdit}
                            extensions={Extension.PDF}
                        />
                    </Col>
                    {
                        ModeEN === "EN" ?
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <b>ไฟล์ที่เกี่ยวข้อง  </b><span className="text-success small"> ภาษาอังกฤษ </span>
                                <Fileuploader
                                    limit="10"
                                    setFileList={setlstFileEN}
                                    fileList={lstFileEN}
                                    onComplete={onUploadFileSuccess}
                                    onRemoveComplete={onUploadFileSuccess}
                                    fileMaxSize="10"
                                    readOnly={!isEdit}
                                    extensions={Extension.PDF}
                                />

                            </Col>
                            : null
                    }
                </Row>
                <br />

                <br />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <b>สถานะ</b>
                        <Form.Item name="switchStatus" valuePropName="checked">
                            <Switch disabled={!isEdit} defaultChecked={true} checkedChildren="ใช้งาน" unCheckedChildren="ไม่ใช้งาน" style={{width : "100px"}} />
                        </Form.Item>
                    </Col>
                </Row>
                <hr />
                <Row gutter={[8, 16]} justify="center">
                    {isEdit ?
                        <React.Fragment>
                            <Col>
                                <ButtonSave disabled={!isEdit} htmlType="submit" />
                            </Col>
                            {
                                sEventId === undefined ?
                                    null
                                    :
                                    <Col>
                                        <ButtonDelete onClick={() => { onDelete() }} />
                                    </Col>
                            }

                        </React.Fragment>
                        : null}
                    <Col>
                        <Button_Cancel onClick={() => { history.push("/admin-event-list") }} />
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    );
};
export default Admin_Map_List;
