import * as React from "react";
const $ = window.jQuery;
export const Extension = {
    Image: ['jpg', 'jpeg', 'png', 'gif'],
    Video: ['mov', 'wmv', 'avi', 'mp4'],
    PDF: ['pdf'],
    Document: ['doc', 'docx', 'xls', 'xlsx', 'txt'],
    Word: ["doc", "docx"],
    Excel: ["xls", "xlsx"],
    Powpoint: ["pptx", "pdf", "ppt"],
    txt: ["txt"],
    //Email: ["msg"],
    Other: ['rar', 'zip'],
    CustomGood_Receive: ['doc', 'docx', 'xls', 'xlsx', 'pdf', 'txt', 'jpg', 'jpeg', 'png', 'gif'],
    CustomFile: ['doc', 'docx', 'pdf', 'ppt'],
    AllType: null
};
class Fileuploader extends React.Component {
    constructor(props) {
        super(props);
        //const sPath = process.env.REACT_APP_API_URL + "UploadFileToTemp";
        const sPath = (process.env.REACT_APP_URL || "") + "api/UploadFileToTemp";

        this.state = {
            name: "files",
            options: {
                upload: {
                    url: sPath,
                }
            }
        };
        this.state.options['upload'] = {
            url: sPath,
            beforeSend: function (item, listEl, parentEl, newInputEl, inputEl) {
                return true;
            },
        }
        this.state.lst = [];
        this.state.options['editor'] = true;
        let sFileType = "";
        this.state.sextensions = "";
        this.state.maxSize = 1024;
        this.state.extensions = this.props.extensions;
        if (this.props.extensions == null) {
            let arrExt = [];
            $.each(Extension, function () {
                for (var key in this) {
                    if (key !== 'GetAll') arrExt = arrExt.concat(this[key]);
                }
            });
            this.state.extensions = arrExt;
        }
        $.each(this.state.extensions, function () {
            sFileType += " /. " + this;
        });
        this.state.sextensions = sFileType;
    }
    SetUploadFile() {
        let onComplete = this.props.onComplete;
        let onRemoveComplete = this.props.onRemoveComplete;

        this.$el = $(this.el);
        //let arrFile = this.state.lst;
        //const sPath = "/api/UploadFileToTemp";
        const sPath = (process.env.REACT_APP_URL || "") + "api" + "/UploadFileToTemp";
        let fileList = this.props.fileList;
        var ThisIsRefProp = this
        let thiAPI = [];
        this.$el.fileuploader($.extend({
            enableApi: true,
            limit: this.props.limit,
            clipboardPaste: false,
            maxSize: this.props.maxSize,
            fileMaxSize: this.props.fileMaxSize,
            extensions: this.state.extensions,
            files: null,
            changeInput: //'<div class="input-group fileuploader-input">' +
                //'<div class="fileuploader-input-caption form-control">${captions.feedback}</div>' +
                '<div class="form-row  fileuploader-input">' +
                '<div class="col">' +
                '<label class="ant-input" style="color: rgb(197,191,191);">${captions.feedback}</label>' +
                '</div>' +
                '<div class="col-auto fileuploader-button">' +
                '<button type="button" class="ant-btn ant-btn-round" style="background: rgb(128, 128, 128); border: none; color: rgb(255, 255, 255);"><i class="fa fa-paperclip"></i> ${captions.button}</button>' +
                '</div>' +
                '</div>' +
                //'<div class="ant-form-item-control-input" style="width: inherit;">' +
                ////'<div class="ant-form-item-control-input-content">' +
                //'<label class="ant-input">${captions.feedback}</label>' +
                //'<div class="input-group-append  fileuploader-button">' +
                //'<button type="button" class="ant-btn ant-btn-round" style="background: rgb(128, 128, 128); border: none; color: rgb(255, 255, 255);"><i class="fa fa-paperclip"></i> ${captions.button}</button>' +
                //'</div>' +
                //'</div>' +
                '<div class="dvValidate text-muted small">' +
                'ไฟล์ที่อนุญาต : ' + this.props.extensions.join(", ") + ' ขนาดสูงสุด ' + this.props.fileMaxSize + ' MB' +
                '</div>',
            thumbnails: {
                item: '<li class="fileuploader-item">' +
                    '<div class="columns">' +
                    '<div class="column-thumbnail" data-file="${name}">${image}<span class="fileuploader-action-popup"></span></div>' +
                    '<div class="column-title" data-file="${name}">' +
                    '<div title="${name}">${name}</div>' +
                    '</div>' +
                    '<div class="column-actions">' +
                    '<a href="${url}" class="mr-1" title="${captions.download}" download><button type="button" class="ant-btn ant-btn-circle" style="background: rgb(18, 117, 220); border: none; color: rgb(255, 255, 255);"><i class="fileuploader-icon-download"></i></button></a>' +
                    '<button type="button" class="ant-btn ant-btn-primary ant-btn-circle ant-btn-dangerous btn-remove" title="${captions.remove}"><i class="fa fa-remove"></i></button>' +
                    '</div>' +
                    '</div>' +
                    '<div class="progress-bar2">${progressBar}<span></span></div>' +
                    '</li>',
                item2: '<li class="fileuploader-item">' +
                    '<div class="columns">' +
                    '<div class="column-thumbnail" data-file="${name}">${image}<span class="fileuploader-action-popup"></span></div>' +
                    '<div class="column-title" data-file="${name}">' +
                    '<a href="${file}" target="_blank">' +
                    '<div title="${name}">${name}</div>' +
                    '</a>' +
                    '</div>' +
                    '<div class="column-actions">' +
                    '<a href="${data.url}" class="mr-1" title="${captions.download}" download><button type="button" class="ant-btn ant-btn-circle" style="background: rgb(18, 117, 220); border: none; color: rgb(255, 255, 255);"><i class="fileuploader-icon-download"></i></button></a>' +
                    '<button type="button" class="ant-btn ant-btn-primary ant-btn-circle ant-btn-dangerous btn-remove" title="${captions.remove}"><i class="fa fa-remove"></i></button>' +
                    '</div>' +
                    '</div>' +
                    '</li>',
                popup: {
                    template: function (data) {
                        if (data.data.url) { data.url = data.data.url; }
                        return '<div class="fileuploader-popup-preview">' +
                            '<button type="button" class="fileuploader-popup-move" data-action="prev"><i class="fileuploader-icon-arrow-left"></i></button>' +
                            '<div class="fileuploader-popup-node node-${format}"> ${reader.node}</div>' +
                            '<div class="fileuploader-popup-content">' +
                            '<div class="fileuploader-popup-footer" style="padding: 1rem;">' +
                            '<ul class="fileuploader-popup-tools">' +
                            (data.format == 'image' && data.reader.node && data.editor ? (data.editor.cropper ? '<li>' +
                                '<button type="button" data-action="crop">' +
                                '<i class="fileuploader-icon-crop"></i> ${captions.crop}' +
                                '</button>' +
                                '</li>' : '') +
                                (data.editor.rotate ? '<li>' +
                                    '<button type="button" data-action="rotate-cw">' +
                                    '<i class="fileuploader-icon-rotate"></i> ${captions.rotate}' +
                                    '</button>' +
                                    '</li>' : '') : ''
                            ) +
                            (data.url ? '<li>' +
                            '<a href="' + data.url + '" class="mr-1" title="Download" download=""><button type="button" class="ant-btn ant-btn-circle" style="background: rgb(18, 117, 220); border: none; color: rgb(255, 255, 255);"><i class="fileuploader-icon-download"></i></button></a>' +
                                '</li>' : ''
                            ) +
                            '<li style="margin-left: unset !important;">' +
                            '</li>' +
                            '</ul>' +
                            '</div>' +
                            '<div class="fileuploader-popup-header">' +
                            '<ul class="fileuploader-popup-meta">' +
                            '<li>' +
                            '<span>${captions.name}:</span>' +
                            '<h5>${name}</h5>' +
                            '</li>' +
                            '<li>' +
                            '<span>${captions.type}:</span>' +
                            '<h5>${extension.toUpperCase()}</h5>' +
                            '</li>' +
                            '<li>' +
                            (data.reader && data.reader.duration ? '<li>' +
                                '<span>${captions.duration}:</span>' +
                                '<h5>${reader.duration2}</h5>' +
                                '</li>' : ''
                            ) +
                            '</ul>' +
                            '<div class="fileuploader-popup-info"></div>' +
                            '<ul class="fileuploader-popup-buttons">' +
                            '<li><button type="button" class="fileuploader-popup-button" data-action="cancel">${captions.cancel}</a></li>' +
                            (data.editor ? '<li><button type="button" class="fileuploader-popup-button button-success" data-action="save">${captions.confirm}</button></li>' : '') +
                            '</ul>' +
                            '</div>' +
                            '</div>' +
                            '<button type="button" class="fileuploader-popup-move" data-action="next"><i class="fileuploader-icon-arrow-right"></i></button>' +
                            '</div>';
                    }
                },
                _selectors: {
                    // list: '.fileuploader-items-list',
                    // item: '.fileuploader-item',
                    // start: '.fileuploader-action-start',
                    // retry: '.fileuploader-action-retry',
                    // remove: '.btn-remove',
                    // sorter: '.fileuploader-action-sort',
                    // rotate: '.fileuploader-action-rotate',
                    // popup: '.fileuploader-popup-preview',
                    popup_open: (Extension.Image.map(function (s) { return '.column-thumbnail[data-file$=".' + s + '"]'; })) + ',' +
                        (Extension.Image.map(function (s) { return '.column-title[data-file$=".' + s + '"]'; }))
                },
            },
            captions: {
                button: function (options) { return 'เลือกไฟล์' },
                feedback: function (options) { return 'คลิกเพี่อแนบไฟล์...'; },
                feedback2: function (options) { return 'Attachment' + ' ' + options.length + ' ' + 'list'; },

                confirm: 'Confirm',
                cancel: 'Cancel',
                name: 'Name',
                type: 'Type',
                size: 'Size',
                dimensions: 'Dimensions',
                duration: 'Duration',
                crop: 'Crop',
                rotate: 'Rotate',
                sort: 'Sort',
                open: 'Open',
                download: 'Download',
                remove: 'Remove',
                drop: 'Drop the files here to upload',
                paste: '<div class="fileuploader-pending-loader"></div> Pasting a file, click here to cancel',

                removeConfirmation: 'ต้องการลบไฟล์ที่เลือกหรือไม่ ?',
                errors: {
                    filesLimit: function (options) {
                        return 'สามารถอัพโหลดได้สูงสุด ${limit} ' + ' ไฟล์.'
                    },
                    filesType: 'ไฟล์นามสกุล ${extensions} เท่านั้นที่สามารถอัพโหลดได้.',
                    fileSize: '${name} มีขนาดใหญ่เกินที่กำหนด ${fileMaxSize} MB.',
                    filesSizeAll: 'ไฟล์ทั้งหมดที่เลือกขนาดใหญ่เกินที่กำหนด ${maxSize} MB.',
                    fileName: 'ไฟล์ ${name} ได้ถูกเลือกก่อนหน้าแล้ว.',
                    remoteFile: 'ไม่สามารถลบไฟล์.',
                    folderUpload: 'ไม่อนุญาตให้อัพโหลดไฟล์.',
                    // filesLimit: function (options) {
                    //     return 'Only ${limit} ' + (options.limit == 1 ? 'file' : 'files') + ' can be uploaded.'
                    // },
                    // filesType: 'Only ${extensions} files are allowed to be uploaded.',
                    // fileSize: '${name} is too large! Please choose a file up to ${fileMaxSize} MB.',
                    // filesSizeAll: 'The chosen files are too large! Please select files up to ${maxSize} MB.',
                    // fileName: 'A file with the same name ${name} is already selected.',
                    // remoteFile: 'Remote files are not allowed.',
                    // folderUpload: 'Folders are not allowed.',
                }
            },
            upload: {
                url: sPath,
                beforeSend: function (item, listEl, parentEl, newInputEl, inputEl) {
                    return true;
                },
                onSuccess: function (data, item, listEl, parentEl, newInputEl, inputEl, textStatus, jqXHR) {
                    item.html.find('.fileuploader-action-remove').addClass('fileuploader-action-success');

                    setTimeout(function () {
                        item.html.find('.progress-bar2').fadeOut(400);
                    }, 400);
                    data.sSize = item.size + "";
                    let nID = 1;
                    let lstFile = ThisIsRefProp.props.fileList;

                    if (lstFile.length > 0) {
                        nID = lstFile.reduce(
                            (max, lstFile) => (lstFile.nID > max ? lstFile.nID : max),
                            lstFile[0].nID
                        );
                        data.nID = nID;
                    }
                    item.url = process.env.REACT_APP_URL + data.sSaveToPath; // + '/' + data.sSaveToFileName;
                    item.IsCompleted = data.IsCompleted;
                    item.IsDelete = false
                    item.IsNewFile = data.IsNewFile;
                    item.nID = data.nID;
                    item.sFileName = data.sFileName;
                    item.sFileType = data.sFileType;
                    item.sMsg = data.sMsg;
                    item.sSaveToFileName = data.sSaveToFileName;
                    item.sSaveToPath = data.sSaveToPath;
                    item.sSize = data.sSize;
                    item.sUrl = data.url;
                    ThisIsRefProp.props.fileList.push(data);
                },
                onComplete: function (listEl, parentEl, newInputEl, inputEl, jqXHR, textStatus) {

                    var arrTempFile = thiAPI.getUploadedFiles();
                    arrTempFile.forEach(data => {
                        var item = {};
                        if (data.IsCompleted) {
                            for (var x = 0; x < ThisIsRefProp.props.fileList.length; x++) {
                                if (ThisIsRefProp.props.fileList[x].sSaveToFileName === data.sSaveToFileName) {
                                    ThisIsRefProp.props.fileList.splice(x, 1);
                                    thiAPI.remove(item);
                                }
                            }
                            item.IsCompleted = false;
                            item.IsDelete = false
                            item.IsNewFile = data.IsNewFile;
                            item.nID = data.nID;
                            item.sFileName = data.sFileName;
                            item.sFileType = data.sFileType;
                            item.sMsg = data.sMsg;
                            item.sSaveToFileName = data.sSaveToFileName;
                            item.sSaveToPath = data.sSaveToPath;
                            item.sSize = data.sSize;
                            item.sUrl = data.url;
                            ThisIsRefProp.props.fileList.push(item)
                        }
                    });
                    if (onComplete) onComplete()
                },
                onError: function (item, listEl, parentEl, newInputEl, inputEl, jqXHR, textStatus, errorThrown) {
                    var progressBar = item.html.find('.progress-bar2');

                    if (progressBar.length > 0) {
                        progressBar.find('span').html(0 + "%");
                        progressBar.find('.fileuploader-progressbar .bar').width(0 + "%");
                        item.html.find('.progress-bar2').fadeOut(400);
                    }
                    if (item.upload.status !== 'cancelled' && item.html.find('.fileuploader-action-retry').length === 0) {
                        item.html.find('.column-actions').prepend('<button class="btn btn-sm btn-warning fileuploader-action-retry" type="button"  data-toggle="tooltip" title="Retry" style="margin-right:6px;"><i class="fa fa-redo"></i></button>');
                    }
                },
                onProgress: function (data, item, listEl, parentEl, newInputEl, inputEl) {
                    var progressBar = item.html.find('.progress-bar2');

                    if (progressBar.length > 0) {
                        progressBar.show();
                        progressBar.find('span').html(data.percentage + "%");
                        progressBar.find('.fileuploader-progressbar .bar').width(data.percentage + "%");
                    }
                },
            },
            onRemove: function (item, listEl, parentEl, newInputEl, inputEl) {
                for (var x = 0; x < ThisIsRefProp.props.fileList.length; x++) {
                    //index = x;
                    if (ThisIsRefProp.props.fileList[x].sSaveToFileName === item.sSaveToFileName && ThisIsRefProp.props.fileList[x].nID === item.nID) {
                        ThisIsRefProp.props.fileList.splice(x, 1);
                        thiAPI.remove(item);
                    }
                }
                if (onRemoveComplete) onRemoveComplete()
            },
            skipFileNameCheck: true,
        }, {}));

        this.api = $.fileuploader.getInstance(this.$el);
        this.API_Obj = this.api;
        thiAPI = this.API_Obj;
        fileList.forEach(e => {
            console.log("fileList", fileList)
            if (e.sSaveToFileName !== "") {
                let arrfilename = (e.sSaveToFileName + "").split('.');
                e.sFileType = arrfilename[arrfilename.length - 1];

                let format = "application"
                let sType = format;
                if (Extension.Image.indexOf(e.sFileType) > -1) {
                    format = "image";
                }
                if (Extension.Video.indexOf(e.sFileType) > -1) {
                    format = 'video';
                }
                sType = format + "/" + e.sFileType;
                e.format = format;
                e.sFileType = sType;
                e.name = e.sFileName
                //e.file = e.sSaveToPath;// + e.sSaveToFileName
                e.file = process.env.REACT_APP_URL + e.sSaveToPath; // + e.sSaveToFileName
                e.type = e.sFileType
                e.size = e.sSize
                //e.url = e.sSaveToPath;// + e.sSaveToFileName;
                e.url = process.env.REACT_APP_URL + e.sSaveToPath; // + e.sSaveToFileName;
                e.sUrl = e.url;
                this.api.append(e)
            }
        })
        if (this.props.readOnly) {
            this.api.disable()
            this.api.getListEl().find(".btn-remove").hide()
            this.api.getParentEl().find("div[class=fileuploader-input]").hide()
            this.api.getParentEl().find("div[class*=dvValidate]").hide()
        } else {
            this.api.enable()
        }
    }
    componentDidMount() {
        this.SetUploadFile()
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.fileList !== nextProps.fileList) {
            this.api.reset();
            nextProps.fileList.forEach(e => {

                if (e.sSaveToFileName !== "") {
                    let arrfilename = (e.sSaveToFileName + "").split('.');
                    e.sFileType = arrfilename[arrfilename.length - 1];

                    let format = "application"
                    let sType = format;
                    if (Extension.Image.indexOf(e.sFileType) > -1) {
                        format = "image";
                    }
                    if (Extension.Video.indexOf(e.sFileType) > -1) {
                        format = 'video';
                    }
                    sType = format + "/" + e.sFileType;
                    e.format = format;
                    e.sFileType = sType;
                    e.name = e.sFileName
                    //e.file = e.sSaveToPath;// + e.sSaveToFileName
                    e.file = process.env.REACT_APP_URL + e.sSaveToPath;
                    e.type = e.sFileType
                    e.size = e.sSize
                    //e.url = e.sSaveToPath;// + e.sSaveToFileName;
                    e.url = process.env.REACT_APP_URL + e.sSaveToPath;
                    e.sUrl = e.url;
                    this.api.append(e)
                }
            })
            if (this.props.readOnly) {
                this.api.disable()
                this.api.getListEl().find(".btn-remove").hide()
                this.api.getParentEl().find("div[class=fileuploader-input]").hide()
                this.api.getParentEl().find("div[class*=dvValidate]").hide()
            } else {
                this.api.enable()
                this.api.getListEl().find(".btn-remove").show()
                this.api.getParentEl().find("div[class=fileuploader-input]").show()
                this.api.getParentEl().find("div[class*=fileuploader-button]").show()
                this.api.getParentEl().find("div[class*=dvValidate]").show()
            }
        }
    }

    componentWillUnmount() {
        if (this.api)
            this.api.destroy();
    }

    render() {

        return (< div > < input type="file"
            name={
                this.state.name
            }
            ref={
                el => this.el = el
            }
        /></div >
        )
    }
}

export default Fileuploader;