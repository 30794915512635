import { Checkbox, Col, Form, Input, Radio, Row, Switch } from "antd";
import { parse } from "query-string";
import * as React from "react";
import { useHistory } from "react-router";
import { ButtonBack, ButtonDelete, ButtonSave, Button_Cancel } from "../Systems/button_all";
import { BoxMsg, Decrypt, DialogConfirm, DialogDelete, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import Fileuploader, { Extension } from "../../Fileuploader";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import ThaiDatePicker from "../ST_Handlers/ThaiDatePicker";
import BlockUi from "react-block-ui";
import ReactPlayer from "react-player";
import AuthenService from "../Service/AuthenService";

const Controller = 'api/AdminBanner/';

const Admin_Banner_Edit = () => {
    var { sSlideID } = parse(window.location.search);
    sSlideID = sSlideID && Decrypt(sSlideID)
    const history = useHistory();
    const [V_BannerSlide, setV_BannerSlide] = React.useState({} as any);
    const [T_Menu, setT_Menu] = React.useState([] as Array<any>);
    const [CheckboxDate, setCheckboxDate] = React.useState(false as boolean);
    const [ModeEN, setModeEN] = React.useState("TH" as string);
    const [AddType, setAddType] = React.useState("1" as string);
    const [AddUrl, setAddUrl] = React.useState("1" as string);
    const [txtSearch, SetTxtSearch] = React.useState({
        dSearchStartDate: undefined as (Date | undefined),
        dSearchEndDate: undefined as (Date | undefined),

    });
    const [SetValue] = Form.useForm();
    const [lstFileImageTH, setlstFileImageTH] = React.useState([] as any);
    const [lstFileImageEN, setlstFileImageEN] = React.useState([] as any);
    const [lstFileVDOTH, setlstFileVDOTH] = React.useState([] as any);
    const [lstFileVDOEN, setlstFileVDOEN] = React.useState([] as any);
    const [st_ActiveBlock, setActiveBlock] = React.useState(true);

    const [isEdit, setisEdit] = React.useState(false);


    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            let test = UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == 2).map(t => t.nPermission)[0] === 2 ? false : true

            setisEdit(test)
        }
    }
    const DateFormat = (today: any) => {
        let day = today.getDate() < 10 ? ("0" + today.getDate().toString()) : today.getDate().toString();
        let month = (today.getMonth() + 1) < 10 ? ("0" + (today.getMonth() + 1).toString()) : (today.getMonth() + 1).toString();
        let date = day + "/" + month + "/" + today.getFullYear();

        return date;
    }

    const GetDataOnPageLoad = async () => {
        let result: any = await AxiosGetJson(Controller + "GetV_BannerSlidebysSlideID?sSlideID=" + (sSlideID === undefined ? "0" : sSlideID));
        setV_BannerSlide(result.V_BannerSlide);
        setModeEN(result.V_BannerSlide.cLanguage)
        setCheckboxDate(sSlideID === undefined ? false : (result.V_BannerSlide.dShowEnd == null ? true : false));
        SetValue.setFieldsValue({
            sNameTH: result.V_BannerSlide.sNameTH,
            sNameEN: result.V_BannerSlide.sNameEN,
            switchStatus: result.V_BannerSlide.isActive,
            lstFileImageTH: result.listFileImageTH,
            lstFileImageEN: result.listFileImageEN,
            lstFileVDOTH: result.listFileVDOTH,
            lstFileVDOEN: result.listFileVDOEN,
            dShowStart: result.V_BannerSlide.dShowStart == null ? undefined : new Date(result.V_BannerSlide.dShowStart),
            dShowEnd: result.V_BannerSlide.dShowEnd == null ? undefined : new Date(result.V_BannerSlide.dShowEnd),
            sUrlEN: result.V_BannerSlide.sUrlEN,
            sUrlTH: result.V_BannerSlide.sUrlTH,
            sUrl: result.V_BannerSlide.sUrl,
        })
        setlstFileImageTH(result.listFileImageTH);
        setlstFileImageEN(result.listFileImageEN);
        setlstFileVDOTH(result.listFileVDOTH);
        setlstFileVDOEN(result.listFileVDOEN);
        setAddType(sSlideID === undefined ? "1" : result.listFileImageTH.length > 0 ? "1" : "2");
        setAddUrl(sSlideID === undefined ? "1" : result.V_BannerSlide.sUrlTH !== null && result.V_BannerSlide.sUrlTH !== "" ? "2" : "1");

        setActiveBlock(false)
    }

    const GetT_Menu = async () => {
        let result: any = await AxiosGetJson(Controller + "GetT_Menu");
        setT_Menu(result);
    }


    const onSave = (values: any) => {

        V_BannerSlide.sNameEN = values.sNameEN;
        V_BannerSlide.sNameTH = values.sNameTH;
        V_BannerSlide.sUrlEN = values.sUrlEN;
        V_BannerSlide.sUrlTH = values.sUrlTH;
        V_BannerSlide.sUrl = values.sUrl;
        V_BannerSlide.isActive = values.switchStatus;
        V_BannerSlide.sShowStart = values.dShowStart == undefined ? "" : DateFormat(values.dShowStart);
        V_BannerSlide.sShowEnd = values.dShowEnd == undefined ? "" : DateFormat(values.dShowEnd);
        V_BannerSlide.cLanguage = ModeEN;
        let data = {
            V_BannerSlide: V_BannerSlide,
            listFileImageTH: lstFileImageTH,
            listFileImageEN: lstFileImageEN,
            listFileVDOTH: lstFileVDOTH,
            listFileVDOEN: lstFileVDOEN,
        }

        DialogConfirm(async () => {
            let result: any = await AxiosPostJson(Controller + "SaveData", data)
            if (result.data.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Desc_Success_Save, BoxMsg.Desc_Success_Save, () => {
                    onBack();
                });
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }


        }, null, BoxMsg.Title_Confirm, BoxMsg.Desc_Confirm_Save)

    };
    const onBack = () => {
        history.push({
            pathname: "/admin-banner-list",
        });
    };


    function onChange(e) {

        SetValue.resetFields(["dShowEnd"]);
        setCheckboxDate(e.target.checked)
        SetValue.setFieldsValue({
            dShowEnd: undefined
        })
    }
    const ValidateDisabledField = (value: any) => {
        setModeEN(value.target.value);
    };
    const ValidateAddType = (value: any) => {
        setAddType(value.target.value);
        setlstFileImageTH([]);
        setlstFileImageEN([]);
        setlstFileVDOTH([]);
        setlstFileVDOEN([]);
        SetValue.setFieldsValue({
            sUrlTH: "",
            sUrlEN: "",
            lstFileImageTH: "",
            lstFileImageEN: "",
            lstFileVDOTH: "",
            lstFileVDOEN: "",
        })
    };
    const ValidateAddUrl = (value: any) => {
        setAddUrl(value.target.value);
        setlstFileImageTH([]);
        setlstFileImageEN([]);
        setlstFileVDOTH([]);
        setlstFileVDOEN([]);
        SetValue.setFieldsValue({
            sUrlTH: "",
            sUrlEN: "",
            lstFileImageTH: "",
            lstFileImageEN: "",
            lstFileVDOTH: "",
            lstFileVDOEN: "",
        })
    };



    const onUploadFileSuccesslstFileImageTH = async () => {

        SetValue.validateFields(["lstFileImageTH"]);
        SetValue.setFieldsValue({
            lstFileImageTH: "lstFileImageTH"
        })
    };

    const onRemoveCompletelstFileImageTH = async () => {

        SetValue.validateFields(["lstFileImageTH"]);
        SetValue.setFieldsValue({
            lstFileImageTH: ""
        })
    };
    const onUploadFileSuccesslstFileImageEN = async () => {

        SetValue.validateFields(["lstFileImageEN"]);
        SetValue.setFieldsValue({
            lstFileImageEN: "lstFileImageEN"
        })
    };

    const onRemoveCompletelstFileImageEN = async () => {

        SetValue.validateFields(["lstFileImageEN"]);
        SetValue.setFieldsValue({
            lstFileImageEN: ""
        })
    };

    const onUploadFileSucceslstFileVDOTH = async () => {

        SetValue.validateFields(["lstFileVDOTH"]);
        SetValue.setFieldsValue({
            lstFileVDOTH: "lstFileVDOTH"
        })
    };

    const onRemoveCompletelstFileVDOTH = async () => {

        SetValue.validateFields(["lstFileVDOTH"]);
        SetValue.setFieldsValue({
            lstFileVDOTH: ""
        })
    };
    const onUploadFileSucceslstFileVDOEN = async () => {

        SetValue.validateFields(["lstFileVDOEN"]);
        SetValue.setFieldsValue({
            lstFileVDOEN: "lstFileVDOEN"
        })
    };

    const onRemoveCompletelstFileVDOEN = async () => {

        SetValue.validateFields(["lstFileVDOEN"]);
        SetValue.setFieldsValue({
            lstFileVDOEN: ""
        })
    };


    const onDelete = async () => {

        if (sSlideID != undefined) {

            DialogDelete(async () => {
                let result: any = await AxiosPostJson(Controller + "onDeleteBysSlideID?sSlideID=" + (sSlideID === undefined ? "0" : sSlideID));
                if (result.data.sStatus === Responsestart.success) {
                    Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete, null);
                    onBack();
                } else if (result.data.sStatus === Responsestart.warning) {
                    Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                } else {
                    Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                }
            });
        }

    };


    React.useEffect(() => {
        GetUserInfo();
    }, []);

    React.useEffect(() => {
        GetT_Menu();
        GetDataOnPageLoad();

    }, [isEdit]);

    console.log(ModeEN,"ModeEN")
    return (
        <React.Fragment>
            <BlockUi blocking={st_ActiveBlock}></BlockUi>
            <Form onFinish={onSave}
                form={SetValue}
            >
                <br />

                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                        <b> มีเนื้อหาภาษาอังกฤษหรือไม่ </b>
                        <br />
                        <Radio.Group disabled={!isEdit} onChange={ValidateDisabledField} value={ModeEN}>
                            <Radio value="EN">ใช่</Radio>
                            <Radio value="TH">ไม่ใช่</Radio>
                        </Radio.Group>
                    </Col>

                </Row>
                <br />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <b>ชื่อแบนเนอร์</b> <span className="text-danger ">*</span><span className="text-primary small"> ภาษาไทย </span>
                        <Form.Item
                            name="sNameTH"
                            rules={[
                                { required: true, message: BoxMsg.Desc_Required_Field + "ชื่อแบนเนอร์" },
                            ]}
                        >
                            <Input disabled={!isEdit} autoComplete="off" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={9} lg={12} xl={12}>
                        <b> ลิงก์ข้อมูล </b>
                        <span className="text-muted small">
                            ตัวอย่าง http://example.com
                        </span>
                        <Form.Item
                            name="sUrl"
                        >
                            <Input disabled={!isEdit} type="url" autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col xl={12} md={12} xs={24}>
                        <Row gutter={[8, 8]} align="middle" >
                            <Col xl={9} md={9} xs={24}>
                                <b> วันที่ประกาศ </b><span className="text-danger ">*</span>
                                <Form.Item
                                    name="dShowStart"
                                    rules={[
                                        { required: true, message: BoxMsg.Desc_Required_Field + "วันที่เริ่มต้น" },
                                    ]}
                                >
                                    <ThaiDatePicker
                                        disabled={!isEdit}
                                        value={txtSearch.dSearchStartDate}
                                        maxDate={txtSearch.dSearchEndDate}
                                        small={false}
                                        placeholder="วันที่เริ่มต้น"
                                        onChange={(value) => {
                                            SetTxtSearch({
                                                ...txtSearch,
                                                dSearchStartDate: value,
                                            });

                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xl={9} md={9} xs={24} >
                                <br />
                                <Form.Item
                                    name="dShowEnd"
                                    rules={[
                                        { required: !CheckboxDate, message: BoxMsg.Desc_Required_Field + "วันที่สิ้นสุด" },
                                    ]}
                                >
                                    <ThaiDatePicker
                                        //disabled={!isEdit}
                                        value={txtSearch.dSearchEndDate}
                                        minDate={txtSearch.dSearchStartDate}
                                        small={false}
                                        disabled={isEdit ? CheckboxDate : true}
                                        placeholder="วันที่สิ้นสุด"
                                        onChange={(value) => {
                                            SetTxtSearch({
                                                ...txtSearch,
                                                dSearchEndDate: value,
                                            });
                                        }} />
                                </Form.Item>
                            </Col>
                            <Col xl={6} md={6} xs={24}>
                                &nbsp;
                                <Checkbox disabled={!isEdit} checked={CheckboxDate} onChange={onChange} >ไม่มีกำหนด</Checkbox>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                        <b> ประเภท </b>
                        <br />
                        <Radio.Group disabled={!isEdit} onChange={ValidateAddType} value={AddType}>
                            <Radio value="1">ภาพ</Radio>
                            <Radio value="2">วิดีทัศน์</Radio>
                        </Radio.Group>
                    </Col>

                </Row>
                <br />
                {
                    AddType === "1" ?
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <b>แนบภาพ </b><span className="text-danger ">* </span><span className="text-primary small"> ภาษาไทย </span>
                                <span className="text-muted small">
                                    ขนาดภาพที่แนะนำ 1920 x 1080 px.
                                </span>
                                <Form.Item
                                    name="lstFileImageTH"
                                    rules={[
                                        { required: true, message: BoxMsg.Desc_Required_Field_File + "ภาพ" },
                                    ]}
                                >
                                    <div id="lstFileImageTH">
                                        <Fileuploader
                                        limit="1"
                                        setFileList={setlstFileImageTH}
                                        fileList={lstFileImageTH}
                                        onComplete={onUploadFileSuccesslstFileImageTH}
                                        onRemoveComplete={onRemoveCompletelstFileImageTH}
                                        fileMaxSize="10"
                                        readOnly={!isEdit}
                                        extensions={Extension.Image}
                                    />
                                    </div>
                                    
                                </Form.Item>
                            </Col>
                            {
                                ModeEN === "EN" ?
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <b>แนบภาพ </b><span className="text-danger "> * </span><span className="text-success small"> ภาษาอังกฤษ </span>
                                        <span className="text-muted small">
                                            ขนาดภาพที่แนะนำ 1920 x 1080 px.
                                        </span>
                                        <Form.Item
                                            name="lstFileImageEN"
                                            rules={[
                                                { required: true, message: BoxMsg.Desc_Required_Field_File + "ภาพ" },
                                            ]}
                                        >
                                            <div id="lstFileImageEN">
                                            <Fileuploader
                                                limit="1"
                                                setFileList={setlstFileImageEN}
                                                fileList={lstFileImageEN}
                                                onComplete={onUploadFileSuccesslstFileImageEN}
                                                onRemoveComplete={onRemoveCompletelstFileImageEN}
                                                fileMaxSize="10"
                                                readOnly={!isEdit}
                                                extensions={Extension.Image}
                                            />
                                            </div>
                                            
                                        </Form.Item>
                                    </Col>

                                    : null
                            }
                        </Row>
                        :

                        null
                }

                {
                    AddType === "2" ?
                        <>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                    <b> แนบวิดีทัศน์ </b>
                                    <br />
                                    <Radio.Group disabled={!isEdit} onChange={ValidateAddUrl} value={AddUrl}>
                                        <Radio value="1">ไฟล์วิดีทัศน์</Radio>
                                        <Radio value="2">Link/URL</Radio>
                                    </Radio.Group>
                                </Col>

                            </Row>
                            <br />
                            <Row gutter={[16, 16]}>
                                {
                                    AddUrl === "1" ?
                                        <>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <b>แนบวิดีทัศน์ </b><span className="text-danger ">* </span><span className="text-primary small"> ภาษาไทย </span>
                                                <Form.Item
                                                    name="lstFileVDOTH"
                                                    rules={[
                                                        { required: true, message: BoxMsg.Desc_Required_Field_File + "วิดีทัศน์" },
                                                    ]}
                                                >
                                                    <div id="lstFileVDOTH">
                                                    <Fileuploader
                                                        limit="1"
                                                        setFileList={setlstFileVDOTH}
                                                        fileList={lstFileVDOTH}
                                                        onComplete={onUploadFileSucceslstFileVDOTH}
                                                        onRemoveComplete={onRemoveCompletelstFileVDOTH}
                                                        fileMaxSize="500"
                                                        readOnly={!isEdit}
                                                        extensions={Extension.Video}
                                                    />
                                                    </div>
                                                    
                                                </Form.Item>
                                            </Col>
                                            {
                                                ModeEN === "EN" ?
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <b>แนบวิดีทัศน์ </b><span className="text-danger "> * </span><span className="text-success small"> ภาษาอังกฤษ </span>
                                                        <Form.Item
                                                            name="lstFileVDOEN"
                                                            rules={[
                                                                { required: true, message: BoxMsg.Desc_Required_Field_File + "วิดีทัศน์" },
                                                            ]}
                                                        >
                                                            <div id="lstFileVDOEN">
                                                            <Fileuploader
                                                                limit="1"
                                                                setFileList={setlstFileVDOEN}
                                                                fileList={lstFileVDOEN}
                                                                onComplete={onUploadFileSucceslstFileVDOEN}
                                                                onRemoveComplete={onRemoveCompletelstFileVDOEN}
                                                                fileMaxSize="500"
                                                                readOnly={!isEdit}
                                                                extensions={Extension.Video}
                                                            />
                                                            </div>
                                                            
                                                        </Form.Item>
                                                    </Col>
                                                    : null
                                            }

                                        </>
                                        :

                                        <>
                                            <Col xs={24} sm={24} md={9} lg={12} xl={12}>
                                                <b> Link/URL</b><span className="text-danger ">* </span><span className="text-primary small"> ภาษาไทย </span>
                                                <span className="text-muted small">
                                                    ตัวอย่าง https://www.youtube.com/watch?v=dsa24ApPuBM
                                                </span>
                                                <Form.Item
                                                    name="sUrlTH"
                                                    rules={[
                                                        { required: true, message: BoxMsg.Desc_Required_Field_File + "Link/URL" },
                                                        { type: "url", message: "ตัวอย่าง https://www.youtube.com/watch?v=dsa24ApPuBM" },
                                                    ]}
                                                >
                                                    <Input disabled={!isEdit} type="url" autoComplete="off" />
                                                </Form.Item>
                                            </Col>

                                            {
                                                ModeEN === "EN" ?
                                                    <Col xs={24} sm={24} md={9} lg={12} xl={12}>
                                                        <b> Link/URL </b><span className="text-danger ">* </span><span className="text-success small"> ภาษาอังกฤษ </span>
                                                        <span className="text-muted small">
                                                            ตัวอย่าง https://www.youtube.com/watch?v=dsa24ApPuBM
                                                        </span>
                                                        <Form.Item
                                                            name="sUrlEN"
                                                            rules={[
                                                                { required: true, message: BoxMsg.Desc_Required_Field_File + "Link/URL" },
                                                                { type: "url", message: "ตัวอย่าง https://www.youtube.com/watch?v=dsa24ApPuBM" },
                                                            ]}
                                                        >
                                                            <Input disabled={!isEdit} type="url" autoComplete="off" />
                                                        </Form.Item>
                                                    </Col>
                                                    : null
                                            }
                                        </>
                                }
                            </Row>
                        </>
                        : null
                }
                <br />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <b>สถานะ</b>
                        <Form.Item name="switchStatus" valuePropName="checked">
                            <Switch
                                disabled={!isEdit}
                                checkedChildren="ใช้งาน"
                                unCheckedChildren="ไม่ใช้งาน" 
                                style={{width : "100px"}}
                                />
                        </Form.Item>
                    </Col>
                </Row>

                <hr />
                <Row gutter={[8, 16]} justify="center">
                    {isEdit ?
                        <React.Fragment>
                            <Col>
                                <ButtonSave htmlType="submit" />
                            </Col>

                            {
                                sSlideID === undefined ?
                                    null
                                    :
                                    <Col>
                                        <ButtonDelete onClick={() => { onDelete() }} />
                                    </Col>
                            }

                        </React.Fragment>
                        : null}
                    <Col>
                        <Button_Cancel onClick={() => { history.push("/admin-banner-list") }} />
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    );
};
export default Admin_Banner_Edit;
