import { Col, Input, Row, Table } from "antd";
import * as React from "react";
import TableAntd from "../Systems/TableAntd";
import { Empty, Select as AntdSelect } from "antd";
import { ButtonExcel, ButtonPDF, ButtonSearch } from "../Systems/button_all";
import { AxiosBlobJson, AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import { setNameFormatReport } from "../Systems/SystemComponent";
import BlockUi from "react-block-ui";

const Controller = 'api/Report/';
const { Option } = AntdSelect;
const Report_Forest_Map_Data = () => {
    const [Stp_Search, setStp_Search] = React.useState([] as any);

    const [Region, setRegion] = React.useState([] as Array<any>);
    const [TH_Province, setTH_Province] = React.useState([] as Array<any>);
    const [listRegion, setlistRegion] = React.useState([] as any);
    const [listnProvince, setlistnProvince] = React.useState([] as any);
    const [Stp_Search2, setStp_Search2] = React.useState([] as any);
    const [st_ActiveBlock, setActiveBlock] = React.useState(true);

    const [txtSearch, SetTxtSearch] = React.useState({
        sRegion: [] as any || undefined,
        sProvinceID: [] as any || undefined,
        sType: "" as string || undefined,
    });


    const [selectedRows, setselectedRows] = React.useState([] as Array<any>);
    const [sPageA, setsPageA] = React.useState(1 as number);
    const [sPageSizeA, setsPageSizeA] = React.useState(10 as number);
    const [sFieldA, setsFieldA] = React.useState("" as string || undefined);
    const [sOrderA, setsOrderA] = React.useState("" as string || undefined);
    const [nTotal, setnTotal] = React.useState(0 as number);
    const [nCurrentPage, setCurrentPage] = React.useState(1 as number);
    const [sortedInfo, setsortedInfo] = React.useState({} as any);

    let months = ['พื้นที่ป่าใหม่(PTT)', 'พื้นที่ป่าเก่า(FPT)', 'พื้นที่ป่าอื่นๆ']
    const dateTobeGenerate = ['จำนวนพื้นที่ป่า']

    const buildData = () => {
        const data = [] as any

        Stp_Search.forEach(d => {
            data.push(d)

        })
        return data
    }


    const buildColumn = () => {
        const columns = [] as any

        columns.push({
            title: 'ภูมิภาค',
            dataIndex: 'sName',
            key: 'sName',
            fixed: 'left',
            align: 'left',
            className: `cell-in-group-name`,
        })
        dateTobeGenerate.forEach((d: string, i: number) => {
            let obj = [] as any
            let num = 1;
            months.forEach(d => {
                let objchildren = {};
                objchildren['title'] = d
                objchildren['dataIndex'] = "sType" + num
                objchildren['key'] = "sType" + num
                objchildren['align'] = 'right'
                obj.push(objchildren)
                num++
            })
            obj.push({
                title: 'ทั้งหมด',
                dataIndex: 'nSummary',
                key: 'nSummary',
                align: 'right',
            })
            columns.push(
                {
                    title: "จำนวนพื้นที่ป่า",
                    children: obj
                }
            )
        })
        return columns
    }



    const header = buildColumn();
    const data = buildData();



    const SearchData = async (sPage, sPageSize, sField, sOrder) => {

        let Page = sPage == undefined ? sPageA : sPage;
        let PageSize = sPageSize == undefined ? sPageSizeA : sPageSize;
        let Field = sField == undefined ? sFieldA : sField;
        let Order = sOrder == undefined ? sOrderA : sOrder;

        setCurrentPage(Page)

        let ModelSearcData = {
            sYear: "",
            sType: txtSearch.sType == undefined ? "" : txtSearch.sType,
            sSocial: "",
            sRegion: txtSearch.sRegion == undefined ? "" : txtSearch.sRegion.map(a => a).toString(),
            sProvince: txtSearch.sProvinceID == undefined ? "" : txtSearch.sProvinceID.map(a => a).toString(),
            sMode: "4",
        }

        let result: any = await AxiosPostJson(Controller + "SearchDataMode4", ModelSearcData);
        setStp_Search(result.data);


        let ModelSearcData2 = {
            sYear: "",
            sType: txtSearch.sType == undefined ? "" : txtSearch.sType,
            sSocial: "",
            sRegion: txtSearch.sRegion == undefined ? "" : txtSearch.sRegion.map(a => a).toString(),
            sProvince: txtSearch.sProvinceID == undefined ? "" : txtSearch.sProvinceID.map(a => a).toString(),
            sMode: "5",
            nPage: Page,
            nPageSize: PageSize,
            sField: Field + "",
            sOrder: Order + "",
            sGenExcel: "0",
        }
        let result2: any = await AxiosPostJson(Controller + "SearchDataMode5", ModelSearcData2);
        setStp_Search2(result2.data.Stp_ReportMode5);
        setnTotal(result2.data.nTotal)
        setActiveBlock(false);


    }


    const GetTH_Province = async () => {
        let result: any = await AxiosGetJson("api/AdminMap/" + "GetTH_Province");
        setTH_Province(result)
        setRegion(groupBy(result.map(m => ({ 'sRegionThai': m.sRegionThai, 'sRegionEng': m.sRegionEng }))));
    }
    const groupBy = (list) => {
        const newArr: any = [];
        list.forEach((o) => {
            const index = newArr.findIndex(x => x.sRegionThai == o.sRegionThai)
            if (index == -1) {
                newArr.push(o);
            }
        });
        return newArr;
    }

    const groupbyarr = (list) => {
        const newArr: any = [];
        list.forEach((o) => {
            const index = newArr.findIndex(x => x == o)
            if (index == -1) {
                newArr.push(o);
            }
        });
        return newArr;
    }
    const onChangeRegion = (data: any) => {

        let listProvince = TH_Province.filter(f => listnProvince.includes(f.nProvinceID) && data.includes(f.sRegionThai)).map(t => t.nProvinceID);

        if (listnProvince.length != 0) {
            txtSearch.sProvinceID = listProvince.length == 0 ? undefined : listProvince
            setlistnProvince(listProvince);
        }
        txtSearch.sRegion = data.length == 0 ? undefined : data
        setlistRegion(data);
    }


    const onChangeProvince = (data: any) => {

        if (listRegion.length == 0) {
            let listRegion = groupbyarr(TH_Province.filter(f => data.includes(f.nProvinceID)).map(t => t.sRegionThai));
            txtSearch.sRegion = listRegion.length == 0 ? undefined : listRegion
            setlistRegion(listRegion);
        }
        txtSearch.sProvinceID = data.length == 0 ? undefined : data
        setlistnProvince(data);
    };


    const buildSummary = (data) => {
        let summary = {} as any
        data.forEach(d => {
            Object.keys(d).filter(k => k !== 'sName').forEach(k => {
                const month = k
                const year = k
                if (summary[year]) {
                    if (summary[year][month]) {
                        summary[year][month] += d[k]
                    } else {
                        summary[year][month] = d[k]
                    }
                } else {
                    summary[year] = { [month]: d[k] }
                }

            })
        })
        return (
            <React.Fragment>
                <Table.Summary.Row >
                    <Table.Summary.Cell index={1} className='ant-total'>รวม</Table.Summary.Cell>
                    {
                        Object.keys(summary).map((y, i) => {
                            return Object.keys(summary[y]).map(m => {
                                return (
                                    <Table.Summary.Cell align='right' index={2} className={`summary-in-group-${(i % 2) + 1}`}>
                                        <div>{summary[y][m].toLocaleString('en-us')}</div>
                                    </Table.Summary.Cell>
                                )
                            })
                        })
                    }
                </Table.Summary.Row>
            </React.Fragment>
        )
    }



    //--------------------------------------------------------------------------------------



    const header2 = [
        {
            title: 'ลำดับที่',
            dataIndex: 'key',
            key: 'key',
            className: "text-center",
            render: (value) => {
                return (<>{value}.</>);
            },
        },
        {
            title: "ภูมิภาค",
            dataIndex: 'sRegionThai',
            key: 'sRegionThai',
            sortOrder: sortedInfo.columnKey === 'sRegionThai' && sortedInfo.order,
            sorter: true,
        },
        {
            title: 'จังหวัด',
            dataIndex: 'sProvinceThai',
            key: "sProvinceThai",
            sortOrder: sortedInfo.columnKey === 'sProvinceThai' && sortedInfo.order,
            sorter: true,
        },
        {
            title: 'อำเภอ',
            dataIndex: 'sDistrictThai',
            key: "sDistrictThai",
            sortOrder: sortedInfo.columnKey === 'sDistrictThai' && sortedInfo.order,
            sorter: true,
        },
        {
            title: 'ตำบล',
            dataIndex: 'sSubDistrictThai',
            key: "sSubDistrictThai",
            sortOrder: sortedInfo.columnKey === 'sSubDistrictThai' && sortedInfo.order,
            sorter: true,
        },
        {
            title: 'ประเภทพื้นที่ป่า',
            dataIndex: 'sType',
            key: "sType",
            sortOrder: sortedInfo.columnKey === 'sType' && sortedInfo.order,
            sorter: true,
        },
        {
            title: 'ชื่อพื้นที่ป่า',
            dataIndex: 'sNameTH',
            key: "sNameTH",
            sortOrder: sortedInfo.columnKey === 'sNameTH' && sortedInfo.order,
            sorter: true,
        },

    ];




    const onPageChange = async (page, pageSize, sorter) => {
        setActiveBlock(true);
        setsPageA(page);
        setsPageSizeA(pageSize);
        SearchData(page, pageSize, undefined, undefined);
        setCurrentPage(page);
        window.scrollTo(0, 500);
    }
    const onTableChange = async (pagination, filters, sorter, extra) => {
        setActiveBlock(true);
        if (sorter) {
            setsFieldA(sorter.order === undefined ? undefined : sorter.field);
            setsOrderA(sorter.order);
            setsortedInfo(sorter);
        }
    }



    const ExportExce = async () => {


        let ModelSearcData = {
            sYear: "",
            sType: txtSearch.sType == undefined ? "" : txtSearch.sType,
            sSocial: "",
            sRegion: txtSearch.sRegion == undefined ? "" : txtSearch.sRegion.map(a => a).toString(),
            sProvince: txtSearch.sProvinceID == undefined ? "" : txtSearch.sProvinceID.map(a => a).toString(),
            sMode: "4",
        }

        let ModelSearcData2 = {
            sYear: "",
            sType: txtSearch.sType == undefined ? "" : txtSearch.sType,
            sSocial: "",
            sRegion: txtSearch.sRegion == undefined ? "" : txtSearch.sRegion.map(a => a).toString(),
            sProvince: txtSearch.sProvinceID == undefined ? "" : txtSearch.sProvinceID.map(a => a).toString(),
            sMode: "5",
            nPage: 0,
            nPageSize: 0,
            sField: 0 + "",
            sOrder: 0 + "",
            sGenExcel: "1",
        }

        let ModelExportExceMap = {
            ModelSearcData: ModelSearcData,
            ModelSearcData5: ModelSearcData2,
        }

        let fileName = setNameFormatReport("รายงานข้อมูลแผนที่ป่า") + ".xlsx";
        let result: any = await AxiosBlobJson(Controller + "ExportExceMap", ModelExportExceMap);
        saveExcel(result.data, fileName)

    }
    const saveExcel = (result: BlobPart, fileName: string) => {
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }



    const ExportPDF = async () => {

        let ModelSearcData = {
            sYear: "",
            sType: txtSearch.sType == undefined ? "" : txtSearch.sType,
            sSocial: "",
            sRegion: txtSearch.sRegion == undefined ? "" : txtSearch.sRegion.map(a => a).toString(),
            sProvince: txtSearch.sProvinceID == undefined ? "" : txtSearch.sProvinceID.map(a => a).toString(),
            sMode: "4",
        }

        let ModelSearcData2 = {
            sYear: "",
            sType: txtSearch.sType == undefined ? "" : txtSearch.sType,
            sSocial: "",
            sRegion: txtSearch.sRegion == undefined ? "" : txtSearch.sRegion.map(a => a).toString(),
            sProvince: txtSearch.sProvinceID == undefined ? "" : txtSearch.sProvinceID.map(a => a).toString(),
            sMode: "5",
            nPage: 0,
            nPageSize: 0,
            sField: 0 + "",
            sOrder: 0 + "",
            sGenExcel: "1",
        }

        let ModelExportExceMap = {
            ModelSearcData: ModelSearcData,
            ModelSearcData5: ModelSearcData2,
        }

        let fileName = setNameFormatReport("รายงานข้อมูลแผนที่ป่า") + ".pdf";
        let result: any = await AxiosBlobJson(Controller + "ExportPDFMap", ModelExportExceMap);
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();



    }




    React.useEffect(() => {
        SearchData(undefined, undefined, undefined, undefined);
    }, [sFieldA, sOrderA]);



    React.useEffect(() => {
        SearchData(undefined, undefined, undefined, undefined);
        GetTH_Province();
    }, []);

    return (
        <React.Fragment>
            <BlockUi blocking={st_ActiveBlock}></BlockUi>
            <div id="REPORT">
                <Row justify="end" gutter={[16, 16]}>
                    <Col xl={4} md={10} xs={20}>

                        <AntdSelect
                            allowClear={true}
                            mode="multiple"
                            showSearch={true}
                            style={{ width: "100%" }}
                            placeholder={"- ภูมิภาค -"}
                            value={txtSearch.sRegion}
                            onChange={(value) => {
                                onChangeRegion(value);
                            }}
                            filterOption={(input, option) =>
                                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                Region.map((p, id) => {
                                    return (
                                        <Option key={"sRegion_" + p.id} value={p.sRegionThai} >{p.sRegionThai}</Option>
                                    )
                                })
                            }
                        </AntdSelect>

                    </Col>
                    <Col xl={4} md={10} xs={20}>
                        <AntdSelect
                            allowClear={true}
                            mode="multiple"
                            showSearch={true}
                            style={{ width: "100%" }}
                            placeholder={"- จังหวัด -"}
                            value={txtSearch.sProvinceID}
                            onChange={(value) => {
                                onChangeProvince(value);
                                //SetTxtSearch({
                                //    ...txtSearch,
                                //    sProvinceID: value,
                                //});
                            }}
                            filterOption={(input, option) =>
                                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                listRegion.length == 0 ?
                                    TH_Province.map((p, id) => {
                                        return (
                                            <Option key={"nProvinceID_" + id} value={p.nProvinceID} >{p.sProvinceThai}</Option>
                                        )
                                    })
                                    :
                                    TH_Province.filter(f => listRegion.includes(f.sRegionThai)).map((p, id) => {
                                        return (
                                            <Option key={"nProvinceID_" + id} value={p.nProvinceID} >{p.sProvinceThai}</Option>
                                        )
                                    })

                            }
                        </AntdSelect>

                    </Col>
                    <Col xl={4} md={10} xs={20}>
                        <AntdSelect
                            allowClear={true}
                            showSearch={true}
                            style={{ width: "100%" }}
                            value={txtSearch.sType}
                            placeholder="- ประเภทพื้นที่ป่า -"
                            onChange={(e) => {
                                SetTxtSearch({
                                    ...txtSearch,
                                    sType: e,
                                });
                            }}
                            filterOption={(input, option) =>
                                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >

                            <Option key="sType_1" value="1">พื้นที่ป่าใหม่(PTT)</Option>
                            <Option key="sType_2" value="2">พื้นที่ป่าเก่า(FPT)</Option>
                            <Option key="sType_3" value="3">พื้นที่ป่าอื่นๆ</Option>
                        </AntdSelect>
                    </Col>
                    <Col >
                        <ButtonSearch onClick={() => SearchData(1, undefined, undefined, undefined)} />
                    </Col>
                    <Col >
                        <ButtonPDF onClick={() => ExportPDF()} />

                    </Col>
                    <Col >
                        <ButtonExcel onClick={() => ExportExce()} />
                    </Col>
                </Row>
                <br />
                <Table
                    columns={header}
                    dataSource={data}
                    bordered
                    size="small"
                    pagination={false}
                    scroll={{ x: 'max-content' }}
                    summary={buildSummary}
                    
                />

                <br />

                <br />
                <TableAntd
                    dataSource={Stp_Search2}
                    columns={header2}
                    onPageChange={onPageChange}
                    currentPage={nCurrentPage}
                    total={nTotal}
                    onChange={onTableChange}
                    onDisableDelete={selectedRows.length > 0 ? false : true}
                    onShowDelete={false}
                    pageSize={sPageSizeA ? sPageSizeA : 10}
                    
                //onShowPagination={true}

                />
            </div>
        </React.Fragment>
    );
};
export default Report_Forest_Map_Data;
