import { Checkbox, Col, Form, Input, Radio, Row, Select, Space, Switch, Tag } from "antd";
import * as React from "react";
import { Empty, Select as AntdSelect } from "antd";
import { ButtonAdd_Table, ButtonBack, ButtonDelete, ButtonEdit_Table, ButtonSave, ButtonSearch, Button_Cancel } from "../../Systems/button_all";
import { useHistory } from "react-router";
import { BoxMsg, DialogConfirm, DialogDelete, Encrypt, Decrypt, Responsestart, Sweetalert, quickInsertButtons, pluginsEnabled, fontFamily } from "../../Systems/SystemComponent";
import { AxiosGetJson, AxiosPostJson } from "../../Service/Config/AxiosMethod";
import { parse } from "query-string"
import Fileuploader, { Extension } from "../../../Fileuploader";
import AuthenService from "../../Service/AuthenService";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
const { Option } = AntdSelect;


const Admin_Content_Index_Edit = ({ sType = "99", backEvent = "/", nPerMenuId = 99 }) => {
    var type = sType ? sType : "99";
    var backEv = backEvent ? backEvent : "/";
    var nMenuId = nPerMenuId ? nPerMenuId : 99;
    const history = useHistory();
    const [SetValue] = Form.useForm();
    const [ModeEN, setModeEN] = React.useState("TH" as string);
    const [lstData, setLstData] = React.useState(null as any);
    const [lstFileCover, setFileCover] = React.useState([] as any);
    const [lstFileCoverEN, setFileCoverEN] = React.useState([] as any);
    const [lstFileBooking, setFileBooking] = React.useState([] as any);
    const [lstFileBookingEN, setFileBookingEN] = React.useState([] as any);
    const [sID, setID] = React.useState("");
    const [sContentLevel, setContentLevel] = React.useState(null as any);
    const [contentLevelOption, setContentLevelOption] = React.useState([] as any);
    const [sContentLv1, setContentLv1] = React.useState(null as any);
    const [sContentLv2, setContentLv2] = React.useState(null as any);
    const [sContent, setContent] = React.useState("");
    const [sContentEN, setContentEN] = React.useState("");
    const [isEdit, setisEdit] = React.useState(false);
    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            setisEdit(UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == nMenuId).map(t => t.nPermission)[0] === 2 ? false : true)
        }
    }

    var configs = {
        placeholderText: "",
        attribution: false,
        key: "0BA3jA11B5C7C4A4D3aIVLEABVAYFKc2Cb1MYGH1g1NYVMiG5G4E3C3A1C8A6D4A3B4==",
        imageUploadURL: process.env.REACT_APP_URL + "api/UploadTextEditor/uploadImage",
        videoUploadURL: process.env.REACT_APP_URL + "api/UploadTextEditor/uploadImage",
        quickInsertButtons: quickInsertButtons,
        pluginsEnabled: pluginsEnabled,
        events: {
            'html.set': async function () {
                let editor: any = this
                let UserInfo: any = await AuthenService.UserInfo();
                if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
                    if (UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu === 13).map(t => t.nPermission)[0] === 2) {
                        editor.edit.off()
                    }
                }
            },
           
        },
        fontFamily: fontFamily,
        fontFamilySelection: true,
    };

    var onUploadFileSuccess = () => {
        //setLstData({ ...lstData, lstExcutionFile: fileList });
        SetValue.validateFields(['lstFileCover']);
    };
    var onUploadFileSuccessEN = () => {
        //setLstData({ ...lstData, lstExcutionFile: fileList });
        SetValue.validateFields(['lstFileCoverEN']);
    };
    var onUploadFileSuccess1 = () => {
        //setLstData({ ...lstData, lstExcutionFile: fileList });
        SetValue.validateFields(['lstFileBooking']);
    };
    var onUploadFileSuccess1EN = () => {
        //setLstData({ ...lstData, lstExcutionFile: fileList });
        SetValue.validateFields(['lstFileBookingEN']);
    };

    const onSaveSub = (values: any) => {
        DialogConfirm(async () => {
            var data = {
                nID: lstData.nID,
                sID: lstData.sID,
                sType: lstData.sType,
                sContentMode: lstData.sContentMode,
                sContentLevel: lstData.sContentLevel,
                sContentLv1: sContentLv1,
                sContentLv2: sContentLv2,
                sSubName: lstData.sSubName,
                sSubNameEng: lstData.sSubNameEng,
                isActive: lstData.isActive,
                lstFileCover: lstFileCover,
                lstFileCoverEN: lstFileCoverEN,
                lstFileBooking: lstFileBooking,
                lstFileBookingEN: lstFileBookingEN,
                sUrlBooking: lstData.sUrlBooking,
                sUrlBookingEN: lstData.sUrlBookingEN,
                sUrlVisit: lstData.sUrlVisit,
                sUrlVisitEN: lstData.sUrlVisitEN,
                sContent: sContent,
                sContentEN: sContentEN,
            };
            let result: any = await AxiosPostJson("api/AdminContent/SaveContentSubType", data)
            if (result.data.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Desc_Success_Save, BoxMsg.Desc_Success_Save, () => {
                    onBack();
                });
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }


        }, null, BoxMsg.Title_Confirm, BoxMsg.Desc_Confirm_Save)
    }

    const onBack = () => {
        history.push({
            pathname: backEv,
        });
    };

    const GetContentLevelOption = async (idStr) => {
        let result: any = await AxiosGetJson("api/AdminContent/GetContentLevelOption?id=" + idStr + "&sType=" + type + "&src=detail");
        setContentLevelOption(result);
    }

    const GetTypeDetail = async (idStr) => {
        let result: any = await AxiosGetJson("api/AdminContent/GetContentTypeData?id=" + idStr + "&sType=" + type);
        SetValue.setFieldsValue({
            sContentLevel: result.sContentLevel,
            sContentLv1: result.sContentLv1,
            sContentLv2: result.sContentLv2,
            sSubName: result.sSubName,
            sSubNameEng: result.sSubNameEng,
            isActive: result.isActive,
            lstFileCover: result.lstFileCover,
            lstFileCoverEN: result.lstFileCoverEN,
            lstFileBooking: result.lstFileBooking,
            lstFileBookingEN: result.lstFileBookingEN,
            sUrlBooking: result.sUrlBooking,
            sUrlBookingEN: result.sUrlBookingEN,
            sUrlVisit: result.sUrlVisit,
            sUrlVisitEN: result.sUrlVisitEN,
            sContent: result.sContent,
            sContentEN: result.sContentEN,
        });
        setModeEN(result.sContentMode);
        setLstData(result);
        setFileCover(result.lstFileCover);
        setFileCoverEN(result.lstFileCoverEN);
        setFileBooking(result.lstFileBooking);
        setFileBookingEN(result.lstFileBookingEN);
        setContentLevel(result.sContentLevel);
        setContentLv1(result.sContentLv1);
        setContentLv2(result.sContentLv2);
        setContent(result.sContent);
        setContentEN(result.sContentEN);
    }

    const onDelete =  (sID) => {
        DialogConfirm(async () => {
            let result: any = await AxiosGetJson("api/AdminContent/DeleteDataTypeDetail?id=" + sID);
            if (result.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete , null);
                history.goBack();
            } else if (result.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }
        }, "", "", "ท่านต้องการลบข้อมูลหรือไม่?");
    }

    const checkContentMode = async (type) => {
        let cateType = "";
        if(sContentLevel === "1"){
            cateType = "0";
        }
        if(sContentLevel === "2"){
            cateType = sContentLv1;
        }
        if(sContentLevel === "3"){
            cateType = sContentLv2;
        }
        let result: any = await AxiosGetJson("api/AdminContent/CheckContentMode?level=" + sContentLevel + "&id=" + cateType+ "&type=" + type);
        if(result) {
            Sweetalert.Warning(BoxMsg.Title_Warning, 
                "ไม่สามารถดำเนินการได้ เนื่องจากเมนูหลักหรือเมนูรองไม่มีการตั้งค่าเนื้อหาภาษาอังกฤษ <br />กรุณาแก้ไขที่เมนูหลักหรือเมนูรองก่อนเพิ่มข้อมูล", 
                () =>{
                    // setModeEN("TH"); 
                    // setLstData({ ...lstData, sContentMode: "EN" });
                    //"ไม่สามารถดำเนินการได้ เนื่องจากเมนูรองไม่มีการตั้งค่าเนื้อหาภาษาอังกฤษ กรุณาแก้ไขที่เมนูรองก่อนเพิ่มข้อมูล"
                    //"ไม่สามารถสร้างรายการภาษาอังกฤษได้<br /> กรุณาตรวจสอบข้อมูลเมนูหลักหรือเมนูรองอีกครั้ง"
                });
            setModeEN("TH"); 
            setLstData({ ...lstData, sContentMode: "TH" });
        }
        return false;
    }

    const checkContentMode1 = async (id) => {
        let cateType = "";
        if(sContentLevel === "1"){
            cateType = "0";
        }
        if(sContentLevel === "2"){
            cateType = id;
        }
        if(sContentLevel === "3"){
            cateType = id;
        }
        let result: any = await AxiosGetJson("api/AdminContent/CheckContentMode?level=" + sContentLevel + "&id=" + cateType+ "&type=" + ModeEN);
        if(result) {
            Sweetalert.Warning(BoxMsg.Title_Warning, 
                "ไม่สามารถดำเนินการได้ เนื่องจากเมนูหลักหรือเมนูรองไม่มีการตั้งค่าเนื้อหาภาษาอังกฤษ <br />กรุณาแก้ไขที่เมนูหลักหรือเมนูรองก่อนเพิ่มข้อมูล", 
                () =>{
                    // setModeEN("TH"); 
                    // setLstData({ ...lstData, sContentMode: "EN" });
                    //"ไม่สามารถดำเนินการได้ เนื่องจากเมนูรองไม่มีการตั้งค่าเนื้อหาภาษาอังกฤษ กรุณาแก้ไขที่เมนูรองก่อนเพิ่มข้อมูล"
                    //"ไม่สามารถสร้างรายการภาษาอังกฤษได้<br /> กรุณาตรวจสอบข้อมูลเมนูหลักหรือเมนูรองอีกครั้ง"
                });
            setModeEN("TH"); 
            setLstData({ ...lstData, sContentMode: "TH" });
        }
        return false;
    }

    React.useEffect(() => {
        GetUserInfo();
    }, []);

    React.useEffect(() => {
        var { id } = parse(window.location.search);
        var idStr: string = "";
        if (id !== undefined && id !== null) {
            idStr = id.toString().split("/").join("%2f");
        }
        else {
            idStr = "xvEhq%2famL3jePelPapN2%2fw==";
        }
        setID(idStr);
        GetTypeDetail(idStr);
        GetContentLevelOption(idStr);
        window.scrollTo(0, 0);
    }, [isEdit])

    return (
        <>
            <Form name="From" form={SetValue} onFinish={onSaveSub} onFinishFailed={({ errorFields }) => {
                let element = document.querySelector(`#From_${errorFields[0].name}`);
                var firstErrorElemY: any = null;
                if (element) {
                    firstErrorElemY = element.getBoundingClientRect().top - 100; // bar height(100)px
                    window.scrollTo(0, window.scrollY + firstErrorElemY)
                }
                else{
                    element = document.getElementById(`${errorFields[0].name}`);
                    if (element) {
                        firstErrorElemY = element.getBoundingClientRect().top - 100; // bar height(100)px
                        window.scrollTo(0, window.scrollY + firstErrorElemY)
                    }
                }
            }}>
                <Row gutter={[16, 16]}>
                    <Col xl={12} md={12} xs={24} >
                        <b> ประเภทเมนู </b><span className="text-danger ">*</span>
                        <Form.Item
                            name="sContentLevel"
                            rules={[
                                { required: true, message: BoxMsg.Desc_Required_Field + "ประเภทเมนู" },
                            ]}
                        >
                            <AntdSelect
                                disabled={!isEdit}
                                allowClear={true}
                                showSearch={true}
                                style={{ width: "100%" }}
                                value={""}
                                placeholder="- เลือก -"
                                onChange={(e) => {
                                    setLstData({ ...lstData, sContentLevel: e, sContentLv1: null, sContentLv2: null});
                                    setContentLevel(e);
                                    setContentLv1(null);
                                    setContentLv2(null);
                                    // checkContentMode1(e);
                                    SetValue.resetFields(["sContentLv1","sContentLv2"])
                                }}
                                filterOption={(input, option) =>
                                    option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <option value="1">เมนูหลัก</option>
                                <option value="2">เมนูรอง</option>
                                <option value="3">เมนูย่อย</option>
                            </AntdSelect>
                        </Form.Item>
                    </Col>
                </Row>
                {
                    sContentLevel === "2" || sContentLevel === "3" ? 
                    <React.Fragment>
                        <Row gutter={[16, 16]}>
                            <Col xl={12} md={12} xs={24} >
                            <b> เมนูหลัก </b><span className="text-danger ">*</span>
                            <Form.Item
                                name="sContentLv1"
                                rules={[
                                    { required: true, message: BoxMsg.Desc_Required_Field + "เมนูหลัก" },
                                ]}
                            >
                                <AntdSelect
                                    disabled={!isEdit}
                                    allowClear={true}
                                    showSearch={true}
                                    style={{ width: "100%" }}
                                    value={""}
                                    placeholder="- เลือก -"
                                    onChange={(e) => {
                                        setLstData({ ...lstData, sContentLv1: e, sContentLv2: null});
                                        setContentLv1(e);
                                        setContentLv2(null);
                                        checkContentMode1(e);
                                        SetValue.resetFields(["sContentLv2"])
                                    }}
                                    filterOption={(input, option) =>
                                        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        contentLevelOption.filter(f => f.nTypeIDLv2 === 0 && f.nTypeIDLv3 === 0).map((e, i) => {
                                            return (
                                                <Option key={"lv1_" + i} value={e.nTypeIDLv1 + ""}>{e.sTypeLv1Name}</Option>
                                            );
                                        })
                                    }
                                </AntdSelect>
                            </Form.Item>
                            </Col>
                        </Row>
                    </React.Fragment>
                    :
                    null
                }
                {
                    sContentLevel === "3" ? 
                    <React.Fragment>
                        <Row gutter={[16, 16]}>
                            <Col xl={12} md={12} xs={24} >
                            <b> เมนูรอง </b><span className="text-danger ">*</span>
                            <Form.Item
                                name="sContentLv2"
                                rules={[
                                    { required: true, message: BoxMsg.Desc_Required_Field + "เมนูรอง" },
                                ]}
                            >
                                <AntdSelect
                                    disabled={!isEdit}
                                    allowClear={true}
                                    showSearch={true}
                                    style={{ width: "100%" }}
                                    value={""}
                                    placeholder="- เลือก -"
                                    onChange={(e) => {
                                        setLstData({ ...lstData, sContentLv2: e});
                                        setContentLv2(e);
                                        checkContentMode1(e);
                                    }}
                                    filterOption={(input, option) =>
                                        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        contentLevelOption.filter(f => f.nTypeIDLv1 === parseInt(sContentLv1) && f.nTypeIDLv2 !== 0 && f.nTypeIDLv3 === 0).map((e, i) => {
                                            return (
                                                <Option key={"lv2_" + i} value={e.nTypeIDLv2 + ""}>{e.sTypeLv2Name}</Option>
                                            );
                                        })
                                    }
                                </AntdSelect>
                            </Form.Item>
                            </Col>
                        </Row>
                    </React.Fragment>
                    :
                    null
                }
                <Row gutter={[16, 16]}>
                    <Col xl={12} md={12} xs={24} >
                        <b> แสดงข้อมูลภาษาอังกฤษหรือไม่ </b>
                        <br />
                        <Radio.Group disabled={!isEdit}
                            onChange={e => { 
                                setModeEN(e.target.value); 
                                setLstData({ ...lstData, sContentMode: e.target.value });
                                checkContentMode(e.target.value); 
                            }} value={ModeEN}>
                            <Radio value="EN">ใช่</Radio>
                            <Radio value="TH">ไม่ใช่</Radio>
                        </Radio.Group>
                    </Col>
                </Row>
                <br />
                <Row gutter={[16, 16]}>
                    <Col xl={12} md={12} xs={24} >
                        <b>ชื่อประเภท</b> <span className="text-danger ">*</span><span className="text-primary small"> ภาษาไทย </span>
                        <Form.Item
                            name="sSubName"
                            rules={[
                                { required: true, message: BoxMsg.Desc_Required_Field + "ชื่อประเภท" },
                            ]}
                        >
                            <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                setLstData({ ...lstData, sSubName: e.target.value });
                            }} />
                        </Form.Item>
                    </Col>
                    {
                        ModeEN === "EN" ?
                        <Col xl={12} md={12} xs={24} >
                            <b>ชื่อประเภท</b> <span className="text-danger ">*</span><span className="text-success small"> ภาษาอังกฤษ </span>
                            <Form.Item
                                name="sSubNameEng"
                                rules={[
                                    { required: true, message: BoxMsg.Desc_Required_Field + "ชื่อประเภทภาษาอังกฤษ" },
                                ]}
                            >
                                <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                    setLstData({ ...lstData, sSubNameEng: e.target.value });
                                }} />
                            </Form.Item>
                        </Col>
                        :
                        null
                    }
                </Row>
                <br />
                <Row gutter={[16, 16]}>
                    <Col xl={12} md={12} xs={24} >
                        <b>ภาพปก </b> <span className="text-danger ">*</span><span className="text-primary small"> ภาษาไทย </span>
                        <span className="text-muted small">
                            ขนาดภาพที่แนะนำ 2700 x 600 px.
                        </span>
                        <Form.Item
                            name="lstFileCover"
                            rules={[
                                { required: true, message: BoxMsg.Desc_Required_Field_File + "ภาพปก" },
                            ]}
                        >
                            <div id="lstFileCover">
                            <Fileuploader
                                limit="1"
                                setFileList={setFileCover}
                                fileList={lstFileCover}
                                onComplete={onUploadFileSuccess}
                                onRemoveComplete={onUploadFileSuccess}
                                fileMaxSize="10"
                                readOnly={!isEdit}
                                extensions={Extension.Image}
                            />
                            </div>
                        </Form.Item>
                    </Col>
                    {
                        ModeEN === "EN" ?
                        <Col xl={12} md={12} xs={24} >
                        <b>ภาพปก </b> <span className="text-danger ">*</span><span className="text-success small"> ภาษาอังกฤษ </span>
                        <span className="text-muted small">
                            ขนาดภาพที่แนะนำ 2700 x 600 px.
                        </span>
                        <Form.Item
                            name="lstFileCoverEN"
                            rules={[
                                { required: true, message: BoxMsg.Desc_Required_Field_File + "ภาพปกภาษาอังกฤษ" },
                            ]}
                        >
                            <div id="lstFileCoverEN">
                            <Fileuploader
                                limit="1"
                                setFileList={setFileCoverEN}
                                fileList={lstFileCoverEN}
                                onComplete={onUploadFileSuccessEN}
                                onRemoveComplete={onUploadFileSuccessEN}
                                fileMaxSize="10"
                                readOnly={!isEdit}
                                extensions={Extension.Image}
                            />
                            </div>
                        </Form.Item>
                    </Col>
                        :
                        null
                    }
                </Row>
                <br />
                {
                    type === "3" ?
                        <>
                            <Row gutter={[16, 16]}>
                                <Col xl={12} md={12} xs={24} >
                                    <b>ลิงก์สำหรับจองเยี่ยมชม</b> <span className="text-danger ">*</span><span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name="sUrlBooking"
                                        rules={[
                                            { required: true, message: BoxMsg.Desc_Required_Field + "ลิงก์สำหรับจองเยี่ยมชม" },
                                            { type: 'url', message: 'ลิงก์ไม่ถูกต้อง' }
                                        ]}
                                    >
                                        <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                            setLstData({ ...lstData, sUrlBooking: e.target.value });
                                        }} />
                                    </Form.Item>
                                </Col>
                                {
                                    ModeEN === "EN" ?
                                    <Col xl={12} md={12} xs={24} >
                                        <b>ลิงก์สำหรับจองเยี่ยมชม</b> <span className="text-danger ">*</span><span className="text-success small"> ภาษาอังกฤษ </span>
                                        <Form.Item
                                            name="sUrlBookingEN"
                                            rules={[
                                                { required: true, message: BoxMsg.Desc_Required_Field + "ลิงก์สำหรับจองเยี่ยมชมภาษาอังกฤษ" },
                                                { type: 'url', message: 'ลิงก์ไม่ถูกต้อง' }
                                            ]}
                                        >
                                            <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                                setLstData({ ...lstData, sUrlBookingEN: e.target.value });
                                            }} />
                                        </Form.Item>
                                    </Col>
                                    :
                                    null
                                }
                                
                            </Row>
                            <br />
                            <Row gutter={[16, 16]}>
                                <Col xl={12} md={12} xs={24} >
                                    <b>ภาพสำหรับจองเยี่ยมชม </b> <span className="text-danger ">*</span><span className="text-primary small"> ภาษาไทย </span>
                                    <span className="text-muted small">
                                        ขนาดภาพที่แนะนำ 2700 x 600 px.
                                    </span>
                                    <Form.Item
                                        name="lstFileBooking"
                                        rules={[
                                            { required: true, message: BoxMsg.Desc_Required_Field_File + "ภาพสำหรับจองเยี่ยมชม" },
                                        ]}
                                    >
                                        <div id="lstFileBooking">
                                        <Fileuploader
                                            limit="1"
                                            setFileList={setFileBooking}
                                            fileList={lstFileBooking}
                                            onComplete={onUploadFileSuccess1}
                                            onRemoveComplete={onUploadFileSuccess1}
                                            fileMaxSize="10"
                                            readOnly={false}
                                            extensions={Extension.Image}
                                        />
                                        </div>
                                    </Form.Item>
                                </Col>
                                {
                                    ModeEN === "EN" ?
                                    <Col xl={12} md={12} xs={24} >
                                        <b>ภาพสำหรับจองเยี่ยมชม </b> <span className="text-danger ">*</span><span className="text-success small"> ภาษาอังกฤษ </span>
                                        <span className="text-muted small">
                                            ขนาดภาพที่แนะนำ 2700 x 600 px.
                                        </span>
                                        <Form.Item
                                            name="lstFileBookingEN"
                                            rules={[
                                                { required: true, message: BoxMsg.Desc_Required_Field_File + "ภาพสำหรับจองเยี่ยมชมภาษาอังกฤษ" },
                                            ]}
                                        >
                                            <div id="lstFileBookingEN">
                                            <Fileuploader
                                                limit="1"
                                                setFileList={setFileBookingEN}
                                                fileList={lstFileBookingEN}
                                                onComplete={onUploadFileSuccess1EN}
                                                onRemoveComplete={onUploadFileSuccess1EN}
                                                fileMaxSize="10"
                                                readOnly={false}
                                                extensions={Extension.Image}
                                            />
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    :
                                    null
                                }
                                
                            </Row>
                            <br />
                            <Row gutter={[16, 16]}>
                                <Col xl={12} md={12} xs={24} >
                                    <b>ลิงก์แนะนำศูนย์เรียนรู้</b> <span className="text-danger ">*</span><span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name="sUrlVisit"
                                        rules={[
                                            { required: true, message: BoxMsg.Desc_Required_Field + "ลิงก์แนะนำศูนย์เรียนรู้" },
                                            { type: 'url', message: 'ลิงก์ไม่ถูกต้อง' }
                                        ]}
                                    >
                                        <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                            setLstData({ ...lstData, sUrlVisit: e.target.value });
                                        }} />
                                    </Form.Item>
                                </Col>
                                {
                                    ModeEN === "EN" ?
                                    <Col xl={12} md={12} xs={24} >
                                        <b>ลิงก์แนะนำศูนย์เรียนรู้</b> <span className="text-danger ">*</span><span className="text-success small"> ภาษาอังกฤษ </span>
                                        <Form.Item
                                            name="sUrlVisitEN"
                                            rules={[
                                                { required: true, message: BoxMsg.Desc_Required_Field + "ลิงก์แนะนำศูนย์เรียนรู้ภาษาอังกฤษ" },
                                                { type: 'url', message: 'ลิงก์ไม่ถูกต้อง' }
                                            ]}
                                        >
                                            <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                                setLstData({ ...lstData, sUrlVisitEN: e.target.value });
                                            }} />
                                        </Form.Item>
                                    </Col>
                                    :
                                    null
                                }
                                
                            </Row>
                            <br />
                        </>
                        :
                        null
                }
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <b>เนื้อหา</b><span className="text-primary small"> ภาษาไทย </span>
                        <Form.Item
                            name="sContent"
                            rules={[
                                { required: false, message: BoxMsg.Desc_Required_Field + "รายละเอียด" },
                            ]}
                        >
                            <FroalaEditor
                                model={sContent}
                                config={configs}
                                onModelChange={(model: any) => {
                                    // var str = model.replace(/<\/?[^>]+(>|$)/g, "");
                                    // if (str.replace(/\&nbsp;/g, "").trim().length <= 0) {
                                    //     setValidContent(true);
                                    // }
                                    // else {
                                    //     setValidContent(false);
                                    // }
                                    setContent(model);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <br />
                {
                    ModeEN === "EN" ?
                    <>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <b>เนื้อหา</b><span className="text-success small"> ภาษาอังกฤษ </span>
                                <Form.Item
                                    name="sContentEN"
                                    rules={[
                                        { required: false, message: BoxMsg.Desc_Required_Field + "รายละเอียดภาษาอังกฤษ" },
                                    ]}
                                >
                                    <FroalaEditor
                                        model={sContentEN}
                                        config={configs}
                                        onModelChange={(model: any) => {
                                            // var str = model.replace(/<\/?[^>]+(>|$)/g, "");
                                            // if (str.replace(/\&nbsp;/g, "").trim().length <= 0) {
                                            //     setValidContent(true);
                                            // }
                                            // else {
                                            //     setValidContent(false);
                                            // }
                                            setContentEN(model);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <br />
                    </>
                    :
                    null
                }
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <b>สถานะ</b>
                        <Form.Item name="isActive" valuePropName="checked">
                            <Switch disabled={!isEdit} defaultChecked={true} checkedChildren="ใช้งาน" unCheckedChildren="ไม่ใช้งาน" style={{width : "100px"}}
                                onChange={e => {
                                    setLstData({ ...lstData, isActive: e });
                                }} />
                        </Form.Item>
                    </Col>
                </Row>
                <br />
                <hr />
                <Row gutter={[8, 16]} justify="center">
                    {isEdit ?
                        <React.Fragment>
                            <Col>
                                <ButtonSave htmlType="submit" />
                            </Col>
                            {
                                sID !== "xvEhq%2famL3jePelPapN2%2fw==" && sID !== "" ? 
                                    <Col>
                                        <ButtonDelete onClick={() => onDelete(sID)} />
                                    </Col>
                                :
                                null
                            }
                        </React.Fragment>
                        : null}
                    <Col>
                        <Button_Cancel onClick={() => { onBack(); }}/>
                    </Col>
                </Row>
            </Form>
        </>
    );
}
export default Admin_Content_Index_Edit;