import * as React from "react";
const $ = window.jQuery;
class FlowPaper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "viewer"
        };
        this.state.PDFFile = this.props.PDFFile;
        //this.state.PDFFile = "/UploadFile/Content/Content_385/0112202113325213.pdf";
        //this.state.PDFFile = "UploadFile/Content/Content_385/0112202117120927.pdf";

    };
    SetViewer = () => {
        $('#documentViewer').FlowPaperViewer({
            config: {
                //IMGFiles: this.state.PathWeb + (this.state.PDFFile.replace(".pdf", "_{page}.png")),
                //JSONFile: this.state.PathWeb + (this.state.PDFFile.replace(".pdf", ".json")),
                PDFFile: (process.env.REACT_APP_URL || "") + this.state.PDFFile,
                Scale: 0.6,
                ZoomTransition: 'easeOut',
                ZoomTime: 0.5,
                ZoomInterval: 0.1,
                FitPageOnLoad: true,
                FitWidthOnLoad: false,
                FullScreenAsMaxWindow: false,
                ProgressiveLoading: false,
                MinZoomSize: 0.2,
                MaxZoomSize: 5,
                SearchMatchAll: false,
                InitViewMode: '',
                RenderingOrder: 'html5,html',
                StartAtPage: '',
                key: "$54c2255f2b58e221265",
                EnableWebGL: true,
                ViewModeToolsVisible: true,
                ZoomToolsVisible: true,
                NavToolsVisible: true,
                CursorToolsVisible: true,
                SearchToolsVisible: true,
                WMode: 'transparent',
                //localeDirectory: (process.env.REACT_APP_URL || "") + 'flowpaper/locale/',
                //jsDirectory: (process.env.REACT_APP_URL || "") + 'flowpaper/js/',
                localeChain: 'en_US'


                ////IMGFiles: "/UploadFile/Content/Content_385/0112202113325213_{page}.png",
                ////JSONFile: "/UploadFile/Content/Content_385/0112202113325213.json",
                ////PDFFile: "/UploadFile/Content/Content_385/0112202113325213.pdf",
                //IMGFiles: this.state.PathWeb + (this.state.PDFFile.replace(".pdf", "_{page}.png")),
                //JSONFile: this.state.PathWeb + (this.state.PDFFile.replace(".pdf", ".json")),
                //PDFFile: this.state.PathWeb + this.state.PDFFile,
                //Scale: 0.5,
                //ZoomTransition: 'easeOut',
                //ZoomTime: 0.1,
                //ZoomInterval: 0.1,
                //FitPageOnLoad: true,
                //FitWidthOnLoad: false,
                //AutoAdjustPrintSize: true,
                //PrintPaperAsBitmap: false,
                //AutoDetectLinks: false,
                //FullScreenAsMaxWindow: true,
                //ProgressiveLoading: false,
                //MinZoomSize: 0.2,
                //MaxZoomSize: 5,
                //SearchMatchAll: true,
                //InitViewMode: 'TwoPage',
                //RenderingOrder: 'html5,html5',
                //StartAtPage: 1,
                //MixedMode: true,
                //EnableWebGL: true,
                //PublicationTitle: '',
                //ViewModeToolsVisible: true,
                //ZoomToolsVisible: true,
                //NavToolsVisible: true,
                //CursorToolsVisible: true,
                //SearchToolsVisible: true,
                ////UIConfig: (process.env.REACT_APP_URL || "") + 'flowpaper/UI_Zine.xml',
                ////localeDirectory: (process.env.REACT_APP_URL || "") + 'flowpaper/locale/',
                ////jsDirectory: (process.env.REACT_APP_URL || "") + 'flowpaper/js/',
                //WMode: 'transparent',
                ////key: '#V2ZzfWBFX1pcQRhwB0lFXlVeYw',
                ////key: "$0351a8e9652fe070fe4",
                //key: "$cbb2e4987f9763c618a",
                //localeChain: 'en_US',
            }
        });
        var url = window.location.href.toString();
        //if (location.length == 0) {
        //    url = document.URL.toString();
        //}
        if (url.indexOf("file:") >= 0) {
            $('#documentViewer').html("<div style='position:relative;background-color:#ffffff;width:420px;font-family:Verdana;font-size:10pt;left:22%;top:20%;padding: 10px 10px 10px 10px;border-style:solid;border-width:5px;'><img src='data:image/gif;base64,R0lGODlhEAAPAMQPAPS+GvXAIfrjnP/89vnZePrhlvS9F//+/PrfjfS/HfrgkPS+GP/9+YJiACAYAP////O3AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAA8ALAAAAAAQAA8AAAVQ4COOD0GQKElA0JmSg7EsxvCOCMsi9xPrkNpNwXI0WIoXA1A8QgCMVEFn1BVQS6rzGR1NtcCriJEAVnWJrgDI1gkehwD7rAsc1u28QJ5vB0IAOw%3D%3D'><b>You are trying to use FlexPaper from a local directory.</b><br/><br/> Use the 'View in browser' button in the Desktop Publisher publish & preview dialog window to preview your publication or copy the contents of your publication directory to a web server and access this html file through a http:// url.</div>");
        }
    }
    componentDidMount() {
        this.SetViewer()
    };
    componentWillUnmount() {

    }

    render() {
        return (
            <div id="documentViewer" className="flexpaper_viewer" style={{ position: "absolute", width: "100%", height: "100%", backgroundColor: "#222222" }}></div>
        );
    }
}

export default FlowPaper;