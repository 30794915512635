import { Col, Input, Row, Space, Tag } from "antd";
import * as React from "react";
import { Empty, Select as AntdSelect } from "antd";
import { ButtonAdd_Table, ButtonEdit_Table, ButtonSearch } from "../Systems/button_all";
import TableAntd from "../Systems/TableAntd";
import { useHistory } from "react-router";
import { BoxMsg, DialogDelete, Encrypt, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";

const Controller = 'api/AdminInstitution/';


const Admin_Institution_List = () => {
    const [txtSearch, SetTxtSearch] = React.useState({
        stxtNewsName: "" as string || undefined,
        stxtIsActive: "" as string || undefined,

    });
    const history = useHistory();
    const [V_Institution, setV_Institution] = React.useState([] as any);

    React.useEffect(() => {
        GetDataOnPageLoad();
    }, []);

    const GetDataOnPageLoad = async () => {
        let result: any = await AxiosGetJson(Controller + "GetV_Institution");
        setnTotal(result.nTotal)
        setV_Institution(result.V_Institution);
    }

    const SearchData = async (sPage, sPageSize, sField, sOrder) => {
        let Page = sPage == undefined ? sPageA : sPage;
        let PageSize = sPageSize == undefined ? sPageSizeA : sPageSize;
        let Field = sField == undefined ? sFieldA : sField;
        let Order = sOrder == undefined ? sOrderA : sOrder;
        setCurrentPage(Page);
        let stxtNewsName = txtSearch.stxtNewsName == undefined ? "" : txtSearch.stxtNewsName;
        let stxtIsActive = txtSearch.stxtIsActive == undefined ? "" : txtSearch.stxtIsActive;
        let result: any = await AxiosGetJson(Controller + "SearchData?stxtName=" + encodeURIComponent(stxtNewsName) + "&stxtIsActive=" + stxtIsActive + "&sPage=" + Page + "&sPageSize=" + PageSize + "&sField=" + Field + "&sOrder=" + Order);
        setnTotal(result.nTotal)
        setV_Institution(result.V_Institution);
    }
    //*****************************TableAntd
    const [selectedRows, setselectedRows] = React.useState([] as Array<any>);
    const [selectedRowKeys, setrowSelected] = React.useState([] as any);
    const [sPageA, setsPageA] = React.useState("1" as string || undefined);
    const [sPageSizeA, setsPageSizeA] = React.useState("10" as string || undefined);
    const [sFieldA, setsFieldA] = React.useState("" as string || undefined);
    const [sOrderA, setsOrderA] = React.useState("" as string || undefined);
    const [nCurrentPage, setCurrentPage] = React.useState(1);
    const [nTotal, setnTotal] = React.useState(0 as number);

    const onselect = (selectedRowKeys, selectedRows: React.SetStateAction<any[]>) => {
        setselectedRows(selectedRows);
        setrowSelected(selectedRowKeys);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onselect,
        getCheckboxProps: (record) => ({
            disabled: record.sIsuse === 'Y', // Column configuration not to be checked
            sIsuse: record.sIsuse,
        }),
    };

    const onPageChange = async (page, pageSize, sorter) => {
        onselect([], []);
        setsPageA(page);
        setsPageSizeA(pageSize);
        SearchData(page, pageSize, undefined, undefined);
        setCurrentPage(page);
        window.scrollTo(0, 0);
    }
    const onTableChange = async (pagination, filters, sorter, extra) => {
        if (sorter) {
            onselect([], []);
            setsFieldA(sorter.field);
            setsOrderA(sorter.order);
            SearchData(undefined, undefined, sorter.field, sorter.order);
        }
    }
    const onDeleteClick = async () => {
        if (selectedRows.length > 0) {

            DialogDelete(async () => {
                let result: any = await AxiosPostJson(Controller + "onDeleteData", selectedRows);
                if (result.data.sStatus === Responsestart.success) {
                    Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete, null);
                    SearchData(undefined, undefined, undefined, undefined);
                    onselect([], []);
                } else if (result.data.sStatus === Responsestart.warning) {
                    Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                } else {
                    Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                }
            });
        }

    }
    //*****************************TableAntd

    const getLinkTo_Edit = (nID: number) => {
        let sID = Encrypt(nID)
        let sTypeComponent = `/admin-institution-edit`
        let sPath = `${sTypeComponent}?sID=${sID}`
        return history.push(sPath);
    };

    const header = [
        {
            title: 'ลำดับที่',
            dataIndex: 'key',
            key: 'key',
            className: "text-center",
            width: 50,
            render: (value) => {
                return (<>{value}.</>);
            },
        },
        {
            title: "ชื่อสถาบัน",
            dataIndex: 'sNameTH',
            key: 'sNameTH',
            sorter: true,
            width: 700,
            render: (text: any, record: { sNameTH: string, sNameEN: string }) => (

                record.sNameTH === "" ? record.sNameEN : record.sNameTH

            ),
        },
        {
            title: 'สถานะ',
            dataIndex: 'sIsActiveDes',
            width: 50,
            sorter: true,
            key: "sIsActiveDes",
            className: "text-center",
            render: (text: any, record: { sIsActive: string; }) => (
                <Tag color={record.sIsActive === "1" ? "#60cc28" : "#cc2838"} key={text} style={{ borderRadius: "32px" }}>
                    {text}
                </Tag>
            ),
        },
        {
            title: <ButtonAdd_Table onClick={() => { history.push("/admin-institution-edit"); }} />,
            key: 'action',
            className: "text-center",
            render: (text, record) => (
                <Space size="middle">
                    <ButtonEdit_Table
                        onClick={() => { getLinkTo_Edit(record.nID); }}
                    >
                        <i className="fas fa-pencil-alt"></i>
                    </ButtonEdit_Table>
                </Space>
            ),
            width: 50,
        },
    ];

    return (
        <React.Fragment>
            <br />
            <Row justify="end" gutter={[16, 16]}>
                <Col xl={5} md={12} xs={24}>

                    <Input
                        autoComplete="off"
                        placeholder="ชื่อสถาบัน"
                        onChange={(e) =>
                            SetTxtSearch({
                                ...txtSearch,
                                stxtNewsName: e.target.value,
                            })
                        }
                        onKeyPress={(e) => {
                            e.key === "Enter" && SearchData(1, undefined, undefined, undefined);
                        }}
                    />
                </Col>
                <Col xl={4} md={10} xs={20}>
                    <AntdSelect
                        allowClear={true}
                        showSearch={true}
                        style={{ width: "100%" }}
                        value={txtSearch.stxtIsActive}
                        placeholder="- สถานะ -"
                        onChange={(e) => {
                            SetTxtSearch({
                                ...txtSearch,
                                stxtIsActive: e,
                            });
                        }}
                        filterOption={(input, option) =>
                            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <option value="1">ใช้งาน</option>
                        <option value="0">ไม่ใช้งาน</option>
                    </AntdSelect>
                </Col>
                <Col xl={1} md={2} xs={4}>
                    <ButtonSearch onClick={() => SearchData(1, undefined, undefined, undefined)} />
                </Col>
            </Row>
            <br />
            <TableAntd
                dataSource={V_Institution}
                columns={header}
                onPageChange={onPageChange}
                currentPage={nCurrentPage}
                total={nTotal}
                rowSelection={rowSelection}
                onChange={onTableChange}
                onDisableDelete={selectedRows.length > 0 ? false : true}
                onShowDelete={true}
                scroll={{ x: 850 }}
                onClickDeleteData={onDeleteClick}
                pageSize={sPageSizeA ? parseInt(sPageSizeA) : 10}
            />
            <br />
        </React.Fragment>
    );
};
export default Admin_Institution_List;
