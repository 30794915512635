import * as React from 'react';
// import Main_Content_Index from '../main-content/content-index'
import Main_Content_Index from '../main-content/new-content/content-index'

const Learning_Center_Index = (props) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Main_Content_Index sType={"5"} />
    );
}
export default Learning_Center_Index;