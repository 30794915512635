import * as React from 'react';
import { BoxMsg, ConfirmLayout, DialogConfirm, Responsestart, Sweetalert } from '../Systems/SystemComponent';
import { AxiosGetJson, AxiosPostJson } from '../Service/Config/AxiosMethod';
import { Button, Col, Form, Input, Row } from "antd";
import { Empty, Select as AntdSelect } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import ClientCaptcha from "react-client-captcha";
import {
    thailanguage,
    englishlanguage,
    currentlanguage,
} from '../app/counterSlice';

import ReCAPTCHA from "react-google-recaptcha";

const Controller = 'api/AdminContactUs/';

const Form_Main = (props) => {
    const translate = useSelector(currentlanguage);
    const [SetValue] = Form.useForm();
    const [T_Complaint_Group, setT_Complaint_Group] = React.useState([] as Array<any>);
    const [V_Complaint, setV_Complaint] = React.useState([] as any);
    const [Code, setCode] = React.useState("" as string);
    const [InputCode, setInputCode] = React.useState("" as string);
    const [CaptchaActive, setCaptchaActive] = React.useState(true as boolean);

    //const onSave = (values: any) => {
    //    if (fileList.length != 0) {
    //        let data = {
    //            nID: nID,
    //            DateStart: cDateStart,
    //            TitleName: values.TitleName,
    //            sDesc: values.cDescription,
    //            isActive: values.IsisActive == "1" ? true : false,
    //            sContent: sContent,
    //            file: fileList,
    //            fileTNewsFile: fileTNewsFile
    //        }
    //        DialogConfirm(async () => {
    //            let result: any = await AxiosPostJson(Controller + "/Savedata", data);
    //            if (result.data.sStatus === Responsestart.success) {
    //                await LinkToListPage();
    //                await Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Save, null);
    //            } else if (result.data.sStatus === Responsestart.warning) {
    //                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
    //            } else {
    //                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
    //            }
    //        })
    //    } else {
    //        Sweetalert.Warning(BoxMsg.Title_Warning, "กรูณาอัพโหลดภาพประกอบ", null);
    //    }
    //};




    const GetT_Complaint_Group = async () => {
        let resulta: any = await AxiosGetJson(Controller + "GetV_ComplaintBysComplaintID?sComplaintID=" + "0");
        setV_Complaint(resulta);

        let result: any = await AxiosGetJson(Controller + "GetT_Complaint_Group");
        setT_Complaint_Group(result);
    }

    const onSave = async (values: any) => {
        V_Complaint.nID_Group = values.nID_Group;
        V_Complaint.sDetail = values.sDetail;
        V_Complaint.sEmail = values.sEmail;
        V_Complaint.sName = values.sName;
        V_Complaint.sSubject = values.sSubject;
        V_Complaint.sTel = values.sTel;
        V_Complaint.sUnit = values.sUnit;

        ConfirmLayout(async () => {
            let result: any = await AxiosPostJson(Controller + "SavedataMain", V_Complaint)
            if (result.data.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Desc_Success_Save, BoxMsg.Desc_Success_Save, () => {
                });
                setCaptchaActive(false);
                SetValue.setFieldsValue({
                    nID_Group: undefined,
                    sName: "",
                    sUnit: "",
                    sTel: "",
                    sEmail: "",
                    sSubject: "",
                    sDetail: "",
                })
                setInputCode("");
                setCaptchaActive(true);

            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }
        }, null, translate.TranslatType === "TH" ? BoxMsg.Title_Confirm : "Confirm", translate.TranslatType === "TH" ? BoxMsg.Desc_Confirm_Save : "Confirm to send contact us ?", translate.TranslatType)
    }

    const [sFilePath, setFilePath] = React.useState("");
    const SetBackground = async () => {
        let result: any = await AxiosGetJson("api/AdminInfo/GetBackground");
        setFilePath(result.sFilePath);
    }
    React.useEffect(() => { //ComponentDidmount
        GetT_Complaint_Group();
        SetBackground();
    }, [])



    //const [checkCAPTCHA, setcheckCAPTCHA] = React.useState(false as any);

    //const onChange = (value) => {
    //    setcheckCAPTCHA(true)
    //}

    //const onExpired = (value) => {
    //    setcheckCAPTCHA(false)
    //}

    return (

        <div id={props.id} className="page-form">
            <Form onFinish={onSave} form={SetValue}>
                <div className="container-fluid position-relative">
                    <div className="form-title"> <b>{translate.ContactUs}</b></div>
                    <div className="mt-4">
                        <div className="form-group">
                            <div className="text-honey">{translate.ContactTopic} <span className="text-danger ">*</span> </div>
                            <Form.Item
                                name="nID_Group"
                                rules={[
                                    { required: true, message: BoxMsg.Desc_Required_Field + translate.ContactTopic },
                                ]}
                            >

                                <AntdSelect
                                    allowClear={true}
                                    showSearch={true}
                                    style={{ width: "100%" }}
                                    placeholder={"- " + translate.ContactTopic + " -"}
                                    filterOption={(input, option) =>
                                        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {

                                        T_Complaint_Group.filter(f => f.isDel === false && f.isActive === true).map((p) => {
                                            return (
                                                <option key={"nID" + p.nID} value={p.nID} >{translate.TranslatType === "TH" ? p.sName : p.sName_EN}</option>
                                            )
                                        })
                                    }
                                </AntdSelect>
                            </Form.Item>
                        </div>

                        <div className="form-group">
                            <div className="text-honey">{translate.ContactName} <span className="text-danger ">*</span></div>
                            <Form.Item
                                name="sName"
                                rules={[
                                    { required: true, message: BoxMsg.Desc_Required_Field + translate.ContactName },
                                ]}
                            >
                                <Input autoComplete="off" />
                            </Form.Item>

                        </div>
                        <div className="form-group">
                            <div className="text-honey">{translate.ContactAgency} <span className="text-danger ">*</span></div>
                            <Form.Item
                                name="sUnit"
                                rules={[
                                    {
                                        required: true,
                                        message: BoxMsg.Desc_Required_Field + translate.ContactAgency
                                    },
                                ]}
                            >
                                <Input autoComplete="off" />
                            </Form.Item>
                        </div>
                        <div className="form-group">
                            <div className="text-honey">{translate.TelephoneNumber}</div>
                            <Form.Item
                                name="sTel"
                                rules={[
                                    {
                                        pattern: /^(0([2689])([0-9])([0-9]{3,})([0-9]{3,}))/,
                                        message: 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง',
                                    },
                                ]}
                            >
                                <Input autoComplete="off" />
                            </Form.Item>


                        </div>
                        <div className="form-group">
                            <div className="text-honey">{translate.Email} <span className="text-danger ">*</span></div>
                            <Form.Item
                                name="sEmail"
                                rules={[
                                    {
                                        required: true,
                                        message: BoxMsg.Desc_Required_Field + "E-mail"
                                    },
                                    { type: 'email', message: 'รูปแบบอีเมลไม่ถูกต้อง (เช่น example@email.com)' },
                                ]}
                            >
                                <Input autoComplete="off" />
                            </Form.Item>
                        </div>
                        <div className="form-group">
                            <div className="text-honey">{translate.Subject} <span className="text-danger ">*</span></div>
                            <Form.Item
                                name="sSubject"
                                rules={[
                                    { required: true, message: BoxMsg.Desc_Required_Field + translate.Subject },
                                ]}
                            >
                                <Input autoComplete="off" />
                            </Form.Item>
                        </div>
                        <div className="form-group">
                            <div className="text-honey">{translate.Details} <span className="text-danger ">*</span></div>
                            <Form.Item
                                name="sDetail"
                                rules={[
                                    {
                                        required: true,
                                        message: BoxMsg.Desc_Required_Field + translate.Details
                                    },
                                ]}
                            >
                                <Input.TextArea maxLength={250} rows={4} />
                            </Form.Item>
                        </div>
                        <div className="form-group">
                            <div className="form-row justify-content-center">
                                <div className="col-ato">


                                    {CaptchaActive ?
                                        <ClientCaptcha captchaCode={code => setCode(code)}
                                            retryIcon={require('../../Images/5630.jpg')}

                                        />
                                        : null
                                    }

                                </div>
                                <div className="col-ato" style={{ marginTop: "5px" }}  >
                                    <Input value={InputCode}
                                        autoComplete="off"
                                        maxLength={10}
                                        onChange={(e) =>
                                            setInputCode(e.target.value)
                                        }
                                    />


                                </div>
                            </div>
                        </div>
                        <div className="form-row justify-content-center">
                            <Form.Item >
                                <Button disabled={Code === InputCode ? false : true} htmlType="submit" size="middle" shape="round" style={{ background: "#60cc28", border: "none", color: "#ffffff" }} >
                                    <i className="fas fa-paper-plane"></i>&nbsp;{translate.Send}
                                </Button>
                                {
                                    //<ButtonSave htmlType="submit" onClick={() => { }} />
                                }
                            </Form.Item>
                        </div>
                    </div>
                </div>


            </Form>
        </div>



    );
}
export default Form_Main;

function LinkToListPage() {
    throw new Error('Function not implemented.');
}
