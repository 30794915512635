import { useDispatch } from "react-redux";
import axios from "axios";
import * as Crypto from "crypto-js";
import Swal, { SweetAlertIcon } from "sweetalert2";
import { ButtonCancel } from "./button_all";
import AuthenService from "../Service/AuthenService";

export const arrShortMonth = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];
export const fontFamily = {
    "Roboto,sans-serif": 'Roboto',
    "Oswald,sans-serif": 'Oswald',
    "Montserrat,sans-serif": 'Montserrat',
    "'Open Sans Condensed',sans-serif": 'Open Sans Condensed',
    "Prompt,sans-serif": 'Prompt',
    "Pattaya,sans-serif": 'Pattaya',
    "Mitr,sans-serif": 'Mitr',
    "Chonburi,sans-serif": 'Chonburi',
    "Sriracha,sans-serif": 'Sriracha',
    "Srisakdi,sans-serif": 'Srisakdi',
    "Charmonman,sans-serif": 'Charmonman',
    "iannnnn-DUCK": 'iannnnn-DUCK',
    "Anuphan": 'Anuphan',
    "DB Sathorn X": 'DB Sathorn'
};


export var quickInsertButtons = [
    "fullscreen",
    "bold",
    "textColor",
    "fontFamily",
    "fontSize",
    "emoticons",
    "image",
    "table",
    "inlineStyle",
    "paragraphStyle",
    "paragraphFormat",
    "align",
    "formatOL",
    "formatUL",
    "outdent",
    "indent",
    "link",
    "video",
    "codeView",


];



export var pluginsEnabled = [
    "fullscreen",
    "bold", "colors",
    "fontFamily",
    "fontSize",
    "emoticons",
    "image",
    "table",
    "inlineStyle",
    "paragraphStyle",
    "paragraphFormat",
    "align",
    "formatOL",
    "formatUL",
    "outdent",
    "indent",
    "link",
    "video",
    "codeView",
];







export var froalaconfigs = {
    placeholderText: "",
    attribution: false,
    key: "0BA3jA11B5C7C4A4D3aIVLEABVAYFKc2Cb1MYGH1g1NYVMiG5G4E3C3A1C8A6D4A3B4==",
    imageUploadURL: process.env.REACT_APP_URL + "api/UploadTextEditor/uploadImage",
    videoUploadURL: process.env.REACT_APP_URL + "api/UploadTextEditor/uploadImage",
    //toolbarBottom: false,
    quickInsertButtons: [
        "fullscreen",
        "bold",
        "textColor",
        "fontFamily",
        "fontSize",
        "emoticons",
        "image",
        "table",
        "inlineStyle",
        "paragraphStyle",
        "paragraphFormat",
        "align",
        "formatOL",
        "formatUL",
        "outdent",
        "indent",
        "link",
        "video",
    ],
    pluginsEnabled: [
        "fullscreen",
        "bold", "colors",
        "fontFamily",
        "fontSize",
        "emoticons",
        "image",
        "table",
        "inlineStyle",
        "paragraphStyle",
        "paragraphFormat",
        "align",
        "formatOL",
        "formatUL",
        "outdent",
        "indent",
        "link",
        "video",
    ]
};




export const ColumnWidthInput = {
    Delete: 50,
    No: 70,
    General: 200,
    Specific: 300,
    Unit: 150,
    Source: 250,
    Month: 160,
};

export const TooltipsMSG = {
    Add: "Add",
    Edit: "Edit",
    Delete: "Delete",
    View: "View",
    Search: "Search",
    Save: "Save",
    Approve: "Approve",
    Reject: "Reject",
    Cancel: "Cancel",
    Logout: "Logout",
    ReviewContent: "Review Content",
    File: "File",
    DownloadTemplate: "Download Template",
    Download: "Download",
    Close: "Close",
    MasterData: "Master Data",
    DocRef: "Document reference",
    Check: "Check",
    Submit: "Submit",
    NewRegistration: "Registration",
    Withdraw: "Withdraw",
    SendToVendor: "Send To Vendor",
    ViewCategory: "View Category",
    SpecialRequest: "Request Special Approve",
    NotApprove: "Not Approve",
    ReviewforAction: "Review for Action",
    Accept: "Accept",
    Next: "Next",
    Back: "Back",
    //Finished: "ยืนยันการใช้งานเสร็จสิ้น",
    //Extend: "ขอขยาย Expire Date",
    OK: "OK",
};
export const AlertTitle = {
    Success: "Action Completed",
    Warning: "Warning",
    Error: "Error",
    Info: "Information",
    Confirm: "Confirmation",
    Hint: "Hint",
    Duplicate: "Duplicate",
};

export const AlertMsg = {
    SaveComplete: "Data is already saved.",
    DeleteComplete: "Data is already deleted.",
    Warning: "Warning",
    Error: "Some thing went wrong",
    ConfirmSave: "Do you want to save data?",
    ConfirmDelete: "Do you want to delete data?",
    UploadImage: "Please upload image",
};

export const Process_System = {
    process_SessionExpire: "SSEXP",
    process_Duplicate: "DUP",
    process_Success: "Success",
    process_Error: "Error",
};

export const AlertButtonText = {
    OK: "OK",
    Cancel: "Cancel",
    Close: "Close",
    Yes: "Yes",
    Confirm: "Confirm",
};

export const SwAlert_Title = (sTitle: any) => {
    return "<strong>" + sTitle + "</strong>";
};

export const actionBlockUI = () => {
    return { type: "BLOCK_UI" };
};

export const actionUnBlockUI = () => {
    return { type: "UNBLOCK_UI" };
};

export const FnBlock_UI = () => {
    const Dispatch = useDispatch();
    const BlockUI = () => {
        Dispatch(actionBlockUI());
    };
    const UnBlockUI = () => Dispatch(actionUnBlockUI());
    return { BlockUI, UnBlockUI };
};

export const scrollToElementValidate = (formID?: any) => {
    let elForm: any = formID
        ? (document.querySelector("form[id=" + formID + "]") as any)
        : document.querySelector("form");
    let elByClass = elForm.querySelectorAll("div.Mui-error") as any;
    if (elByClass && elByClass.length > 0)
        elByClass[0].scrollIntoView({ behavior: "smooth", block: "center" });
};

export const lnkToLogin = () => {
    let el = document.getElementById("lnkToLogin") as any;
    el && el.click();
};

export const lnkToNotPermission = () => {
    let el = document.getElementById("NotPermission") as any;
    el && el.click();
};

export const Effect = (props: any) => {
    const effect = () => {
        if (props.formik.submitCount > 0 && !props.formik.isValid) {
            props.onSubmissionError();
        }
    };
    props.useEffect(effect, [props.formik.submitCount, props.formik.errors]);
    return null;
};

export const IsNumberic = (sVal: any) => {
    sVal = (sVal + "").replace(/,/g, "");
    return !isNaN(sVal);
};
export const addCommas = (nStr: any) => {
    nStr += "";
    let x = nStr.split(".");
    let x1 = x[0];
    let x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1,$2");
    }
    return x1 + x2;
};
export const SetFormatNumber = (nNumber: any, nDecimal: any, sEmpty: any) => {
    if (IsNumberic(nNumber)) {
        if (IsNumberic(nDecimal)) return addCommas(nNumber.toFixed(nDecimal));
        else return addCommas(nNumber);
    } else {
        return !nNumber ? (sEmpty === undefined ? "" : sEmpty) : nNumber;
    }
};
const URL_API = process.env.REACT_APP_API_URL + "api/";
export const autoCompleteDataSource = async (
    Controller: any,
    MethodName: any,
    ObjSearch: any
) => {
    const res = await axios.post(
        URL_API + Controller + "/" + MethodName,
        ObjSearch
    );
    return res;
};

const keyCrypto = "P4ssw0rd3ndCr7pt";
export const Encrypt = (dataEncrypt: any) => {
    let data = dataEncrypt + "";

    let result = Crypto.AES.encrypt(data, keyCrypto).toString();
    result = result.replace(/\//g, "s14sh").replace(/\+/g, "p1u5");

    return result;
};
export const Decrypt = (dataDecrypt: any) => {
    if (dataDecrypt != null) {
        dataDecrypt = dataDecrypt + "";
        dataDecrypt = dataDecrypt.replace(/s14sh/g, "/").replace(/p1u5/g, "+");
        let bytes = Crypto.AES.decrypt(dataDecrypt, keyCrypto);
        let result = bytes.toString(Crypto.enc.Utf8);
        return result;
    } else {
        return "";
    }
};

export const TableRowColor = {
    OutputTotal: "rgb(167, 215, 113)",
    RowCollapse: "rgb(167, 215, 113)",
    OutputSubTotal1: "rgb(223, 237, 161)",
};

export const BackToComponent = (lnkBack: any) => {
    var elLink = document.getElementById(lnkBack) as any;
    if (elLink) {
        elLink.click();
    }
};

export const RegexpPasswork = () => {
    let regexp1 = "((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}))"; //พิมพ์ใหญ่และตัวเลขและอักขระพิเศษ
    let regexp2 = "((?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])(?=.{8,}))";
    let regexp3 = "((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}))";
    let regexp4 = "((?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}))";
    let regexp5 = "((?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,}))";
    let regexp6 = "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}))"; //พิมพ์เล็กและพิมพ์ใหญ่และตัวเลขและอักขระพิเศษ
    let egexp =
        "^(" +
        regexp1 +
        "|" +
        regexp2 +
        "|" +
        regexp3 +
        "|" +
        regexp4 +
        "|" +
        regexp5 +
        "|" +
        regexp6 +
        ")";
    return egexp;
};
export const MsgValidate = {
    PleaseSpecify: "Please Specify ",
    Passwork:
        "Passwords must have at least 8 characters and contain at least three of the following: uppercase letters, lowercase letters, numbers, symbols.",
    InvalidEmail: "Invalid Email",
    ConfirmPassword: "Invalid Password",
};

export const MsgValidateMaxLength = (sField: any, nMaxLength: any) => {
    return sField + " must be at most " + nMaxLength + " characters";
};

export const resetFromData = (formProps: any, sFild: any, sValue: any) => {
    formProps.setFieldValue(sFild, sValue, false);
    formProps.setFieldError(sFild, "", false);
};
export const resetFromSubmitData = (
    setFieldValue: any,
    setFieldError: any,
    sFild: any,
    sValue: any
) => {
    setFieldValue(sFild, sValue, false);
    setFieldError(sFild, "", false);
};

export const exportsFileSave = (
    data: any,
    filename: any,
    mime?: any,
    bom?: any
) => {
    var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
    var blob = new Blob(blobData, { type: mime || "application/octet-stream" });
    if (typeof window.navigator.msSaveBlob !== "undefined") {
        window.navigator.msSaveBlob(blob, filename);
    } else {
        var blobURL =
            window.URL && window.URL.createObjectURL
                ? window.URL.createObjectURL(blob)
                : window.webkitURL.createObjectURL(blob);
        var tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = blobURL;
        tempLink.setAttribute("download", filename);
        if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        setTimeout(function () {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }, 200);
    }
};

export const BoxMsg = {
    Title_Confirm: 'ยืนยันการทำงาน',
    Title_Warning: 'แจ้งเตือน',
    Title_Success: 'การทำงานเสร็จสิ้น',
    Title_Error: 'การทำงานผิดพลาด',

    // Title_Confirm: 'Confirm action',
    // Title_Warning: 'Warning',
    // Title_Success: 'Action finished',
    // Title_Error: 'Something went wrong',

    //Desc_Confirm_Delete: 'Do you want to delete the selected data?',
    //Desc_Warning_Delete: 'Please check <i class="fa fa-check-square"></i> the record(s) you want to delete',
    //Desc_Success_Delete: 'Deleting completed',

    //Desc_Confirm_Cancel: 'Do you want to unsaved this?',
    //Desc_Confirm_ChangeOrder: 'Do you want to change sequence?',
    //Desc_Confirm_Back: 'Do you want to leave this page?',

    //Desc_Confirm_Save: 'Do you want to save data?',
    //Desc_Confirm_Submit: 'Do you want to submit data?',
    //Desc_Confirm_SaveDraft: 'Do you want to save draft data?',
    //Desc_Warning_Save: 'Please provide full information before proceeding.',
    //Desc_Success_Save: 'Saving completed',

    //Desc_Warning_FinishForm: 'Please finish form before saving.',

    //Desc_Error: 'There is working problem. The system can not continue. Please contact the administrator.',

    //Desc_Warning_Login: 'Please enter a valid username and password.',
    //Desc_Confirm_Logout: 'Do you want to logout?',

    //Desc_Warning_SessionExpire: 'Session has expired, Please login again.',

    //Desc_Warning_NoPermission: 'You have no permission for access this page',

    Desc_Confirm_Delete: 'ต้องการลบข้อมูลที่เลือกหรือไม่?',
    Desc_Warning_Delete: 'โปรดตรวจสอบ <i class="fa fa-check-square"></i> รายการที่จะทำการลบ',
    Desc_Success_Delete: 'ลบข้อมูลเสร็จสิ้น',

    Desc_Confirm_Cancel: 'ต้องการยกเลืกหรือไม่?',
    Desc_Confirm_ChangeOrder: 'ต้องการเปลี่ยนลำดับหรือไม่?',
    Desc_Confirm_Back: 'ต้องการออกจากหน้านี้หรือไม่?',

    Desc_Confirm_Save: 'ต้องการบันทึกหรือไม่?',
    Desc_Confirm_Submit: 'ต้องการบันทึกข้อมูลหรือไม่?',
    Desc_Confirm_SaveDraft: 'ต้องการบันทึกแบบร่างหรือไม่?',
    Desc_Warning_Save: 'โปรดตรวจสอบข้อมูลก่อนบันทึกข้อมูล',
    Desc_Success_Save: 'บันทึกข้อมูลเสร็จสิ้น',

    Desc_Warning_FinishForm: 'โปรดกรุณากรอกข้อมูลให้ครบถุ้วน',

    Desc_Error: 'ระบบเกิดข้อขัดข้องไม่สามารถทำงานต่อได้ โปรดติดต่อผู้ดูแลระบบ',

    Desc_Warning_Login: 'โปรดระบุรหัสผู้ใช้งานและรหัสผ่าน',
    Desc_Confirm_Logout: 'ต้องการออกจากระบบหรือไม่?',

    Desc_Warning_SessionExpire: 'เซสชั่นหมดอายุ กรูณาเข้าสู่ระบบใหม่',

    Desc_Warning_NoPermission: 'ไม่มีสิทธิ์เข้าถึง',
    Desc_Required_Field: 'โปรดระบุ',
    Desc_Required_Field_File: 'โปรดแนบ',
};

export const DialogConfirm = (funcYes: any, funcNo?: any, title = "", message = "") => {
    Sweetalert.Confirm(title === "" ? BoxMsg.Title_Confirm : title, message === "" ? BoxMsg.Desc_Confirm_Save : message, funcYes, funcNo);
};
export const DialogConfirmSubmit = (funcYes: any, funcNo?: any, title = "", message = "") => {
    Sweetalert.Confirm(title === "" ? BoxMsg.Title_Confirm : title, message === "" ? BoxMsg.Desc_Confirm_Submit : message, funcYes, funcNo);
};
export const DialogConfirmSaveDraft = (funcYes: any, funcNo?: any, title = "", message = "") => {
    Sweetalert.Confirm(title === "" ? BoxMsg.Title_Confirm : title, message === "" ? BoxMsg.Desc_Confirm_SaveDraft : message, funcYes, funcNo);
};
export const DialogDelete = (funcYes: any, funcNo?: any, title = "", message = "") => {
    Sweetalert.Confirm(title === "" ? BoxMsg.Title_Confirm : title, message === "" ? BoxMsg.Desc_Confirm_Delete : message, funcYes, funcNo);
};
export const DialogLogout = (funcYes: any, funcNo?: any, title = "", message = "") => {
    Sweetalert.Confirm(title === "" ? BoxMsg.Desc_Confirm_Logout : title, "", funcYes, funcNo);
};
export const ConfirmLayout = (funcYes: any, funcNo?: any, title = "", message = "", cLanguage = "") => {
    Sweetalert.ConfirmLayout(title === "" ? BoxMsg.Title_Confirm : title, message === "" ? BoxMsg.Desc_Confirm_Save : message, funcYes, funcNo, cLanguage);
};





export const AlertIcon = {
    info: "info" as SweetAlertIcon,
    success: "success" as SweetAlertIcon,
    error: "error" as SweetAlertIcon,
    warning: "warning" as SweetAlertIcon,
    question: "question" as SweetAlertIcon,
    confirm: "Confirmation" as SweetAlertIcon,
};
export const Responsestart = {
    success: "Success",
    error: "Error",
    warning: "Warning",
    SSEXP: "SSEXP",
    failed: "Failed",
};

export const Sweetalert = {
    Warning: function (sTitle: any, sMessage: any, fnOK?: any) {
        Swal.fire({
            icon: AlertIcon.warning,
            title: sTitle,
            html: sMessage,
            //confirmButtonText: "Ok",
            confirmButtonText: "ปิด",
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
                confirmButton: 'swal2-border-radius'
            },
        }).then(function (result) {
            if (result.value) {
                if (fnOK) {
                    fnOK();
                } else {
                    Swal.close();
                }
            }
        });
    },
    WarningEN: function (sTitle: any, sMessage: any, fnOK?: any) {
        Swal.fire({
            icon: AlertIcon.warning,
            title: sTitle,
            html: sMessage,
            confirmButtonText: "Ok",
            // confirmButtonText: "ปิด",
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
                confirmButton: 'swal2-border-radius'
            },
        }).then(function (result) {
            if (result.value) {
                if (fnOK) {
                    fnOK();
                } else {
                    Swal.close();
                }
            }
        });
    },
    Success: function (sTitle: any, sMessage: any, fnOK?: any) {
        Swal.fire({
            icon: AlertIcon.success,
            title: sTitle,
            //confirmButtonText: "Ok",
            confirmButtonText: "ปิด",
            html: sMessage || BoxMsg.Desc_Success_Save,
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
                confirmButton: 'swal2-border-radius'
            },
        }).then(function (result) {
            if (result.value) {
                if (fnOK) {
                    fnOK();
                } else {
                    Swal.close();
                }
            }
        });
    },
    SuccessEN: function (sTitle: any, sMessage: any, fnOK?: any) {
        Swal.fire({
            icon: AlertIcon.success,
            title: sTitle,
            confirmButtonText: "Ok",
            //confirmButtonText: "ปิด",
            html: sMessage || BoxMsg.Desc_Success_Save,
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
                confirmButton: 'swal2-border-radius'
            },
        }).then(function (result) {
            if (result.value) {
                if (fnOK) {
                    fnOK();
                } else {
                    Swal.close();
                }
            }
        });
    },
    Error: function (sTitle: any, sMessage: any, fnOK?: any) {
        Swal.fire({
            icon: AlertIcon.error,
            title: sTitle || "Error",
            html: sMessage,
            //confirmButtonText: "Ok",
            confirmButtonText: "ปิด",
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
                confirmButton: 'swal2-border-radius'
            },
        }).then(function (result) {
            if (result.value) {
                if (fnOK) {
                    fnOK();
                } else {
                    Swal.close();
                }
            }
        });
    },
    ErrorEN: function (sTitle: any, sMessage: any, fnOK?: any) {
        Swal.fire({
            icon: AlertIcon.error,
            title: sTitle || "Error",
            html: sMessage,
            confirmButtonText: "Ok",
            // confirmButtonText: "ปิด",
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
                confirmButton: 'swal2-border-radius'
            },
        }).then(function (result) {
            if (result.value) {
                if (fnOK) {
                    fnOK();
                } else {
                    Swal.close();
                }
            }
        });
    },
    Confirm: function (sTitle: any, sMessage: any, fnYes?: any, fnNo?: any) {
        Swal.fire({
            title: sTitle,
            html: sMessage,
            icon: AlertIcon.question,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#B2BABB",
            //confirmButtonText: '<i class="fas fa-check"></i> Confirm',
            //cancelButtonText: '<i class="fas fa-times"></i> Cancel',
            confirmButtonText: '<i class="fas fa-check"></i> ยืนยัน',
            cancelButtonText: '<i class="fas fa-times"></i> ยกเลิก',

            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            customClass: {
                cancelButton: 'swal2-border-radius',
                confirmButton: 'swal2-border-radius'
            },
            preConfirm: function () {
                return new Promise(function () {
                    //resolve, reject
                    Swal.disableButtons();

                    if (fnYes) {
                        fnYes();
                    } else {
                        Swal.close();
                    }
                });
            },
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.cancel) {
                if (fnNo) {
                    fnNo();
                } else {
                    Swal.close();
                }
            }
        });
    },
    ConfirmEN: function (sTitle: any, sMessage: any, fnYes?: any, fnNo?: any) {
        Swal.fire({
            title: sTitle,
            html: sMessage,
            icon: AlertIcon.question,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#B2BABB",
            confirmButtonText: '<i class="fas fa-check"></i> Confirm',
            cancelButtonText: '<i class="fas fa-times"></i> Cancel',
            // confirmButtonText: '<i class="fas fa-check"></i> ยืนยัน',
            // cancelButtonText: '<i class="fas fa-times"></i> ยกเลิก',

            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            customClass: {
                cancelButton: 'swal2-border-radius',
                confirmButton: 'swal2-border-radius'
            },
            preConfirm: function () {
                return new Promise(function () {
                    //resolve, reject
                    Swal.disableButtons();

                    if (fnYes) {
                        fnYes();
                    } else {
                        Swal.close();
                    }
                });
            },
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.cancel) {
                if (fnNo) {
                    fnNo();
                } else {
                    Swal.close();
                }
            }
        });
    },
    ConfirmLayout: function (sTitle: any, sMessage: any, fnYes?: any, fnNo?: any, cLanguage?: any) {
        Swal.fire({
            title: sTitle,
            html: sMessage,
            icon: AlertIcon.question,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#B2BABB",
            //confirmButtonText: '<i class="fas fa-check"></i> Confirm',
            //cancelButtonText: '<i class="fas fa-times"></i> Cancel',
            confirmButtonText: cLanguage === "TH" ? '<i class="fas fa-check"></i> ยืนยัน' : '<i class="fas fa-check"></i> Confirm',
            cancelButtonText: cLanguage === "TH" ? '<i class="fas fa-times"></i> ยกเลิก' : '<i class="fas fa-times"></i> Cancel',

            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            customClass: {
                cancelButton: 'swal2-border-radius',
                confirmButton: 'swal2-border-radius'
            },
            preConfirm: function () {
                return new Promise(function () {
                    //resolve, reject
                    Swal.disableButtons();

                    if (fnYes) {
                        fnYes();
                    } else {
                        Swal.close();
                    }
                });
            },
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.cancel) {
                if (fnNo) {
                    fnNo();
                } else {
                    Swal.close();
                }
            }
        });
    },


    Close: Swal.close
};

export const setNameFormatReport = (type) => {
    var today = new Date();
    let day = today.getDate() < 10 ? ("0" + today.getDate().toString()) : today.getDate().toString();
    let month = (today.getMonth() + 1) < 10 ? ("0" + (today.getMonth() + 1).toString()) : (today.getMonth() + 1).toString();
    let hour = today.getHours() < 10 ? ("0" + today.getHours().toString()) : today.getHours().toString();
    let minute = today.getMinutes() < 10 ? ("0" + today.getMinutes().toString()) : today.getMinutes().toString();
    let second = today.getSeconds() < 10 ? ("0" + today.getSeconds().toString()) : today.getSeconds().toString();
    let date = today.getFullYear() + month + day + + hour + minute + second;
    return type + "_" + date;
}
