import * as React from 'react';
// import Admin_Content from '../admin-content/admin-content2'
import Admin_Content from '../admin-content/new-content/admin-content'
import { parse } from "query-string"

const admin_hl_project_edit = () => {
    return (
        <Admin_Content nPerMenuId={7} sType={"3"} backEvent={"/admin-hl-project-list"} />
    );
}
export default admin_hl_project_edit;