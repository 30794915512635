import * as React from 'react';
import Admin_Content_Sub from '../admin-content/admin-content-sub-edit'
import Admin_Content_Index_Edit from '../admin-content/new-content/admin-content-index-edit'
import { parse } from "query-string"

const Admin_hl_project_sub_edit = (props) => {
    return (
        // <Admin_Content_Sub nPerMenuId={7} sType={"4"} backEvent={"/admin-hl-project-list"} />\
        <Admin_Content_Index_Edit nPerMenuId={7} sType={"4"} backEvent={"/admin-hl-project-list"} />
    );
}
export default Admin_hl_project_sub_edit;