import * as React from "react";
import { AxiosGetJson } from "../Service/Config/AxiosMethod";
import { useHistory } from "react-router";

const IndexPage = () => {
    const history = useHistory();

    const CheckIntro = async () => {
        var result = await AxiosGetJson("api/Home/CheckIntro");
        if (result) {
            GoToIntro();
        }
        else {
            GoToHome();
        }
    }

    const GoToIntro = () => {
         history.push({
             pathname: "/index",
         });
    }

    const GoToHome = () => {
        history.push({
            pathname: "/home",
        });
    }

    React.useEffect(() => {
        CheckIntro();
    });
    return <></>;
}
export default IndexPage;