import { Col, Form, Input, Row, Switch } from "antd";
import * as React from "react";
import { useHistory } from "react-router";
import { ButtonBack, ButtonDelete, ButtonSave, Button_Cancel } from "../Systems/button_all";
import TableAntd from "../Systems/TableAntd";
import { parse } from "query-string";
import { BoxMsg, Decrypt, DialogConfirm, DialogDelete, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import AuthenService from "../Service/AuthenService";

const Controller = 'api/AdminUserPermission/';

const Admin_Userpermission_EditGroup = () => {
    const [SetValue] = Form.useForm();
    const history = useHistory();
    const [V_AdminMenu, setV_AdminMenu] = React.useState([] as any);
    const [V_AdminGroup, setV_AdminGroup] = React.useState([] as any);
    var { sID } = parse(window.location.search);
    sID = sID && Decrypt(sID)
    const [total, setTotal] = React.useState(0);

    React.useEffect(() => {
        GetDataOnPageLoad();
        GetV_AdminMenubysID(undefined, undefined, undefined, undefined);
        GetUserInfo();
    }, []);

    const [isEdit, setisEdit] = React.useState(false);
    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            setisEdit(UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == 11).map(t => t.nPermission)[0] === 2 ? false : true)
        }
    }

    const GetDataOnPageLoad = async () => {
        let result: any = await AxiosGetJson(Controller + "GetV_AdminGroupbysID?sID=" + (sID === undefined ? "0" : sID));
        setV_AdminGroup(result);
        SetValue.setFieldsValue({
            sName: result.sName,
            isActive: result.isActive
        })

    }


    const GetV_AdminMenubysID = async (sPage, sPageSize, sField, sOrder) => {
        let Page = sPage == undefined ? sPageA : sPage;
        let PageSize = sPageSize == undefined ? sPageSizeA : sPageSize;
        let Field = sField == undefined ? sFieldA : sField;
        let Order = sOrder == undefined ? sOrderA : sOrder;

        let resulta: any = await AxiosGetJson(Controller + "GetV_AdminMenubysID?sID=" + (sID === undefined ? "0" : sID) + "&sPage=" + Page + "&sPageSize=" + PageSize + "&sField=" + Field + "&sOrder=" + Order);
        setTotal(resulta.nTotal);
        setV_AdminMenu(resulta.V_AdminMenu);

    }
    const header = [
        {
            title: 'ลำดับที่',
            dataIndex: 'key',
            key: 'key',
            width: 100,
            className: "text-center",
            render: (value) => {
                return (<>{value}.</>);
            },
        },
        {
            title: 'เมนู',
            dataIndex: 'sName',
            key: 'sName'
        },
        {
            title: 'สิทธิ์การใช้งาน',
            children: [
                {
                    title: 'จำกัดสิทธิ์',
                    dataIndex: 'nPermission',
                    key: 'nPermission',
                    className: "text-center",
                    render: (text, record, id) => (

                        <input disabled={!isEdit} type="radio" name={"radio" + record.nID} value="1" onChange={(e) => { ChangeRadio(e.target.value, id); }} checked={record.nPermission === 1 ? true : false} />


                    ),
                    width: 200,
                },
                {
                    title: 'อ่านเท่านั้น',
                    dataIndex: 'nPermission',
                    key: 'nPermission',
                    width: 200,
                    className: "text-center",
                    render: (text, record, id) => (
                        <input disabled={!isEdit} type="radio" name={"radio" + record.nID} value="2" onChange={(e) => { ChangeRadio(e.target.value, id); }} checked={record.nPermission === 2 ? true : false} />

                    ),
                },
                {
                    title: 'อนุญาต (เพิ่ม/แก้ไข/ลบ)',
                    dataIndex: 'nPermission',
                    key: 'nPermission',
                    width: 200,
                    className: "text-center",
                    render: (text, record, id) => (


                        <input disabled={!isEdit} type="radio" name={"radio" + record.nID} value="3" onChange={(e) => { ChangeRadio(e.target.value, id); }} checked={record.nPermission === 3 ? true : false} />

                    ),
                }
            ]
        }
    ];
    //*****************************TableAntd
    const [selectedRows, setselectedRows] = React.useState([] as Array<any>);
    const [selectedRowKeys, setrowSelected] = React.useState([] as any);
    const [sPageA, setsPageA] = React.useState("1" as string || undefined);
    const [sPageSizeA, setsPageSizeA] = React.useState("10" as string || undefined);
    const [sFieldA, setsFieldA] = React.useState("" as string || undefined);
    const [sOrderA, setsOrderA] = React.useState("" as string || undefined);
    const [nCurrentPage, setCurrentPage] = React.useState(1);

    const onselect = (selectedRowKeys, selectedRows: React.SetStateAction<any[]>) => {
        setselectedRows(selectedRows);
        setrowSelected(selectedRowKeys);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onselect,
        getCheckboxProps: (record) => ({
            disabled: record.sIsuse === 'Y', // Column configuration not to be checked
            sIsuse: record.sIsuse,
        }),
    };

    const onPageChange = async (page, pageSize, sorter) => {
        onselect([], []);
        setsPageA(page);
        setsPageSizeA(pageSize);
        setCurrentPage(page);
        window.scrollTo(0, 0);
        GetV_AdminMenubysID(page, pageSize, undefined, undefined);
    }
    const onTableChange = async (pagination, filters, sorter, extra) => {
        if (sorter) {
            onselect([], []);
            setsFieldA(sorter.field);
            setsOrderA(sorter.order);
        }
        GetV_AdminMenubysID(undefined, undefined, sorter.field, sorter.order);
    }
    //*****************************TableAntd
    const ChangeRadio = async (data, id) => {
        let lists = [...V_AdminMenu]
        lists[id].nPermission = parseInt(data);
        setV_AdminMenu(lists);
    }
    const onSave = (values: any) => {
        let data = {
            sName: values.sName,
            isActive: values.isActive,
            V_AdminMenu: V_AdminMenu
        }
        DialogConfirm(async () => {
            let result: any = await AxiosPostJson(Controller + "SaveData", data);
            if (result.data.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Save, () => {
                    onBack();
                });
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }
        })
    };

    const onBack = () => {
        history.push("/admin-user-permission-list?sMode=1");
    };

    const onDelete = async () => {

        if (sID != undefined) {

            DialogDelete(async () => {
                let result: any = await AxiosPostJson(Controller + "onDeleteBysIDGroup?sID=" + (sID === undefined ? "0" : sID));
                if (result.data.sStatus === Responsestart.success) {
                    Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete, null);
                    onBack();
                } else if (result.data.sStatus === Responsestart.warning) {
                    Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                } else {
                    Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                }
            });
        }

    };

    return (
        <React.Fragment>
            <Form
                onFinish={onSave}
                form={SetValue}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <b> ชื่อกลุ่ม </b> <span className="text-danger ">*</span>
                        <Form.Item
                            name="sName"
                            rules={[{ required: true, message: BoxMsg.Desc_Required_Field + "ชื่อกลุ่ม" }]}

                        >
                            <Input disabled={!isEdit} maxLength={200} autoComplete="off" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <b>สิทธิ์การใช้งาน </b><span className="text-danger ">*</span>
                        <div className="ant-spin-nested-loading">
                            <div className="ant-spin-container">
                                <div className="ant-table">
                                    <div className="ant-table-container">
                                        <div className="ant-table-content">
                                            <TableAntd
                                                dataSource={V_AdminMenu}
                                                columns={header}
                                                onPageChange={onPageChange}
                                                currentPage={nCurrentPage}
                                                total={total}
                                                //rowSelection={rowSelection}
                                                onChange={onTableChange}
                                                onDisableDelete={selectedRows.length > 0 ? false : true}
                                                onShowDelete={false}
                                                onShowPagination={false}
                                                scroll={{ x: 950 }}
                                            //onClickDeleteData={onDeleteClick}
                                                pageSize={sPageSizeA ? parseInt(sPageSizeA) : 10}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <br />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <b>สถานะ</b>
                        <Form.Item name="isActive" valuePropName="checked">
                            <Switch disabled={!isEdit} defaultChecked={true} checkedChildren="ใช้งาน" unCheckedChildren="ไม่ใช้งาน" style={{width : "100px"}} />
                        </Form.Item>
                    </Col>
                </Row>
                <hr />
                <Row gutter={[8, 16]} justify="center">
                    {isEdit ?
                        <React.Fragment>
                            <Col>
                                <ButtonSave disabled={!isEdit} htmlType="submit" />
                            </Col>
                            {
                                sID === undefined || V_AdminGroup.sIsuse === "Y" ?
                                    null
                                    :
                                    <Col>
                                        <ButtonDelete onClick={() => { onDelete() }} />
                                    </Col>
                            }
                        </React.Fragment>
                        : null}
                    <Col>
                        <Button_Cancel onClick={() => { history.push("/admin-user-permission-list?sMode=1") }} />
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}
export default Admin_Userpermission_EditGroup;