import * as React from 'react';
// import Main_Content_Index from '../main-content/content-index'
import Main_Content_Index from '../main-content/new-content/content-index'

const HL_Project_Index = (props) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Main_Content_Index sType={"6"} />
    );
}
export default HL_Project_Index;