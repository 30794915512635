import * as React from 'react';
// import Main_Content_List from '../main-content/content-list'
import Main_Content_List from '../main-content/new-content/content-list'

const Knowledge_List = (props) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Main_Content_List sType={"7"} />
    );
}
export default Knowledge_List;
