import { Checkbox, Col, Form, Input, Radio, Row, Select, Space, Switch, Tag } from "antd";
import * as React from "react";
import { Empty, Select as AntdSelect } from "antd";
import { ButtonAdd_Table, ButtonBack, ButtonDelete, ButtonEdit_Table, ButtonSave, ButtonSearch, Button_Cancel } from "../../Systems/button_all";
import TableAntd from "../../Systems/TableAntd";
import { useHistory } from "react-router";
import { BoxMsg, DialogConfirm, DialogDelete, froalaconfigs, Encrypt, Responsestart, Sweetalert, pluginsEnabled, quickInsertButtons, fontFamily } from "../../Systems/SystemComponent";
import { AxiosGetJson, AxiosPostJson } from "../../Service/Config/AxiosMethod";
import AuthenService from "../../Service/AuthenService";
import ThaiDatePicker from "../../ST_Handlers/ThaiDatePicker";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import Fileuploader, { Extension } from "../../../Fileuploader";
import { parse } from "query-string"
const { Option } = AntdSelect;

const Admin_Content_Plants_Animals = ({ sType = "99", backEvent = "/", nPerMenuId = 99 }) => {
    var type = sType ? sType : "99";
    var backEv = backEvent ? backEvent : "/";
    const { TextArea } = Input;
    var nMenuId = nPerMenuId ? nPerMenuId : 99;
    var configs = {
        placeholderText: "",
        attribution: false,
        key: "0BA3jA11B5C7C4A4D3aIVLEABVAYFKc2Cb1MYGH1g1NYVMiG5G4E3C3A1C8A6D4A3B4==",
        imageUploadURL: process.env.REACT_APP_URL + "api/UploadTextEditor/uploadImage",
        videoUploadURL: process.env.REACT_APP_URL + "api/UploadTextEditor/uploadImage",
        quickInsertButtons: quickInsertButtons,
        pluginsEnabled: pluginsEnabled,
        events: {
            'html.set': async function () {
                let editor: any = this
                let UserInfo: any = await AuthenService.UserInfo();
                if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
                    if (UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu === nMenuId).map(t => t.nPermission)[0] === 2) {
                        editor.edit.off()
                    }
                }
            },
        },
        fontFamily: fontFamily,
        fontFamilySelection: true,
    };

    const [ModeEN, setModeEN] = React.useState("TH" as string);
    const [isAnimal, setIsAnimal] = React.useState(null as any);
    const [lstData, setLstData] = React.useState(null as any);
    const [lstFileCover, setFileCover] = React.useState([] as any);
    const [lstFileCoverEN, setFileCoverEN] = React.useState([] as any);
    const [lstFileGallery, setFileGallery] = React.useState([] as any);
    const [lstFileGalleryEN, setFileGalleryEN] = React.useState([] as any);
    const [lstFilePlantTrunk, setFilePlantTrunk] = React.useState([] as any);
    const [lstFilePlantLeaf, setFilePlantLeaf] = React.useState([] as any);
    const [lstFilePlantFruit, setFilePlantFruit] = React.useState([] as any);
    const [lstFilePlantFlower, setFilePlantFlower] = React.useState([] as any);
    
    const [lstFileRef, setFileRef] = React.useState([] as any);
    const [lstFileRefEN, setFileRefEN] = React.useState([] as any);
    const [sPlantDetails, setPlantDetails] = React.useState("");
    const [sPlantDetailsEN, setPlantDetailsEN] = React.useState("");
    const [sPlantProperties, setPlantProperties] = React.useState(""); 
    const [sPlantPropertiesEN, setPlantPropertiesEN] = React.useState(""); 
    const [sAnimalBehavior, setAnimalBehavior] = React.useState("");
    const [sAnimalBehaviorEN, setAnimalBehaviorEN] = React.useState("");
    const [sAnimalCurrent, setAnimalCurrent] = React.useState("");
    const [sAnimalCurrentEN, setAnimalCurrentEN] = React.useState("");
    const [SetValue] = Form.useForm();
    const [CheckboxFruit, setCheckboxFruit] = React.useState(false as boolean);
    const [CheckboxFlower, setCheckboxFlower] = React.useState(false as boolean);
    const [lstContent, setLstContent] = React.useState([] as any);
    const [sContentLevel, setContentLevel] = React.useState(null as any);
    const [contentLevelOption, setContentLevelOption] = React.useState([] as any);
    const [sContentLv1, setContentLv1] = React.useState(null as any);
    const [sContentLv2, setContentLv2] = React.useState(null as any);
    const [sContentLv3, setContentLv3] = React.useState(null as any);
    const [sID, setID] = React.useState("");
    const history = useHistory();
    const [stContent, setStContent] = React.useState("" as any);
    const [isEdit, setisEdit] = React.useState(false);
    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            setisEdit(UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == nMenuId).map(t => t.nPermission)[0] === 2 ? false : true)
        }
    }

    var onUploadFileSuccess = () => {
        //setLstData({ ...lstData, lstExcutionFile: fileList });
        SetValue.validateFields(['lstFileCover']);
    };
    var onUploadFileSuccessEN = () => {
        //setLstData({ ...lstData, lstExcutionFile: fileList });
        SetValue.validateFields(['lstFileCoverEN']);
    };
    var onUploadFileSuccess1 = () => {
        //setLstData({ ...lstData, lstExcutionFile: fileList });
        SetValue.validateFields(['lstFileGallery']);
    };
    var onUploadFileSuccess1EN = () => {
        //setLstData({ ...lstData, lstExcutionFile: fileList });
        SetValue.validateFields(['lstFileGalleryEN']);
    };
    var onUploadFileSuccess2 = () => {
        //setLstData({ ...lstData, lstExcutionFile: fileList });
        SetValue.validateFields(['lstFilePlantTrunk']);
    };
    var onUploadFileSuccess3 = () => {
        //setLstData({ ...lstData, lstExcutionFile: fileList });
        SetValue.validateFields(['lstFilePlantLeaf']);
    };
    var onUploadFileSuccess4 = () => {
        //setLstData({ ...lstData, lstExcutionFile: fileList });
        SetValue.validateFields(['lstFilePlantFruit']);
    };
    var onUploadFileSuccess5 = () => {
        //setLstData({ ...lstData, lstExcutionFile: fileList });
        SetValue.validateFields(['lstFilePlantFlower']);
    };
    var onUploadFileSuccess6 = () => {
        //setLstData({ ...lstData, lstExcutionFile: fileList });
        SetValue.validateFields(['lstFileRef']);
    };
    var onUploadFileSuccess6EN = () => {
        //setLstData({ ...lstData, lstExcutionFile: fileList });
        SetValue.validateFields(['lstFileRefEN']);
    };

    const onSaveContent = (values: any) => {
        DialogConfirm(async () => {
            var data = {
                nContentID: lstData.nContentID,
                sContentID: lstData.sContentID,
                nType: lstData.nType,
                isAnimal: isAnimal,
                sContentMode: ModeEN,
                sPlantName: lstData.sPlantName,
                sPlantNameEN: lstData.sPlantNameEN,
                sPlantSciName: lstData.sPlantSciName,
                sPlantSciNameEN: lstData.sPlantSciNameEN,
                sPlantFamily: lstData.sPlantFamily,
                sPlantFamilyEN: lstData.sPlantFamilyEN,
                sPlantNative: lstData.sPlantNative,
                sPlantNativeEN: lstData.sPlantNativeEN,
                sPlantDetails: sPlantDetails,
                sPlantDetailsEN: sPlantDetailsEN,
                sPlantTrunk: lstData.sPlantTrunk,
                sPlantTrunkEN: lstData.sPlantTrunkEN,
                lstFilePlantTrunk: lstFilePlantTrunk,
                sPlantLeaf: lstData.sPlantLeaf,
                sPlantLeafEN: lstData.sPlantLeafEN,
                lstFilePlantLeaf: lstFilePlantLeaf,
                sPlantFruit: lstData.sPlantFruit,
                sPlantFruitEN: lstData.sPlantFruitEN,
                lstFilePlantFruit: lstFilePlantFruit,
                sPlantFlower: lstData.sPlantFlower,
                sPlantFlowerEN: lstData.sPlantFlowerEN,
                lstFilePlantFlower: lstFilePlantFlower,
                sPlantProperties: sPlantProperties,
                sPlantPropertiesEN: sPlantPropertiesEN,
                sAnimalName: lstData.sAnimalName,
                sAnimalNameEN: lstData.sAnimalNameEN,
                sAnimalNormalName: lstData.sAnimalNormalName,
                sAnimalNormalNameEN: lstData.sAnimalNormalNameEN,
                sAnimalSciName: lstData.sAnimalSciName,
                sAnimalSciNameEN: lstData.sAnimalSciNameEN,
                sAnimalHabitat: lstData.sAnimalHabitat,
                sAnimalHabitatEN: lstData.sAnimalHabitatEN,
                sAnimalBehavior: sAnimalBehavior,
                sAnimalBehaviorEN: sAnimalBehaviorEN,
                sAnimalCurrent: sAnimalCurrent,
                sAnimalCurrentEN: sAnimalCurrentEN,
                sSubjectTH: lstData.sSubjectTH,
                lstFileCover: lstData.lstFileCover,
                lstFileCoverEN: lstData.lstFileCoverEN,
                lstFileGallery: lstData.lstFileGallery,
                lstFileGalleryEN: lstData.lstFileGalleryEN,
                lstFileRef: lstData.lstFileRef,
                lstFileRefEN: lstData.lstFileRefEN,
                msRefContent: lstData.msRefContent,
                msRefLink: lstData.msRefLink,
                msTag: lstData.msTag,
                isActive: lstData.isActive,
                isSetBackground: lstData.isSetBackground,
                sTypeContent: stContent,
                sContentLevel: sContentLevel,
                sContentLv1: sContentLv1,
                sContentLv2: sContentLv2,
                sContentLv3: sContentLv3
            };
            let result: any = await AxiosPostJson("api/AdminContent/SaveDataContentPlantsAnimals", data)
            if (result.data.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Desc_Success_Save, BoxMsg.Desc_Success_Save, () => {
                    onBack();
                });
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }


        }, null, BoxMsg.Title_Confirm, BoxMsg.Desc_Confirm_Save)
    }

    const onBack = () => {
        history.goBack();
        // history.push({
        //     pathname: backEv,
        // });
    };

    const GetContentDetail = async (idStr, typeStr) => {
        let result: any = await AxiosGetJson("api/AdminContent/GetContentPlantsAnimalsData?id=" + idStr + "&sType=" + typeStr);
        SetValue.setFieldsValue({
            sPlantName: result.sPlantName,
            sPlantNameEN: result.sPlantNameEN,
            sPlantSciName: result.sPlantSciName,
            sPlantSciNameEN: result.sPlantSciNameEN,
            sPlantFamily: result.sPlantFamily,
            sPlantFamilyEN: result.sPlantFamilyEN,
            sPlantNative: result.sPlantNative,
            sPlantNativeEN: result.sPlantNativeEN,
            sPlantDetails: result.sPlantDetails,
            sPlantDetailsEN: result.sPlantDetailsEN,
            sPlantTrunk: result.sPlantTrunk,
            sPlantTrunkEN: result.sPlantTrunkEN,
            lstFilePlantTrunk: result.lstFilePlantTrunk,
            sPlantLeaf: result.sPlantLeaf,
            sPlantLeafEN: result.sPlantLeafEN,
            lstFilePlantLeaf: result.lstFilePlantLeaf,
            sPlantFruit: result.sPlantFruit,
            sPlantFruitEN: result.sPlantFruitEN,
            lstFilePlantFruit: result.lstFilePlantFruit,
            sPlantFlower: result.sPlantFlower,
            sPlantFlowerEN: result.sPlantFlowerEN,
            lstFilePlantFlower: result.lstFilePlantFlower,
            sPlantProperties: result.sPlantProperties,
            sPlantPropertiesEN: result.sPlantPropertiesEN,
            sAnimalName: result.sAnimalName,
            sAnimalNameEN: result.sAnimalNameEN,
            sAnimalNormalName: result.sAnimalNormalName,
            sAnimalNormalNameEN: result.sAnimalNormalNameEN,
            sAnimalSciName: result.sAnimalSciName,
            sAnimalSciNameEN: result.sAnimalSciNameEN,
            sAnimalHabitat: result.sAnimalHabitat,
            sAnimalHabitatEN: result.sAnimalHabitatEN,
            sAnimalBehavior: result.sAnimalBehavior,
            sAnimalBehaviorEN: result.sAnimalBehaviorEN,
            sAnimalCurrent: result.sAnimalCurrent,
            sAnimalCurrentEN: result.sAnimalCurrentEN,
            lstFileCover: result.lstFileCover,
            lstFileCoverEN: result.lstFileCoverEN,
            lstFileGallery: result.lstFileGallery,
            lstFileGalleryEN: result.lstFileGalleryEN,
            lstFileRef: result.lstFileRef,
            lstFileRefEN: result.lstFileRefEN,
            msRefContent: result.msRefContent,
            msRefLink: result.msRefLink,
            msTag: result.msTag,
            isActive: result.isActive,
            isSetBackground: result.isSetBackground,
            sContentLevel: result.sContentLevel,
            sContentLv1: result.sContentLv1,
            sContentLv2: result.sContentLv2,
            sContentLv3: result.sContentLv3
        });
        // setModeEN(sType === "1" ? "EN" : sType === "2" ? "TH" : "");
        setModeEN(result.sContentMode);
        setIsAnimal(result.isAnimal);
        setFileCover(result.lstFileCover);
        setFileCoverEN(result.lstFileCoverEN);
        setFileGallery(result.lstFileGallery);
        setFileGalleryEN(result.lstFileGalleryEN);
        setFilePlantTrunk(result.lstFilePlantTrunk);
        setFilePlantLeaf(result.lstFilePlantLeaf);
        setFilePlantFruit(result.lstFilePlantFruit);
        setFilePlantFlower(result.lstFilePlantFlower);
        setCheckboxFruit(idStr === "xvEhq%2famL3jePelPapN2%2fw==" ? false : result.sPlantFruit === "");
        setCheckboxFlower(idStr === "xvEhq%2famL3jePelPapN2%2fw==" ? false : result.sPlantFlower === "");
        setPlantDetails(result.sPlantDetails);
        setPlantDetailsEN(result.sPlantDetailsEN);
        setPlantProperties(result.sPlantProperties);
        setPlantPropertiesEN(result.sPlantPropertiesEN);
        setAnimalBehavior(result.sAnimalBehavior);
        setAnimalBehaviorEN(result.sAnimalBehaviorEN);
        setAnimalCurrent(result.sAnimalCurrent);
        setAnimalCurrentEN(result.sAnimalCurrentEN);
        setFileRef(result.lstFileRef);
        setFileRefEN(result.lstFileRefEN);
        setContentLevel(result.sContentLevel);
        setContentLv1(result.sContentLv1);
        setContentLv2(result.sContentLv2);
        setContentLv3(result.sContentLv3);
        setLstData(result);
        GetRelatedContent(result.nContentType, idStr)
    }

    const GetRelatedContent = async (nContentType, idStr) => {
        let result: any = await AxiosGetJson("api/AdminContent/GetRelatedContent?nType=" + nContentType + "&id=" + idStr);
        setLstContent(result);
    }

    const onDelete = async (sID) => {
        DialogConfirm(async () => {
            let result: any = await AxiosGetJson("api/AdminContent/DeleteDataDetail?id=", sID);
            if (result.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete , null);
                history.goBack();
            } else if (result.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }
        }, "", "", "ท่านต้องการลบข้อมูลหรือไม่?");
    }

    const GetContentLevelOption = async (idStr, typeStr) => {
        let result: any = await AxiosGetJson("api/AdminContent/GetContentLevelOption?id=" + idStr + "&sType=" + typeStr + "&src=detail");
        setContentLevelOption(result);
    }

    const checkContentMode = async (type) => {
        let cateType = "";
        if(sContentLevel === "1"){
            cateType = sContentLv1;
        }
        if(sContentLevel === "2"){
            cateType = sContentLv2;
        }
        if(sContentLevel === "3"){
            cateType = sContentLv3;
        }
        let result: any = await AxiosGetJson("api/AdminContent/CheckContentMode?level=" + sContentLevel + "&id=" + cateType+ "&type=" + type);
        if(result) {
            Sweetalert.Warning(BoxMsg.Title_Warning, 
                "ไม่สามารถดำเนินการได้ เนื่องจากเมนูหลัก เมนูรองหรือเมนูย่อยไม่มีการตั้งค่าเนื้อหาภาษาอังกฤษ<br /> กรุณาแก้ไขที่เมนูหลัก เมนูรองหรือเมนูย่อยก่อนเพิ่มข้อมูล", 
                () =>{
                    // setModeEN("TH"); 
                    // setLstData({ ...lstData, sContentMode: "EN" });
                    //"ไม่สามารถดำเนินการได้ เนื่องจากเมนูรองไม่มีการตั้งค่าเนื้อหาภาษาอังกฤษ กรุณาแก้ไขที่เมนูรองก่อนเพิ่มข้อมูล"
                    //"ไม่สามารถสร้างรายการภาษาอังกฤษได้<br /> กรุณาตรวจสอบข้อมูลเมนูหลัก เมนูรองหรือเมนูย่อยอีกครั้ง"
                });
            setModeEN("TH"); 
            setLstData({ ...lstData, sContentMode: "TH" });
        }
        return false;
        
    }

    React.useEffect(() => {
        GetUserInfo();
    }, []);

    React.useEffect(() => {
        var { id, type } = parse(window.location.search);
        var idStr: string = "";
        var typeStr: string = "";
        if (id !== undefined && id !== null) {
            idStr = id.toString().split("/").join("%2f");
        }
        else {
            idStr = "xvEhq%2famL3jePelPapN2%2fw==";
        }
        if (type !== undefined && type !== null) {
            typeStr = type.toString().split("/").join("%2f");
        }
        else {
            typeStr = "xvEhq%2famL3jePelPapN2%2fw==";
        }
        setStContent(typeStr);
        // GetRelatedContent();
        setID(idStr);
        GetContentDetail(idStr, typeStr);
        GetContentLevelOption(idStr, typeStr);
        window.scrollTo(0, 0);
    }, [isEdit])


    return (
        <React.Fragment>
            <Form name="From" form={SetValue} onFinish={onSaveContent}
                onFinishFailed={({ errorFields }) => {
                    let element = document.querySelector(`#From_${errorFields[0].name}`);
                    var firstErrorElemY: any = null;
                    if (element) {
                        firstErrorElemY = element.getBoundingClientRect().top - 100; // bar height(100)px
                        window.scrollTo(0, window.scrollY + firstErrorElemY)
                    }
                    else{
                        element = document.getElementById(`${errorFields[0].name}`);
                        if (element) {
                            firstErrorElemY = element.getBoundingClientRect().top - 100; // bar height(100)px
                            window.scrollTo(0, window.scrollY + firstErrorElemY)
                        }
                    }
                }}>
                <Row gutter={[16, 16]}>
                    <Col xl={12} md={12} xs={24} >
                        <b> ประเภทเมนู </b><span className="text-danger ">*</span>
                        <Form.Item
                            name="sContentLevel"
                            rules={[
                                { required: true, message: BoxMsg.Desc_Required_Field + "ประเภทเมนู" },
                            ]}
                        >
                            <AntdSelect
                                disabled={!isEdit}
                                allowClear={true}
                                showSearch={true}
                                style={{ width: "100%" }}
                                value={""}
                                placeholder="- เลือก -"
                                onChange={(e) => {
                                    setLstData({ ...lstData, sContentLevel: e, sContentLv2: null, sContentLv3: null});
                                    setContentLevel(e);
                                    setContentLv2(null);
                                    setContentLv3(null);
                                    checkContentMode(ModeEN);
                                    SetValue.resetFields(["sContentLv2","sContentLv3"])
                                }}
                                filterOption={(input, option) =>
                                    option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {/*
                                    nPerMenuId === 6 ?
                                    <option value="1">เมนูหลัก</option>
                                    :
                                    null
                                */}
                                <option value="1">เมนูหลัก</option>
                                <option value="2">เมนูรอง</option>
                                <option value="3">เมนูย่อย</option>
                            </AntdSelect>
                        </Form.Item>
                    </Col>
                </Row>
                {
                    sContentLevel === "1" ||sContentLevel === "2" || sContentLevel === "3" ? 
                    <React.Fragment>
                        <Row gutter={[16, 16]}>
                            <Col xl={12} md={12} xs={24} >
                            <b> เมนูหลัก </b><span className="text-danger ">*</span>
                            <Form.Item
                                name="sContentLv1"
                                rules={[
                                    { required: true, message: BoxMsg.Desc_Required_Field + "เมนูหลัก" },
                                ]}
                            >
                                <AntdSelect
                                    disabled={true}
                                    allowClear={true}
                                    showSearch={true}
                                    style={{ width: "100%" }}
                                    value={""}
                                    placeholder="- เลือก -"
                                    onChange={(e) => {
                                        setLstData({ ...lstData, sContentLv1: e, sContentLv2: null, sContentLv3: null});
                                        setContentLv1(e);
                                        setContentLv2(null);
                                        setContentLv3(null);
                                        checkContentMode(ModeEN);
                                        SetValue.resetFields(["sContentLv2","sContentLv3"])
                                    }}
                                    filterOption={(input, option) =>
                                        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        contentLevelOption.filter(f => f.nTypeIDLv2 === 0 && f.nTypeIDLv3 === 0).map((e, i) => {
                                            return (
                                                <Option key={"lv1_" + i} value={e.nTypeIDLv1 + ""}>{e.sTypeLv1Name}</Option>
                                            );
                                        })
                                    }
                                </AntdSelect>
                            </Form.Item>
                            </Col>
                        </Row>
                    </React.Fragment>
                    :
                    null
                }
                {
                    sContentLevel === "2" || sContentLevel === "3" ? 
                    <React.Fragment>
                        <Row gutter={[16, 16]}>
                            <Col xl={12} md={12} xs={24} >
                            <b> เมนูรอง </b><span className="text-danger ">*</span>
                            <Form.Item
                                name="sContentLv2"
                                rules={[
                                    { required: true, message: BoxMsg.Desc_Required_Field + "เมนูรอง" },
                                ]}
                            >
                                <AntdSelect
                                    disabled={!isEdit}
                                    allowClear={true}
                                    showSearch={true}
                                    style={{ width: "100%" }}
                                    value={""}
                                    placeholder="- เลือก -"
                                    onChange={(e) => {
                                        setLstData({ ...lstData, sContentLv2: e, sContentLv3: null});
                                        setContentLv2(e);
                                        setContentLv3(null);
                                        checkContentMode(ModeEN);
                                        SetValue.resetFields(["sContentLv3"])
                                    }}
                                    filterOption={(input, option) =>
                                        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        contentLevelOption.filter(f => f.nTypeIDLv1 === parseInt(sContentLv1) && f.nTypeIDLv2 !== 0 && f.nTypeIDLv3 === 0).map((e, i) => {
                                            return (
                                                <Option key={"lv2_" + i} value={e.nTypeIDLv2 + ""}>{e.sTypeLv2Name}</Option>
                                            );
                                        })
                                    }
                                </AntdSelect>
                            </Form.Item>
                            </Col>
                        </Row>
                    </React.Fragment>
                    :
                    null
                }
                {
                    sContentLevel === "3" ? 
                    <React.Fragment>
                        <Row gutter={[16, 16]}>
                            <Col xl={12} md={12} xs={24} >
                            <b> เมนูย่อย </b><span className="text-danger ">*</span>
                            <Form.Item
                                name="sContentLv3"
                                rules={[
                                    { required: true, message: BoxMsg.Desc_Required_Field + "เมนูย่อย" },
                                ]}
                            >
                                <AntdSelect
                                    disabled={!isEdit}
                                    allowClear={true}
                                    showSearch={true}
                                    style={{ width: "100%" }}
                                    value={""}
                                    placeholder="- เลือก -"
                                    onChange={(e) => {
                                        setLstData({ ...lstData, sContentLv3: e});
                                        setContentLv3(e);
                                        checkContentMode(ModeEN);
                                    }}
                                    filterOption={(input, option) =>
                                        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        contentLevelOption.filter(f => f.nTypeIDLv1 === parseInt(sContentLv1) && f.nTypeIDLv2 === parseInt(sContentLv2) && f.nTypeIDLv3 !== 0).map((e, i) => {
                                            return (
                                                <Option key={"lv3_" + i} value={e.nTypeIDLv3 + ""}>{e.sTypeLv3Name}</Option>
                                            );
                                        })
                                    }
                                </AntdSelect>
                            </Form.Item>
                            </Col>
                        </Row>
                    </React.Fragment>
                    :
                    null
                }
                <br />
                <Row gutter={[16, 16]}>
                    <Col xl={12} md={12} xs={24} >
                        <b> มีเนื้อหาภาษาอังกฤษหรือไม่ </b>
                        <br />
                        <Radio.Group disabled={!isEdit}
                            onChange={e => { 
                                setModeEN(e.target.value); 
                                setLstData({ ...lstData, sContentMode: e.target.value }); 
                                checkContentMode(e.target.value);
                            }} value={ModeEN}>
                            <Radio value="EN">ใช่</Radio>
                            <Radio value="TH">ไม่ใช่</Radio>
                        </Radio.Group>
                    </Col>
                </Row>
                <br />
                {
                    isAnimal === false ?
                        <>
                            <Row gutter={[16, 16]}>
                                <Col xl={12} md={12} xs={24} >
                                    <b>ชื่อพืช</b> <span className="text-danger ">*</span><span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name="sPlantName"
                                        rules={[
                                            { required: true, message: BoxMsg.Desc_Required_Field + "ชื่อพืช" },
                                        ]}
                                    >
                                        <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                            setLstData({ ...lstData, sPlantName: e.target.value });
                                        }} />
                                    </Form.Item>
                                </Col>
                                <Col xl={12} md={12} xs={24} >
                                    <b>ชื่อวิทยาศาสตร์</b> <span className="text-danger ">*</span><span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name="sPlantSciName"
                                        rules={[
                                            { required: true, message: BoxMsg.Desc_Required_Field + "ชื่อวิทยาศาสตร์" },
                                        ]}
                                    >
                                        <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                            setLstData({ ...lstData, sPlantSciName: e.target.value });
                                        }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <br />
                            {
                                ModeEN === "EN" ?
                                <React.Fragment>
                                    <Row gutter={[16, 16]}>
                                        <Col xl={12} md={12} xs={24} >
                                            <b>ชื่อพืช</b> <span className="text-danger ">*</span><span className="text-success small"> ภาษาอังกฤษ </span>
                                            <Form.Item
                                                name="sPlantNameEN"
                                                rules={[
                                                    { required: true, message: BoxMsg.Desc_Required_Field + "ชื่อพืชภาษาอังกฤษ" },
                                                ]}
                                            >
                                                <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                                    setLstData({ ...lstData, sPlantNameEN: e.target.value });
                                                }} />
                                            </Form.Item>
                                        </Col>
                                        <Col xl={12} md={12} xs={24} >
                                            <b>ชื่อวิทยาศาสตร์</b> <span className="text-danger ">*</span><span className="text-success small"> ภาษาอังกฤษ </span>
                                            <Form.Item
                                                name="sPlantSciNameEN"
                                                rules={[
                                                    { required: true, message: BoxMsg.Desc_Required_Field + "ชื่อวิทยาศาสตร์ภาษาอังกฤษ" },
                                                ]}
                                            >
                                                <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                                    setLstData({ ...lstData, sPlantSciNameEN: e.target.value });
                                                }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br />
                                </React.Fragment>
                                :
                                null
                            }
                            <Row gutter={[16, 16]}>
                                <Col xl={12} md={12} xs={24} >
                                    <b>วงศ์</b> <span className="text-danger ">*</span><span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name="sPlantFamily"
                                        rules={[
                                            { required: true, message: BoxMsg.Desc_Required_Field + "วงศ์" },
                                        ]}
                                    >
                                        <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                            setLstData({ ...lstData, sPlantFamily: e.target.value });
                                        }} />
                                    </Form.Item>
                                </Col>
                                <Col xl={12} md={12} xs={24} >
                                    <b>ชื่อพื้นเมือง</b> <span className="text-danger "></span><span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name="sPlantNative"
                                        rules={[
                                            { required: false, message: BoxMsg.Desc_Required_Field + "ชื่อพื้นเมือง" },
                                        ]}
                                    >
                                        <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                            setLstData({ ...lstData, sPlantNative: e.target.value });
                                        }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <br />
                            {
                                ModeEN === "EN" ?
                                <React.Fragment>
                                    <Row gutter={[16, 16]}>
                                        <Col xl={12} md={12} xs={24} >
                                            <b>วงศ์</b> <span className="text-danger ">*</span><span className="text-success small"> ภาษาอังกฤษ </span>
                                            <Form.Item
                                                name="sPlantFamilyEN"
                                                rules={[
                                                    { required: true, message: BoxMsg.Desc_Required_Field + "วงศ์ภาษาอังกฤษ" },
                                                ]}
                                            >
                                                <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                                    setLstData({ ...lstData, sPlantFamilyEN: e.target.value });
                                                }} />
                                            </Form.Item>
                                        </Col>
                                        <Col xl={12} md={12} xs={24} >
                                            <b>ชื่อพื้นเมือง</b> <span className="text-danger "></span><span className="text-success small"> ภาษาอังกฤษ </span>
                                            <Form.Item
                                                name="sPlantNativeEN"
                                                rules={[
                                                    { required: false, message: BoxMsg.Desc_Required_Field + "ชื่อพื้นเมืองภาษาอังกฤษ" },
                                                ]}
                                            >
                                                <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                                    setLstData({ ...lstData, sPlantNativeEN: e.target.value });
                                                }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br />
                                </React.Fragment>
                                :
                                null
                            }
                            <Row gutter={[16, 16]}>
                                <Col xl={12} md={12} xs={24} >
                                    <b>ภาพปก </b>
                                    <span className="text-danger ">* </span><span className="text-primary small"> ภาษาไทย </span>
                                    <span className="text-muted small">
                                        ขนาดภาพที่แนะนำ 1280 x 600 px.
                                    </span>
                                    <Form.Item
                                        name="lstFileCover"
                                        rules={[
                                            { required: true, message: BoxMsg.Desc_Required_Field_File + "ภาพปก" },
                                        ]}
                                    >
                                        <div id="lstFileCover">
                                        <Fileuploader
                                            limit="1"
                                            setFileList={setFileCover}
                                            fileList={lstFileCover}
                                            onComplete={onUploadFileSuccess}
                                            onRemoveComplete={onUploadFileSuccess}
                                            fileMaxSize="10"
                                            readOnly={!isEdit}
                                            extensions={Extension.Image}
                                        />
                                        </div>
                                        
                                    </Form.Item>
                                </Col>   
                                <Col xl={12} md={12} xs={24} >
                                    <b>คลังภาพ </b><span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name="lstFileGallery"
                                        rules={[
                                            { required: false, message: BoxMsg.Desc_Required_Field_File + "คลังภาพ" },
                                        ]}
                                    >
                                        <div id="lstFileGallery">
                                        <Fileuploader
                                            limit="100"
                                            setFileList={setFileGallery}
                                            fileList={lstFileGallery}
                                            onComplete={onUploadFileSuccess}
                                            onRemoveComplete={onUploadFileSuccess}
                                            fileMaxSize="10"
                                            readOnly={!isEdit}
                                            extensions={Extension.Image}
                                        />
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <br />
                            {
                                ModeEN === "EN" ?
                                <React.Fragment>
                                    <Row gutter={[16, 16]}>
                                        <Col xl={12} md={12} xs={24} >
                                            <b>ภาพปก </b>
                                            <span className="text-danger ">* </span><span className="text-success small"> ภาษาอังกฤษ </span>
                                            <span className="text-muted small">
                                                ขนาดภาพที่แนะนำ 1280 x 600 px.
                                            </span>
                                            <Form.Item
                                                name="lstFileCoverEN"
                                                rules={[
                                                    { required: true, message: BoxMsg.Desc_Required_Field_File + "ภาพปกภาษาอังกฤษ" },
                                                ]}
                                            >
                                                <div id="lstFileCoverEN">
                                                <Fileuploader
                                                    limit="1"
                                                    setFileList={setFileCoverEN}
                                                    fileList={lstFileCoverEN}
                                                    onComplete={onUploadFileSuccessEN}
                                                    onRemoveComplete={onUploadFileSuccessEN}
                                                    fileMaxSize="10"
                                                    readOnly={!isEdit}
                                                    extensions={Extension.Image}
                                                />
                                                </div>
                                                
                                            </Form.Item>
                                        </Col>   
                                        <Col xl={12} md={12} xs={24} >
                                            <b>คลังภาพ </b><span className="text-success small"> ภาษาอังกฤษ </span>
                                            <Form.Item
                                                name="lstFileGalleryEN"
                                                rules={[
                                                    { required: false, message: BoxMsg.Desc_Required_Field_File + "คลังภาพภาษาอังกฤษ" },
                                                ]}
                                            >
                                                <div id="lstFileGalleryEN">
                                                <Fileuploader
                                                    limit="100"
                                                    setFileList={setFileGalleryEN}
                                                    fileList={lstFileGalleryEN}
                                                    onComplete={onUploadFileSuccessEN}
                                                    onRemoveComplete={onUploadFileSuccessEN}
                                                    fileMaxSize="10"
                                                    readOnly={!isEdit}
                                                    extensions={Extension.Image}
                                                />
                                                </div>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br />
                                </React.Fragment>
                                :
                                null
                            }
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <b>ข้อมูลพันธุ์ไม้</b> <span className="text-danger "></span><span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name="sPlantDetails"
                                        rules={[
                                            { required: false, message: BoxMsg.Desc_Required_Field + "ข้อมูลพันธุ์ไม้" },
                                        ]}
                                    >
                                        <div id="sPlantDetails">
                                        <FroalaEditor
                                            model={sPlantDetails}
                                            config={configs}
                                            onModelChange={(model: any) => {
                                                //var str = model.replace(/<\/?[^>]+(>|$)/g, "");
                                                //if (str.replace(/\&nbsp;/g, "").trim().length <= 0) {
                                                //    setValidContentEN(true);
                                                //}
                                                //else {
                                                //    setValidContentEN(false);
                                                //}
                                                setPlantDetails(model);
                                            }}
                                        />
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <br />
                            {
                                ModeEN === "EN" ?
                                <React.Fragment>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <b>ข้อมูลพันธุ์ไม้</b> <span className="text-danger "></span><span className="text-success small"> ภาษาอังกฤษ </span>
                                            <Form.Item
                                                name="sPlantDetailsEN"
                                                rules={[
                                                    { required: false, message: BoxMsg.Desc_Required_Field + "ข้อมูลพันธุ์ไม้ภาษาอังกฤษ" },
                                                ]}
                                            >
                                                <div id="sPlantDetailsEN">
                                                <FroalaEditor
                                                    model={sPlantDetailsEN}
                                                    config={configs}
                                                    onModelChange={(model: any) => {
                                                        //var str = model.replace(/<\/?[^>]+(>|$)/g, "");
                                                        //if (str.replace(/\&nbsp;/g, "").trim().length <= 0) {
                                                        //    setValidContentEN(true);
                                                        //}
                                                        //else {
                                                        //    setValidContentEN(false);
                                                        //}
                                                        setPlantDetailsEN(model);
                                                    }}
                                                />
                                                </div>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br />
                                </React.Fragment>
                                :
                                null
                            }
                            <Row gutter={[16, 16]}>
                                <Col xl={12} md={12} xs={24} >
                                    <b>ลำต้น / ลักษณะเนื้อไม้</b> <span className="text-danger "></span><span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name="sPlantTrunk"
                                        rules={[
                                            { required: false, message: BoxMsg.Desc_Required_Field + "ลำต้น / ลักษณะเนื้อไม้" },
                                        ]}
                                    >
                                        <TextArea
                                            disabled={!isEdit}
                                            maxLength={250}
                                            autoSize={{ minRows: 4, maxRows: 4 }}
                                            onChange={e => {
                                                setLstData({ ...lstData, sPlantTrunk: e.target.value });
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xl={12} md={12} xs={24} >
                                    <b>ภาพประกอบ </b>
                                    <span className="text-danger "> </span>
                                    <span className="text-muted small">
                                    </span>
                                    <Form.Item
                                        name="lstFilePlantTrunk"
                                        rules={[
                                            { required: false, message: BoxMsg.Desc_Required_Field_File + "ภาพประกอบลำต้น / ลักษณะเนื้อไม้" },
                                        ]}
                                    >
                                        <div id="lstFilePlantTrunk">
                                        <Fileuploader
                                            limit="10"
                                            setFileList={setFilePlantTrunk}
                                            fileList={lstFilePlantTrunk}
                                            onComplete={onUploadFileSuccess2}
                                            onRemoveComplete={onUploadFileSuccess2}
                                            fileMaxSize="10"
                                            readOnly={!isEdit}
                                            extensions={Extension.Image}
                                        />
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <br />
                            {
                                ModeEN === "EN" ?
                                <React.Fragment>
                                    <Row gutter={[16, 16]}>
                                        <Col xl={12} md={12} xs={24} >
                                            <b>ลำต้น / ลักษณะเนื้อไม้</b> <span className="text-danger "></span><span className="text-success small"> ภาษาอังกฤษ </span>
                                            <Form.Item
                                                name="sPlantTrunkEN"
                                                rules={[
                                                    { required: false, message: BoxMsg.Desc_Required_Field + "ลำต้น / ลักษณะเนื้อไม้ภาษาอังกฤษ" },
                                                ]}
                                            >
                                                <TextArea
                                                    disabled={!isEdit}
                                                    maxLength={250}
                                                    autoSize={{ minRows: 4, maxRows: 4 }}
                                                    onChange={e => {
                                                        setLstData({ ...lstData, sPlantTrunkEN: e.target.value });
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br />
                                </React.Fragment>
                                :
                                null
                            }
                            <Row gutter={[16, 16]}>
                                <Col xl={12} md={12} xs={24} >
                                    <b>ใบ</b> <span className="text-danger "></span><span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name="sPlantLeaf"
                                        rules={[
                                            { required: false, message: BoxMsg.Desc_Required_Field + "ใบ" },
                                        ]}
                                    >
                                        <TextArea
                                            disabled={!isEdit}
                                            maxLength={250}
                                            autoSize={{ minRows: 4, maxRows: 4 }}
                                            onChange={e => {
                                                setLstData({ ...lstData, sPlantLeaf: e.target.value });
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xl={12} md={12} xs={24} >
                                    <b>ภาพประกอบ </b>
                                    <span className="text-danger "> </span>
                                    <span className="text-muted small">
                                    </span>
                                    <Form.Item
                                        name="lstFilePlantLeaf"
                                        rules={[
                                            { required: false, message: BoxMsg.Desc_Required_Field_File + "ภาพประกอบใบ" },
                                        ]}
                                    >
                                        <div id="lstFilePlantLeaf">
                                        <Fileuploader
                                            limit="10"
                                            setFileList={setFilePlantLeaf}
                                            fileList={lstFilePlantLeaf}
                                            onComplete={onUploadFileSuccess3}
                                            onRemoveComplete={onUploadFileSuccess3}
                                            fileMaxSize="10"
                                            readOnly={!isEdit}
                                            extensions={Extension.Image}
                                        />
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <br />
                            {
                                ModeEN === "EN" ?
                                <React.Fragment>
                                    <Row gutter={[16, 16]}>
                                        <Col xl={12} md={12} xs={24} >
                                            <b>ใบ</b> <span className="text-danger "></span><span className="text-success small"> ภาษาอังกฤษ </span>
                                            <Form.Item
                                                name="sPlantLeafEN"
                                                rules={[
                                                    { required: false, message: BoxMsg.Desc_Required_Field + "ใบภาษาอังกฤษ" },
                                                ]}
                                            >
                                                <TextArea
                                                    disabled={!isEdit}
                                                    maxLength={250}
                                                    autoSize={{ minRows: 4, maxRows: 4 }}
                                                    onChange={e => {
                                                        setLstData({ ...lstData, sPlantLeafEN: e.target.value });
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br />
                                </React.Fragment>
                                :
                                null
                            }
                            <Row gutter={[16, 16]}>
                                <Col xl={12} md={12} xs={24} >
                                    <b>ผล</b> <span className="text-danger "></span><span className="text-primary small"> ภาษาไทย </span>
                                    &nbsp;
                                    <Checkbox disabled={!isEdit} checked={CheckboxFruit} onChange={e => {
                                        SetValue.resetFields(["sPlantFruit","sPlantFruitEN", "lstFilePlantFruit"]);
                                        setCheckboxFruit(e.target.checked)
                                        SetValue.setFieldsValue({
                                            sPlantFruit: "",
                                            lstFilePlantFruit:[]
                                        });
                                        setLstData({ ...lstData, sPlantFruit: "", lstFilePlantFruit: [] });
                                    }} >
                                        ไม่มีผล
                                    </Checkbox>
                                    <Form.Item
                                        name="sPlantFruit"
                                        rules={[
                                            { required: false, message: BoxMsg.Desc_Required_Field + "ผล" },
                                        ]}
                                    >
                                        <TextArea
                                            disabled={isEdit ? CheckboxFruit : true}
                                            maxLength={250}
                                            autoSize={{ minRows: 4, maxRows: 4 }}
                                            onChange={e => {
                                                setLstData({ ...lstData, sPlantFruit: e.target.value });
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                {
                                    CheckboxFruit ?
                                        null :
                                        <Col xl={12} md={12} xs={24} >
                                            <b>ภาพประกอบ </b>
                                            <span className="text-danger "> </span>
                                            <span className="text-muted small">
                                            </span>
                                            <Form.Item
                                                name="lstFilePlantFruit"
                                                rules={[
                                                    { required: false, message: BoxMsg.Desc_Required_Field_File + "ภาพประกอบผล" },
                                                ]}
                                            >
                                                <div id="lstFilePlantFruit">
                                                <Fileuploader
                                                    limit="10"
                                                    setFileList={setFilePlantFruit}
                                                    fileList={lstFilePlantFruit}
                                                    onComplete={onUploadFileSuccess4}
                                                    onRemoveComplete={onUploadFileSuccess4}
                                                    fileMaxSize="10"
                                                    readOnly={isEdit ? CheckboxFruit : true}
                                                    extensions={Extension.Image}
                                                />
                                                </div>
                                            </Form.Item>
                                        </Col>
                                }
                            </Row>
                            <br />
                            {
                                ModeEN === "EN" ?
                                <React.Fragment>
                                    <Row gutter={[16, 16]}>
                                        <Col xl={12} md={12} xs={24} >
                                            <b>ผล</b> <span className="text-danger "></span><span className="text-success small"> ภาษาอังกฤษ </span>
                                            <Form.Item
                                                name="sPlantFruitEN"
                                                rules={[
                                                    { required: false, message: BoxMsg.Desc_Required_Field + "ผลภาษาอังกฤษ" },
                                                ]}
                                            >
                                                <TextArea
                                                    disabled={isEdit ? CheckboxFruit : true}
                                                    maxLength={250}
                                                    autoSize={{ minRows: 4, maxRows: 4 }}
                                                    onChange={e => {
                                                        setLstData({ ...lstData, sPlantFruitEN: e.target.value });
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br />
                                </React.Fragment>
                                :
                                null
                            }
                            <Row gutter={[16, 16]}>
                                <Col xl={12} md={12} xs={24} >
                                    <b>ดอก</b> <span className="text-danger "></span><span className="text-primary small"> ภาษาไทย </span>
                                    &nbsp;
                                    <Checkbox disabled={!isEdit} checked={CheckboxFlower} onChange={e => {
                                        SetValue.resetFields(["sPlantFlower","sPlantFlowerEN", "lstFilePlantFlower"]);
                                        setCheckboxFlower(e.target.checked)
                                        SetValue.setFieldsValue({
                                            sPlantFlower: "",
                                            lstFilePlantFlower: []
                                        });
                                        setLstData({ ...lstData, sPlantFlower: "", lstFilePlantFlower: [] });
                                    }} >
                                        ไม่มีดอก
                                    </Checkbox>
                                    <Form.Item
                                        name="sPlantFlower"
                                        rules={[
                                            { required: false, message: BoxMsg.Desc_Required_Field + "ดอก" },
                                        ]}
                                    >
                                        <TextArea
                                            disabled={isEdit ? CheckboxFlower : true}
                                            maxLength={250}
                                            autoSize={{ minRows: 4, maxRows: 4 }}
                                            onChange={e => {
                                                setLstData({ ...lstData, sPlantFlower: e.target.value });
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                {
                                    CheckboxFlower ?
                                        null :
                                        <Col xl={12} md={12} xs={24} >
                                            <b>ภาพประกอบ </b>
                                            <span className="text-danger "> </span>
                                            <span className="text-muted small">
                                            </span>
                                            <Form.Item
                                                name="lstFilePlantFlower"
                                                rules={[
                                                    { required: false, message: BoxMsg.Desc_Required_Field_File + "ภาพประกอบดอก" },
                                                ]}
                                            >
                                                <div id="lstFilePlantFlower">
                                                <Fileuploader
                                                    limit="10"
                                                    setFileList={setFilePlantFlower}
                                                    fileList={lstFilePlantFlower}
                                                    onComplete={onUploadFileSuccess5}
                                                    onRemoveComplete={onUploadFileSuccess5}
                                                    fileMaxSize="10"
                                                    readOnly={isEdit ? CheckboxFlower : true}
                                                    extensions={Extension.Image}
                                                />
                                                </div>
                                            </Form.Item>
                                        </Col>
                                }
                            </Row>
                            <br />
                            {
                                ModeEN === "EN" ?
                                <React.Fragment>
                                    <Row gutter={[16, 16]}>
                                        <Col xl={12} md={12} xs={24} >
                                            <b>ดอก</b> <span className="text-danger "></span><span className="text-success small"> ภาษาอังกฤษ </span>
                                            <Form.Item
                                                name="sPlantFlowerEN"
                                                rules={[
                                                    { required: false, message: BoxMsg.Desc_Required_Field + "ดอกภาษาอังกฤษ" },
                                                ]}
                                            >
                                                <TextArea
                                                    disabled={isEdit ? CheckboxFlower : true}
                                                    maxLength={250}
                                                    autoSize={{ minRows: 4, maxRows: 4 }}
                                                    onChange={e => {
                                                        setLstData({ ...lstData, sPlantFlowerEN: e.target.value });
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br />
                                </React.Fragment>
                                :
                                null
                            }
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <b>ประโยชน์</b> <span className="text-danger "></span><span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name="sPlantProperties"
                                        rules={[
                                            { required: false, message: BoxMsg.Desc_Required_Field + "ประโยชน์" },
                                        ]}
                                    >
                                        <div id="sPlantProperties">
                                        <FroalaEditor
                                            model={sPlantProperties}
                                            config={configs}
                                            onModelChange={(model: any) => {
                                                //var str = model.replace(/<\/?[^>]+(>|$)/g, "");
                                                //if (str.replace(/\&nbsp;/g, "").trim().length <= 0) {
                                                //    setValidContentEN(true);
                                                //}
                                                //else {
                                                //    setValidContentEN(false);
                                                //}
                                                setPlantProperties(model);
                                            }}
                                        />
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <br />
                            {
                                ModeEN === "EN" ?
                                <React.Fragment>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <b>ประโยชน์</b> <span className="text-danger "></span><span className="text-success small"> ภาษาอังกฤษ </span>
                                            <Form.Item
                                                name="sPlantPropertiesEN"
                                                rules={[
                                                    { required: false, message: BoxMsg.Desc_Required_Field + "ประโยชน์ภาษาอังกฤษ" },
                                                ]}
                                            >
                                                <div id="sPlantPropertiesEN">
                                                <FroalaEditor
                                                    model={sPlantPropertiesEN}
                                                    config={configs}
                                                    onModelChange={(model: any) => {
                                                        //var str = model.replace(/<\/?[^>]+(>|$)/g, "");
                                                        //if (str.replace(/\&nbsp;/g, "").trim().length <= 0) {
                                                        //    setValidContentEN(true);
                                                        //}
                                                        //else {
                                                        //    setValidContentEN(false);
                                                        //}
                                                        setPlantPropertiesEN(model);
                                                    }}
                                                />
                                                </div>
                                                
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br />
                                </React.Fragment>
                                :
                                null
                            }
                        </>
                            :
                        null
                }
                {
                    isAnimal === true ?
                        <>
                            <Row gutter={[16, 16]}>
                                <Col xl={12} md={12} xs={24} >
                                    <b>ชื่อสัตว์</b> <span className="text-danger ">*</span><span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name="sAnimalName"
                                        rules={[
                                            { required: true, message: BoxMsg.Desc_Required_Field + "ชื่อสัตว์" },
                                        ]}
                                    >
                                        <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                            setLstData({ ...lstData, sAnimalName: e.target.value });
                                        }} />
                                    </Form.Item>
                                </Col>
                                <Col xl={12} md={12} xs={24} >
                                    <b>ชื่อวิทยาศาสตร์</b> <span className="text-danger "></span><span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name="sAnimalSciName"
                                        rules={[
                                            { required: false, message: BoxMsg.Desc_Required_Field + "ชื่อวิทยาศาสตร์" },
                                        ]}
                                    >
                                        <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                            setLstData({ ...lstData, sAnimalSciName: e.target.value });
                                        }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <br />
                            {
                                ModeEN === "EN" ?
                                <React.Fragment>
                                    <Row gutter={[16, 16]}>
                                        <Col xl={12} md={12} xs={24} >
                                            <b>ชื่อสัตว์</b> <span className="text-danger ">*</span><span className="text-success small"> ภาษาอังกฤษ </span>
                                            <Form.Item
                                                name="sAnimalNameEN"
                                                rules={[
                                                    { required: true, message: BoxMsg.Desc_Required_Field + "ชื่อสัตว์ภาษาอังกฤษ" },
                                                ]}
                                            >
                                                <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                                    setLstData({ ...lstData, sAnimalNameEN: e.target.value });
                                                }} />
                                            </Form.Item>
                                        </Col>
                                        <Col xl={12} md={12} xs={24} >
                                            <b>ชื่อวิทยาศาสตร์</b> <span className="text-danger "></span><span className="text-success small"> ภาษาอังกฤษ </span>
                                            <Form.Item
                                                name="sAnimalSciNameEN"
                                                rules={[
                                                    { required: false, message: BoxMsg.Desc_Required_Field + "ชื่อวิทยาศาสตร์ภาษาอังกฤษ" },
                                                ]}
                                            >
                                                <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                                    setLstData({ ...lstData, sAnimalSciNameEN: e.target.value });
                                                }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br />
                                </React.Fragment>
                                :
                                null
                            }
                            <Row gutter={[16, 16]}>
                                <Col xl={12} md={12} xs={24} >
                                    <b>ชื่อสามัญ</b> <span className="text-danger "></span><span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name="sAnimalNormalName"
                                        rules={[
                                            { required: false, message: BoxMsg.Desc_Required_Field + "ชื่อสามัญ" },
                                        ]}
                                    >
                                        <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                            setLstData({ ...lstData, sAnimalNormalName: e.target.value });
                                        }} />
                                    </Form.Item>
                                </Col>
                                <Col xl={12} md={12} xs={24} >
                                    <b>ถิ่นอาศัย</b> <span className="text-danger "></span><span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name="sAnimalHabitat"
                                        rules={[
                                            { required: false, message: BoxMsg.Desc_Required_Field + "ถิ่นอาศัย" },
                                        ]}
                                    >
                                        <Input autoComplete="off" maxLength={250} onChange={e => {
                                            setLstData({ ...lstData, sAnimalHabitat: e.target.value });
                                        }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <br />
                            {
                                ModeEN === "EN" ?
                                <React.Fragment>
                                    <Row gutter={[16, 16]}>
                                        <Col xl={12} md={12} xs={24} >
                                            <b>ชื่อสามัญ</b> <span className="text-danger "></span><span className="text-success small"> ภาษาอังกฤษ </span>
                                            <Form.Item
                                                name="sAnimalNormalNameEN"
                                                rules={[
                                                    { required: false, message: BoxMsg.Desc_Required_Field + "ชื่อสามัญภาษาอังกฤษ" },
                                                ]}
                                            >
                                                <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                                    setLstData({ ...lstData, sAnimalNormalNameEN: e.target.value });
                                                }} />
                                            </Form.Item>
                                        </Col>
                                        <Col xl={12} md={12} xs={24} >
                                            <b>ถิ่นอาศัย</b> <span className="text-danger "></span><span className="text-success small"> ภาษาอังกฤษ </span>
                                            <Form.Item
                                                name="sAnimalHabitatEN"
                                                rules={[
                                                    { required: false, message: BoxMsg.Desc_Required_Field + "ถิ่นอาศัยภาษาอังกฤษ" },
                                                ]}
                                            >
                                                <Input autoComplete="off" maxLength={250} onChange={e => {
                                                    setLstData({ ...lstData, sAnimalHabitatEN: e.target.value });
                                                }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br />
                                </React.Fragment>
                                :
                                null
                            }
                            <Row gutter={[16, 16]}>
                                <Col xl={12} md={12} xs={24} >
                                    <b>ภาพปก </b>
                                    <span className="text-danger ">* </span><span className="text-primary small"> ภาษาไทย </span>
                                    <span className="text-muted small">
                                        ขนาดภาพที่แนะนำ 1280 x 600 px.
                                    </span>
                                    <Form.Item
                                        name="lstFileCover"
                                        rules={[
                                            { required: true, message: BoxMsg.Desc_Required_Field_File + "ภาพปก" },
                                        ]}
                                    >
                                        <div id="lstFileCover">
                                        <Fileuploader
                                            limit="1"
                                            setFileList={setFileCover}
                                            fileList={lstFileCover}
                                            onComplete={onUploadFileSuccess}
                                            onRemoveComplete={onUploadFileSuccess}
                                            fileMaxSize="10"
                                            readOnly={!isEdit}
                                            extensions={Extension.Image}
                                        />
                                        </div>
                                        
                                    </Form.Item>
                                </Col>   
                                <Col xl={12} md={12} xs={24} >
                                    <b>คลังภาพ </b><span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name="lstFileGallery"
                                        rules={[
                                            { required: false, message: BoxMsg.Desc_Required_Field_File + "คลังภาพ" },
                                        ]}
                                    >
                                        <div id="lstFileGallery">
                                        <Fileuploader
                                            limit="100"
                                            setFileList={setFileGallery}
                                            fileList={lstFileGallery}
                                            onComplete={onUploadFileSuccess}
                                            onRemoveComplete={onUploadFileSuccess}
                                            fileMaxSize="10"
                                            readOnly={!isEdit}
                                            extensions={Extension.Image}
                                        />
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <br />
                            {
                                ModeEN === "EN" ?
                                <React.Fragment>
                                    <Row gutter={[16, 16]}>
                                        <Col xl={12} md={12} xs={24} >
                                            <b>ภาพปก </b>
                                            <span className="text-danger ">* </span><span className="text-success small"> ภาษาอังกฤษ </span>
                                            <span className="text-muted small">
                                                ขนาดภาพที่แนะนำ 1280 x 600 px.
                                            </span>
                                            <Form.Item
                                                name="lstFileCoverEN"
                                                rules={[
                                                    { required: true, message: BoxMsg.Desc_Required_Field_File + "ภาพปกภาษาอังกฤษ" },
                                                ]}
                                            >
                                                <div id="lstFileCoverEN">
                                                <Fileuploader
                                                    limit="1"
                                                    setFileList={setFileCoverEN}
                                                    fileList={lstFileCoverEN}
                                                    onComplete={onUploadFileSuccessEN}
                                                    onRemoveComplete={onUploadFileSuccessEN}
                                                    fileMaxSize="10"
                                                    readOnly={!isEdit}
                                                    extensions={Extension.Image}
                                                />
                                                </div>
                                                
                                            </Form.Item>
                                        </Col>   
                                        <Col xl={12} md={12} xs={24} >
                                            <b>คลังภาพ </b><span className="text-success small"> ภาษาอังกฤษ </span>
                                            <Form.Item
                                                name="lstFileGalleryEN"
                                                rules={[
                                                    { required: false, message: BoxMsg.Desc_Required_Field_File + "คลังภาพภาษาอังกฤษ" },
                                                ]}
                                            >
                                                <div id="lstFileGalleryEN">
                                                <Fileuploader
                                                    limit="100"
                                                    setFileList={setFileGalleryEN}
                                                    fileList={lstFileGalleryEN}
                                                    onComplete={onUploadFileSuccessEN}
                                                    onRemoveComplete={onUploadFileSuccessEN}
                                                    fileMaxSize="10"
                                                    readOnly={!isEdit}
                                                    extensions={Extension.Image}
                                                />
                                                </div>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br />
                                </React.Fragment>
                                :
                                null
                            }
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <b>พฤติกรรม</b> <span className="text-danger "></span><span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name="sAnimalBehavior"
                                        rules={[
                                            { required: false, message: BoxMsg.Desc_Required_Field + "พฤติกรรม" },
                                        ]}
                                    >
                                        <div id="sAnimalBehavior">
                                        <FroalaEditor
                                            model={sAnimalBehavior}
                                            config={configs}
                                            onModelChange={(model: any) => {
                                                //var str = model.replace(/<\/?[^>]+(>|$)/g, "");
                                                //if (str.replace(/\&nbsp;/g, "").trim().length <= 0) {
                                                //    setValidContentEN(true);
                                                //}
                                                //else {
                                                //    setValidContentEN(false);
                                                //}
                                                setAnimalBehavior(model);
                                            }}
                                        />
                                        </div>
                                        
                                    </Form.Item>
                                </Col>
                            </Row>
                            <br />
                            {
                                ModeEN === "EN" ?
                                <React.Fragment>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <b>พฤติกรรม</b> <span className="text-danger "></span><span className="text-success small"> ภาษาอังกฤษ </span>
                                            <Form.Item
                                                name="sAnimalBehaviorEN"
                                                rules={[
                                                    { required: false, message: BoxMsg.Desc_Required_Field + "พฤติกรรมภาษาอังกฤษ" },
                                                ]}
                                            >
                                                <div id="sAnimalBehaviorEN">
                                                <FroalaEditor
                                                    model={sAnimalBehaviorEN}
                                                    config={configs}
                                                    onModelChange={(model: any) => {
                                                        //var str = model.replace(/<\/?[^>]+(>|$)/g, "");
                                                        //if (str.replace(/\&nbsp;/g, "").trim().length <= 0) {
                                                        //    setValidContentEN(true);
                                                        //}
                                                        //else {
                                                        //    setValidContentEN(false);
                                                        //}
                                                        setAnimalBehaviorEN(model);
                                                    }}
                                                />
                                                </div>
                                                
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br />
                                </React.Fragment>
                                :
                                null
                            }
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <b>สถานะภาพปัจจุบัน</b> <span className="text-danger "></span><span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name="sAnimalCurrent"
                                        rules={[
                                            { required: false, message: BoxMsg.Desc_Required_Field + "สถานะภาพปัจจุบัน" },
                                        ]}
                                    >
                                        <div id="sAnimalCurrent">
                                        <FroalaEditor
                                            model={sAnimalCurrent}
                                            config={configs}
                                            onModelChange={(model: any) => {
                                                //var str = model.replace(/<\/?[^>]+(>|$)/g, "");
                                                //if (str.replace(/\&nbsp;/g, "").trim().length <= 0) {
                                                //    setValidContentEN(true);
                                                //}
                                                //else {
                                                //    setValidContentEN(false);
                                                //}
                                                setAnimalCurrent(model);
                                            }}
                                        />
                                        </div>
                                        
                                    </Form.Item>
                                </Col>
                            </Row>
                            <br />
                            {
                                ModeEN === "EN" ? 
                                <React.Fragment>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <b>สถานะภาพปัจจุบัน</b> <span className="text-danger "></span><span className="text-success small"> ภาษาอังกฤษ </span>
                                            <Form.Item
                                                name="sAnimalCurrentEN"
                                                rules={[
                                                    { required: false, message: BoxMsg.Desc_Required_Field + "สถานะภาพปัจจุบันภาษาอังกฤษ" },
                                                ]}
                                            >
                                                <div id="sAnimalCurrentEN">
                                                <FroalaEditor
                                                    model={sAnimalCurrentEN}
                                                    config={configs}
                                                    onModelChange={(model: any) => {
                                                        //var str = model.replace(/<\/?[^>]+(>|$)/g, "");
                                                        //if (str.replace(/\&nbsp;/g, "").trim().length <= 0) {
                                                        //    setValidContentEN(true);
                                                        //}
                                                        //else {
                                                        //    setValidContentEN(false);
                                                        //}
                                                        setAnimalCurrentEN(model);
                                                    }}
                                                />
                                                </div>
                                                
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br />
                                </React.Fragment>
                                :
                                null
                            }
                        </>
                        :
                        null
                }
                <Row gutter={[16, 16]}>
                    <Col xl={12} sm={24} xs={24}>
                        <b>ไฟล์ที่เกี่ยวข้อง </b><span className="text-primary small"> ภาษาไทย </span>
                        <Form.Item
                            name="lstFileRef"
                            rules={[
                                { required: false, message: BoxMsg.Desc_Required_Field_File + "ไฟล์ที่เกี่ยวข้อง" },
                            ]}
                        >
                            <div id="lstFileRef">
                            <Fileuploader
                                limit="100"
                                setFileList={setFileRef}
                                fileList={lstFileRef}
                                onComplete={onUploadFileSuccess6}
                                onRemoveComplete={onUploadFileSuccess6}
                                fileMaxSize="10"
                                readOnly={!isEdit}
                                extensions={Extension.CustomGood_Receive}
                            />
                            </div>
                        </Form.Item>
                    </Col>
                    {
                        ModeEN === "EN" ?
                        <Col xl={12} sm={24} xs={24}>
                            <b>ไฟล์ที่เกี่ยวข้อง </b><span className="text-success small"> ภาษาอังกฤษ </span>
                            <Form.Item
                                name="lstFileRefEN"
                                rules={[
                                    { required: false, message: BoxMsg.Desc_Required_Field_File + "ไฟล์ที่เกี่ยวข้องภาษาอังกฤษ" },
                                ]}
                            >
                                <div id="lstFileRefEN">
                                <Fileuploader
                                    limit="100"
                                    setFileList={setFileRefEN}
                                    fileList={lstFileRefEN}
                                    onComplete={onUploadFileSuccess6EN}
                                    onRemoveComplete={onUploadFileSuccess6EN}
                                    fileMaxSize="10"
                                    readOnly={!isEdit}
                                    extensions={Extension.CustomGood_Receive}
                                />
                                </div>
                            </Form.Item>
                        </Col>
                        :
                        null
                    }
                </Row>
                <br />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <b>หัวข้อที่เกี่ยวข้อง</b>
                        <Form.Item
                            name="msRefContent"
                            rules={[
                                { required: false, message: BoxMsg.Desc_Required_Field + "หัวข้อที่เกี่ยวข้อง" },
                            ]}
                        >
                            <Select
                                disabled={!isEdit}
                                mode="multiple"
                                defaultValue={lstData ? lstData.msRefContent : undefined}
                                onChange={e => {
                                    setLstData({ ...lstData, msRefContent: e });
                                }}
                                style={{ width: '100%' }}
                            >
                                {
                                    lstContent.map((e, i) => {
                                        return (
                                            <Option key={"cont_" + i} value={e.value}>{e.label}</Option>
                                        );
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <br />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <b>แหล่งอ้างอิง</b>
                        <Form.Item
                            name="msRefLink"
                            rules={[
                                { required: false, message: BoxMsg.Desc_Required_Field + "แหล่งอ้างอิง" },
                            ]}
                        >
                            <Select
                                disabled={!isEdit}
                                mode="tags"
                                defaultValue={lstData ? lstData.msRefLink : undefined}
                                onChange={e => {
                                    setLstData({ ...lstData, msRefLink: e });
                                }}
                                style={{ width: '100%' }}
                                open={false}
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <br />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <b>แท็ก</b>
                        <Form.Item
                            name="msTag"
                            rules={[
                                { required: false, message: BoxMsg.Desc_Required_Field + "แท็ก" },
                            ]}
                        >
                            <Select
                                disabled={!isEdit}
                                mode="tags"
                                defaultValue={lstData ? lstData.msTag : undefined}
                                onChange={e => {
                                    setLstData({ ...lstData, msTag: e });
                                }}
                                style={{ width: '100%' }}
                                open={false}
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <br />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <b>สถานะ</b>
                        <Form.Item name="isActive" valuePropName="checked">
                            <Switch disabled={!isEdit} defaultChecked={true} checkedChildren="ใช้งาน" unCheckedChildren="ไม่ใช้งาน" style={{width : "100px"}}
                                onChange={e => {
                                    setLstData({ ...lstData, isActive: e });
                                }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <b>กำหนดให้มีภาพพื้นหลัง</b>
                        <Form.Item name="isSetBackground" valuePropName="checked">
                            <Switch disabled={!isEdit} defaultChecked={true} checkedChildren="มี" unCheckedChildren="ไม่มี" style={{width : "100px"}}
                                onChange={e => {
                                    setLstData({ ...lstData, isSetBackground: e });
                                }} />
                        </Form.Item>
                    </Col>
                </Row>
                <br />
                <hr />
                <Row gutter={[8, 16]} justify="center">
                    {isEdit ?
                        <React.Fragment>
                            <Col>
                                <ButtonSave htmlType="submit" />
                            </Col>
                            {
                                sID !== "xvEhq%2famL3jePelPapN2%2fw==" && sID !== "" ? 
                                    <Col>
                                        <ButtonDelete onClick={() => onDelete(sID)} />
                                    </Col>
                                :
                                null
                            }
                        </React.Fragment>
                        : null}
                    <Col>
                        <Button_Cancel onClick={() => { onBack(); }}/>
                    </Col>
                </Row>
            </Form>
        </React.Fragment >
    );
}
export default Admin_Content_Plants_Animals;