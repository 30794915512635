import * as React from "react";
import './style.css';
import ContactForm from './form';
import { List } from "antd";
import { AxiosGetJson } from "../Service/Config/AxiosMethod";
import Image from "../Systems/Image";
import { useSelector, useDispatch } from 'react-redux';
import {
    thailanguage,
    englishlanguage,
    currentlanguage,
} from '../app/counterSlice';


const Controller = 'api/AdminContactUs/';

const Main_Contact_Us = () => {
    const [V_Social, setV_Social] = React.useState([] as Array<any>);
    const [T_SocialUrl, setT_SocialUrl] = React.useState([] as Array<any>);
    const translate = useSelector(currentlanguage);
    const dispatch = useDispatch();

    React.useEffect(() => {
        GetT_SocialUrl();
        GetDataOnPageLoad();
        SetBackground();

    }, []);



    const GetDataOnPageLoad = async () => {
        let result: any = await AxiosGetJson(Controller + "GetV_Social_Main");

        setV_Social(result);
    }

    const GetT_SocialUrl = async () => {
        let result: any = await AxiosGetJson(Controller + "GetT_SocialUrl");
        setT_SocialUrl(result);
    }

    const [sFilePath, setFilePath] = React.useState("");
    const SetBackground = async () => {
        let result: any = await AxiosGetJson("api/AdminInfo/GetBackground");
        setFilePath(result.sFilePath);
    }


    return (
        <React.Fragment>
            {
                //<div >
                //    <button

                //        aria-label="Increment value"
                //        onClick={() => dispatch(thailanguage())}
                //    >
                //        TH
                //    </button>
                //    <button

                //        aria-label="Decrement value"
                //        onClick={() => dispatch(englishlanguage())}
                //    >
                //        EN
                //    </button>
                //</div>
            }



            <div id="PAGE_HOME" style={{ backgroundImage: "url(" + sFilePath + ")", }}>
                <div className="home-news">
                    <div className="news-panel">
                        <div className="panel-head">
                            <div className="panel-title">{translate.Contact}</div>
                        </div>
                    </div>
                </div>
                <div className="home-news">
                    <div className="news-panel" style={{ marginTop: "-5vw" }}>
                        <div className="panel-body-content" style={{ justifyContent: "start" }}>

                            <div id="CONTACT">

                                <div className="bg-contact">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div id="contact_info" className="page-contact">
                                                    <div className="contact-title"><b>{translate.Contact}</b></div>
                                                    <br />
                                                    <List
                                                        dataSource={V_Social}
                                                        renderItem={(item: any) => (
                                                            <div className="row align-items-center" style={{ margin: "10px" }}>
                                                                <div className="col-lg-2 col-xs-2 col-sm-2">
                                                                    <Image src={item.sFilePath} fallbackSrc={require("../../Images/noimage.png")} />
                                                                </div>
                                                                <div className="col-lg-10 col-xs-10 col-sm-10">
                                                                    <b>{item.sName}</b>
                                                                    {
                                                                        T_SocialUrl.filter(w => w.nSocialID == item.nSocialID).map(o => <><br /> <a style={{ color: "#3da393", fontSize:"12px" }} href={o.sUrl}>{translate.TranslatType === "TH" ? o.sDetail : o.sDetail_EN}</a ></>)
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                    >
                                                    </List>
                                                </div>
                                            </div>
                                            <div className="col-lg-6"><ContactForm id="contact_form"></ContactForm></div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>
    );
};
export default Main_Contact_Us;
