import * as React from "react";
import { AxiosGetJson } from "../Service/Config/AxiosMethod";
import "./intro.css";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {
    thailanguage,
    englishlanguage,
    currentlanguage,
} from '../app/counterSlice';

const Intro = () => {
    const [sUrlImage, setUrlImage] = React.useState("");
    const [sHexColor, setHexColor] = React.useState("#ffffff");
    const [sLinkTo, setLinkTo] = React.useState("");
    
    const translate = useSelector(currentlanguage);
    const dispatch = useDispatch();

    const CheckIntro = async () => {
        var result = await AxiosGetJson("api/Home/GetIntro");
        console.log(result);
        setUrlImage(result.sUrlImage);
        setHexColor(result.sHexColor);
        setLinkTo(result.sLinkTo);
    }
    React.useEffect(() => {
        CheckIntro();
    });
    return (
        <div id="PAGE_INTRO">
            <div className="intro-cover" style={{ backgroundImage: "url(" + sUrlImage + ")" }}
                onClick={() => {
                    if (sLinkTo !== "") {
                        //window.location.href = sLinkTo;
                        window.open(sLinkTo, '_blank');
                    }
                }}></div>
            <div className="intro-bar" style={{ backgroundColor: sHexColor }}>
                <Link to={"/home"} onClick={() => dispatch(thailanguage())}>
                    เข้าสู่เว็บไซต์
                </Link>
                <Link to={"/home"} onClick={() => dispatch(englishlanguage())}>
                    Enter Website
                </Link>
                {/*<a>เข้าสู่เว็บไซต์</a>
                <a>Enter Website</a>*/}
            </div>
        </div>);
};
export default Intro;