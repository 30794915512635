import { Col, Input, Row, Space, Tag } from "antd";
import * as React from "react";
import { Empty, Select as AntdSelect } from "antd";
import { ButtonAdd_Table, ButtonEdit_Table, ButtonInfo_Table, ButtonSearch } from "../Systems/button_all";
import TableAntd from "../Systems/TableAntd";
import { useHistory } from "react-router";
import { BoxMsg, DialogDelete, Encrypt, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import AuthenService from "../Service/AuthenService";
import ThaiDatePicker from "../ST_Handlers/ThaiDatePicker";

const Admin_IntroPage_List = () => {
    const history = useHistory();
    const [selectedRows, setselectedRows] = React.useState([] as Array<any>);
    const [selectedRowKeys, setrowSelected] = React.useState([] as any);
    const [lstIntroPage, setLstIntroPage] = React.useState([] as any);
    const [txtSearch, SetTxtSearch] = React.useState({
        sIntroName: "",
        dStartDate: undefined as Date | undefined,
        dbStartDate: 0,
        sStatus: null as any
    });
    const [total, setTotal] = React.useState(0);
    const [sPageA, setsPageA] = React.useState("1" as string || undefined);
    const [sPageSizeA, setsPageSizeA] = React.useState("10" as string || undefined);
    const [sFieldA, setsFieldA] = React.useState("" as string || undefined);
    const [sOrderA, setsOrderA] = React.useState("" as string || undefined);
    const [nCurrentPage, setCurrentPage] = React.useState(1);

    const [isEdit, setisEdit] = React.useState(false);
    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            setisEdit(UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == 1).map(t => t.nPermission)[0] === 2 ? false : true)
        }
    }

    const header = [
        {
            title: "ลำดับที่", dataIndex: "key", key: "Key_No_1", width: 50, render: (value) => {
                return (<a key={value}>{value}.</a>);
            },
            className: "text-center text-nowrap",
        },
        { title: "ชื่อหน้าเริ่มต้น", dataIndex: "sIntroName", key: "Key_No_2", sorter: true, className: "text-left text-nowrap", width: 400, },
        { title: "วันที่แสดง ", dataIndex: "sStartDate", key: "Key_No_3", sorter: true, className: "text-center text-wrap", width: 150, },
        {
            title: 'สถานะ',
            dataIndex: 'isActive',
            width: 50,
            sorter: true,
            className: "text-center",
            render: (text: any, record: { sIsActive: string; }) => (
                <Tag color={record.sIsActive === "1" ? "#60cc28" : "#cc2838"} key={text} style={{ borderRadius: "32px" }}>
                    {record.sIsActive === "1" ? "ใช้งาน" : "ไม่ใช้งาน"}
                </Tag>
            ),
        },
        {
            title: isEdit ? <ButtonAdd_Table onClick={() => { history.push("/admin-intropage-edit"); }} /> : null,
            dataIndex: "4", key: "Key_No_8",
            render: (text: any, record: any) => (

                isEdit ?
                    <Space size="middle">
                        <ButtonEdit_Table
                            onClick={() => { getLinkTo_Edit(record.sID); }}
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </ButtonEdit_Table>
                    </Space>
                    :
                    <Space size="middle">
                        <ButtonInfo_Table
                            onClick={() => { getLinkTo_Edit(record.sID); }}
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </ButtonInfo_Table>
                    </Space>
            ),
            width: 1,
        },
    ];
    const getLinkTo_Edit = (sID: any) => {
        let sTypeComponent = `/admin-intropage-edit`;
        let sPath = `${sTypeComponent}?id=${sID}`;
        return history.push(sPath);
    };

    const GetDataOnPageLoad = async (sPage, sPageSize, sField, sOrder) => {
        var data = {
            sIntroName: txtSearch.sIntroName,
            dStartDate: txtSearch.dStartDate,
            dbStartDate: txtSearch.dbStartDate,
            sStatus: txtSearch.sStatus,
            sPage: sPage ? sPage.toString() : sPageA,
            sPageSize: sPageSize ? sPageSize.toString() : sPageSizeA,
            sField: sField ? sField : sFieldA,
            sOrder: sOrder ? sOrder : sOrderA,
        };
        let result: any = await AxiosPostJson("api/AdminIntroPage/GetIntroList", data);
        setLstIntroPage(result.data.cIntroList);
        setTotal(result.data.nTotal);
    }

    const onPageChange = async (page, pageSize, sorter) => {
        setsPageA(page);
        setsPageSizeA(pageSize);
        GetDataOnPageLoad(page, pageSize, sFieldA, sOrderA);
        setCurrentPage(page);
        window.scrollTo(0, 0)
    }

    const onTableChange = async (pagination, filters, sorter, extra) => {
        if (sorter) {
            setsFieldA(sorter.field);
            setsOrderA(sorter.order);
            GetDataOnPageLoad2(sPageA, sPageSizeA, sorter.field, sorter.order);
        }
    }

    const GetDataOnPageLoad2 = async (sPage, sPageSize, sField, sOrder) => {
        var data = {
            sIntroName: txtSearch.sIntroName,
            dStartDate: txtSearch.dStartDate,
            dbStartDate: txtSearch.dbStartDate,
            sStatus: txtSearch.sStatus,
            sPage: sPage ? sPage.toString() : sPageA,
            sPageSize: sPageSize ? sPageSize.toString() : sPageSizeA,
            sField: sField ? sField : sFieldA,
            sOrder: sOrder,
        };
        let result: any = await AxiosPostJson("api/AdminIntroPage/GetIntroList", data);
        setLstIntroPage(result.data.cIntroList);
        setTotal(result.data.nTotal);
    }

    const onDeleteClick = async () => {
        if (selectedRows.length > 0) {
            DialogDelete(async () => {
                let result: any = await AxiosPostJson("api/AdminIntroPage/DeleteData", selectedRows);
                if (result.data.sStatus === Responsestart.success) {
                    Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete,
                        () => {
                            setselectedRows([]);
                            setrowSelected([]);
                            GetDataOnPageLoad(sPageA, sPageSizeA, sFieldA, sOrderA);
                        });
                } else if (result.data.sStatus === Responsestart.warning) {
                    Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                } else {
                    Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                }
            });
        }
    }

    const onselect = (selectedRowKeys, selectedRows: React.SetStateAction<any[]>) => {
        setselectedRows(selectedRows);
        setrowSelected(selectedRowKeys);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onselect,
        getCheckboxProps: (record) => ({
            disabled: false, // Column configuration not to be checked
            name: record.sIntroName,
        }),
    };

    React.useEffect(() => {
        GetDataOnPageLoad(undefined, undefined, undefined, undefined);
        GetUserInfo();
    }, []);



    return (
        <React.Fragment>
            <br />
            <Row justify="end" gutter={[16, 16]}>
                <Col xl={5} md={12} xs={24}>

                    <Input
                        autoComplete="off"
                        placeholder="ชื่อหน้าเริ่มต้น"
                        onChange={(e) =>
                            SetTxtSearch({
                                ...txtSearch,
                                sIntroName: e.target.value,
                            })
                        }
                        onKeyPress={(e) => {
                            e.key === "Enter" && GetDataOnPageLoad(undefined, undefined, undefined, undefined);
                        }}
                    />
                </Col>
                <Col xl={4} md={12} xs={24}>
                    <ThaiDatePicker
                        value={txtSearch.dStartDate}
                        small={true}
                        placeholder="วันที่แสดง"
                        onChange={(value) => {
                            SetTxtSearch({
                                ...txtSearch,
                                dStartDate: value,
                                dbStartDate: value ? value.valueOf() : 0
                            });
                        }}
                    />
                </Col>
                <Col xl={4} md={10} xs={20}>
                    <AntdSelect
                        allowClear={true}
                        showSearch={true}
                        style={{ width: "100%" }}
                        value={txtSearch.sStatus}
                        placeholder="- สถานะ -"
                        onChange={(e) => {
                            SetTxtSearch({
                                ...txtSearch,
                                sStatus: e,
                            });
                        }}
                        filterOption={(input, option) =>
                            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <option value="1">ใช้งาน</option>
                        <option value="0">ไม่ใช้งาน</option>
                    </AntdSelect>
                </Col>
                <Col xl={1} md={2} xs={4}>
                    <ButtonSearch onClick={() => { GetDataOnPageLoad(sPageA, sPageSizeA, sFieldA, sOrderA); }} />
                </Col>
            </Row>
            <br />
            <div className="ant-spin-nested-loading">
                <div className="ant-spin-container">
                    <div className="ant-table">
                        <div className="ant-table-container">
                            <div className="ant-table-content">
                                <TableAntd
                                    dataSource={lstIntroPage}
                                    columns={header}
                                    onPageChange={onPageChange}
                                    currentPage={nCurrentPage}
                                    total={total}
                                    //rowSelection={rowSelection}
                                    onChange={onTableChange}
                                    //onShowDelete={true}
                                    onClickDeleteData={onDeleteClick}
                                    scroll={{ x: 650 }}
                                    rowSelection={isEdit ? rowSelection : undefined}
                                    onShowDelete={isEdit}
                                    onDisableDelete={rowSelection === undefined}
                                    pageSize={sPageSizeA ? parseInt(sPageSizeA) : 10}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </React.Fragment>
    );
};
export default Admin_IntroPage_List;
