import * as React from 'react';
// import Admin_Content_Sub from '../admin-content/admin-content-sub'
import Admin_Content_Index from '../admin-content/new-content/admin-content-index'
import { parse } from "query-string"

const Admin_learning_center = (props) => {
    return (
        // <Admin_Content_Sub nPerMenuId={6} sType={"3"} editTypeEvent={"/admin-learning-center-sub-edit"} editEvent={"/admin-learning-center-sub-list"}   />
        <Admin_Content_Index nPerMenuId={6} sType={"3"} editTypeEvent={"/admin-learning-center-type-edit"} editEvent={"/admin-learning-center-sub-list"} />
    );
}
export default Admin_learning_center;