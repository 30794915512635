import * as React from "react";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../main-home/home.css';
import { AxiosGetJson } from "../Service/Config/AxiosMethod";
import { parse } from "query-string";
import { useSelector, useDispatch } from 'react-redux';
import {
    thailanguage,
    englishlanguage,
    currentlanguage,
} from '../app/counterSlice';

const Main_Content_Detail = ({ isShowCover = false, adminMenu ="99" }) => {
    var isCover = isShowCover ? isShowCover : false;
    var adMunu = adminMenu ? adminMenu : false;
    const translate = useSelector(currentlanguage);
    const dispatch = useDispatch();

    const [sFilePath, setFilePath] = React.useState("");
    const [contentDetail, setContentDetail] = React.useState(null as any);
    const SetBackground = async () => {
        let result: any = await AxiosGetJson("api/AdminInfo/GetBackground");
        setFilePath(result.sFilePath);
    }
    const GetContentDetail = async (idStr, TranslatType) => {
        let result: any = await AxiosGetJson("api/AdminInfo/GetContentDetail?nMenuID=" + adMunu + "&id=" + idStr + "&Mode=" + TranslatType);
        setContentDetail(result);
    }

    React.useEffect(() => {
        var { id } = parse(window.location.search);
        var idStr: string = "";
        if (id !== undefined && id !== null) {
            idStr = id.toString().split("/").join("%2f");
        }
        else {
            idStr = "xvEhq%2famL3jePelPapN2%2fw==";
        }
        SetBackground();
        GetContentDetail(idStr, translate.TranslatType);
        window.scrollTo(0, 0);
    }, [translate])

    return (
        <React.Fragment>
            <div id="PAGE_HOME" style={{ backgroundImage: "url(" + sFilePath + ")" }}>
                {/*<div className="home-news">
                    <div className="news-panel">
                        <div className="panel-head">
                            <div className="panel-title">ข่าวประชาสัมพันธ์</div>
                        </div>
                    </div>
                </div>*/}
                {
                    contentDetail ? 
                        contentDetail.sContentType === "TH" && translate.TranslatType == "EN" ? 
                            <div
                                className="home-history"
                                style={{
                                width: "78%",
                                maxWidth:"78%",
                                height: "500px",
                                alignItems: "left",
                                marginLeft: "auto",
                                marginRight: "auto",
                                display: "block",
                                }}
                            >
                                <h3 style={{ textAlign: "center",marginTop: "revert"}}>No Content</h3>
                            </div>
                            :
                            <React.Fragment>
                                <div className="home-news">
                                    <div className="news-panel">
                                        <div className="panel-head">
                                            <div className="panel-title">{contentDetail ? contentDetail.sSubject : ""}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="home-news">
                                    <div className="news-panel" style={{ marginTop: isCover ? "0vw" : "-5vw" }}>
                                        <div className="panel-body-content" style={{ justifyContent: "start" }}>
                                            <div style={{ backgroundColor: "#ffffff", lineHeight:"1.4", padding: "0 2.5vw" }} dangerouslySetInnerHTML={{ __html: contentDetail ? contentDetail.sContent : "" }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                    :
                    null
                }
            </div>
        </React.Fragment>

    );
}
export default Main_Content_Detail;
