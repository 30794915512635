import * as React from "react";
import { AxiosGetJson } from "../Service/Config/AxiosMethod";
import Flowpaper from "../../Flowpaper";

const TestConnection = () => {
    const [result, setResult] = React.useState("");
    const [resultPIS, setResultPIS] = React.useState("");
    const TestConnection = async () => {
        var data = await AxiosGetJson("api/Home/TestConnectionDatabase");
        setResult(data);
        var data2 = await AxiosGetJson("api/Home/TestConnectionPIS");
        setResultPIS(data2);
    }

    React.useEffect(() => {
        TestConnection();
    });

    return (
        <React.Fragment>
            <div dangerouslySetInnerHTML={{ __html: result }}></div><br />
            <div dangerouslySetInnerHTML={{ __html: resultPIS }}></div><br /><br />
            <div style={{width: "500px", height: "500px", backgroundColor: "red"}}>
                <Flowpaper  PDFFile="UploadFile/Content/Content_385/0112202117120927.pdf"/>
            </div>
        </React.Fragment>
    );
}
export default TestConnection;