import * as React from 'react';
import Main_Content_Detail from '../main-content/content-detail2'

const Institute = (props) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Main_Content_Detail isShowCover={false} adminMenu={"0"} />
    );
}
export default Institute;