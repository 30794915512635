import { Checkbox, Col, Form, Input, Row, Switch } from "antd";
import * as React from "react";
import { useHistory } from "react-router";
import { ButtonBack, ButtonDelete, ButtonSave, Button_Cancel } from "../Systems/button_all";
import { Empty, Select as AntdSelect } from "antd";
import ThaiDatePicker from "../ST_Handlers/ThaiDatePicker";
import { BoxMsg, Decrypt, DialogConfirm, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import { parse } from "query-string";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import Fileuploader, { Extension } from "../../Fileuploader";
import { ChromePicker } from 'react-color';
import AuthenService from "../Service/AuthenService";

const Admin_IntroPage_Edit = () => {
    const history = useHistory();
    const [SetValue] = Form.useForm();
    const [file, setfile] = React.useState([] as any);
    const [lstData, setLstData] = React.useState(null as any);
    const [dStartDate, setStartDate] = React.useState(undefined as Date | undefined);
    const [dEndDate, setEndDate] = React.useState(undefined as Date | undefined);
    const [CheckboxDate, setCheckboxDate] = React.useState(false as boolean);
    const [sColorHex, setColorHex] = React.useState("#255058");
    const [sID, setID] = React.useState("");
    const [isEdit, setisEdit] = React.useState(false);
    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            setisEdit(UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == 1).map(t => t.nPermission)[0] === 2 ? false : true)
        }
    }
    var onUploadFileSuccess = () => {
        //setLstData({ ...lstData, lstExcutionFile: fileList });
        SetValue.validateFields(['lstFileIntro']);
    };
    const GetIntroPage = async (idStr) => {
        let result: any = await AxiosGetJson("api/AdminIntroPage/GetIntroPageDetail?id=" + idStr);
        SetValue.setFieldsValue({
            sIntroPageName: result.sIntroPageName,
            sLinkTo: result.sLinkTo,
            dStartDate: result.dStartDate === null ? undefined : new Date(result.dStartDate),
            dEndDate: result.dEndDate === null ? undefined : new Date(result.dEndDate),
            lstFileIntro: result.lstFileIntro,
            isActive: result.isActive,
            sColorHex: result.sColorHex
        });
        setColorHex(result.sColorHex);
        setLstData(result);
        setStartDate(result.dStartDate === null ? undefined : new Date(result.dStartDate));
        setEndDate(result.dEndDate === null ? undefined : new Date(result.dEndDate));
        setCheckboxDate(idStr === "xvEhq%2famL3jePelPapN2%2fw==" ? false : result.dEndDate === null);
        setfile(result.lstFileIntro);
    }

    const onSave = (values: any) => {
        DialogConfirm(async () => {
            var data = {
                nIntroPageID: lstData.nIntroPageID,
                sIntroPageID: lstData.sIntroPageID,
                sLinkTo: lstData.sLinkTo,
                sIntroPageName: lstData.sIntroPageName,
                dStartDate: lstData.dStartDate,
                dbStartDate: lstData.dbStartDate,
                dEndDate: lstData.dEndDate,
                dbEndDate: lstData.dbEndDate,
                isActive: lstData.isActive,
                lstFileIntro: file,
                sColorHex: sColorHex
            }
            let result: any = await AxiosPostJson("api/AdminIntroPage/SaveData", data);
            if (result.data.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Save, () => {
                    history.push("/admin-intropage-list");
                });
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }

        });
    }

    const onDelete = (sID) => {
        DialogConfirm(async () => {
            let result: any = await AxiosGetJson("api/AdminIntroPage/DeleteDataDetail?id=" + sID);
            if (result.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete , null);
                history.goBack();
            } else if (result.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }
        }, "", "", "ท่านต้องการลบข้อมูลหรือไม่?");
    }

    const onChange = (e) => {

        SetValue.resetFields(["dEndDate"]);
        setCheckboxDate(e.target.checked)
        SetValue.setFieldsValue({
            dEndDate: undefined,
            dbEndDate: null
        })
    }

    const handleChangeComplete = (color) => {
        setColorHex(color.hex);
    };

    React.useEffect(() => {
        GetUserInfo();
    }, []);

    React.useEffect(() => {
        var { id } = parse(window.location.search);
        var idStr: string = "";
        if (id !== undefined && id !== null) {
            idStr = id.toString().split("/").join("%2f");
        }
        else {
            idStr = "xvEhq%2famL3jePelPapN2%2fw==";
        }
        setID(idStr);
        GetIntroPage(idStr)
        window.scrollTo(0, 0);
    }, [isEdit])

    return (
        <Form name="From" form={SetValue} onFinish={onSave}
            onFinishFailed={({ errorFields }) => {
                let element = document.querySelector(`#From_${errorFields[0].name}`);
                    var firstErrorElemY: any = null;
                    if (element) {
                        firstErrorElemY = element.getBoundingClientRect().top - 100; // bar height(100)px
                        window.scrollTo(0, window.scrollY + firstErrorElemY)
                    }
                    else{
                        element = document.getElementById(`${errorFields[0].name}`);
                        if (element) {
                            firstErrorElemY = element.getBoundingClientRect().top - 100; // bar height(100)px
                            window.scrollTo(0, window.scrollY + firstErrorElemY)
                        }
                    }
            }}>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <b>ชื่อหน้าเริ่มต้น</b> <span className="text-danger">*</span>
                    <Form.Item name="sIntroPageName"
                        rules={[{ required: true, message: BoxMsg.Desc_Required_Field + "ชื่อหน้าเริ่มต้น" }]}
                    >
                        <Input disabled={!isEdit} maxLength={250} autoComplete="off"
                            onChange={e => {
                                setLstData({ ...lstData, sIntroPageName: e.target.value });
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <b>ลิงก์เมื่อคลิก</b><span className="text-danger"></span>
                    <Form.Item name="sLinkTo"
                        rules={[
                            { required: false, message: BoxMsg.Desc_Required_Field + "ลิงก์เมื่อคลิก" },
                            { type: 'url', message: 'ลิงก์ไม่ถูกต้อง' }
                        ]}
                    >
                        <Input disabled={!isEdit} type="url" maxLength={250} autoComplete="off"
                            onChange={e => {
                                setLstData({ ...lstData, sLinkTo: e.target.value });
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]} align="middle">
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <b>วันที่เริ่มต้นแสดง</b> <span className="text-danger">*</span>
                    <Form.Item name="dStartDate"
                        rules={[{ required: true, message: BoxMsg.Desc_Required_Field + "วันที่เริ่มต้นแสดง" }]}
                    >
                        <ThaiDatePicker
                            value={dStartDate}
                            disabled={!isEdit}
                            small={false}
                            placeholder=""
                            maxDate={dEndDate}
                            onChange={(value) => {
                                setStartDate(value);
                                setLstData({ ...lstData, dStartDate: value, dbStartDate: value ? value.valueOf() : null });
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <b>วันที่สิ้นสุดแสดง</b> <span className="text-danger">*</span>
                    <Form.Item name="dEndDate"
                        rules={[{ required: !CheckboxDate, message: BoxMsg.Desc_Required_Field + "วันที่สิ้นสุดแสดง" }]}
                    >
                        <ThaiDatePicker
                            value={dEndDate}
                            disabled={isEdit ? CheckboxDate : true}
                            small={false}
                            placeholder=""
                            minDate={dStartDate}
                            onChange={(value) => {
                                setEndDate(value);
                                setLstData({ ...lstData, dEndDate: value, dbEndDate: value ? value.valueOf() : null });
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col xl={6} md={6} xs={24}>
                    &nbsp;
                    <Checkbox checked={CheckboxDate} disabled={!isEdit} onChange={onChange} >ไม่มีกำหนด</Checkbox>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <b>ภาพเริ่มต้น </b>
                    <span className="text-danger">*</span>
                    <span className="text-muted small">
                        ขนาดภาพที่แนะนำ 1280 x 720 px.
                    </span>
                    <Form.Item
                        name="lstFileIntro"
                        rules={[
                            { required: true, message: BoxMsg.Desc_Required_Field + "ภาพเริ่มต้น" },
                        ]}
                    >
                        <div id="lstFileIntro">
                        <Fileuploader
                            limit="1"
                            setFileList={setfile}
                            fileList={file}
                            onComplete={onUploadFileSuccess}
                            onRemoveComplete={onUploadFileSuccess}
                            fileMaxSize="10"
                            readOnly={!isEdit}
                            extensions={Extension.Image}
                        />
                        </div>
                    </Form.Item>
                </Col>
            </Row>
            <br />
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <b>สีพื้นหลัง </b>
                    <span className="text-danger">*</span>
                    {
                        isEdit ?
                        <>
                            <ChromePicker
                                color={sColorHex}
                                onChangeComplete={handleChangeComplete}
                                disabled={!isEdit}
                            />
                        </>
                        :
                        null
                    }
                    
                    <br />
                    <b>โค้ดสี : </b>
                    <span>{sColorHex}</span>
                    <br />
                    <div style={{
                        background: sColorHex,
                        width: 100,
                        height: 50,
                        color: 'white'
                    }}>
                    </div>
                </Col>
            </Row>
            <br />
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <b>สถานะ</b>
                    <Form.Item name="isActive" valuePropName="checked">
                        <Switch disabled={!isEdit} defaultChecked={true} checkedChildren="ใช้งาน" unCheckedChildren="ไม่ใช้งาน" style={{width : "100px"}}
                            onChange={e => {
                                setLstData({ ...lstData, isActive: e });
                            }} />
                    </Form.Item>
                </Col>
            </Row>
            <hr />
            <Row gutter={[8, 16]} justify="center">
                {
                    isEdit ?
                        <React.Fragment>
                            <Col>
                                <ButtonSave htmlType="submit" />
                            </Col>
                            {
                                sID !== "xvEhq%2famL3jePelPapN2%2fw==" && sID !== "" ? 
                                    <Col>
                                        <ButtonDelete onClick={() => onDelete(sID)} />
                                    </Col>
                                :
                                null
                            }
                            
                        </React.Fragment>
                        : null
                }
                <Col>
                    <Button_Cancel onClick={() => { history.push("/admin-intropage-list") }} />
                </Col>
            </Row>
        </Form>

    );
};
export default Admin_IntroPage_Edit;


