import * as React from 'react';
import Main_Content_List from '../main-content/content-list'

const Forest_Story_List = (props) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Main_Content_List sType={"2"} />
    );
}
export default Forest_Story_List;