import * as React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "../main-home/home.css";
import "../main-content/content.css";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import { useHistory } from "react-router";
import { parse } from "query-string";
import Slider from "react-slick";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Tooltip,
  Slider as AntdSlider,
  Switch,
  Pagination as AntdPagination,
} from "antd";
import ReactPlayer from "react-player";
import { useSelector, useDispatch } from "react-redux";
import {
  thailanguage,
  englishlanguage,
  currentlanguage,
} from "../app/counterSlice";
import HTMLFlipBook from "react-pageflip";
import ThaiDatePicker from "../ST_Handlers/ThaiDatePicker";
import { ButtonMainSearch, ButtonSearch } from "../Systems/button_all";
import Pagination from "react-js-pagination";

const Page = React.forwardRef(
  (props: { number: any; children: any }, ref: any) => {
    return (
      <div className="demoPage" ref={ref}>
        <img
          src={props.children}
          style={{ maxWidth: "100%", maxHeight: "100%", height: "auto" }}
        />
      </div>
    );
  }
);

const Main_Content_List = ({ sType = "99" }) => {
  var BookingList_Settings = {
    infinite: true,
    swipeToSlide: true,
    speed: 1000,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    arrows: false,
  };

  const { id } = parse(window.location.search);
  var type = sType ? sType : "99";
  const [sFilePath, setFilePath] = React.useState("");
  const [lstContentPin, setLstContentPin] = React.useState([] as any);
  const [lstContentUnpin, setLstContentUnpin] = React.useState([] as any);
  const [sIdBefore, setIdBefore] = React.useState("" as any);
  const [lstBooking, setLstBooking] = React.useState([] as any);

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isModalVisibleFile, setIsModalVisibleFile] = React.useState(false);
  const [sPath, setsPath] = React.useState(("" as string) || undefined);
  const [lv1, setLv1] = React.useState(("" as string) || undefined);
  const [dataHeader, setDataHeader] = React.useState(null as any);
  const [lstImagePDF, setLstImagePDF] = React.useState([] as any);
  const [isShowList, setIsShowList] = React.useState(true);
  const [sKeyword, setKeyword] = React.useState("");
  const [dStartDate, setStartDate] = React.useState(
    undefined as Date | undefined
  );
  const [dEndDate, setEndDate] = React.useState(undefined as Date | undefined);
  const [dbStartDate, setDbStartDate] = React.useState(null as any);
  const [dbEndDate, setDbEndDate] = React.useState(null as any);
  const [nPage, setPage] = React.useState(1);
  const [nMax, setMax] = React.useState(1);
  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const [isLoad, setIsLoad] = React.useState(false);
  const [nLvZoom, setLvZoom] = React.useState(0);
  const [sPathWeb, setPathWeb] = React.useState("");
  const history = useHistory();
  const translate = useSelector(currentlanguage);
  const dispatch = useDispatch();

  const handleOk = () => {
    setIsModalVisible(false);
    setsPath(undefined);
    setLv1(undefined);
    setIsModalVisibleFile(false);
    setIsFullScreen(false);
    setLstImagePDF([]);
    setLvZoom(0);
  };

  const SetBackground = async () => {
    let result: any = await AxiosGetJson("api/AdminInfo/GetBackground");
    setFilePath(result.sFilePath);
  };

  const GetConTentList = async (idStr, TranslatType) => {
    setIsShowList(false);
    let result: any = await AxiosGetJson(
      "api/AdminInfo/GetContentList?sType=" +
        type +
        (idStr === "" ? "" : "&subType=" + idStr) +
        "&Mode=" +
        TranslatType
    );
    setLstContentPin(result.cContentPin);
    setLstContentUnpin(result.cContentUnPin);
    setIdBefore(result.sIdBefore);
    setMax(result.nMax);
    setIsShowList(true);
  };

  const onSearchContent = async () => {
    setIsShowList(false);
    var data = {
      sType: type,
      subType: id ? id.toString().split("/").join("%2f") : "",
      Mode: translate.TranslatType,
      sKeyword: sKeyword,
      dStartDate: dStartDate ? dStartDate : null,
      dEndDate: dEndDate ? dEndDate : null,
      dbStartDate: dbStartDate,
      dbEndDate: dbEndDate,
      nPage: nPage,
    };
    let result: any = await AxiosPostJson(
      "api/AdminInfo/GetContentListFilter",
      data
    );
    setLstContentPin(result.data.cContentPin);
    setLstContentUnpin(result.data.cContentUnPin);
    setIdBefore(result.data.sIdBefore);
    setMax(result.data.nMax);
    setIsShowList(true);
  };

  const onOpenNews = async (i) => {
    await AxiosGetJson("api/AdminInfo/UpdateViewContent?id=" + i.sUrl);

    let sTypeComponent = "";
    if (type === "1") {
      sTypeComponent = "/news-detail";
    }
    if (type === "2") {
      sTypeComponent = "/forest-story-detail";
    }
    if (type === "5") {
      sTypeComponent = "/learning-center-detail";
    }
    if (type === "6") {
      sTypeComponent = "/hl-project-detail";
    }
    if (type === "7") {
      if (i.nHeadLv1 === "16") {
        //law
        setIsModalVisibleFile(true);
        setsPath(i.sFileRef);
        setLv1(i.nHeadLv1);
      } else if (i.nHeadLv1 === "14") {
        //ebook
        setIsModalVisibleFile(true);
        setsPath(i.sFileRef);
        setLstImagePDF(i.lstImagePDF);
        setLv1(i.nHeadLv1);
      } else if (i.nHeadLv1 === "11") {
        sTypeComponent = "/knowledge-detail-plant";
      } else if (i.nHeadLv1 === "12") {
        sTypeComponent = "/knowledge-detail-animal";
      } else if (i.nHeadLv1 === "13") {
        //video
        setsPath(i.sUrlRef === "" ? i.sFileRef : i.sUrlRef);
        setLv1(i.nHeadLv1);
        setIsModalVisible(true);
      } else {
        sTypeComponent = "/knowledge-detail";
      }
    }
    if (sTypeComponent !== "") {
      let sPath = `${sTypeComponent}?id=${i.sUrl}`;
      //return history.push(sPath);
      return window.open(sPath, "_self");
    } else {
      return;
    }
  };

  const GetDataHeader = async (idStr, TranslatType) => {
    let result: any = await AxiosGetJson(
      "api/AdminInfo/GetDataHeader?id=" + idStr + "&Mode=" + TranslatType
    );
    setDataHeader(result);
  };

  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  const onDocumentLoadSuccess = (numPages) => {
    setNumPages(numPages);
  };

  const GetBookingList = async (idStr) => {
    let result: any = await AxiosGetJson(
      "api/AdminInfo/GetBookingList?id=" + idStr
    );
    setLstBooking(result);
  };

  const onOpenBooking = (o) => {
    window.open(o.sBookingUrl, "_blank");
  };

  const GetPathWeb = async () => {
    let result: any = await AxiosGetJson("api/AdminInfo/GetUrlPath");
    setPathWeb(result);
  };

  React.useEffect(() => {
    var { id } = parse(window.location.search);
    var idStr: string = "";
    if (id !== undefined && id !== null) {
      idStr = id.toString().split("/").join("%2f");
    }
    //else {
    //    idStr = "xvEhq%2famL3jePelPapN2%2fw==";
    //}
    SetBackground();
    GetConTentList(idStr, translate.TranslatType);
    GetDataHeader(idStr, translate.TranslatType);
    GetBookingList(idStr);
    window.scrollTo(0, 0);
    GetPathWeb();
  }, [id, translate]);

  const [slideIndex, setSlideIndex] = React.useState(0);
  const [updateCount, setupdateCount] = React.useState(0);

  const settings = {
    autoplay: false,
    dots: true,
    arrows: false,
    swipeToSlide: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    beforeChange: (current, next) => setSlideIndex(next),
    // responsive: [
    //     {
    //         breakpoint: 767,
    //         settings: {
    //             slidesToShow: 2,
    //         },
    //     },
    //     {
    //         breakpoint: 575,
    //         settings: {
    //             slidesToShow: 1,
    //         },
    //     },
    // ],
  };

  const scrollimg = React.createRef<Slider>();

  const onBackTo = async () => {
    let sTypeComponent = "";
    let sId = "";
    let sPath = "";
    if (type === "6") {
      sTypeComponent = "/hl-project-sub";
      sId = sIdBefore;
    }

    if (type === "7") {
      let result: any = await AxiosGetJson(
        "api/AdminInfo/IsOneChildContent?id=" + id
      );
      if (result) {
        sTypeComponent = "/knowledge";
      } else {
        sTypeComponent = "/knowledge-sub";
        sId = sIdBefore;
      }
    }

    if (sId !== "") {
      sPath = `${sTypeComponent}?id=${sId}`;
    } else {
      sPath = `${sTypeComponent}`;
    }
    return history.push(sPath);
  };

  // const onPageChange = async (nPage) =>{
  //     setPage(nPage);
  //     setIsShowList(false);
  //     var data ={
  //         sType: type,
  //         subType: id  ? id.toString().split("/").join("%2f") : "",
  //         Mode: translate.TranslatType,
  //         sKeyword: sKeyword,
  //         dStartDate: dStartDate ? dStartDate: null,
  //         dEndDate: dEndDate ? dEndDate: null,
  //         nPage: nPage,
  //         dbStartDate: dbStartDate,
  //         dbEndDate: dbEndDate
  //     };
  //     let result: any = await AxiosPostJson("api/AdminInfo/GetContentListFilter", data);
  //     setLstContentPin(result.data.cContentPin);
  //     setLstContentUnpin(result.data.cContentUnPin);
  //     setIdBefore(result.data.sIdBefore);
  //     setMax(result.data.nMax);
  //     setIsShowList(true);

  // }

  const onPageChange = async (page: any) => {
    setPage(page);
    setIsShowList(false);
    var data = {
      sType: type,
      subType: id ? id.toString().split("/").join("%2f") : "",
      Mode: translate.TranslatType,
      sKeyword: sKeyword,
      dStartDate: dStartDate ? dStartDate : null,
      dEndDate: dEndDate ? dEndDate : null,
      dbStartDate: dbStartDate,
      dbEndDate: dbEndDate,
      nPage: page,
    };
    let result: any = await AxiosPostJson(
      "api/AdminInfo/GetContentListFilter",
      data
    );
    setLstContentPin(result.data.cContentPin);
    setLstContentUnpin(result.data.cContentUnPin);
    setIdBefore(result.data.sIdBefore);
    setMax(result.data.nMax);
    setIsShowList(true);
  };

  return (
    <React.Fragment>
      <div id="PAGE_HOME" style={{ backgroundImage: "url(" + sFilePath + ")" }}>
        {type === "5" ? (
          <div className="home-news">
            <div className="news-panel">
              <div className="head-back">
                <div
                  className="icon-back"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/learning-center")}
                >
                  <i className="icofont-long-arrow-left"></i>
                </div>
                <div
                  className="title-back"
                  onClick={() => history.push("/learning-center")}
                >
                  {translate.Back}
                </div>
                <div className="back-img">
                  <a
                    href={dataHeader ? dataHeader.sUrlVisit : ""}
                    target="_blank"
                  >
                    <img
                      src={dataHeader ? dataHeader.sImageUrl : ""}
                      // width={"auto"}
                      // height={"120"}
                    />
                  </a>
                </div>
                {type === "5" ? (
                  <div className="content-booking">
                    <Slider
                      {...BookingList_Settings}
                      className="booking-slider"
                      style={{ marginTop: "2vw" }}
                    >
                      {lstBooking.length > 0
                        ? lstBooking.map((b, ib) => {
                            return (
                              <>
                                <div
                                  key={"b_" + ib}
                                  className="booking-link"
                                  onClick={() => onOpenBooking(b)}
                                  style={{
                                    backgroundImage:
                                      "url(" + b.sBooking_Path + ")",
                                  }}
                                ></div>
                              </>
                            );
                          })
                        : null}
                    </Slider>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}

        <div className="home-news">
          <div className="news-panel">
            <div className="panel-head">
              {type === "6" || type === "7" ? (
                <div
                  className="panel-title-first"
                  style={{ cursor: "pointer" }}
                  onClick={() => onBackTo()}
                >
                  <i className="icofont-arrow-left"></i> {translate.Back}
                </div>
              ) : null}
              <div className="panel-title">
                {type === "1"
                  ? translate.News
                  : type === "2"
                  ? translate.ForestStory
                  : dataHeader
                  ? dataHeader.sName
                  : ""}
              </div>
              <div className="form-row" style={{ marginLeft: "auto" }}>
                <div
                  className={
                    type === "1"
                      ? "search col-md-4 ml-auto"
                      : "search col-md-9 ml-auto"
                  }
                >
                  <Input
                    value={sKeyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    autoComplete="off"
                    placeholder={translate.Topic}
                  />
                </div>
                {type === "1" ? (
                  <React.Fragment>
                    <div className="search col-md-3 col-10">
                      <ThaiDatePicker
                        small={false}
                        placeholder={type === "1" ? translate.NewsDate : ""}
                        value={dStartDate}
                        maxDate={dEndDate}
                        onChange={(value) => {
                          setStartDate(value);
                          setDbStartDate(value ? value.valueOf() : null);
                        }}
                      />
                    </div>
                    <div className="search col-md-3 col-10">
                      <ThaiDatePicker
                        small={false}
                        placeholder={type === "1" ? translate.To : ""}
                        value={dEndDate}
                        minDate={dStartDate}
                        onChange={(value) => {
                          setEndDate(value);
                          setDbEndDate(value ? value.valueOf() : null);
                        }}
                      />
                    </div>
                  </React.Fragment>
                ) : null}
                <div className="search col-auto">
                  <ButtonMainSearch onClick={() => onSearchContent()} />
                </div>
              </div>
            </div>
            <div className="panel-item">
              {lstContentPin.length > 0
                ? lstContentPin.map((i, indx) => {
                    if (indx === 0) {
                      return (
                        <div className="left" key={"x_" + indx}>
                          <div
                            className={
                              "news-item " +
                              (i.isSetBackground
                                ? "news-primary"
                                : "news-primary-x")
                            }
                            onClick={() => {
                              onOpenNews(i);
                            }}
                          >
                            <div
                              className="news-cover"
                              style={{
                                backgroundImage: "url(" + i.sFilePath + ")",
                              }}
                            ></div>
                            <div className="news-title">{i.sType}</div>
                            {type === "1" ? (
                              <div className="news-date">{i.sDate}</div>
                            ) : null}
                          </div>
                        </div>
                      );
                    } else {
                      return <></>;
                    }
                  })
                : null}
              <div className="right">
                {isShowList ? (
                  <React.Fragment>
                    <Slider ref={scrollimg} {...settings}>
                      {lstContentUnpin.length > 0
                        ? lstContentUnpin.map((up, inup) => {
                            return (
                              <>
                                <div className="right-item" key={"ri_" + inup}>
                                  {up.cContentData.map((de, inde) => {
                                    return (
                                      <div
                                        className={
                                          "secondary-item " +
                                          (de.isSetBackground
                                            ? "news-secondary"
                                            : "news-secondary-x")
                                        }
                                        key={"der _" + inup + "_" + inde}
                                        onClick={() => {
                                          onOpenNews(de);
                                        }}
                                      >
                                        <div
                                          className="news-cover"
                                          style={{
                                            //backgroundImage: "url(" + de.sFilePath + ")",
                                            //backgroundImage: de.sFilePath,
                                            backgroundImage: `url(${de.sFilePath})`,
                                          }}
                                        ></div>
                                        <div className="news-title">
                                          {de.sType}
                                        </div>
                                        {type === "1" ? (
                                          <div className="news-date">
                                            {de.sDate}
                                          </div>
                                        ) : null}
                                      </div>
                                    );
                                  })}
                                </div>
                              </>
                            );
                          })
                        : null}
                      {/*
                                        <>
                                        <div className="right-item">
                                            <div className="secondary-item news-secondary">
                                                <div
                                                    className="news-cover"
                                                    style={{
                                                        backgroundImage: `url(${require("../../Images/home/example/news/news1.jpg")})`,
                                                    }}
                                                ></div>
                                                <div className="news-title">
                                                    ครบรอบ 5 ปี สถาปนา ศูนย์เรียนรู้ป่าวังจันทร์
                                                    วันที่ป่าไม้ เกษตรกรรม ความรู้
                                                    เติบโตเคียงคู่อยู่กับชุมชน
                                                </div>
                                                <div className="news-date">20/01/2564</div>
                                            </div>
                                            <div className="secondary-item news-secondary">
                                                <div
                                                    className="news-cover"
                                                    style={{
                                                        backgroundImage: `url(${require("../../Images/home/example/news/news1.jpg")})`,
                                                    }}
                                                ></div>
                                                <div className="news-title">
                                                    ครบรอบ 5 ปี สถาปนา ศูนย์เรียนรู้ป่าวังจันทร์
                                                    วันที่ป่าไม้ เกษตรกรรม ความรู้
                                                    เติบโตเคียงคู่อยู่กับชุมชน
                                                </div>
                                                <div className="news-date">20/01/2564</div>
                                            </div>
                                        </div>
                                    </>
                                    <>
                                        <div className="right-item">
                                            <div className="secondary-item news-secondary">
                                                <div
                                                    className="news-cover"
                                                    style={{
                                                        backgroundImage: `url(${require("../../Images/home/example/news/news1.jpg")})`,
                                                    }}
                                                ></div>
                                                <div className="news-title">
                                                    ครบรอบ 5 ปี สถาปนา ศูนย์เรียนรู้ป่าวังจันทร์
                                                    วันที่ป่าไม้ เกษตรกรรม ความรู้
                                                    เติบโตเคียงคู่อยู่กับชุมชน
                                                </div>
                                                <div className="news-date">20/01/2564</div>
                                            </div>
                                            <div className="secondary-item news-secondary">
                                                <div
                                                    className="news-cover"
                                                    style={{
                                                        backgroundImage: `url(${require("../../Images/home/example/news/news1.jpg")})`,
                                                    }}
                                                ></div>
                                                <div className="news-title">
                                                    ครบรอบ 5 ปี สถาปนา ศูนย์เรียนรู้ป่าวังจันทร์
                                                    วันที่ป่าไม้ เกษตรกรรม ความรู้
                                                    เติบโตเคียงคู่อยู่กับชุมชน
                                                </div>
                                                <div className="news-date">20/01/2564</div>
                                            </div>
                                        </div>
                                    </>
                                    <>
                                        <div className="right-item">
                                            <div className="secondary-item news-secondary">
                                                <div
                                                    className="news-cover"
                                                    style={{
                                                        backgroundImage: `url(${require("../../Images/home/example/news/news1.jpg")})`,
                                                    }}
                                                ></div>
                                                <div className="news-title">
                                                    ครบรอบ 5 ปี สถาปนา ศูนย์เรียนรู้ป่าวังจันทร์
                                                    วันที่ป่าไม้ เกษตรกรรม ความรู้
                                                    เติบโตเคียงคู่อยู่กับชุมชน
                                                </div>
                                                <div className="news-date">20/01/2564</div>
                                            </div>
                                            <div className="secondary-item news-secondary">
                                                <div
                                                    className="news-cover"
                                                    style={{
                                                        backgroundImage: `url(${require("../../Images/home/example/news/news1.jpg")})`,
                                                    }}
                                                ></div>
                                                <div className="news-title">
                                                    ครบรอบ 5 ปี สถาปนา ศูนย์เรียนรู้ป่าวังจันทร์
                                                    วันที่ป่าไม้ เกษตรกรรม ความรู้
                                                    เติบโตเคียงคู่อยู่กับชุมชน
                                                </div>
                                                <div className="news-date">20/01/2564</div>
                                            </div>
                                        </div>
                                    </>

                                    */}
                    </Slider>
                    {/*
                                    lstContentUnpin.length > 0 ?
                                    <div className="button-tags">
                                        <div 
                                            className="badge button-badge-1 badge-pill"
                                            style={{cursor: "pointer", display: nPage === 1 ? "none" : ""}}
                                            onClick={() =>{
                                                onPageChange(nPage - 1);
                                            }}>
                                            {translate.Previous}
                                        </div>
                                        <div 
                                            className={nPage === 1 ? "badge button-badge-3 badge-pill" : "badge button-badge-2 badge-pill" }
                                            style={{cursor: "pointer", display: nPage === nMax ? "none" : ""}}
                                            onClick={() =>{
                                                onPageChange(nPage + 1);
                                            }}>
                                            {translate.Next}
                                        </div>
                                    </div>
                                    :
                                    null
                                */}
                    {nMax !== 0 ? (
                      <div
                        className="button-tags"
                        style={{ textAlign: "center" }}
                      >
                        {/*<Pagination defaultCurrent={nPage} total={nMax} onChange={onPageChange}  pageSize={1} />*/}
                        <Pagination
                          activePage={nPage}
                          itemsCountPerPage={1}
                          totalItemsCount={nMax}
                          pageRangeDisplayed={5}
                          hideFirstLastPages={false}
                          prevPageText={
                            <i className="icofont-rounded-left"></i>
                          }
                          firstPageText={
                            <i className="icofont-rounded-double-left"></i>
                          }
                          nextPageText={
                            <i className="icofont-rounded-right"></i>
                          }
                          lastPageText={
                            <i className="icofont-rounded-double-right"></i>
                          }
                          itemClass="ant-pagination-item"
                          linkClass="ant-pagination-item-link"
                          innerClass="ant-pagination justify-content-center"
                          onChange={onPageChange}
                        />
                      </div>
                    ) : null}
                  </React.Fragment>
                ) : null}

                {/* {lstContentPin.length > 0
                  ? lstContentPin.map((i, indx) => {
                      if (indx === 0) {
                        return <></>;
                      } else {
                        return (
                          <>
                          <div className="right-item">
                              <div
                                key={"r_" + indx}
                                className="secondary-item news-secondary"
                                onClick={() => {
                                  onOpenNews(i);
                                }}
                              >
                                <div
                                  className="news-cover"
                                  style={{
                                    backgroundImage: "url(" + i.sFilePath + ")",
                                  }}
                                ></div>
                                <div className="news-title">{i.sType}</div>
                                <div className="news-date">{i.sDate}</div>
                              </div>
                            </div>
                          </>
                        );
                      }
                    })
                  : null} */}
              </div>

              {/*
                                 <div className="left">
                                    <div className="news-item news-primary">
                                        <div
                                            className="news-cover"
                                            style={{
                                                backgroundImage: `url(${require("../../Images/home/example/news/news1.jpg")})`,
                                            }}
                                        ></div>
                                        <div className="news-title">
                                            ครบรอบ 5 ปี สถาปนา ศูนย์เรียนรู้ป่าวังจันทร์ วันที่ป่าไม้
                                            เกษตรกรรม ความรู้ เติบโตเคียงคู่อยู่กับชุมชน
                                        </div>
                                        <div className="news-date">20/01/2564</div>
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="secondary-item news-secondary">
                                        <div
                                            className="news-cover"
                                            style={{
                                                backgroundImage: `url(${require("../../Images/home/example/news/news1.jpg")})`,
                                            }}
                                        ></div>
                                        <div className="news-title">
                                            ครบรอบ 5 ปี สถาปนา ศูนย์เรียนรู้ป่าวังจันทร์ วันที่ป่าไม้
                                            เกษตรกรรม ความรู้ เติบโตเคียงคู่อยู่กับชุมชน
                                        </div>
                                        <div className="news-date">20/01/2564</div>
                                    </div>
                                    <div className="secondary-item news-secondary">
                                        <div
                                            className="news-cover"
                                            style={{
                                                backgroundImage: `url(${require("../../Images/home/example/news/news1.jpg")})`,
                                            }}
                                        ></div>
                                        <div className="news-title">
                                            ครบรอบ 5 ปี สถาปนา ศูนย์เรียนรู้ป่าวังจันทร์ วันที่ป่าไม้
                                            เกษตรกรรม ความรู้ เติบโตเคียงคู่อยู่กับชุมชน
                                        </div>
                                        <div className="news-date">20/01/2564</div>
                                    </div>
                                    <div className="secondary-item news-secondary">
                                        <div
                                            className="news-cover"
                                            style={{
                                                backgroundImage: `url(${require("../../Images/home/example/news/news1.jpg")})`,
                                            }}
                                        ></div>
                                        <div className="news-title">
                                            ครบรอบ 5 ปี สถาปนา ศูนย์เรียนรู้ป่าวังจันทร์ วันที่ป่าไม้
                                            เกษตรกรรม ความรู้ เติบโตเคียงคู่อยู่กับชุมชน
                                        </div>
                                        <div className="news-date">20/01/2564</div>
                                    </div>
                                    <div className="secondary-item news-secondary">
                                        <div
                                            className="news-cover"
                                            style={{
                                                backgroundImage: `url(${require("../../Images/home/example/news/news1.jpg")})`,
                                            }}
                                        ></div>
                                        <div className="news-title">
                                            ครบรอบ 5 ปี สถาปนา ศูนย์เรียนรู้ป่าวังจันทร์ วันที่ป่าไม้
                                            เกษตรกรรม ความรู้ เติบโตเคียงคู่อยู่กับชุมชน
                                        </div>
                                        <div className="news-date">20/01/2564</div>
                                    </div>
                                </div>
                             */}
            </div>
          </div>
        </div>
        {/* {
                    type === "5" ?
                    <div className="home-booking">
                        <Slider {...BookingList_Settings} className="booking-slider" style={{ marginTop: "2vw" }}>
                            {
                                lstBooking.length > 0 ?
                                    lstBooking.map((b, ib) => {
                                        return (
                                            <><div key={"b_" + ib} className="booking-link" onClick={() => onOpenBooking(b)} style={{ backgroundImage: "url(" + b.sBooking_Path + ")", }}></div></>
                                        )
                                    })
                                    :
                                    null
                            }
                        </Slider>
                    </div>
                    :
                    null
                } */}
      </div>
      {isModalVisible ? (
        <Modal
          className="modal-x"
          visible={isModalVisible}
          width={1000}
          onCancel={handleOk}
          footer={false}
          bodyStyle={{ backgroundColor: "#3f3838" }}
        >
          <div className="player-wrapper" style={{ height: "500px" }}>
            <ReactPlayer
              key={"vdo_" + sPath}
              playing
              url={sPath}
              width="100%"
              height="100%"
              controls
            />
          </div>
        </Modal>
      ) : null}
      {isModalVisibleFile && lv1 === "14" ? (
        <Modal
          //className={"modal-x " + (isFullScreen ? "ebook-full" : "ebook-popup")}
          className={"modal-x ebook-popup"}
          visible={isModalVisibleFile}
          //width={isFullScreen ? "100vw" : "1000"}
          width={1000}
          onCancel={handleOk}
          footer={false}
          bodyStyle={{ backgroundColor: "transparent", height: "750px" }}
        >
          <br />
          {/*
                            <Row gutter={[8, 16]} justify="center">
                            <Col>
                                <Tooltip 
                                    style={{ width : "400px" }}
                                    title={translate.ZoomOut}
                                >
                                    <Button 
                                        size="middle" 
                                        shape="circle" 
                                        style={{ background: "#91d5ff", border: "none", color: "#000000" }} 
                                        disabled={isFullScreen} 
                                        onClick={() => {
                                            if (nLvZoom === 0){
                                                return;
                                            }
                                            else{
                                                setIsLoad(true);
                                                setLvZoom(nLvZoom - 1);
                                                setTimeout(() => {
                                                    setIsLoad(false);
                                                }, 500);
                                            }
                                        }}
                                    >
                                        <i className="icofont-ui-zoom-out"></i>
                                    </Button>
                                </Tooltip>
                            </Col>
                            <Col>
                                <AntdSlider 
                                    min={0} 
                                    max={5}  
                                    defaultValue={nLvZoom}
                                    value={nLvZoom} 
                                    disabled={isFullScreen} 
                                    onChange={e => {
                                        setIsLoad(true);
                                        setLvZoom(e);
                                        setTimeout(() => {
                                            setIsLoad(false);
                                        }, 500);
                                    }} 
                                    style={{width : "200px"}}
                                />
                            </Col>
                            <Col>
                                <Tooltip 
                                    style={{ width : "400px" }}
                                    title={translate.ZoomIn}
                                >
                                    <Button 
                                        size="middle" 
                                        shape="circle" 
                                        style={{ background: "#91d5ff", border: "none", color: "#000000" }} 
                                        disabled={isFullScreen} 
                                        onClick={() => {
                                            if (nLvZoom === 5){
                                                return;
                                            }
                                            else{
                                                setIsLoad(true);
                                                setLvZoom(nLvZoom + 1);
                                                setTimeout(() => {
                                                    setIsLoad(false);
                                                }, 500);
                                            }
                                        }}
                                    >
                                        <i className="icofont-ui-zoom-in"></i>
                                    </Button>
                                </Tooltip>
                            </Col>
                            <Col>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Col>
                            <Col>
                                <Tooltip 
                                    style={{ width : "400px" }}
                                    title={isFullScreen ? translate.ExitFullScreen : translate.FullScreen}
                                >
                                    <Button size="middle" shape="circle" style={{ background: "#00c3da", border: "none", color: "#000000" }}
                                        onClick={() => {
                                            setIsLoad(true);
                                            setIsFullScreen(!isFullScreen);
                                            setTimeout(() => {
                                                setIsLoad(false);
                                            }, 500);
                                        }}
                                    >
                                        <i className="icofont-resize"></i>
                                    </Button>
                                </Tooltip>
                            </Col>
                            <Col>
                                <Tooltip 
                                    style={{ width : "400px" }}
                                    title={translate.Download}
                                >
                                    <a href={sPath} download="">
                                        <Button size="middle" shape="circle" style={{ background: "#ffc107", border: "none", color: "#000000" }}>
                                            <i className="icofont-download"></i>
                                        </Button>
                                    </a>
                                </Tooltip>
                            </Col>
                        </Row>
                        <br />
                        */}
          {isLoad ? null : (
            <React.Fragment>
              {/* <iframe src={"/content-ebook?path=" + sPath} width={"100%"} height={"100%"}/> */}
              <iframe
                src={
                  sPathWeb +
                  "content-ebook?path=" +
                  sPath +
                  "&Mode=" +
                  translate.TranslatType
                }
                width={"100%"}
                height={"100%"}
              />
              {/*
                                    isFullScreen ?
                                    <div className="player-wrapper" style={{ height: "88vh", justifyContent:"center" }}>
                                        <HTMLFlipBook 
                                            width={500}
                                            height={700}
                                            style={{ marginLeft: "auto", marginRight: "auto" }} 
                                            className={""} 
                                            startPage={0} 
                                            size={"fixed"} 
                                            minWidth={0} 
                                            maxWidth={0} 
                                            minHeight={0} 
                                            maxHeight={0} 
                                            drawShadow={false} 
                                            flippingTime={1000} 
                                            usePortrait={false} 
                                            startZIndex={0} 
                                            autoSize={true} 
                                            maxShadowOpacity={0} 
                                            showCover={true} 
                                            mobileScrollSupport={false} 
                                            clickEventForward={true}
                                            useMouseEvents={true} 
                                            swipeDistance={0} 
                                            showPageCorners={true} 
                                            disableFlipByClick={false}                            
                                        >
                                        {
                                            lstImagePDF ?
                                                lstImagePDF.map((le, ile) => {
                                                    return(
                                                        <Page key={"eb_" + ile} number="1">{le}</Page>
                                                    )
                                                })
                                                :
                                                null
                                        }
                                        </HTMLFlipBook>
                                    </div>
                                    :
                                    <div className="player-wrapper" style={{ height: "600px", justifyContent:"center" }}>
                                        <HTMLFlipBook 
                                            width={400 + (nLvZoom * 15)}
                                            height={600 + (nLvZoom * 15)}
                                            style={{ marginLeft: "auto", marginRight: "auto" }} 
                                            className={""} 
                                            startPage={0} 
                                            size={"fixed"} 
                                            minWidth={0} 
                                            maxWidth={0} 
                                            minHeight={0} 
                                            maxHeight={0} 
                                            drawShadow={false} 
                                            flippingTime={1000} 
                                            usePortrait={false} 
                                            startZIndex={0} 
                                            autoSize={true} 
                                            maxShadowOpacity={0} 
                                            showCover={true} 
                                            mobileScrollSupport={false} 
                                            clickEventForward={true}
                                            useMouseEvents={true} 
                                            swipeDistance={0} 
                                            showPageCorners={true} 
                                            disableFlipByClick={false}                            
                                        >
                                        {
                                            lstImagePDF ?
                                                lstImagePDF.map((le, ile) => {
                                                    return(
                                                        <Page key={"eb_" + ile} number="1">{le}</Page>
                                                    )
                                                })
                                                :
                                                null
                                        }
                                        </HTMLFlipBook>
                                    </div>
                                */}
            </React.Fragment>
          )}
        </Modal>
      ) : null}

      {isModalVisibleFile && lv1 === "16" ? (
        <Modal
          className="modal-x"
          visible={isModalVisibleFile}
          width={1000}
          onCancel={handleOk}
          footer={false}
          bodyStyle={{ backgroundColor: "#3f3838" }}
        >
          <br />
          <div className="player-wrapper" style={{ height: "500px" }}>
            <iframe src={sPath} width={"100%"} height={"100%"} />
          </div>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};
export default Main_Content_List;
