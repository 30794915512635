import * as React from 'react';
import Admin_Content from '../admin-content/new-content/admin-content'
import { parse } from "query-string"
import { AxiosGetJson } from '../Service/Config/AxiosMethod';
import Admin_Content_Plants_Animals from '../admin-content/new-content/admin-content-plants-animals';

const Admin_knowledge_edit = () => {
    const [lstData, setLstData] = React.useState(null as any);
    const GetContentDetail = async (idStr, typeStr) => {
        let result: any = await AxiosGetJson("api/AdminContent/CheckData?id=" + idStr + "&sType=" + typeStr);
        setLstData(result);
    }

    React.useEffect(() => {
        var { id, type } = parse(window.location.search);
        var idStr: string = "";
        var typeStr: string = "";
        if (id !== undefined && id !== null) {
            idStr = id.toString().split("/").join("%2f");
        }
        else {
            idStr = "xvEhq%2famL3jePelPapN2%2fw==";
        }
        if (type !== undefined && type !== null) {
            typeStr = type.toString().split("/").join("%2f");
        }
        else {
            typeStr = "xvEhq%2famL3jePelPapN2%2fw==";
        }
        GetContentDetail(idStr, typeStr);
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            {
                lstData ?
                (
                        lstData.isPA ?
                            <Admin_Content_Plants_Animals nPerMenuId={8} sType={lstData.isAnimal ? "2" : "1"} backEvent={"/admin-knowledge-list"} />
                            :
                            <Admin_Content nPerMenuId={8} sType={"3"} backEvent={"/admin-knowledge-list"} />
                )
                : null
            }
        </>
    );
}
export default Admin_knowledge_edit;