import { Checkbox, Col, Form, Input, Row, Switch, Select as AntdSelect} from "antd";
import * as React from "react";
import { ButtonBack, ButtonSave } from "../Systems/button_all";
import { BoxMsg, Decrypt, DialogConfirm, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import AuthenService from "../Service/AuthenService";
const { Option } = AntdSelect;

const Admin_BackGround = () => {
    const [SetValue] = Form.useForm();
    const [datMaster, setDatMaster] = React.useState(null as any);
    const [sPicType, setPicType] = React.useState("");
    const [sFilePath, setFilePath] = React.useState("");
    const [isEdit, setisEdit] = React.useState(false);


    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            let test = UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == 2).map(t => t.nPermission)[0] === 2 ? false : true

            setisEdit(test)
        }
    }

    const GetBackground = async () => {
        let masterDat: any = await AxiosGetJson("api/AdminInfo/GetMaster");
        setDatMaster(masterDat);

        let result: any = await AxiosGetJson("api/AdminInfo/GetBackground");
        SetValue.setFieldsValue({
            sPicType: result.sPicType,
        });
        setPicType(result.sPicType);
        setFilePath(result.sFilePath);

    }

    const onSave = (values: any) => {
        DialogConfirm(async () => {
            var data = {
                sPicType: sPicType,
            }
            let result: any = await AxiosPostJson("api/AdminInfo/SaveDataBackground", data);
            if (result.data.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Save, () => {
                    //history.push("/admin-intropage-list");
                });
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }

        });
    }
    React.useEffect(() => {
        GetUserInfo();
    }, []);

    React.useEffect(() => {
        GetBackground()
        window.scrollTo(0, 0);
    }, [isEdit])

    return (
        <Form name="From" form={SetValue} onFinish={onSave}
            onFinishFailed={({ errorFields }) => {
                let element = document.querySelector(`#From_${errorFields[0].name}`);
                    var firstErrorElemY: any = null;
                    if (element) {
                        firstErrorElemY = element.getBoundingClientRect().top - 100; // bar height(100)px
                        window.scrollTo(0, window.scrollY + firstErrorElemY)
                    }
                    else{
                        element = document.getElementById(`${errorFields[0].name}`);
                        if (element) {
                            firstErrorElemY = element.getBoundingClientRect().top - 100; // bar height(100)px
                            window.scrollTo(0, window.scrollY + firstErrorElemY)
                        }
                    }
            }}>
            <br />
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <b>ภาพพื้นหลัง </b>
                    <span className="text-danger">*</span>
                    <Form.Item
                        name="sPicType"
                        rules={[
                            { required: true, message: BoxMsg.Desc_Required_Field + "ภาพพื้นหลัง" },
                        ]}
                    >
                        <AntdSelect
                            disabled={!isEdit}
                            allowClear={true}
                            showSearch={true}
                            onChange={(e) => {
                                if (typeof e != "number") {
                                    setPicType( e ? e.toString() : "" );
                                    var dat = datMaster.filter(f => f.value === e);
                                    if (dat.length > 0) {
                                        setFilePath(dat[0].sFilePath)
                                    }
                                }
                            }}
                        >
                            {
                                datMaster ?
                                    datMaster.map((e, indx) => {
                                        return (
                                            <Option key={"indx_" + indx} value={e.value}>{e.label}</Option>
                                        );
                                    })
                                    :
                                    null
                            }
                        </AntdSelect>
                    </Form.Item>
                    <br />
                    <div style={{
                        backgroundImage: "url(" + sFilePath + ")",
                        width: 1080,
                        height: 480,
                        flexGrow: 1,
                        display: "block",
                        backgroundPositionX: "center",
                        backgroundPositionY: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover"
                    }}>
                    </div>
                </Col>
            </Row>
            <hr />
            <Row gutter={[16, 16]} justify="center">
                {isEdit ?
                    <Col>
                        <ButtonSave htmlType="submit" />
                    </Col>
                    : null
                }
            </Row>
        </Form>

    );
};
export default Admin_BackGround;


