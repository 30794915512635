import * as React from "react";
import * as ReactDom from 'react-dom';
import { loadModules } from 'esri-loader'
import { AxiosGetJson } from "../Service/Config/AxiosMethod";
import { Button, Form, Input, List, Modal } from "antd";
import { Select as AntdSelect } from "antd";
import { ButtonClear, ButtonSearchExcel } from "../Systems/button_all";
import './Main_Map.css'
import { useHistory } from "react-router";
import Image from "../Systems/Image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector, useDispatch } from 'react-redux';
import '../main-home/home.css';
import {
    thailanguage,
    englishlanguage,
    currentlanguage,
} from '../app/counterSlice';
import GalleryTrigger from "../ST_Handlers/GalleryTrigger";
import ScrollArea from "react-scrollbar";
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from "react-leaflet";
import * as L from "leaflet";

const { Option } = AntdSelect;
const Controller = 'api/AdminMap/';

const Main_Map = () => {
    //const mapDiv = React.useRef(null);
    const [V_Map, setV_Map] = React.useState([] as any);
    const [TH_Province, setTH_Province] = React.useState([] as Array<any>);
    const [TH_District, setTH_District] = React.useState([] as Array<any>);
    const [TH_SubDistrict, setTH_SubDistrict] = React.useState([] as Array<any>);
    const [Region, setRegion] = React.useState([] as Array<any>);
    const [T_Map_Image, setT_Map_Image] = React.useState([] as Array<any>);
    const [SetValue] = Form.useForm();
    const translate = useSelector(currentlanguage);
    const [listnProvince, setlistnProvince] = React.useState([] as any);
    const [listnDistrict, setlistnDistrict] = React.useState([] as any);
    const [listnSubDistrict, setlistnSubDistrict] = React.useState([] as any);
    const [listRegion, setlistRegion] = React.useState([] as any);
    const [state, setState] = React.useState([] as any);
    const GetTH_District = async () => {
        let result: any = await AxiosGetJson(Controller + "GetTH_District");
        setTH_District(result)
    }
    const GetTH_SubDistrict = async () => {
        let result: any = await AxiosGetJson(Controller + "GetTH_SubDistrict");
        setTH_SubDistrict(result)
    }

    const GetTH_Province = async () => {
        let result: any = await AxiosGetJson(Controller + "GetTH_Province");
        setTH_Province(result)
        setRegion(groupBy(result.map(m => ({ 'sRegionThai': m.sRegionThai, 'sRegionEng': m.sRegionEng }))));
    }
    const GetDataOnPageLoad = async () => {
        let result: any = await AxiosGetJson(Controller + "GetV_Map_Main");

        setV_Map(result.filter(f => f.cLanguage === translate.TranslatType));
        SetValue.setFieldsValue({
            nProvince: [],
            nDistrict: [],
            nSubDistrictID: [],
        });
        //Genmap(result);
    }
    const GetT_Map_Image = async () => {
        let result: any = await AxiosGetJson(Controller + "GetT_Map_Image");
        setT_Map_Image(result);
    }
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
    };

    //const icontents = (data, listImage) => {

    //    return (
    //        <React.Fragment>
    //            <div>{translate.TranslatType === "TH" ? data.sDescTH : data.sDescEN}</div><br />
    //            <a style={{ color: 'blue' }} onClick={() => { showModal(data, listImage); }} > {translate.Readmore}...</a ><br /><br />
    //            <Slider {...settings}>

    //                {
    //                    listImage.map((p, id) => {
    //                        return (

    //                            <div key={"listImage_" + id}>
    //                                <Image src={p.sFilePath} fallbackSrc={require("../../Images/noimage.png")} />
    //                            </div>

    //                        )
    //                    })
    //                }

    //            </Slider>

    //        </React.Fragment>
    //    )
    //}

    //const [view, setview] = React.useState([] as any);
    //const [point, setpoint] = React.useState([] as any);
    //const Genmap = (data) => {

    //    loadModules(["esri/config",
    //        "esri/Map",
    //        "esri/views/MapView",
    //        "esri/widgets/Search",
    //        "esri/geometry/Point",
    //        "esri/Graphic"
    //    ],
    //        { css: "https://js.arcgis.com/4.10/esri/css/main.css" })

    //        .then(([esriConfig, Map, MapView, Search, Point, Graphic]) => {
    //            esriConfig.apiKey = "AAPKd39a53020e384d4fb4f5101624a4ea15R7BY3xucw4L2R-vbTxvyZ0G7j9KBVEO8hfp846W8hCxs9kwIDOv-uWC6g5IA9tQw";
    //            var map = new Map({
    //                basemap: "osm-standard", //Basemap layer service
    //            });
    //            setpoint(new Point)
    //            let view = new MapView({
    //                map: map,
    //                center: [100.53, 13.709], // Longitude, latitude
    //                zoom: 7, // Zoom level
    //                container: mapDiv.current // Div element
    //            });
    //            /*************************
    //            * Create point graphics
    //            *************************/
    //            // Create a symbol for drawing the point


    //            const contents = (data, listImage) => {
    //                const div = document.createElement('div');
    //                ReactDom.render(icontents(data, listImage), div);
    //                return div;
    //            }

    //            // Create a graphic and add the geometry and symbol to it
    //            const pointGraphic = new Array();

    //            data.forEach(o => {
    //                const textSymbol = {
    //                    type: "simple-marker",
    //                    color: o.sType == 1 ? "#3ae4bb" :
    //                        o.sType == 2 ? "#ee9852" :
    //                            "#f96993",
    //                    //outline: {
    //                    //    color: "#000000",
    //                    //    width: 2
    //                    //}
    //                };


    //                const point = new Point({
    //                    type: "point",
    //                    longitude: parseFloat(o.sLongitude),
    //                    latitude: parseFloat(o.sLatitude)
    //                });

    //                const pointGraphicMap = new Graphic({
    //                    geometry: point,

    //                    symbol: textSymbol,
    //                    popupTemplate: {
    //                        title: translate.TranslatType === "TH" ? o.sNameTH : o.sNameEN,
    //                        lastEditInfoEnabled: false,
    //                        content: contents(o, T_Map_Image.filter(w => w.nID_Map == o.nID && w.cLanguage == translate.TranslatType))


    //                    },
    //                    attattributes: {
    //                        itemID: o.nID
    //                    }
    //                });
    //                pointGraphic.push(pointGraphicMap)

    //            })
    //            // Add the graphics to the view's graphics layer
    //            view.graphics.addMany(pointGraphic);
    //            setview(view);


    //        });

    //}
    //let Goto = ({ nID, sLongitude, sLatitude, sNameTH, sDescTH }) => {
    //    const pinIndex = view.graphics.findIndex(i => i.attattributes.itemID == nID)
    //    if (pinIndex > -1) {
    //        const pin = view.graphics.items[pinIndex];
    //        view.popup.open({
    //            location: {
    //                type: "point",
    //                x: parseFloat(sLongitude),
    //                y: parseFloat(sLatitude)
    //            },
    //            features: [pin]
    //        });
    //        let pt = point
    //        pt.latitude = parseFloat(sLatitude);
    //        pt.longitude = parseFloat(sLongitude);
    //        view.goTo({ target: pt, zoom: 16 });
    //    }
    //}

    //const ClickGoto = async (item) => {
    //    Goto(item);
    //}
    const SearchData = async (stxtName, listnProvince, listnDistrict, listnSubDistrict, listsRegion) => {
        let sName = stxtName == undefined ? "" : stxtName;
        let sProvince = listnProvince == undefined ? "" : listnProvince.map(a => a).toString();
        let sDistrict = listnDistrict == undefined ? "" : listnDistrict.map(a => a).toString();
        let sSubDistrict = listnSubDistrict == undefined ? "" : listnSubDistrict.map(a => a).toString();
        let sRegion = listsRegion == undefined ? "" : listsRegion.map(a => a).toString();

        let result: any = await AxiosGetJson(Controller + "SearchData_Main?stxtName=" + encodeURIComponent(sName) + "&sProvince=" + sProvince + "&sDistrict=" + sDistrict + "&sSubDistrict=" + sSubDistrict + "&sRegion=" + sRegion);
        setV_Map(result.filter(f => f.cLanguage === translate.TranslatType));
        //Genmap(result);
    }
    const onSave = (values: any) => {
        SearchData(values.sName, values.nProvince, values.nDistrict, values.nSubDistrictID, values.sRegion)
        const { map } = state;
        if (map) {
            map.flyTo([13.709, 100.53], 6);
        }


    };
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [sTitle, setsTitle] = React.useState("" as string);
    const [Images, setImages] = React.useState([] as Array<any>);
    const [sDetailTH, setssDetailTH] = React.useState("" as string);
    const [dataModal, setdataModal] = React.useState([] as any);
    const showModal = (data, listImage) => {
        setdataModal(data);
        setIsModalVisible(true);
        setsTitle(translate.TranslatType === "TH" ? data.sNameTH : data.sNameEN);
        setImages(T_Map_Image.filter(f => f.nID_Map === data.nID && f.cLanguage === translate.TranslatType));
        setssDetailTH(translate.TranslatType === "TH" ? data.sDetailTH : data.sDetailEN);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        setImages([]);
    };
    const ClearData = () => {
        SetValue.setFieldsValue({
            sName: "",
            nProvince: undefined,
            sRegion: undefined,
            nDistrict: undefined,
            nSubDistrictID: undefined,
        })


        setlistnProvince([]);
        setlistnDistrict([]);
        setlistnSubDistrict([]);
        setlistRegion([]);
    };
    const onChangeRegion = (data: any) => {

        let listProvince = TH_Province.filter(f => listnProvince.includes(f.nProvinceID) && data.includes(f.sRegionThai)).map(t => t.nProvinceID);

        let listDistrict = TH_District.filter(f => listnDistrict.includes(f.nDistrictID) && listProvince.includes(f.nProvinceID)).map(t => t.nDistrictID);
        let listSubDistrict = TH_SubDistrict.filter(f => listnSubDistrict.includes(f.nSubDistrictID) && listDistrict.includes(f.nDistrictID)).map(t => t.nSubDistrictID);


        if (listnProvince.length != 0) {

            SetValue.setFieldsValue({
                nProvince: listProvince,
                nDistrict: listDistrict,
                nSubDistrictID: listSubDistrict
            })
        }

        setlistRegion(data);
        setlistnProvince(listProvince);
        setlistnDistrict(listDistrict);
        setlistnSubDistrict(listSubDistrict);
    }

    const onChangeProvince = (data: any) => {
        let listDistrict = TH_District.filter(f => listnDistrict.includes(f.nDistrictID) && data.includes(f.nProvinceID)).map(t => t.nDistrictID);
        let listSubDistrict = TH_SubDistrict.filter(f => listnSubDistrict.includes(f.nSubDistrictID) && listDistrict.includes(f.nDistrictID)).map(t => t.nSubDistrictID);
        if (listRegion.length == 0) {
            let listRegion = groupbyarr(TH_Province.filter(f => data.includes(f.nProvinceID)).map(t => t.sRegionThai));


            SetValue.setFieldsValue({
                nDistrict: listDistrict,
                nSubDistrictID: listSubDistrict,
                sRegion: listRegion,
            })
            setlistRegion(listRegion);


        } else {
            SetValue.setFieldsValue({
                nDistrict: listDistrict,
                nSubDistrictID: listSubDistrict,
            })
        }
        setlistnProvince(data);
        setlistnDistrict(listDistrict);
        setlistnSubDistrict(listSubDistrict);
    };

    const onChangeDistrict = (data: any) => {
        let listSubDistrict = TH_SubDistrict.filter(f => listnSubDistrict.includes(f.nSubDistrictID) && data.includes(f.nDistrictID)).map(t => t.nSubDistrictID);
        if (listnProvince.length == 0 && listRegion.length == 0) {
            let listProvince = groupbyarr(TH_District.filter(f => data.includes(f.nDistrictID)).map(t => t.nProvinceID));
            let listRegion = groupbyarr(TH_Province.filter(f => listProvince.includes(f.nProvinceID)).map(t => t.sRegionThai));
            SetValue.setFieldsValue({
                nProvince: listProvince,
                sRegion: listRegion,
                nSubDistrictID: listSubDistrict
            })
            setlistRegion(listRegion);
            setlistnProvince(listProvince);

        } else {

            SetValue.setFieldsValue({
                nSubDistrictID: listSubDistrict
            })

        }
        setlistnSubDistrict(listSubDistrict);
        setlistnDistrict(data);
    };

    const onChangeSubDistrict = (data: any) => {
        if (listnDistrict.length == 0 && listnProvince.length == 0 && listRegion.length == 0) {
            let listDistrict = groupbyarr(TH_SubDistrict.filter(f => data.includes(f.nSubDistrictID)).map(t => t.nDistrictID));
            let listProvince = groupbyarr(TH_District.filter(f => listDistrict.includes(f.nDistrictID)).map(t => t.nProvinceID));
            let listRegion = groupbyarr(TH_Province.filter(f => listProvince.includes(f.nProvinceID)).map(t => t.sRegionThai));
            SetValue.setFieldsValue({
                nDistrict: listDistrict,
                nProvince: listProvince,
                sRegion: listRegion,
            })
            setlistRegion(listRegion);
            setlistnProvince(listProvince);
            setlistnDistrict(listDistrict);
        }
        setlistnSubDistrict(data);

    };
    const groupbyarr = (list) => {
        const newArr: any = [];
        list.forEach((o) => {
            const index = newArr.findIndex(x => x == o)
            if (index == -1) {
                newArr.push(o);
            }
        });
        return newArr;
    }

    const groupBy = (list) => {
        const newArr: any = [];
        list.forEach((o) => {
            const index = newArr.findIndex(x => x.sRegionThai == o.sRegionThai)
            if (index == -1) {
                newArr.push(o);
            }
        });
        return newArr;
    }

    const [sFilePath, setFilePath] = React.useState("");
    const SetBackground = async () => {
        let result: any = await AxiosGetJson("api/AdminInfo/GetBackground");
        setFilePath(result.sFilePath);
    }

    const icon1 = L.icon({
        iconUrl: require('../../Images/map/new.png'),
        iconSize: [30, 40],
        //iconAnchor: [22, 94],
        //popupAnchor: [-3, -76],
    });

    const icon2 = L.icon({
        iconUrl: require('../../Images/map/old.png'),
        iconSize: [30, 40],
        //iconAnchor: [22, 94],
        //popupAnchor: [-3, -76],
    });
    const icon3 = L.icon({
        iconUrl: require('../../Images/map/other.png'),
        iconSize: [30, 40],
        //iconAnchor: [22, 94],
        //popupAnchor: [-3, -76],
    });








    const changePos = (item) => {
        //setState({ position: [item.sLatitude, item.sLongitude] });
        const { map } = state;
        if (map) {
            map.flyTo([item.sLatitude, item.sLongitude], 15);
            //map.openPopup(
            //    (translate.TranslatType === "TH" ? item.sDescTH : item.sDescEN)
            //    , [item.sLatitude, item.sLongitude]);
            //this.setState({ marker: id, zoom: 16, center: [lat, lng] });
            //markerRefs[item.nID].leafletElement.openPopup()
            //map.openPopup();

        }
    }

    React.useEffect(() => {
        GetT_Map_Image();
    }, []);

    React.useEffect(() => {
        SetBackground();
        GetTH_Province();
        GetTH_District();
        GetTH_SubDistrict();
        GetDataOnPageLoad();
    }, [T_Map_Image]);

    return (
        <React.Fragment>
            {
                //<div >
                //    <button

                //        aria-label="Increment value"
                //        onClick={() => dispatch(thailanguage())}
                //    >
                //        TH
                //    </button>
                //    <ButtonBack onClick={() => { history.push("/main-contact-us"); }} />
                //    <button

                //        aria-label="Decrement value"
                //        onClick={() => dispatch(englishlanguage())}
                //    >
                //        EN
                //    </button>
                //</div>
            }
            <div id="PAGE_HOME" style={{ backgroundImage: "url(" + sFilePath + ")", }}>
                <div className="home-news">
                    
                        <div className="map-panel">
                            <div className="panel-head">
                               
                                <div className="panel-title">{translate.PTTForestMap}</div>
                            </div>
                        </div>
                    
                </div>
                <div className="home-news">
                    <div className="map-panel" style={{ marginTop: "-5vw" }}>
                        <div className="panel-body-content" style={{ justifyContent: "start" }}>


                            <div id="MAIN_MAP">
                                <br />
                                <ol className="breadcrumb pull-left" style={{ marginTop: "-10px", marginBottom: "5px", paddingTop: "0px", paddingBottom: "0px", backgroundColor: "transparent" }} >

                                    <li>
                                        <img style={{ marginBottom: "8px" }} src={require('../../Images/map/old.png')} width="15px" height="20px" />
                                        {translate.PTTforest}
                                    </li>&nbsp;&nbsp;

                                    <li>
                                        <img style={{ marginBottom: "8px" }} src={require('../../Images/map/new.png')} width="15px" height="20px" />
                                        {translate.FPTForest}
                                    </li>&nbsp;&nbsp;

                                    <li>
                                        <img style={{ marginBottom: "8px" }} src={require('../../Images/map/other.png')} width="15px" height="20px" />
                                        {translate.OtherForest}
                                    </li>
                                </ol>
                                <div className="container-fluid"  >
                                    <div className="row">
                                        <div className="col-sm-12 col-xl-8 col-md-12" >
                                            {
                                                //<div style={{ height: "750px" }} ref={mapDiv}>
                                                //</div>

                                            }

                                            <MapContainer className="mapcontainer" center={[13.709, 100.53]} zoom={7} scrollWheelZoom={true} style={{ height: "100%", width: "100%" }} whenCreated={map => setState({ map })}  >
                                                {
                                                    <TileLayer
                                                        //attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                    />
                                                }
                                                {

                                                    V_Map.filter(f => f.sType == "1").map((o, i) =>
                                                        <Marker key={"key_" + i} icon={icon1} position={[o.sLatitude, o.sLongitude]}    >
                                                            <Popup maxWidth={300} minWidth={300}   >
                                                                <div style={{ backgroundColor: "#ffffff" }} dangerouslySetInnerHTML={{ __html: translate.TranslatType === "TH" ? o.sDescTH : o.sDescEN }}>
                                                                </div>
                                                                <a style={{ color: 'blue' }} onClick={() => { showModal(o, undefined); }} > {translate.Readmore}...</a >
                                                            </Popup>
                                                        </Marker>
                                                    )

                                                }


                                                {

                                                    V_Map.filter(f => f.sType == "2").map((o, i) =>
                                                        <Marker key={"key_" + i} icon={icon2} position={[o.sLatitude, o.sLongitude]}>
                                                            <Popup maxWidth={300} minWidth={300}   >
                                                                <div style={{ backgroundColor: "#ffffff" }} dangerouslySetInnerHTML={{ __html: translate.TranslatType === "TH" ? o.sDescTH : o.sDescEN }}>
                                                                </div>
                                                                <a style={{ color: 'blue' }} onClick={() => { showModal(o, undefined); }} > {translate.Readmore}...</a >
                                                            </Popup>
                                                        </Marker>
                                                    )

                                                }
                                                {

                                                    V_Map.filter(f => f.sType == "3").map((o, i) =>
                                                        <Marker key={"key_" + i} icon={icon3} position={[o.sLatitude, o.sLongitude]}>
                                                            <Popup maxWidth={300} minWidth={300}   >
                                                                <div style={{ backgroundColor: "#ffffff" }} dangerouslySetInnerHTML={{ __html: translate.TranslatType === "TH" ? o.sDescTH : o.sDescEN }}>
                                                                </div>
                                                                <a style={{ color: 'blue' }} onClick={() => { showModal(o, undefined); }} > {translate.Readmore}...</a >
                                                            </Popup>
                                                        </Marker>
                                                    )

                                                }
                                            </MapContainer>

                                        </div>

                                        <div className="col-sm-12 col-xl-4 col-md-12"  >
                                            <Form onFinish={onSave}
                                                form={SetValue}
                                            >
                                                <div className="row">
                                                    <div className="col-12">
                                                        <b>{translate.SearchTerm}</b>
                                                        <Form.Item
                                                            name="sName"

                                                        >
                                                            <Input
                                                                placeholder={translate.SearchTerm}
                                                                autoComplete="off"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-12">
                                                        <b>{translate.Region} </b>
                                                        <span className="text-muted small">
                                                            {translate.More_than_1_item_canbe_selected}
                                                        </span>
                                                        <Form.Item
                                                            name="sRegion"
                                                        >
                                                            <AntdSelect
                                                                allowClear={true}
                                                                mode="multiple"
                                                                showSearch={true}
                                                                style={{ width: "100%" }}
                                                                placeholder={"- " + translate.Region + " -"}
                                                                onChange={(value) => {
                                                                    onChangeRegion(value);
                                                                }}
                                                                filterOption={(input, option) =>
                                                                    option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {
                                                                    Region.map((p, id) => {
                                                                        return (
                                                                            <Option key={"sRegion_" + p.id} value={p.sRegionThai} >{translate.TranslatType === "TH" ? p.sRegionThai : p.sRegionEng}</Option>
                                                                        )
                                                                    })
                                                                }
                                                            </AntdSelect>
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12">
                                                        <b>{translate.Province} </b>
                                                        <span className="text-muted small">
                                                            {translate.More_than_1_item_canbe_selected}
                                                        </span>
                                                        <Form.Item
                                                            name="nProvince"
                                                        >
                                                            <AntdSelect
                                                                allowClear={true}
                                                                mode="multiple"
                                                                showSearch={true}
                                                                style={{ width: "100%" }}
                                                                placeholder={"- " + translate.Province + " -"}
                                                                onChange={(value) => {
                                                                    onChangeProvince(value);
                                                                }}
                                                                filterOption={(input, option) =>
                                                                    option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {
                                                                    listRegion.length == 0 ?
                                                                        TH_Province.map((p, id) => {
                                                                            return (
                                                                                <Option key={"nProvinceID_" + id} value={p.nProvinceID} >{translate.TranslatType === "TH" ? p.sProvinceThai : p.sProvinceEng}</Option>
                                                                            )
                                                                        })
                                                                        :
                                                                        TH_Province.filter(f => listRegion.includes(f.sRegionThai)).map((p, id) => {
                                                                            return (
                                                                                <Option key={"nProvinceID_" + id} value={p.nProvinceID} >{translate.TranslatType === "TH" ? p.sProvinceThai : p.sProvinceEng}</Option>
                                                                            )
                                                                        })
                                                                }
                                                            </AntdSelect>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <b>{translate.District} </b>
                                                        <span className="text-muted small">
                                                            {translate.More_than_1_item_canbe_selected}
                                                        </span>
                                                        <Form.Item
                                                            name="nDistrict"
                                                        >
                                                            <AntdSelect
                                                                allowClear={true}
                                                                mode="multiple"
                                                                showSearch={true}
                                                                style={{ width: "100%" }}
                                                                placeholder={"- " + translate.District + " -"}
                                                                onChange={(value) => {
                                                                    onChangeDistrict(value);
                                                                }}
                                                                filterOption={(input, option) =>
                                                                    option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {
                                                                    listnProvince.length == 0 && listRegion.length == 0 ?

                                                                        TH_District.map((p) => {
                                                                            return (
                                                                                <Option key={"nDistrictID_" + p.nDistrictID} value={p.nDistrictID} >{translate.TranslatType === "TH" ? p.sDistrictThai : p.sDistrictEng}</Option>
                                                                            )
                                                                        })

                                                                        :

                                                                        TH_District.filter(f => listnProvince.includes(f.nProvinceID)).map((p) => {
                                                                            return (
                                                                                <Option key={"nDistrictID_" + p.nDistrictID} value={p.nDistrictID} >{translate.TranslatType === "TH" ? p.sDistrictThai : p.sDistrictEng}</Option>
                                                                            )
                                                                        })

                                                                }
                                                            </AntdSelect>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <b>{translate.SubDistrict} </b>
                                                        <span className="text-muted small">
                                                            {translate.More_than_1_item_canbe_selected}
                                                        </span>
                                                        <Form.Item
                                                            name="nSubDistrictID"
                                                        >
                                                            <AntdSelect
                                                                allowClear={true}
                                                                mode="multiple"
                                                                showSearch={true}
                                                                style={{ width: "100%" }}
                                                                placeholder={"- " + translate.SubDistrict + " -"}
                                                                onChange={(value) => {
                                                                    onChangeSubDistrict(value);
                                                                }}
                                                                filterOption={(input, option) =>
                                                                    option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {

                                                                    listnDistrict.length == 0 && listnProvince.length == 0 && listRegion.length == 0 ?


                                                                        TH_SubDistrict.map((p) => {
                                                                            return (
                                                                                <Option key={"nSubDistrictID_" + p.nSubDistrictID} value={p.nSubDistrictID} >{translate.TranslatType === "TH" ? p.sSubDistrictThai : p.sSubDistrictEng}</Option>
                                                                            )
                                                                        })
                                                                        :
                                                                        TH_SubDistrict.filter(f => listnDistrict.includes(f.nDistrictID)).map((p) => {
                                                                            return (
                                                                                <Option key={"nSubDistrictID_" + p.nSubDistrictID} value={p.nSubDistrictID} >{translate.TranslatType === "TH" ? p.sSubDistrictThai : p.sSubDistrictEng}</Option>
                                                                            )
                                                                        })
                                                                }
                                                            </AntdSelect>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className="form-row justify-content-center">

                                                    {//<ButtonSearchExcel htmlType="submit" //onClick={() => SearchData(1, undefined, undefined, undefined, undefined)}
                                                    }
                                                    <Button htmlType="submit" shape="round" style={{ background: "#000000", border: "none", color: "#ffffff" }} ><i className="fas fa-search"></i>&nbsp;{translate.Search}</Button>
                                                    &nbsp;
                                                    <Button onClick={() => ClearData()} shape="round" style={{ background: "#99A3A4", border: "none", color: "#ffffff" }} ><i className="fas fa-undo"></i>&nbsp;{translate.Clear}</Button>
                                                </div>
                                            </Form>
                                            <hr style={{ backgroundColor: "#3da393" }} />
                                            <div className="scroll-data" >
                                                <ScrollArea speed={0.25} className="scroll-panel">
                                                    <List bordered={true}
                                                        dataSource={V_Map}
                                                        className="map-list"
                                                        renderItem={(item: any) => (
                                                            <List.Item key={item.nID} onClick={() => changePos(item)} className="list-province"  >
                                                                <List.Item.Meta
                                                                    key={item.nID}
                                                                    title={
                                                                        <>

                                                                            <b><div style={{ color: "#346460", fontSize: "1.5em", lineHeight: "1" }} >{translate.TranslatType === "TH" ? item.sNameTH : item.sNameEN}</div></b>

                                                                            <div className="row" style={{ alignItems: "center", marginTop: "10px" }}>
                                                                                <div className="col-lg-8">
                                                                                    <div className="row">
                                                                                        <div className="col" style={{ lineHeight: "1.1" }}>
                                                                                            <div className="form-group">
                                                                                                <span className="text-muted" style={{ display: "block" }}><b>{translate.Address} </b></span>
                                                                                                <span className="text-muted">{translate.TranslatType === "TH" ? item.sAddressTH : item.sAddressEN}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col" style={{ lineHeight: "1.1" }}>
                                                                                            <span className="text-success" style={{ display: "block" }}> <b> {translate.Coordinates}</b> </span>
                                                                                            <span className="text-success" style={{ display: "block" }}>{translate.Latitude + " " + item.sLatitude}</span>
                                                                                            <span className="text-success">{translate.Longitude + " " + item.sLongitude} </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <span className="text-muted">  <b>{translate.Address + " : "} </b>  {translate.TranslatType === "TH" ? item.sAddressTH : item.sAddressEN}</span><br /> */}
                                                                                    {/* <span className="text-success"> <b> {translate.Coordinates + " : "} </b> {translate.Latitude + " " + item.sLatitude} </span>
                                                                                    <span className="text-success">{translate.Longitude + " : " + item.sLongitude} </span> */}
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <Image width="70px" height="70px" src={item.sImgPathTH} fallbackSrc={require("../../Images/noimage.png")} />

                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }

                                                                />

                                                            </List.Item>
                                                        )}
                                                        id="goToButton"
                                                    >
                                                    </List>

                                                </ScrollArea>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Modal title={<> <i className="fas fa-map-marked-alt"></i> {sTitle} </>}
                    visible={isModalVisible}
                    okText="ปิด"
                    onCancel={handleOk}
                    onOk={handleOk}
                    footer={false}
                    width={1000}
                >
                    <>
                        <div
                            dangerouslySetInnerHTML={{ __html: sDetailTH }}
                        />
                        <div style={{ display: "flex", justifyContent: "flex-end" }} >

                            <div  >

                                {dataModal.sFilePathTH != null || dataModal.sFilePathEN != null
                                    ?
                                    <>
                                        <a style={{ color: "#52c41a" }} href={translate.TranslatType === "TH" ? dataModal.sFilePathTH : dataModal.sFilePathEN} download > {translate.RelatedFile} </a>
                                        <i className="icofont-download"></i>
                                    </>
                                    : null
                                }

                            </div>
                            &nbsp;&nbsp;&nbsp;
                            <div style={{ color: "#45b4fc" }} >
                                <a target="_blank" href={"https://www.google.com/maps/search/" + dataModal.sLatitude + "," + dataModal.sLongitude + "/@" + dataModal.sLatitude + "," + dataModal.sLongitude + ",12z/data=!4m5!3m4!1s"}>  {translate.Map} </a>
                            </div>
                            <div style={{ color: "red" }} >  <i className="icofont-location-pin"></i></div>
                        </div>

                        <hr />
                        <Slider {...settings}>
                            {isModalVisible ?
                                Images.map((x, ix) => {
                                    return (
                                        <>
                                            <GalleryTrigger key={"Images_" + x.sFilePath} imgSrc={x.sFilePath}>
                                                <Image src={x.sFilePath} fallbackSrc={require("../../Images/noimage.png")} width="200px" height="140px" />
                                            </GalleryTrigger>
                                        </>
                                    )
                                })
                                : null
                            }

                        </Slider>
                    </>
                </Modal >
            </div>
        </React.Fragment >
    );
};
export default Main_Map;
