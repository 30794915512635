import { Button, Col, Input, Modal, Row, Space, Tabs, Tag, Tooltip } from "antd";
import * as React from "react";
import { Empty, Select as AntdSelect } from "antd";
import { ButtonAdd_Table, ButtonEdit_Table, ButtonInfo_Table, ButtonSearch } from "../Systems/button_all";
import TableAntd from "../Systems/TableAntd";
import { BoxMsg, DialogDelete, Encrypt, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import { useHistory } from "react-router";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import { parse } from "query-string";
import AuthenService from "../Service/AuthenService";

const Controller = 'api/AdminContactUs/';


const Admin_Contact_Us_List = () => {
    const [txtSearch, SetTxtSearch] = React.useState({
        stxtsName: "" as string || undefined,
        stxtsSubject: "" as string || undefined,
        stxtnID_Group: "" as string || undefined,
        stxtStatusID: "" as string || undefined,

    });
    const [txtSearchGroup, SetTxtSearchGroup] = React.useState({
        stxtGroupName: "" as string || undefined,
        stxtIsActive: "" as string || undefined,

    });

    var { sMode } = parse(window.location.search);
    const [V_Complaint, setV_Complaint] = React.useState([] as any);
    const [V_ComplaintGroup, setV_ComplaintGroup] = React.useState([] as any);
    const [T_Complaint_Group, setT_Complaint_Group] = React.useState([] as Array<any>);
    const history = useHistory();
    const { TabPane } = Tabs;
    const [sortedInfo, setsortedInfo] = React.useState({} as any);
    const SearchData = async (sPage, sPageSize, sField, sOrder, sTabSelectA, sGroup) => {
        let Page = sPage == undefined ? sPageA : sPage;
        let PageSize = sPageSize == undefined ? sPageSizeA : sPageSize;
        let Field = sField == undefined ? sFieldA : sField;
        let Order = sOrder == undefined ? sOrderA : sOrder;
        setCurrentPage(+Page);
        let TabSelect = sTabSelectA == undefined ? sTabSelect : sTabSelectA;

        if (TabSelect == "1") {
            let stxtsName = txtSearch.stxtsName == undefined ? "" : txtSearch.stxtsName;
            let stxtsSubject = txtSearch.stxtsSubject == undefined ? "" : txtSearch.stxtsSubject;
            let stxtnID_Group = txtSearch.stxtnID_Group == undefined ? "" : txtSearch.stxtnID_Group;
            let stxtStatusID = txtSearch.stxtStatusID == undefined ? "" : txtSearch.stxtStatusID;

            let result: any = await AxiosGetJson(Controller + "SearchData?stxtsName=" + encodeURIComponent(stxtsName) + "&stxtsSubject=" + encodeURIComponent(stxtsSubject) + "&stxtnID_Group=" + stxtnID_Group + "&stxtStatusID=" + stxtStatusID + "&sPage=" + Page + "&sPageSize=" + PageSize + "&sField=" + Field + "&sOrder=" + Order);
            setnTotal(result.nTotal)
            setV_Complaint(result.V_Complaint);

        } else {

            let stxtGroupName = txtSearchGroup.stxtGroupName == undefined ? "" : txtSearchGroup.stxtGroupName;
            let stxtIsActive = txtSearchGroup.stxtIsActive == undefined ? "" : txtSearchGroup.stxtIsActive;

            let result: any = await AxiosGetJson(Controller + "SearchDataGroup?stxtsName=" + encodeURIComponent(stxtGroupName) + "&stxtIsActive=" + stxtIsActive + "&sPage=" + Page + "&sPageSize=" + PageSize + "&sField=" + Field + "&sOrder=" + Order);
            setnTotalGroup(result.nTotal)
            setV_ComplaintGroup(result.V_ComplaintGroup);

        }

    }


    React.useEffect(() => {
        setsTabSelect(sMode != undefined ? sMode[0] : "1");
        GetT_Complaint_Group();
        GetDataOnPageLoad();
        GetUserInfo();
    }, []);

    const [isEdit, setisEdit] = React.useState(false);
    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            setisEdit(UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == 10).map(t => t.nPermission)[0] === 2 ? false : true)
        }
    }



    const GetDataOnPageLoad = async () => {
        let result: any = await AxiosGetJson(Controller + "GetV_Complaint");
        setnTotal(result.nTotal)
        setV_Complaint(result.V_Complaint);


        let resulta: any = await AxiosGetJson(Controller + "GetV_ComplaintGroup");
        setnTotalGroup(resulta.nTotal)
        setV_ComplaintGroup(resulta.V_ComplaintGroup);
    }

    const GetT_Complaint_Group = async () => {
        let result: any = await AxiosGetJson(Controller + "GetT_Complaint_Group");
        setT_Complaint_Group(result);
    }


    const header = [
        {
            title: 'ลำดับที่',
            dataIndex: 'key',
            key: 'key',
            className: "text-center",
            width: 100,
            render: (value) => {
                return (<>{value}.</>);
            },
        },
        {
            title: 'สถานะ',
            dataIndex: 'sStatusID',
            key: "sStatusID",
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'sStatusID' && sortedInfo.order,
            width: 100,
            className: "text-center",
            render: (text, record) => (
                <Space size="middle">
                    {
                        record.sStatusID === "3" ? (
                            <Space size="middle">
                                <Tooltip title="ตอบกลับแล้ว" overlayInnerStyle={{ backgroundColor: "#b5b5b5" }}  >
                                    <span className="fa-stack" >
                                        <i className="fa fa-circle fa-stack-2x st st-unread-2x" style={{ color: '#e8e8e8' }} />
                                        <i className="fa fa-reply fa-stack-1x fa-inverse st st-unread-2x" style={{ color: '#666666' }} />
                                    </span>
                                </Tooltip>
                            </Space>
                        ) : record.sStatusID === "2" ? (
                            <Space size="middle">
                                <Tooltip title="อ่านแล้ว" overlayInnerStyle={{ backgroundColor: "#9eeaff", color: "#000" }} >
                                    <span className="fa-stack" >
                                        <i className="fa fa-circle fa-stack-2x st st-unread-2x" style={{ color: '#e0f6fc' }} />
                                        <i className="fa fa-envelope-open fa-stack-1x fa-inverse st st-unread-2x" style={{ color: '#5bc0de' }} />
                                    </span>
                                </Tooltip>
                            </Space>
                        ) : (
                            <Space size="middle">
                                <Tooltip title="ยังไม่อ่าน" overlayInnerStyle={{ backgroundColor: "#fff18e", color: "#000" }}>
                                    <span className="fa-stack" >
                                        <i className="fa fa-circle fa-stack-2x st st-unread-2x" style={{ color: '#fcf9e0' }} />
                                        <i className="fa fa-envelope fa-stack-1x fa-inverse st st-unread-2x" style={{ color: '#f2da07' }} />
                                    </span>
                                </Tooltip>
                            </Space>
                        )
                    }
                </Space>
            ),
        },
        {
            title: "ชื่อผู้ติดต่อ",
            dataIndex: 'sName',
            key: 'sName',
            sorter: true,
            width: 300,
            sortOrder: sortedInfo.columnKey === 'sName' && sortedInfo.order,
        },
        {
            title: 'เรื่อง',
            dataIndex: 'sSubject',
            key: "sSubject",
            sortOrder: sortedInfo.columnKey === 'sSubject' && sortedInfo.order,
            sorter: true,
        },
        {
            title: "หัวข้อติดต่อ",
            dataIndex: 'sGroupName',
            key: 'sGroupName',
            sortOrder: sortedInfo.columnKey === 'sGroupName' && sortedInfo.order,
            sorter: true,
            width: 300,
        },
        {
            title: 'วันที่แจ้ง',
            dataIndex: 'sUpdate',
            width: 100,
            key: "sUpdate",
            sortOrder: sortedInfo.columnKey === 'sUpdate' && sortedInfo.order,
            sorter: true,
            className: "text-center",
        },
        {
            title: "",
            key: 'complaintID',
            className: "text-center",
            render: (text, record) => (

                isEdit ?
                    <Space size="middle">
                        <ButtonEdit_Table
                            onClick={() => { getLinkTo_Edit(record.nComplaintID); }}
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </ButtonEdit_Table>
                    </Space>
                    :
                    <Space size="middle">
                        <ButtonInfo_Table
                            onClick={() => { getLinkTo_Edit(record.nComplaintID); }}
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </ButtonInfo_Table>
                    </Space>

            ),
            width: "1%",
        },
    ];

    const getLinkTo_Edit = (nComplaintID: number) => {
        let sComplaintID = Encrypt(nComplaintID)
        let sTypeComponent = `/admin-contact-us-edit`
        let sPath = `${sTypeComponent}?sComplaintID=${sComplaintID}`
        return history.push(sPath);
    };

    //*****************************TableAntd
    const [selectedRows, setselectedRows] = React.useState([] as Array<any>);
    const [selectedRowKeys, setrowSelected] = React.useState([] as any);
    //const [selectedRowGroup, setselectedRowGroup] = React.useState([] as Array<any>);
    //const [selectedRowKeyGroup, setselectedRowKeyGroup] = React.useState([] as any);

    const [sPageA, setsPageA] = React.useState("1" as string || undefined);
    const [sPageSizeA, setsPageSizeA] = React.useState("10" as string || undefined);
    const [sFieldA, setsFieldA] = React.useState("" as string || undefined);
    const [sOrderA, setsOrderA] = React.useState("" as string || undefined);
    const [nCurrentPage, setCurrentPage] = React.useState(1);
    const [nTotal, setnTotal] = React.useState(0 as number);
    const [nTotalGroup, setnTotalGroup] = React.useState(0 as number);
    const [sTabSelect, setsTabSelect] = React.useState("1" as string);

    const onselect = (selectedRowKeys, selectedRows: React.SetStateAction<any[]>) => {
        setselectedRows(selectedRows);
        setrowSelected(selectedRowKeys);
    }
    //const onselectGroup = (selectedRowKeysInfo, selectedRows: React.SetStateAction<any[]>) => {
    //    setselectedRowGroup(selectedRows);
    //    setselectedRowKeyGroup(selectedRowKeysInfo);
    //}

    const rowSelection = {
        selectedRowKeys,
        onChange: onselect,
        getCheckboxProps: (record) => ({
            disabled: record.sIsuse === 'Y', // Column configuration not to be checked
            sIsuse: record.sIsuse,
        }),
    };
    //const rowSelectionGroup = {
    //    selectedRowKeyGroup,
    //    onChange: onselectGroup,
    //    getCheckboxProps: (record) => ({
    //        disabled: record.sIsuse === 'Y', // Column configuration not to be checked
    //        sIsuse: record.sIsuse,
    //    }),
    //};

    const onPageChange = async (page, pageSize, sorter) => {
        onselect([], []);
        setsPageA(page);
        setsPageSizeA(pageSize);
        SearchData(page, pageSize, undefined, undefined, undefined, undefined);
        setCurrentPage(page);
        window.scrollTo(0, 0);
    }
    const onTableChange = async (pagination, filters, sorter, extra) => {
        if (sorter) {
            onselect([], []);
            setsFieldA(sorter.order === undefined ? undefined : sorter.field);
            setsOrderA(sorter.order);
            setsortedInfo(sorter);
        }
    }

    React.useEffect(() => {
        SearchData(undefined, undefined, undefined, undefined, undefined, undefined);
    }, [sFieldA, sOrderA]);

    const onDeleteClick = async () => {
        if (selectedRows.length > 0) {

            DialogDelete(async () => {
                let result: any = await AxiosPostJson(Controller + "onDeleteData", selectedRows);
                if (result.data.sStatus === Responsestart.success) {
                    Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete, null);
                    SearchData(undefined, undefined, undefined, undefined, "1", undefined);
                    onselect([], []);
                } else if (result.data.sStatus === Responsestart.warning) {
                    Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                } else {
                    Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                }
            });
        }

    }
    const onDeleteClickGroup = async () => {
        if (selectedRows.length > 0) {

            DialogDelete(async () => {
                let result: any = await AxiosPostJson(Controller + "onDeleteDataGroup", selectedRows);
                if (result.data.sStatus === Responsestart.success) {
                    Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete, null);
                    SearchData(undefined, undefined, undefined, undefined, "2", undefined);
                    onselect([], []);
                } else if (result.data.sStatus === Responsestart.warning) {
                    Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                } else {
                    Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                }
            });
        }

    }

    //*****************************TableAntd
    const TabsonChange = async (key: string) => {
        setsortedInfo({});
        setsFieldA(undefined);
        setsTabSelect(key);
        onselect([], []);
        setsPageA("1");
        setsPageSizeA("10");
        SearchData("1", "10", undefined, undefined, key, undefined);
        setCurrentPage(1);
        window.scrollTo(0, 0);


    }

    const headerGroup = [
        {
            title: 'ลำดับที่',
            dataIndex: 'key',
            className: "text-center",
            width: 100,
            render: (value) => {
                return (<>{value}.</>);
            },
        },
        {
            title: "หัวข้อติดต่อ",
            dataIndex: 'sNameg',
            sorter: true,
            key: "sNameg",
            sortOrder: sortedInfo.columnKey === 'sNameg' && sortedInfo.order,
        },
        {
            title: "กลุ่มผู้ดูแล",
            dataIndex: 'nMember',
            className: "text-center",
            sorter: true,
            key: "nMember",
            sortOrder: sortedInfo.columnKey === 'nMember' && sortedInfo.order,
            width: 100,
            render: (text, record: { nID: number; }) => (

                <a style={{ color: "#3498DB" }} onClick={() => {
                    showModal(record.nID);
                }
                } > {text} < i className="fas fa-user-friends" ></i ></a >

            ),
        },
        {
            title: 'สถานะ',
            dataIndex: 'sIsActiveDes',
            key: "sIsActiveDes",
            width: 50,
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'sIsActiveDes' && sortedInfo.order,
            className: "text-center",
            render: (text: any, record: { sIsActive: string; }) => (
                <Tag color={record.sIsActive === "1" ? "#60cc28" : "#cc2838"} key={text} style={{ borderRadius: "32px" }}>
                    {text}
                </Tag>
            ),
        },
        {
            title: isEdit ? <ButtonAdd_Table onClick={() => { history.push("/admin-contact-us-editgroup"); }} /> : null,
            key: 'action',
            className: "text-center",
            render: (text, record) => (
                isEdit ?
                    <Space size="middle">
                        <ButtonEdit_Table onClick={() => getLinkTo_EditGroup(record.nID)} />
                    </Space >
                    :
                    <Space size="middle">
                        <ButtonInfo_Table onClick={() => getLinkTo_EditGroup(record.nID)} />
                    </Space >
            ),
            width: "1%",
        },
    ];


    const getLinkTo_EditGroup = (nID: number) => {
        let sID = Encrypt(nID)
        let sTypeComponent = `/admin-contact-us-editgroup`
        let sPath = `${sTypeComponent}?sID=${sID}`
        return history.push(sPath);
    };

    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const showModal = (nID: number) => {
        GetV_AdminGroup_ComplaintbysID(nID);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const [V_AdminGroup_Complaint, setV_AdminGroup_Complaint] = React.useState([] as any);

    const GetV_AdminGroup_ComplaintbysID = async (nID: number) => {
        let result: any = await AxiosGetJson(Controller + "GetV_AdminGroup_ComplaintbysID?sID=" + (nID + ""));
        setnTotal(result.nTotal)
        setV_AdminGroup_Complaint(result.V_AdminGroup_Complaint);
    }

    const headerUser = [
        {
            title: 'ลำดับที่',
            dataIndex: 'key',
            key: 'key',
            className: "text-center",
            width: 100,
            render: (value) => {
                return (<>{value}.</>);
            },
        },
        {
            title: "ชื่อ - นามสกุล",
            dataIndex: 'sName',
            key: 'sName',
            width: 200,
        },
        {
            title: 'ชื่อบัญชีผู้ใช้งาน',
            dataIndex: 'sUsername',
            key: "sUsername",
            width: 200,
        },
    ];
    return (
        <React.Fragment>
            <br />
            <Tabs type="card" onChange={(e) => { TabsonChange(e); }} activeKey={sTabSelect}  >
                <TabPane tab="ข้อความ" key="1">
                    <Row justify="end" gutter={[16, 16]}>
                        <Col xl={5} md={12} xs={24}>

                            <Input
                                autoComplete="off"
                                placeholder="ชื่อผู้ติดต่อ"
                                onChange={(e) =>
                                    SetTxtSearch({
                                        ...txtSearch,
                                        stxtsName: e.target.value,
                                    })
                                }
                                onKeyPress={(e) => {
                                    e.key === "Enter" && SearchData(1, undefined, undefined, undefined, "1", undefined);
                                }}
                            />
                        </Col>
                        <Col xl={5} md={12} xs={24}>

                            <Input
                                autoComplete="off"
                                placeholder="เรื่อง"
                                onChange={(e) =>
                                    SetTxtSearch({
                                        ...txtSearch,
                                        stxtsSubject: e.target.value,
                                    })
                                }
                                onKeyPress={(e) => {
                                    e.key === "Enter" && SearchData(1, undefined, undefined, undefined, "1", undefined);
                                }}
                            />
                        </Col>
                        <Col xl={4} md={12} xs={24}>

                            <AntdSelect
                                allowClear={true}
                                value={txtSearch.stxtnID_Group}
                                showSearch={true}
                                style={{ width: "100%" }}
                                placeholder="- หัวข้อติดต่อ -"
                                filterOption={(input, option) =>
                                    option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(e) => {
                                    SetTxtSearch({
                                        ...txtSearch,
                                        stxtnID_Group: e,
                                    });
                                }}
                            >
                                {

                                    T_Complaint_Group.map((p) => {
                                        return (
                                            <option key={"nID" + p.nID} value={p.nID + ""} >{p.sName}</option>
                                        )
                                    })
                                }
                            </AntdSelect>
                        </Col>
                        <Col xl={4} md={10} xs={20}>
                            <AntdSelect
                                allowClear={true}
                                showSearch={true}
                                style={{ width: "100%" }}
                                value={txtSearch.stxtStatusID}
                                placeholder="- สถานะ -"
                                onChange={(e) => {
                                    SetTxtSearch({
                                        ...txtSearch,
                                        stxtStatusID: e,
                                    });
                                }}
                                filterOption={(input, option) =>
                                    option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <option value="1">ยังไม่อ่าน</option>
                                <option value="2">อ่านแล้ว</option>
                                <option value="3">ตอบกลับแล้ว</option>

                            </AntdSelect>
                        </Col>
                        <Col xl={1} md={2} xs={4}>
                            <ButtonSearch onClick={() => SearchData(1, undefined, undefined, undefined, "1", undefined)} />
                        </Col>
                    </Row>
                    <br />
                    <ol className="breadcrumb pull-left" style={{ marginTop: "-10px", marginBottom: "5px", paddingTop: "0px", paddingBottom: "0px", backgroundColor: "transparent" }} >
                        <li>
                            <span className="fa-stack" >
                                <i className="fa fa-circle fa-stack-2x st st-unread-2x" style={{ color: '#fcf9e0' }} />
                                <i className="fa fa-envelope fa-stack-1x fa-inverse st st-unread-2x" style={{ color: '#f2da07' }} />
                            </span>&nbsp;ยังไม่อ่าน
                        </li>
                        <li>  &nbsp;&nbsp;
                            <span className="fa-stack" >
                                <i className="fa fa-circle fa-stack-2x st st-unread-2x" style={{ color: '#e0f6fc' }} />
                                <i className="fa fa-envelope-open fa-stack-1x fa-inverse st st-unread-2x" style={{ color: '#5bc0de' }} />
                            </span>&nbsp;อ่านแล้ว
                        </li>
                        <li>&nbsp;&nbsp;
                            <span className="fa-stack" >
                                <i className="fa fa-circle fa-stack-2x st st-unread-2x" style={{ color: '#e8e8e8' }} />
                                <i className="fa fa-reply fa-stack-1x fa-inverse st st-unread-2x" style={{ color: '#666666' }} />
                            </span>&nbsp;ตอบกลับแล้ว
                        </li>
                    </ol>
                    <TableAntd
                        dataSource={V_Complaint}
                        columns={header}
                        onPageChange={onPageChange}
                        currentPage={nCurrentPage}
                        total={nTotal}
                        onChange={onTableChange}
                        onDisableDelete={selectedRows.length > 0 ? false : true}
                        rowSelection={isEdit ? rowSelection : undefined}
                        onShowDelete={isEdit}
                        scroll={{ x: 800 }}
                        onClickDeleteData={onDeleteClick}
                        pageSize={sPageSizeA ? parseInt(sPageSizeA) : 10}
                    />
                    <br />
                </TabPane>
                <TabPane tab="กำหนดผู้รับข้อความ" key="2">

                    <Row justify="end" gutter={[16, 16]}>
                        <Col xl={5} md={12} xs={24}>

                            <Input
                                autoComplete="off"
                                placeholder="หัวข้อติดต่อ"
                                onChange={(e) =>
                                    SetTxtSearchGroup({
                                        ...txtSearchGroup,
                                        stxtGroupName: e.target.value,
                                    })
                                }
                                onKeyPress={(e) => {
                                    e.key === "Enter" && SearchData(undefined, undefined, undefined, undefined, undefined, undefined);
                                }}
                            />
                        </Col>
                        <Col xl={4} md={10} xs={20}>
                            <AntdSelect
                                allowClear={true}
                                showSearch={true}
                                style={{ width: "100%" }}
                                value={txtSearchGroup.stxtIsActive}
                                placeholder="- สถานะ -"
                                onChange={(e) => {
                                    SetTxtSearchGroup({
                                        ...txtSearchGroup,
                                        stxtIsActive: e,
                                    });
                                }}
                                filterOption={(input, option) =>
                                    option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <option value="1">ใช้งาน</option>
                                <option value="0">ไม่ใช้งาน</option>
                            </AntdSelect>
                        </Col>
                        <Col xl={1} md={2} xs={4}>
                            <ButtonSearch onClick={() => { SearchData(1, undefined, undefined, undefined, undefined, undefined); }} />
                        </Col>
                    </Row>

                    <br />


                    <TableAntd
                        dataSource={V_ComplaintGroup}
                        columns={headerGroup}
                        onPageChange={onPageChange}
                        currentPage={nCurrentPage}
                        total={nTotalGroup}
                        onChange={onTableChange}
                        rowSelection={isEdit ? rowSelection : undefined}
                        onDisableDelete={selectedRows.length > 0 ? false : true}
                        onShowDelete={isEdit}
                        scroll={{ x: 800 }}
                        onClickDeleteData={onDeleteClickGroup}
                        pageSize={sPageSizeA ? parseInt(sPageSizeA) : 10}
                    />

                    <br />
                </TabPane>
            </Tabs>
            <Modal title={<> < i className="fas fa-user-friends" ></i > กลุ่มผู้ดูแล  </>}
                visible={isModalVisible}
                okText="ปิด"
                onCancel={handleOk}
                onOk={handleOk}
                footer={false}
                width={1000}
                bodyStyle={{ backgroundColor: "#f2f2f2" }}
            >

                <TableAntd
                    dataSource={V_AdminGroup_Complaint}
                    columns={headerUser}
                    onDisableDelete={selectedRows.length > 0 ? false : true}
                    onShowDelete={false}
                    scroll={{ x: 800 }}
                    onShowPagination={false}
                    pageSize={sPageSizeA ? parseInt(sPageSizeA) : 10}
                />

            </Modal >
        </React.Fragment >
    );
};
export default Admin_Contact_Us_List;
