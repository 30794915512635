import * as React from 'react';
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";

interface IProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
    imgSrc?: string | string[]
}

const GalleryTrigger = (props: IProps) => {
    const { imgSrc, onClick, ...attrs } = props;
    let source: string[] = [];
    if (imgSrc) source = typeof imgSrc === "string" ? [imgSrc] : imgSrc;

    let PhotoGroup = 'GALLERY_' + Math.floor(Math.random() * 999999);

    return (
        <LightgalleryProvider>
            <div {...attrs} onClick={(e => {
                let elem0 = document.querySelector("[id^=" + PhotoGroup + "_]");
                if (elem0) (elem0 as HTMLDivElement).click();
                if (onClick) onClick(e);
            })}>{props.children}</div>
            {source.map((sPath, i) => (
                <LightgalleryItem key={PhotoGroup + "_" + (i + 1)} group={PhotoGroup} src={sPath}>
                    <div id={PhotoGroup + "_" + (i + 1)}></div>
                </LightgalleryItem>
            ))}
        </LightgalleryProvider>
    );
}
export default GalleryTrigger;