import * as React from 'react';
// import Admin_Content_Sub from '../admin-content/admin-content-sub'
import Admin_Content_Index from '../admin-content/new-content/admin-content-index'

import { parse } from "query-string"

const Admin_hl_Project = (props) => {
    return (
        // <Admin_Content_Sub nPerMenuId={7} sType={"4"} editTypeEvent={"/admin-hl-project-sub-edit"} editEvent={"/admin-hl-project-sub-list"} />
        <Admin_Content_Index nPerMenuId={7} sType={"4"} editTypeEvent={"/admin-hl-project-type-edit"} editEvent={"/admin-hl-project-sub-list"} />
    );
}
export default Admin_hl_Project;