import { Button, Col, Form, Input, Row, Switch, Table } from "antd";
import * as React from "react";
import { useHistory } from "react-router";
import { BoxMsg, Decrypt, DialogConfirm, DialogDelete, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import Fileuploader, { Extension } from "../../Fileuploader";
import { ButtonBack, ButtonDelete, ButtonSave, Button_Cancel } from "../Systems/button_all";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import { parse } from "query-string";
import TableAntd from "../Systems/TableAntd";
import AuthenService from "../Service/AuthenService";

const Controller = 'api/AdminSocial/';


const Admin_Social_Edit = () => {
    const history = useHistory();
    const [SetValue] = Form.useForm();
    var { sSocialID } = parse(window.location.search);
    sSocialID = sSocialID && Decrypt(sSocialID)

    const [lstFile, setlstFile] = React.useState([] as any);
    const [oV_SocialPageEdit, setoV_SocialPageEdit] = React.useState({} as any);
    const [oT_SocialUrl, setoT_SocialUrl] = React.useState([] as any);



    const [isEdit, setisEdit] = React.useState(false);
    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            setisEdit(UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == 2).map(t => t.nPermission)[0] === 2 ? false : true)
        }
    }

    const GetDataOnPageLoad = async () => {
        let result: any = await AxiosGetJson(Controller + "GetV_SocialbysSlideID?sSocialID=" + (sSocialID === undefined ? "0" : sSocialID));
        setoV_SocialPageEdit(result);
        let value = {
            sName: result.V_Social.sName,
            switchStatus: result.V_Social.isActive,
            lstFile: result.lstFile,
        }
        result.T_SocialUrl.forEach((data, id) => {
            value['sUrl' + (id + 1)] = data.sUrl
            value['sDetail' + (id + 1)] = data.sDetail
            value['sDetail_EN' + (id + 1)] = data.sDetail_EN
            result.T_SocialUrl[id] = { ...data, "key": id + 1 }
        })

        setoT_SocialUrl(result.T_SocialUrl)
        SetValue.setFieldsValue(value)
        setlstFile(result.lstFile)
    }


    const onSave = (values: any) => {

        oT_SocialUrl.forEach((data, id) => {
            oT_SocialUrl[id].sUrl = values['sUrl' + data.key];
            oT_SocialUrl[id].sDetail = values['sDetail' + data.key];
            oT_SocialUrl[id].sDetail_EN = values['sDetail_EN' + data.key];
        })
        oV_SocialPageEdit.V_Social.sName = values.sName;
        oV_SocialPageEdit.V_Social.sDetail = values.sDetail;
        oV_SocialPageEdit.V_Social.sUrl = values.sUrl;
        oV_SocialPageEdit.V_Social.isActive = values.switchStatus;
        oV_SocialPageEdit.lstFile = lstFile;
        oV_SocialPageEdit.T_SocialUrl = oT_SocialUrl;

        if (oT_SocialUrl.length != 0) {

            DialogConfirm(async () => {
                let result: any = await AxiosPostJson(Controller + "Savedata", oV_SocialPageEdit)
                if (result.data.sStatus === Responsestart.success) {
                    Sweetalert.Success(BoxMsg.Desc_Success_Save, BoxMsg.Desc_Success_Save, () => {
                        onBack();
                    });
                } else if (result.data.sStatus === Responsestart.warning) {
                    Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                } else {
                    Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                }


            }, null, BoxMsg.Title_Confirm, BoxMsg.Desc_Confirm_Save)

        } else {
            Sweetalert.Warning(BoxMsg.Title_Warning, "โปดระบุช่องทางการติดต่อ", null);
        }
    };
    const onBack = () => {
        history.push({
            pathname: "/admin-social-list",
        });
    };

    const onUploadFileSuccess = () => {
        SetValue.validateFields(["lstFile"]);
        SetValue.setFieldsValue({
            lstFile: "lstFile"
        })

    };
    const onUploadFileRemove = () => {
        SetValue.validateFields(["lstFile"]);
        SetValue.setFieldsValue({
            lstFile: ""
        })

    };
    const handleAdd = async () => {

        let list = [...oT_SocialUrl];
        let key = list.length > 0 ? Math.max(...list.map(o => { return +o.key; })) + 1 : 1
        const newData = { key: key, nUrlID: 0, nSocialID: 0, sUrl: '', sDetail: '', sDetail_EN: '' };
        setoT_SocialUrl([...oT_SocialUrl, newData]);
    };

    const onDeleteData = async (id: number, key: number) => {
        DialogDelete(async () => {
            let array = [...oT_SocialUrl];
            if (id !== -1) {
                array.splice(id, 1);
                setoT_SocialUrl(array)

                SetValue.setFieldsValue({
                    ['sUrl' + key]: "",
                    ['sDetail' + key]: "",
                    ['sDetail_EN' + key]: ""
                }
                )
            }
            Sweetalert.Success(BoxMsg.Title_Confirm, BoxMsg.Desc_Success_Delete, null);

        })
    }

    const header = [
        {
            title:
                isEdit ?
                    <Button
                        shape="circle"
                        size="middle"
                        onClick={() => { handleAdd(); }}
                        type="primary"
                    >
                        <i className="fas fa-plus"></i>
                    </Button>
                    :
                    null
            ,
            dataIndex: '',
            key: '',
            className: "text-center",
            width: "1%",
            render: (value, obj, index) => {
                return (
                    isEdit ?

                        <Button shape="circle" type="primary" size="middle" danger
                            onClick={() => onDeleteData(index, obj.key)}
                        >
                            <i className="fas fa-times"></i>
                        </Button>
                        : null
                );
            },
        },
        {
            title: 'ลำดับที่',
            dataIndex: 'key',
            key: 'key',
            className: "text-center",
            width: "5%",
            render: (value, data, id) => {
                return (<>
                    {id + 1}.
                </>);
            },
        },
        {
            title: 'ช่องทางการติดต่อ',
            dataIndex: 'sUrl',
            key: "sUrl",
            width: "45%",
            render: (value, data, id) => {
                return (
                    <Form.Item
                        name={"sUrl" + data.key}
                        rules={[
                            { required: true, message: BoxMsg.Desc_Required_Field + "ช่องทางการติดต่อ" },
                            { type: "url", message: "ตัวอย่าง http://example.com" },
                        ]}
                        style={{ marginBottom: 0 }}
                    >
                        <Input disabled={!isEdit} maxLength={250} autoComplete="off" />
                    </Form.Item>
                );
            },
        },
        {
            title: "รายละเอียด",
            dataIndex: 'sDetail',
            key: 'sDetail',
            className: "text-center",
            width: "45%",
            children: [
                {
                    title: "ไทย", dataIndex: "sDetail", key: "Key_sDetail",
                    render: (value, data, id) => {
                        return (
                            <Form.Item
                                name={"sDetail" + data.key}
                                rules={[
                                    {
                                        required: true,
                                        message: BoxMsg.Desc_Required_Field + "รายละเอียด"
                                    },
                                ]}
                                style={{ marginBottom: 0 }}
                            >
                                <Input disabled={!isEdit} maxLength={250} autoComplete="off" />
                            </Form.Item>
                        );
                    },
                    className: "text-center", width: 100,
                },
                {
                    title: "อังกฤษ", dataIndex: "sDetail_EN", key: "Key_sDetail_EN",
                    render: (value, data, id) => {
                        return (
                            <Form.Item
                                name={"sDetail_EN" + data.key}
                                rules={[
                                    {
                                        required: true,
                                        message: BoxMsg.Desc_Required_Field + "รายละเอียด"
                                    },
                                ]}
                                style={{ marginBottom: 0 }}
                            >
                                <Input disabled={!isEdit} maxLength={250} autoComplete="off" />
                            </Form.Item>
                        );
                    },
                    className: "text-center", width: 100,
                }
            ]





            //render: (value, data, id) => {
            //    return (
            //        <Form.Item
            //            name={"sDetail" + data.key}
            //            rules={[
            //                {
            //                    required: true,
            //                    message: BoxMsg.Desc_Required_Field + "รายละเอียด"
            //                },
            //            ]}
            //            style={{ marginBottom: 0 }}
            //        >
            //            <Input disabled={!isEdit} maxLength={250} autoComplete="off" />
            //        </Form.Item>
            //    );
            //},




        },
    ];

    const onDelete = async () => {

        if (sSocialID != undefined) {

            DialogDelete(async () => {
                let result: any = await AxiosPostJson(Controller + "onDeleteBysSocialID?sSocialID=" + (sSocialID === undefined ? "0" : sSocialID));
                if (result.data.sStatus === Responsestart.success) {
                    Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete, null);
                    onBack();
                } else if (result.data.sStatus === Responsestart.warning) {
                    Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                } else {
                    Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                }
            });
        }

    };



    React.useEffect(() => {
        GetUserInfo();
    }, []);

    React.useEffect(() => {
        GetDataOnPageLoad()
    }, [isEdit]);
    return (
        <React.Fragment>
            <br />
            <Form onFinish={onSave}
                form={SetValue}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <b>ชื่อช่องทาง</b> <span className="text-danger ">*</span>
                        <Form.Item
                            name="sName"
                            rules={[
                                { required: true, message: BoxMsg.Desc_Required_Field + "ชื่อช่องทาง" },
                            ]}

                        >
                            <Input disabled={!isEdit} maxLength={250} autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col xl={12} md={12} xs={24} >
                        <b>ไฟล์ภาพ </b><span className="text-danger ">* </span>
                        {/*<span className="text-muted small">
                            ขนาดภาพที่แนะนำ 1200 x 600 px.
                        </span>*/}
                        <Form.Item
                            name="lstFile"
                            rules={[
                                { required: true, message: BoxMsg.Desc_Required_Field_File + "ไฟล์ภาพ" },
                            ]}
                        >
                            <div id="lstFile">
                                <Fileuploader
                                    limit="1"
                                    setFileList={setlstFile}
                                    fileList={lstFile}
                                    onComplete={onUploadFileSuccess}
                                    onRemoveComplete={onUploadFileRemove}
                                    fileMaxSize="10"
                                    readOnly={!isEdit}
                                    extensions={Extension.Image}
                                />
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
                <br />
                <div>
                    <b>ช่องทางการติดต่อ </b><span className="text-danger ">* </span>
                    <TableAntd
                        dataSource={oT_SocialUrl}
                        columns={header}
                        onShowDelete={false}
                        onShowPagination={false}

                    />
                </div>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <b>สถานะ</b>
                        <Form.Item name="switchStatus" valuePropName="checked">
                            <Switch
                                disabled={!isEdit}
                                checkedChildren="ใช้งาน"
                                unCheckedChildren="ไม่ใช้งาน" style={{width : "100px"}} />
                        </Form.Item>
                    </Col>
                </Row>


                <hr />
                <Row gutter={[8, 16]} justify="center">
                    {isEdit ?
                        <React.Fragment>
                            <Col>
                                <ButtonSave disabled={!isEdit} htmlType="submit" />
                            </Col>
                            {
                                sSocialID === undefined ?
                                    null
                                    :
                                    <Col>
                                        <ButtonDelete onClick={() => { onDelete() }} />
                                    </Col>
                            }
                        </React.Fragment>
                        : null}
                    <Col>
                        <Button_Cancel onClick={() => { history.push("/admin-social-list") }} />
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    );
};
export default Admin_Social_Edit;
