import * as React from "react";
import "../cookies-policy/CookiesPolicy.css";
import { useSelector, useDispatch } from 'react-redux';
import {
    thailanguage,
    englishlanguage,
    currentlanguage,
} from '../app/counterSlice';
import { AxiosGetJson } from "../Service/Config/AxiosMethod";
import { Helmet } from "react-helmet"

declare global {
    interface Window {
        OneTrust: any;
    }
}

const scriptOnload = () => {
    window.OneTrust.NoticeApi.Initialized.then(function () {
        window.OneTrust.NoticeApi.LoadNotices([
            "https://apacds2334.blob.core.windows.net/048d443e-6bdc-4428-9428-487ef6231e7e/privacy-notices/2b600f13-ec49-4f8b-ab07-82058478b5e0.json"
        ]);
    });
}

const Cookies_Policy = () => {
    const translate = useSelector(currentlanguage);
    //const [sPathWeb, setPathWeb] = React.useState("");

    //const GetPathWeb = async () => {
    //    let result: any = await AxiosGetJson("api/AdminInfo/GetUrlPath");
    //    setPathWeb(result);
    //}

    React.useEffect(() => {
        window.scrollTo(0, 0);
        // GetPathWeb();
        scriptOnload();
    }, [translate]);


    return (



        <div id="PAGE_HOME">


            <Helmet htmlAttributes={{ lang: translate.TranslatType }} />

            <div className="home-news">
                <div className="news-panel">
                    <div className="panel-head">
                        <div className="panel-title">{translate.CookiesPolicy}</div>
                    </div>
                </div>
            </div>




            <div className="home-news">
                <div className="news-panel" style={{ marginTop: "-5vw" }}>
                    <div className="panel-body-content" style={{ justifyContent: "start" }}>
                        <div id="Cookies_Policy" style={{ backgroundColor: "#FFFFFF" }} >



                            <div className="row marginNoneLeftRight bgWhite" style={{ minHeight: "250px", paddingLeft: "40px", paddingTop: "20px", paddingRight: "20px" }}>
                                <div id="otnotice-2b600f13-ec49-4f8b-ab07-82058478b5e0" className="otnotice"></div>
                            </div>



                            {
                                //translate.TranslatType === "TH" ?
                                //    <iframe
                                //        className="policy"
                                //        frameBorder="0"
                                //        scrolling="no"
                                //        // src="CookiesPolicy.html"
                                //        src={sPathWeb + "CookiesPolicy.html"}
                                //        allowFullScreen
                                //    ></iframe>
                                //    :
                                //    <iframe
                                //        className="policy"
                                //        frameBorder="0"
                                //        scrolling="no"
                                //        // src="CookiesPolicy.html"
                                //        src={sPathWeb + "CookiesPolicyEN.html"}
                                //        allowFullScreen
                                //    ></iframe>
                            }

                        </div>


                    </div>
                </div>
            </div>

        </div >

    );
};
export default Cookies_Policy;
