import * as React from 'react';
import Admin_Content_List from '../admin-content/admin-content-list'
import { parse } from "query-string"

const admin_news_list = () => {
    //const [cId, setCID] = React.useState(null as any);

    //React.useEffect(() => {
    //    var { id } = parse(window.location.search);
    //    var idStr: string = "";
    //    if (id !== undefined && id !== null) {
    //        idStr = id.toString().split("/").join("%2f");
    //    }
    //    else {
    //        idStr = "xvEhq%2famL3jePelPapN2%2fw==";
    //    }
    //    setCID(idStr);
    //    window.scrollTo(0, 0);
    //}, [])

    return (
        <Admin_Content_List sType={"1"} editEvent={"/admin-news-edit"} nPerMenuId={4} />
    );
}
export default admin_news_list;