
import { Button, Col, Input, Row, Select, Space, Tabs, Tag } from "antd";
import * as React from "react";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import { BoxMsg, DialogConfirm, DialogDelete, Encrypt, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import TableAntd from "../Systems/TableAntd";
import { Select as AntdSelect } from "antd";
import { ButtonAdd_Table, ButtonEdit_Table, ButtonInfo_Table, ButtonSearch } from "../Systems/button_all";
import { useHistory } from "react-router";
import { Pin, PinFill } from "react-bootstrap-icons";
import AuthenService from "../Service/AuthenService";

const Controller = 'api/AdminEvent/';

const Admin_Map_List = () => {
    const { TabPane } = Tabs;
    const history = useHistory();
    const [V_Event, setV_Event] = React.useState([] as any);
    const [V_Event_Pin, setV_Event_Pin] = React.useState([] as any);
    const [V_EventAll, setV_EventAll] = React.useState([] as any);
    const [txtSearch, SetTxtSearch] = React.useState({
        stxtName: "" as string || undefined,
        stxtIsActive: "" as string || undefined,

    });
    const [sortedInfo, setsortedInfo] = React.useState({} as any);
    const { Option } = Select;
    React.useEffect(() => {
        GetDataOnPageLoad();
        GetUserInfo();
    }, []);

    const [isEdit, setisEdit] = React.useState(false);
    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            setisEdit(UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == 12).map(t => t.nPermission)[0] === 2 ? false : true)
        }
    }

    const GetDataOnPageLoad = async () => {
        let result: any = await AxiosGetJson(Controller + "GetV_Event");
        setV_Event(result.V_Event)
        setnTotal(result.nTotal)
        GetV_EventAll();
    }

    const GetV_EventAll = async () => {
        let result: any = await AxiosGetJson(Controller + "GetV_EventAll");
        setV_EventAll(result)
    }


    const SearchData = async (sPage, sPageSize, sField, sOrder, sTabSelectA) => {
        let Page = sPage == undefined ? sPageA : sPage;
        let PageSize = sPageSize == undefined ? sPageSizeA : sPageSize;
        let Field = sField == undefined ? sFieldA : sField;
        let Order = sOrder == undefined ? sOrderA : sOrder;
        setCurrentPage(+Page)
        let TabSelect = sTabSelectA == undefined ? sTabSelect : sTabSelectA;

        let stxtName = "";
        let stxtIsActive = "";
        if (TabSelect == "1") {
            stxtName = txtSearch.stxtName == undefined ? "" : txtSearch.stxtName;
            stxtIsActive = txtSearch.stxtIsActive == undefined ? "" : txtSearch.stxtIsActive;
        }


        let result: any = await AxiosGetJson(Controller + "SearchData?stxtName=" + encodeURIComponent(stxtName) + "&stxtIsActive=" + stxtIsActive + "&sPage=" + Page + "&sPageSize=" + PageSize + "&sField=" + Field + "&sOrder=" + Order + "&TabSelect=" + TabSelect);


        let resultb: any = await AxiosGetJson(Controller + "GetV_Event_Pin?sPage=" + Page + "&sPageSize=" + PageSize + "&sField=" + Field + "&sOrder=" + Order);
        setnTotalPin(resultb.nTotal)
        setV_Event_Pin(resultb.V_Event);





        setnTotal(result.nTotal)
        setV_Event(result.V_Event);
        let resulta: any = await AxiosGetJson(Controller + "GetT_Event_Pin");
        setnTotalPin(resulta.nTotal)

        GetV_EventAll();

    }



    //*****************************TableAntd
    const [selectedRows, setselectedRows] = React.useState([] as Array<any>);
    const [selectedRowKeys, setrowSelected] = React.useState([] as any);
    const [sPageA, setsPageA] = React.useState(1 as number || undefined);
    const [sPageSizeA, setsPageSizeA] = React.useState(10 as number || undefined);
    const [sFieldA, setsFieldA] = React.useState("" as string || undefined);
    const [sOrderA, setsOrderA] = React.useState("" as string || undefined);
    const [nTotal, setnTotal] = React.useState(0 as number);
    const [nTotalPin, setnTotalPin] = React.useState(0 as number);
    const [sTabSelect, setsTabSelect] = React.useState("1" as string);
    const [nCurrentPage, setCurrentPage] = React.useState(1);

    const onselect = (selectedRowKeys, selectedRows: React.SetStateAction<any[]>) => {
        setselectedRows(selectedRows);
        setrowSelected(selectedRowKeys);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onselect,
        getCheckboxProps: (record) => ({
            disabled: record.sIsuse === 'Y', // Column configuration not to be checked
            sIsuse: record.sIsuse,
        }),
    };

    const onPageChange = async (page, pageSize, sorter) => {
        onselect([], []);
        setsPageA(page);
        setsPageSizeA(pageSize);
        SearchData(page, pageSize, undefined, undefined, undefined);
        setCurrentPage(page);
        window.scrollTo(0, 0);
    }
    const onTableChange = async (pagination, filters, sorter, extra) => {
        if (sorter) {
            onselect([], []);
            setsFieldA(sorter.order === undefined ? undefined : sorter.field);
            setsOrderA(sorter.order);
            setsortedInfo(sorter);
        }
    }

    React.useEffect(() => {
        SearchData(undefined, undefined, undefined, undefined, undefined);
    }, [sFieldA, sOrderA]);


    const onDeleteClick = async () => {
        if (selectedRows.length > 0) {

            DialogDelete(async () => {
                let result: any = await AxiosPostJson(Controller + "onDeleteData", selectedRows);
                if (result.data.sStatus === Responsestart.success) {
                    Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete, null);
                    SearchData(undefined, undefined, undefined, undefined, undefined);
                    onselect([], []);
                } else if (result.data.sStatus === Responsestart.warning) {
                    Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                } else {
                    Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                }
            });
        }
    }
    const onDeleteClickPin = async () => {
        if (selectedRows.length > 0) {

            DialogDelete(async () => {
                let result: any = await AxiosPostJson(Controller + "onDeleteDataPin", selectedRows);
                if (result.data.sStatus === Responsestart.success) {
                    Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Delete, null);
                    SearchData(undefined, undefined, undefined, undefined, undefined);
                    onselect([], []);
                } else if (result.data.sStatus === Responsestart.warning) {
                    Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
                } else {
                    Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
                }
            });
        }
    }
    //*****************************TableAntd
    const header = [
        {
            title: 'ลำดับที่',
            dataIndex: 'key',
            key: 'key',
            className: "text-center",
            width: 100,
            render: (value) => {
                return (<>{value}.</>);
            },
        },
        {
            title: "ชื่อกิจกรรม",
            dataIndex: 'sNameTH',
            key: 'sNameTH',
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'sNameTH' && sortedInfo.order,
            render: (text: any, record: { sNameTH: string, sNameEN: string }) => (

                record.sNameTH === "" ? record.sNameEN : record.sNameTH

            ),
        },
        {
            title: "ช่วงวันที่แสดงข้อมูล",
            dataIndex: 'sDateShow',
            key: 'sDateShow',
            className: "text-center",
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'sDateShow' && sortedInfo.order,
            width: 200,

        },
        {
            title: 'สถานะ',
            dataIndex: 'sIsActiveDes',
            width: '1%',
            sorter: true,
            key: "sIsActiveDes",
            sortOrder: sortedInfo.columnKey === 'sIsActiveDes' && sortedInfo.order,
            className: "text-center",
            render: (text: any, record: { sIsActive: string; }) => (
                <Tag color={record.sIsActive === "1" ? "#60cc28" : "#cc2838"} key={text} style={{ borderRadius: "32px" }}>
                    {text}
                </Tag>
            ),
        },

        {
            title: 'ปักหมุด',
            dataIndex: 'isPin',
            width: 100,
            sorter: false,
            key: "isPin",
            sortOrder: sortedInfo.columnKey === 'isPin' && sortedInfo.order,
            className: "text-center",
            render: (text: any, record: { nEventId: number, isPin: boolean, sIsActive: string }) => (
                <>
                    {
                        record.sIsActive === "1" ?

                            record.isPin === true ?

                                <Button disabled={!isEdit} shape="circle" type="text" color="link" style={{

                                    color: "#ffffff",
                                    background: "#7d68db",
                                    border: "#5867a9"

                                }}
                                    onClick={(e: any) => { MapPin(record.nEventId, !record.isPin) }}
                                >
                                    <PinFill />
                                </Button >
                                :
                                <Button disabled={!isEdit} shape="circle" type="text" color="link" style={{

                                    background: "#e3e3e3",
                                    border: "#424d7d"

                                }}
                                    onClick={(e: any) => { MapPin(record.nEventId, !record.isPin) }}
                                >
                                    <Pin style={{ transform: "rotate(45deg)" }} />
                                </Button >

                            : null

                    }

                </>

            ),
        },
        {
            title: isEdit ? <ButtonAdd_Table onClick={() => { history.push("/admin-event-edit"); }} /> : null,
            key: 'nEventId',
            className: "text-center",
            render: (text, record) => (
                isEdit ?
                    <Space size="middle">
                        <ButtonEdit_Table
                            onClick={() => { getLinkTo_Edit(record.nEventId); }}
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </ButtonEdit_Table>
                    </Space>
                    :
                    <Space size="middle">
                        <ButtonInfo_Table
                            onClick={() => { getLinkTo_Edit(record.nEventId); }}
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </ButtonInfo_Table>
                    </Space>

            ),
            width: "1%",
        },
    ];
    const getLinkTo_Edit = (nEventId: number) => {
        let sEventId = Encrypt(nEventId)
        let sTypeComponent = `/admin-event-edit`
        let sPath = `${sTypeComponent}?sEventId=${sEventId}`
        return history.push(sPath);
    };


    const MapPin = async (nEventId: number, isPin: boolean) => {
        DialogConfirm(async () => {
            let result: any = await AxiosPostJson(Controller + "Change_Pins?nEventId=" + nEventId + "&isPin=" + isPin);
            if (result.data.sStatus === Responsestart.success) {
                SearchData(undefined, undefined, undefined, undefined, undefined);
                //GetDataOnPagePinLoad()
                await Sweetalert.Success(BoxMsg.Title_Success, isPin == true ? "ปักหมุดข้อมูลเรียบร้อย" : "เอาปักหมุดออกเรียบร้อย", null);
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }
        }, "", "", isPin == true ? "ท่านต้องการปักหมุดข้อมูลหรือไม่?" : "ท่านต้องการเอาปักหมุดออกหรือไม่?")
        //GetDataOnPageLoad();
        //GetDataOnPagePinLoad()

    }

    const SetOrder = async (sOrder: number, nEventId: number) => {

        if (sOrder != 0) {
            let result: any = await AxiosPostJson(Controller + "SetOrder?sOrder=" + sOrder + "&nEventId=" + nEventId);
            if (result.data.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Title_Success, BoxMsg.Desc_Success_Save, null);
                onselect([], []);
                SearchData(1, undefined, undefined, undefined, undefined);
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }
        }

    }

    const headerPin = [
        {
            title: 'ลำดับที่',
            dataIndex: 'nOrder',
            key: 'nOrder',
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'nOrder' && sortedInfo.order,
            className: "text-center",
            width: 75,
            render: (value: any, record: { nOrder: number, nEventId: number }) => (

                <AntdSelect
                    disabled={!isEdit}
                    //allowClear={true}
                    showSearch={true}
                    style={{ width: "100%" }}
                    value={record.nOrder}
                    onChange={(e: any) => SetOrder(e, record.nEventId)}
                    filterOption={(input, option) =>
                        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {

                        V_EventAll.map((p) => {
                            return (
                                <Option key={p.nOrder} value={p.nOrder} >{p.nOrder + ""}</Option>
                            )
                        })
                    }
                </AntdSelect>
            ),
        },
        {
            title: "ชื่อกิจกรรม",
            dataIndex: 'sNameTH',
            key: 'sNameTH',
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'sNameTH' && sortedInfo.order,
            render: (text: any, record: { sNameTH: string, sNameEN: string }) => (

                record.sNameTH === "" ? record.sNameEN : record.sNameTH

            ),
        },
    ];


    const TabsonChange = async (key: string) => {
        setsortedInfo({});
        setsFieldA(undefined);
        setsOrderA(sOrderA);
        setsTabSelect(key);
        onselect([], []);
        setsPageA(1);
        setsPageSizeA(10);
        setCurrentPage(1);
        window.scrollTo(0, 0);
        SearchData("1", "10", "", "", key);

    }



    return (
        <React.Fragment>
            <Tabs type="card" onChange={(e) => {
                SetTxtSearch({
                    ...txtSearch,
                    stxtName: "",
                    stxtIsActive: undefined,
                });
                TabsonChange(e);

            }} activeKey={sTabSelect} >

                <TabPane tab="กิจกรรม" key="1">

                    <Row justify="end" gutter={[16, 16]}>
                        <Col xl={5} md={12} xs={24}>

                            <Input
                                autoComplete="off"
                                placeholder="ชื่อกิจกรรม"
                                value={txtSearch.stxtName}
                                onChange={(e) =>
                                    SetTxtSearch({
                                        ...txtSearch,
                                        stxtName: e.target.value,
                                    })
                                }
                                onKeyPress={(e) => {
                                    e.key === "Enter" && SearchData(1, undefined, undefined, undefined, undefined);
                                }}
                            />
                        </Col>
                        <Col xl={4} md={10} xs={20}>
                            <AntdSelect
                                allowClear={true}
                                showSearch={true}
                                style={{ width: "100%" }}
                                value={txtSearch.stxtIsActive}
                                placeholder="- สถานะ -"
                                onChange={(e) => {
                                    SetTxtSearch({
                                        ...txtSearch,
                                        stxtIsActive: e,
                                    });
                                }}
                                filterOption={(input, option) =>
                                    option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="1">ใช้งาน</Option>
                                <Option value="0">ไม่ใช้งาน</Option>
                            </AntdSelect>
                        </Col>
                        <Col xl={1} md={2} xs={4}>
                            <ButtonSearch onClick={() => SearchData(1, undefined, undefined, undefined, undefined)} />
                        </Col>
                    </Row>
                    <br />
                    <TableAntd
                        dataSource={V_Event}
                        columns={header}
                        onPageChange={onPageChange}
                        currentPage={nCurrentPage}
                        total={nTotal}
                        onChange={onTableChange}
                        onDisableDelete={selectedRows.length > 0 ? false : true}
                        rowSelection={isEdit ? rowSelection : undefined}
                        onShowDelete={isEdit}
                        onClickDeleteData={onDeleteClick}
                        scroll={{ x: 800 }}
                        pageSize={sPageSizeA ? sPageSizeA : 10}
                    />
                </TabPane>
                <TabPane tab="กิจกรรมที่ปักหมุด" key="2">
                    <TableAntd
                        dataSource={V_Event_Pin}
                        columns={headerPin}
                        onPageChange={onPageChange}
                        currentPage={nCurrentPage}
                        total={nTotalPin}
                        onChange={onTableChange}
                        onDisableDelete={selectedRows.length > 0 ? false : true}
                        rowSelection={isEdit ? rowSelection : undefined}
                        onShowDelete={isEdit}
                        scroll={{ x: 800 }}
                        onClickDeleteData={onDeleteClickPin}
                        pageSize={sPageSizeA ? sPageSizeA : 10}
                    />
                </TabPane>
            </Tabs>

        </React.Fragment>
    );
};
export default Admin_Map_List;
