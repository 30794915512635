import { Checkbox, Col, Form, Input, Radio, Row, Select, Space, Switch, Tag } from "antd";
import * as React from "react";
import { Empty, Select as AntdSelect } from "antd";
import { ButtonAdd_Table, ButtonBack, ButtonEdit_Table, ButtonSave, ButtonSearch, Button_Cancel } from "../Systems/button_all";
import TableAntd from "../Systems/TableAntd";
import { useHistory } from "react-router";
import { BoxMsg, DialogConfirm, DialogDelete, Encrypt, fontFamily, froalaconfigs, pluginsEnabled, quickInsertButtons, Responsestart, Sweetalert } from "../Systems/SystemComponent";
import { AxiosGetJson, AxiosPostJson } from "../Service/Config/AxiosMethod";
import AuthenService from "../Service/AuthenService";
import ThaiDatePicker from "../ST_Handlers/ThaiDatePicker";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import Fileuploader, { Extension } from "../../Fileuploader";
import { parse } from "query-string"
const { Option } = AntdSelect;


const Admin_CMS = () => {
    const history = useHistory();
    const [SetValue] = Form.useForm();
    //const [lstOrder, setLstOrder] = React.useState([] as any);
    const [CheckboxNewTab, setCheckboxNewTab] = React.useState(false as boolean);
    const [ModeContent, setModeContent] = React.useState("0" as string);
    const [validContent, setValidContent] = React.useState(true);
    const [validContentENG, setValidContentENG] = React.useState(true);
    const [sContent, setContent] = React.useState("");
    const [sContentENG, setContentENG] = React.useState("");
    const [lstData, setLstData] = React.useState(null as any);
    const [lstOrder, setLstOrder] = React.useState([] as any);
    const [isEdit, setisEdit] = React.useState(false);
    const GetUserInfo = async () => {
        let UserInfo: any = await AuthenService.UserInfo();
        if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
            setisEdit(UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu == 13).map(t => t.nPermission)[0] === 2 ? false : true)
        }
    }

    var configs = {
        placeholderText: "",
        attribution: false,
        key: "0BA3jA11B5C7C4A4D3aIVLEABVAYFKc2Cb1MYGH1g1NYVMiG5G4E3C3A1C8A6D4A3B4==",
        imageUploadURL: process.env.REACT_APP_URL + "api/UploadTextEditor/uploadImage",
        videoUploadURL: process.env.REACT_APP_URL + "api/UploadTextEditor/uploadImage",
        quickInsertButtons: quickInsertButtons,
        pluginsEnabled: pluginsEnabled,
        events: {
            'html.set': async function () {
                let editor: any = this
                let UserInfo: any = await AuthenService.UserInfo();
                if (UserInfo.data.listT_AdminGroup_Permission.length > 0) {
                    if (UserInfo.data.listT_AdminGroup_Permission.filter(f => f.nID_Menu === 13).map(t => t.nPermission)[0] === 2) {
                        editor.edit.off()
                    }
                }
            },
           
        },
        fontFamily: fontFamily,
        fontFamilySelection: true,
    };
    const onSaveContent = (values: any) => {
        DialogConfirm(async () => {
            var data = {
                nID: lstData.nID,
                sID: lstData.sID,
                nOrder: lstData.nOrder,
                sMenuName: lstData.sMenuName,
                sMenuNameEng: lstData.sMenuNameEng,
                sContentType: lstData.sContentType,
                isNewTab: CheckboxNewTab,
                sContentDetail: sContent,
                sContentDetailENG: sContentENG,
                sUrl: lstData.sUrl,
                isActive: lstData.isActive,
            };
            let result: any = await AxiosPostJson("api/AdminCMS/Savedata", data)
            if (result.data.sStatus === Responsestart.success) {
                Sweetalert.Success(BoxMsg.Desc_Success_Save, BoxMsg.Desc_Success_Save, () => {
                    onBack();
                });
            } else if (result.data.sStatus === Responsestart.warning) {
                Sweetalert.Warning(BoxMsg.Title_Warning, result.data.sMsg, null);
            } else {
                Sweetalert.Error(BoxMsg.Title_Error, result.data.sMsg, null);
            }


        }, null, BoxMsg.Title_Confirm, BoxMsg.Desc_Confirm_Save)
    }

    const GetCMSDetail = async (idStr) => {
        let result: any = await AxiosGetJson("api/AdminCMS/GetCMSDetail?id=" + idStr);
        SetValue.setFieldsValue({
            nOrder: result.nOrder,
            sMenuName: result.sMenuName,
            sMenuNameEng: result.sMenuNameEng,
            sContentType: result.sContentType,
            isNewTab: result.isNewTab,
            sContent: null,
            sContentENG: null,
            sUrl: result.sUrl,
            isActive: result.isActive,
        });
        setModeContent(result.sContentType)
        setLstOrder(result.lstOrder);
        setContent(result.sContentDetail);
        setContentENG(result.sContentDetailENG);
        setCheckboxNewTab(result.isNewTab);
        setLstData(result);
    }

    React.useEffect(() => {
        GetUserInfo();
    }, []);

    React.useEffect(() => {
        var { id } = parse(window.location.search);
        var idStr: string = "";
        if (id !== undefined && id !== null) {
            idStr = id.toString().split("/").join("%2f");
        }
        else {
            idStr = "xvEhq%2famL3jePelPapN2%2fw==";
        }
        GetCMSDetail(idStr);
        window.scrollTo(0, 0);
    }, [isEdit])

    const onBack = () => {
        history.push({
            pathname: "/admin-cms-list",
        });
    };

    return (
        <>
            <Form name="From" form={SetValue} onFinish={onSaveContent} onFinishFailed={({ errorFields }) => {
                let element = document.querySelector(`#From_${errorFields[0].name}`);
                var firstErrorElemY: any = null;
                if (element) {
                    firstErrorElemY = element.getBoundingClientRect().top - 100; // bar height(100)px
                    window.scrollTo(0, window.scrollY + firstErrorElemY)
                }
                else{
                    element = document.getElementById(`${errorFields[0].name}`);
                    if (element) {
                        firstErrorElemY = element.getBoundingClientRect().top - 100; // bar height(100)px
                        window.scrollTo(0, window.scrollY + firstErrorElemY)
                    }
                }
            }}>
                <br />
                <Row gutter={[16, 16]}>
                    <Col xl={12} md={12} xs={24} >
                        <b>ชื่อเมนู</b> <span className="text-danger ">*</span><span className="text-primary small"> ภาษาไทย </span>
                        <Form.Item
                            name="sMenuName"
                            rules={[
                                { required: true, message: BoxMsg.Desc_Required_Field + "ชื่อเมนู" },
                            ]}
                        >
                            <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                setLstData({ ...lstData, sMenuName: e.target.value });
                            }} />
                        </Form.Item>
                    </Col>
                    <Col xl={12} md={12} xs={24} >
                        <b>ชื่อเมนู</b> <span className="text-danger ">*</span><span className="text-success small"> ภาษาอังกฤษ </span>
                        <Form.Item
                            name="sMenuNameEng"
                            rules={[
                                { required: true, message: BoxMsg.Desc_Required_Field + "ชื่อเมนูภาษาอังกฤษ" },
                            ]}
                        >
                            <Input disabled={!isEdit} autoComplete="off" maxLength={250} onChange={e => {
                                setLstData({ ...lstData, sMenuNameEng: e.target.value });
                            }} />
                        </Form.Item>
                    </Col>
                </Row>
                <br />
                {
                    ModeContent === "1" ?
                        <React.Fragment>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <b>เนื้อหา</b> <span className="text-danger ">*</span><span className="text-primary small"> ภาษาไทย </span>
                                    <Form.Item
                                        name="sContent"
                                        rules={[
                                            { required: validContent, message: BoxMsg.Desc_Required_Field + "รายละเอียด" },
                                        ]}
                                    >
                                        <FroalaEditor
                                            model={sContent}
                                            config={configs}
                                            onModelChange={(model: any) => {
                                                var str = model.replace(/<\/?[^>]+(>|$)/g, "");
                                                if (str.replace(/\&nbsp;/g, "").trim().length <= 0) {
                                                    setValidContent(true);
                                                }
                                                else {
                                                    setValidContent(false);
                                                }
                                                setContent(model);
                                            }}
                                        />
                                    </Form.Item>

                                </Col>
                            </Row>
                            <br />
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <b>เนื้อหา</b> <span className="text-danger ">*</span><span className="text-success small"> ภาษาอังกฤษ </span>
                                    <Form.Item
                                        name="sContentENG"
                                        rules={[
                                            { required: validContentENG, message: BoxMsg.Desc_Required_Field + "รายละเอียดภาษาอังกฤษ" },
                                        ]}
                                    >
                                        <FroalaEditor
                                            model={sContentENG}
                                            config={configs}
                                            onModelChange={(model: any) => {
                                                var str = model.replace(/<\/?[^>]+(>|$)/g, "");
                                                if (str.replace(/\&nbsp;/g, "").trim().length <= 0) {
                                                    setValidContentENG(true);
                                                }
                                                else {
                                                    setValidContentENG(false);
                                                }
                                                setContentENG(model);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <br />
                        </React.Fragment>
                        :
                        null
                }
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <b>สถานะ</b>
                        <Form.Item name="isActive" valuePropName="checked">
                            <Switch disabled={!isEdit} defaultChecked={true} checkedChildren="ใช้งาน" unCheckedChildren="ไม่ใช้งาน" style={{width : "100px"}}
                                onChange={e => {
                                    setLstData({ ...lstData, isActive: e });
                                }} />
                        </Form.Item>
                    </Col>
                </Row>
                <br />
                <hr />
                <Row gutter={[16, 16]} justify="center">
                    {
                        isEdit ?
                            <Col>
                                <ButtonSave htmlType="submit" />
                            </Col>
                            :
                            null
                    }
                    <Col>
                        <Button_Cancel onClick={() => { onBack(); }} />
                    </Col>
                </Row>
            </Form>
        </>
    );
}
export default Admin_CMS;