import * as React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../main-home/home.css";
import "../main-content/content.css";
import { AxiosGetJson } from "../Service/Config/AxiosMethod";
import { parse } from "query-string";
import { useSelector, useDispatch } from 'react-redux';
import {
    thailanguage,
    englishlanguage,
    currentlanguage,
} from '../app/counterSlice';
import { Translate } from "react-bootstrap-icons";
import GalleryTrigger from "../ST_Handlers/GalleryTrigger";
import Image from "../Systems/Image";

const Event_Detail = ({ isShowCover = false, adminMenu = "99" }) => {
  var isCover = isShowCover ? isShowCover : false;
  var adMunu = adminMenu ? adminMenu : false;
  const [sFilePath, setFilePath] = React.useState("");
  const [contentDetail, setContentDetail] = React.useState(null as any);
  const [sFileCover, setFileCover] = React.useState("");
  const translate = useSelector(currentlanguage);
  const dispatch = useDispatch();

  const SetBackground = async () => {
    let result: any = await AxiosGetJson("api/AdminInfo/GetBackground");
    setFilePath(result.sFilePath);
  };
  const GetContentDetail = async (idStr, TranslatType) => {
    let result: any = await AxiosGetJson(
      "api/AdminInfo/GetEventDetail?id=" + idStr + "&Mode=" + TranslatType
    );
    setContentDetail(result);
    setFileCover(result.lstFileCover[0].sSaveToPath);
  };

  React.useEffect(() => {
    var { id } = parse(window.location.search);
    var idStr: string = "";
    if (id !== undefined && id !== null) {
      idStr = id.toString().split("/").join("%2f");
    } else {
      idStr = "xvEhq%2famL3jePelPapN2%2fw==";
    }
    SetBackground();
    GetContentDetail(idStr, translate.TranslatType);
    window.scrollTo(0, 0);
  }, [translate]);

  return (
    <React.Fragment>
      <div id="PAGE_HOME" style={{ backgroundImage: "url(" + sFilePath + ")" }}>
        {/*<div className="home-news">
          <div className="news-panel">
            <div className="panel-head">
              <div className="panel-title">
                {contentDetail ? contentDetail.sSubject : ""}
              </div>
            </div>
          </div>
        </div>*/}
        <div className="home-news">
          <div className="news-panel">
              <div className="panel-head">
                  <div className="panel-title-first" style={{cursor:"pointer"}} onClick={() => window.open("/home", "_self")}>
                      <i className="icofont-arrow-left"></i> {translate.Back}
                  </div>
                  <div className="panel-title">
                      {contentDetail ? contentDetail.sSubject : ""}
                  </div>
              </div>
          </div>
        </div>
        {
                    contentDetail ? 
                        contentDetail.sContentType === "TH" && translate.TranslatType == "EN" ? 
                            <div
                                className="home-history"
                                style={{
                                width: "78%",
                                maxWidth:"78%",
                                height: "500px",
                                alignItems: "left",
                                marginLeft: "auto",
                                marginRight: "auto",
                                display: "block",
                                }}
                            >
                                <h3 style={{ textAlign: "center"}}>No Content</h3>
                            </div>
                            :
                            <React.Fragment>
                              <div
            className="home-history"
            style={{
              width: "fit-content",
              maxWidth:"78%",
              // height: "400px",
              alignItems: "left",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
          >
            <img src={sFileCover} style={{ width: "100%", height: "100%" }} />
          </div>
        <div className="news">
          <div className="news-content">
            <div className="content">
              <div className="news-title">
                {contentDetail ? contentDetail.sSubject : ""}
              </div>
              <div className="news-detail">
                <div
                  style={{ display: "inline-block" }}
                  dangerouslySetInnerHTML={{
                    __html: contentDetail ? contentDetail.sContent : "",
                  }}
                ></div>
              </div>
              <div className="news-title">
                {translate.EventDetail}
              </div>
              <div className="news-detail">
                <div
                  style={{ display: "inline-block" }}
                  dangerouslySetInnerHTML={{
                    __html: contentDetail ? contentDetail.sDetail : "",
                  }}
                ></div>
              </div>
              <div className="news-file">
                <div className="file-title">{translate.LearningCenter}</div>
                <div className="file-doc">
                  <div className="doc">
                    {contentDetail ? contentDetail.sLearningCenter : ""}
                  </div>
                </div>
              </div>
              <div className="news-file">
                <div className="file-title">{translate.EventDuration}</div>
                <div className="file-doc">
                  <div className="doc">
                    {(contentDetail ? contentDetail.sDateStartEvent : "") +
                      " - " +
                      (contentDetail ? contentDetail.sDateEndEvent : "")}
                  </div>
                </div>
              </div>
              {contentDetail ? (
                contentDetail.lstFileGallery.length > 0 ? (
                  <>
                    <div className="news-img">{translate.Gallery}</div>
                    <div className="news-item">
                      {contentDetail.lstFileGallery.map((x, ix) => {
                        return (
                          <div key={"g_" + ix} className="item-box">
                            {/*<div
                              className="item-cover"
                              style={{
                                backgroundImage:
                                  "url(" + x.sSaveToPath + ")",
                              }}
                            ></div>*/}
                            <GalleryTrigger imgSrc={x.sSaveToPath}>
                                <Image src={x.sSaveToPath} fallbackSrc={require("../../Images/noimage.png")} width="200px" height="140px" />
                            </GalleryTrigger>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : null
              ) : null}
              <div className="news-file">
                <div className="file-title">{translate.Reference}</div>
                <div className="file-doc">
                  <div className="doc">
                  <div onClick={() =>{
                        if(contentDetail) 
                        {
                          if(contentDetail.sUrl !== "=")
                          window.open(contentDetail.sUrl, '_blank');
                        }
                        
                    }}>
                      {(contentDetail ? contentDetail.sUrl : "")}
                    </div>
                  </div>
                </div>
              </div>
              {
                contentDetail ?
                    contentDetail.lstFileRef.length > 0 ?
                        <>
                            <div className="news-file">
                                <div className="file-title">{translate.RelatedFile}</div>
                                <div className="file-doc">
                                    {
                                        contentDetail.lstFileRef.map((x, ix) => {
                                            return (
                                                <div key={"f_" + ix} className="doc" style={{cursor: "pointer"}}>
                                                    <div onClick={() =>{
                                                        window.open(x.sSaveToPath, '_blank');
                                                    }}>{x.sFileName}</div>
                                                </div>
                                            )

                                        })
                                    }
                                </div>
                            </div>
                        </>
                        :
                        null
                    :
                    null
                }
              <div className="news-date">
                <i className="icofont-ui-calendar"></i>&nbsp;
                {contentDetail ? contentDetail.sDate : ""}
              </div>
              <hr />
            </div>
          </div>
        </div>
                            </React.Fragment>
                          :
                          null
        }
          
      </div>
    </React.Fragment>
  );
};
export default Event_Detail;
