import * as React from 'react';
import Admin_Content_Index from '../admin-content/new-content/admin-content-index'
import Admin_Content_Sub from '../admin-content/admin-content-sub'
import { parse } from "query-string"

const Admin_knowledge = (props) => {
    return (
        // <Admin_Content_Index nPerMenuId={8} sType={"5"} editTypeEvent={"/admin-knowledge-sub-edit"} editEvent={"/admin-knowledge-sub-list"} />
        <Admin_Content_Index nPerMenuId={8} sType={"5"} editTypeEvent={"/admin-knowledge-type-edit"} editEvent={"/admin-knowledge-sub-list"} />
    );
}
export default Admin_knowledge;