
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import * as React from "react";

export function ButtonDelete_Table(props) {
    return (
        <Button type="primary" shape="circle" {...props} danger><i className="fas fa-trash-alt"></i></Button>
    )
}
export function ButtonAdd_Table(props) {
    return (
        <Button size="sm" shape="circle" style={{ background: "#1275dc", border: "none", color: "#ffffff" }} {...props} ><i className="fas fa-plus"></i></Button>
    )
}
export function ButtonEdit_Table(props) {
    return (
        <Button size="sm" shape="circle" style={{ background: "#3dd5f3", border: "none", color: "#ffffff" }} {...props} ><i className="fas fa-pencil-alt"></i></Button>
    )
}
export function ButtonInfo_Table(props) {
    return (
        <Button size="sm" shape="circle" style={{ background: "#ffc107", border: "none", color: "#000000" }} {...props} ><i className="icofont-share-alt"></i></Button>
    )
}
export function ButtonImgAdd_Table(props) {
    return (
        <a size="sm" style={{ border: "none", color: "#007bff" }} {...props} ><i className="fas fa-images">&nbsp;[{props.Count}]</i></a>
    )
}
export function ButtonBack(props) {
    return (
        <Button shape="round" style={{ background: "#808080", border: "none", color: "#ffffff" }} {...props} ><i className="fas fa-arrow-left"></i>&nbsp;ย้อนกลับ</Button>
    )
}
export function Button_Cancel(props) {
    return (
        <Button shape="round" style={{ background: "#808080", border: "none", color: "#ffffff" }} {...props} ><i className="fas fa-times"></i>&nbsp;ยกเลิก</Button>
    )
}
export function ButtonSave(props) {
    return (
        <Button shape="round" type="primary" style={{ background: "#52c41a", border: "none", color: "#ffffff" }} {...props} ><i className="fas fa-save"></i>&nbsp;บันทึก</Button>
    )
}
export function ButtonDelete(props) {
    return (
        <Button type="primary" shape="round" {...props} danger><i className="fas fa-trash-alt"></i>&nbsp;ลบ</Button>
    )
}
export function ButtonSearch(props) {
    return (
        <Button shape="circle" style={{ background: "#000000", border: "none", color: "#ffffff" }} {...props} ><i className="fas fa-search"></i></Button>
    )
}
export function ButtonMainSearch(props) {
    return (
        <Button shape="round" style={{ background: "#000000", border: "none", color: "#ffffff" }} {...props} ><i className="fas fa-search"></i></Button>
    )
}

export function ButtonExcel(props) {
    return (
        <Button size="sm" shape="round" style={{ background: "#228B22", border: "none", color: "#ffffff" }} {...props} ><i className="far fa-file-excel"></i>&nbsp;Excel</Button>
    )
}
export function ButtonPDF(props) {
    return (
        <Button size="sm" shape="round" style={{ background: "#f5222d", border: "none", color: "#ffffff" }} {...props} ><i className="far fa-file-pdf"></i>&nbsp;PDF</Button>
    )
}

export function ButtonClear(props) {
    return (
        <Button shape="round" style={{ background: "#99A3A4", border: "none", color: "#ffffff" }} {...props} ><i className="fas fa-undo"></i>&nbsp;ล้างข้อมูล</Button>
    )
}

export function ButtonSearchExcel(props) {
    return (
        <Button shape="round" style={{ background: "#000000", border: "none", color: "#ffffff" }} {...props} ><i className="fas fa-search"></i>&nbsp;ค้นหา</Button>
    )
}
export function ButtonConfirm(props) {
    return (
        <Button shape="round" type="primary" {...props} ><i className="fas fa-check"></i>&nbsp;Confirm</Button>
    )
}

export function ButtonCancel(props) {
    return (
        <Button shape="round" style={{ background: "#808080", border: "none", color: "#ffffff" }} {...props} ><i className="fas fa-times"></i>&nbsp;Cancel</Button>
    )
}

export function ButtonSaveDraft(props) {
    return (
        <Button shape="round" style={{ background: "#17A2B8", border: "none", color: "#ffffff" }} {...props} ><i className="fas fa-edit"></i>&nbsp;Save Draft</Button>
    )
}

export function ButtonSubmit(props) {
    return (
        <Button shape="round" style={{ background: "#007BFF", border: "none", color: "#ffffff" }} {...props} ><i className="fas fa-save"></i>&nbsp;Submit</Button>
    )
}

export function ButtonResetPassword(props) {
    return (
        <Button size="sm" shape="round" style={{ background: "#ffc107", border: "none", color: "#212529" }} {...props} ><i className="fas fa-sync-alt"></i>&nbsp;เปลี่ยนรหัสผ่าน</Button>
    )
}
export function ButtonRespond(props) {
    return (
        <Button size="sm" shape="round" style={{ background: "#60cc28", border: "none", color: "#ffffff" }} {...props} ><i className="fas fa-paper-plane"></i>&nbsp;ตอบกลับ</Button>
    )
}



