
import React, { useState, useEffect, Fragment, CSSProperties } from 'react'
import { Table, Radio, Divider, Input, Space, Button, Pagination, Popover, Empty } from 'antd';
import { ButtonDelete_Table } from "../Systems/button_all";
import { FormItemPrefixContext } from 'antd/lib/form/context';

const AntdTable = (props: { dataSource: [], columns: any, rowSelection?: any, total?: number, onPageChange?: any, currentPage?: number, onChange?: any, onClickDeleteData?: any, onShowDelete?: boolean, onDisableDelete?: boolean, scroll?: any, onShowPagination?: boolean, pageSize?: number }) => {
    const [isDisable, setIsDisable] = React.useState(false);
    
    React.useMemo(() => {
        if (props.rowSelection && props.rowSelection.selectedRowKeys !== undefined) {
            setIsDisable(props.rowSelection ? props.rowSelection.selectedRowKeys !== undefined && props.rowSelection.selectedRowKeys.length > 0 ? false : true : true);
        }
        else if (props.rowSelection && props.rowSelection.selectedRowKeysP !== undefined) {
            setIsDisable(props.rowSelection ? props.rowSelection.selectedRowKeysP !== undefined && props.rowSelection.selectedRowKeysP.length > 0 ? false : true : true);
        }
        
    }, [props.rowSelection]);
    
    return (
        <Fragment>
            <Table
                size="small"
                dataSource={props.dataSource}
                columns={props.columns}
                rowSelection={props.rowSelection}
                pagination={false}
                scroll={props.scroll}
                onChange={props.onChange}
                locale={{ cancelSort: "คลิกเพื่อยกเลิกการเรียง", triggerAsc: 'คลิกเพื่อเรียงจากน้อยไปมาก', triggerDesc: 'คลิกเพื่อเรียงจากมากไปน้อย', emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="ไม่พบข้อมูล" /> }}

            />
            <div className="row mt-3">
                {
                    props.onShowDelete === false ? <Fragment></Fragment> :
                        <div className="col-auto" style={{ marginLeft: "10px" }}>
                            {/* <ButtonDelete_Table onClick={props.onClickDeleteData} disabled={props.onDisableDelete}></ButtonDelete_Table> */}
                            <ButtonDelete_Table onClick={props.onClickDeleteData} disabled={isDisable}></ButtonDelete_Table>
                        </div>
                }
                {
                    props.onShowPagination === false ? <Fragment></Fragment> :
                        <div className="col text-right">
                            <Pagination
                                total={props.total}
                                showSizeChanger
                                // showQuickJumper
                                onChange={props.onPageChange}
                                current={props.currentPage}
                                showTotal={total => `จำนวน ${total} รายการ`}
                                onShowSizeChange={e => {

                                }}
                                pageSize={props.pageSize}
                                locale={{ items_per_page: "", alignment: 'center' }}
                            />
                        </div>
                }
            </div>
        </Fragment>
    )

}

export default AntdTable;