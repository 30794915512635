import * as React from 'react';
import Main_Content_Detail from '../main-content/content-detail'

const Forest_Story_Detail = (props) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Main_Content_Detail isShowCover={true} adminMenu={"2"} />
    );
}
export default Forest_Story_Detail;