import * as React from "react";
import Flowpaper from "../../Flowpaper";
import { parse } from "query-string";
import { Button, Col, Row, Tooltip} from "antd";
import { useSelector, useDispatch } from 'react-redux';
import {
    thailanguage,
    englishlanguage,
    currentlanguage,
} from '../app/counterSlice';


const Main_Ebook = () => {
    const { path, Mode, isFull } = parse(window.location.search);
    //var sPath = path ? path : "";

    const translate = useSelector(currentlanguage);
    const dispatch = useDispatch();
    
    const [isFullScreen, setIsFullScreen] = React.useState(false);

    return (
        <React.Fragment>
            <Flowpaper  PDFFile={path} Mode={Mode}/>
            <div style={{position: "absolute" , right: "4em", top: "-5px", zIndex: 99}}>
                <br />
                {
                    isFull === "1" ?
                    <React.Fragment>
                        <Tooltip 
                            style={{ width : "400px" }}
                            title={translate.ExitFullScreen}
                        >
                            <Button size="small" shape="round" style={{ background: "#00c3da", border: "none", color: "#000000" }}
                                onClick={() => {
                                    window.close();
                                }}
                            >
                                {/* <i className="icofont-resize"></i> */}
                                <i className="fas fa-compress"></i>
                                
                            </Button>
                        </Tooltip>
                        &nbsp;&nbsp;
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Tooltip 
                            style={{ width : "400px" }}
                            title={translate.FullScreen}
                        >
                            <Button size="small" shape="round" style={{ background: "#00c3da", border: "none", color: "#000000" }}
                                onClick={() => {
                                    window.open("/content-ebook?path=" + path + "&Mode=" + Mode + "&isFull=1", "_blank")
                                    //https://localhost:44364/?path=UploadFile/Content/Content_391/0712202114115339.pdf&Mode=EN#page=1&isFull=1
                                }}
                            >
                                {/* <i className="icofont-resize"></i> */}
                                <i className="fas fa-expand"></i>
                            </Button>
                        </Tooltip>
                        &nbsp;&nbsp;
                    </React.Fragment>
                }
                <Tooltip 
                    style={{ width : "400px" }}
                    title={translate.Download}
                >
                    <a href={path ? path.toString() : ""} download="">
                        <Button size="small" shape="round" style={{ background: "#ffc107", border: "none", color: "#000000" }}>
                            <i className="icofont-download"></i>
                        </Button>
                    </a>
                </Tooltip>
            </div>
        </React.Fragment>
    );
};
export default Main_Ebook;
