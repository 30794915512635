import * as React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../main-home/home.css";
import "../main-content/content.css";
import { AxiosGetJson } from "../Service/Config/AxiosMethod";
import { parse } from "query-string";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from 'react-redux';
import {
    thailanguage,
    englishlanguage,
    currentlanguage,
} from '../app/counterSlice';
import { Translate } from "react-bootstrap-icons";

const Main_Search = () => {
    const { key } = parse(window.location.search);
    const [sFilePath, setFilePath] = React.useState("");
    const [contentDetail, setContentDetail] = React.useState([] as any);
    const [showType, setShowType] = React.useState(null as any);
    const history = useHistory();
    const translate = useSelector(currentlanguage);
    const dispatch = useDispatch();

    const SetBackground = async () => {
        let result: any = await AxiosGetJson("api/AdminInfo/GetBackground");
        setFilePath(result.sFilePath);
    };
    const GetSearchDetail = async (key,showType, TranslatType) => {
        let result: any = await AxiosGetJson(
            "api/AdminInfo/GetSearchDetail?key=" + key + "&showType=" + showType + "&Mode=" + TranslatType
        );
        setContentDetail(result);
    };

    const onOpenRelated = (x) => {
        let sTypeComponent = "";
        if(x.nHeadLv0 === "1"){
            sTypeComponent = "/news-detail";
        }
        if(x.nHeadLv0 === "2"){
            sTypeComponent = "/forest-story-detail";
        }
        if(x.nHeadLv0 === "3"){
            sTypeComponent = "/learning-center-detail";
        }
        if(x.nHeadLv0 === "4"){
            sTypeComponent = "/hl-project-detail";
        }
        if(x.nHeadLv0 === "5"){
            if(x.nHeadLv1 === "11"){
                sTypeComponent = "/knowledge-detail-plant";
            }
            else if(x.nHeadLv1 === "12"){
                sTypeComponent = "/knowledge-detail-animal";
            }
            else{
                sTypeComponent = "/knowledge-detail";
            }
        }
        let sPath = `${sTypeComponent}?id=${x.sUrl}&ref=search`;
        return history.push(sPath);
    }

    React.useEffect(() => {
        var { key, showType } = parse(window.location.search);
        setShowType(showType);
        SetBackground();
        GetSearchDetail(key,showType, translate.TranslatType);
        window.scrollTo(0, 0);
    }, [key, translate]);

    return (
        <React.Fragment>
            <div id="PAGE_HOME" style={{ backgroundImage: "url(" + sFilePath + ")" }}>
                <div className="home-news">
                    <div className="news-panel">
                        <div className="panel-head">
                            <div className="panel-title">
                                {showType == "All" ? translate.AllContent :  translate.Keyword +  " : " + key}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="search">
                    <div className="search-content">
                        <div className="content">
                        
                        {
                            contentDetail.length > 0 ?
                                <>
                                    {
                                        contentDetail.map((x, ix) => {
                                            return (
                                                <div key={"ref_" + ix} className="col-xl-12 mt-4">
                                                    <div className="ibox ibox-2" style={{cursor:"pointer"}} onClick={() => onOpenRelated(x)}>
                                                        <div className="ibox-thumb">
                                                            <div
                                                                className="box-cover"
                                                                style={{ backgroundImage: "url(" + x.sFilePath + ")" }}>
                                                            </div>
                                                        </div>
                                                        <div className="ibox-info">
                                                            <div className="box-title" >
                                                                <span>{x.sSubject}</span>
                                                            </div>
                                                            <ul className="box-links">
                                                                <li>
                                                                    <div>
                                                                        {" "}
                                                                        <i className="icofont-ui-calendar"></i> {x.sDate}
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )

                                        })
                                    }
                                </>
                                :
                                null
                        }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
};
export default Main_Search;
